import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Form, Select } from 'antd';
import Input from 'antd/es/input/Input';

import { EFileExtensions } from '@aduvi/types';

export const FileSettings = () => {
  const { t: translate } = useTranslation();

  return (
    <>
      <Form.Item name={'multiple'} valuePropName='checked' initialValue={false} className='mb-10'>
        <Checkbox>{translate('components.customFieldsDrawer.allowMultiple')}</Checkbox>
      </Form.Item>

      <Form.Item
        className='mt-20'
        name={['settings', 'allowed_extensions']}
        label={translate('components.customFieldsDrawer.allowedExtensions')}
        rules={[{ required: true }]}>
        <Select
          mode='multiple'
          placeholder={translate('components.customFieldsDrawer.extensions')}
          options={Object.values(EFileExtensions)?.map((extension) => ({ label: extension, value: extension }))}
        />
      </Form.Item>
      <Form.Item
        name={['settings', 'maximum_upload_size']}
        label={translate('components.customFieldsDrawer.uploadSizeLabel')}
        rules={[{ required: true }]}>
        <Input placeholder={translate('components.customFieldsDrawer.uploadSize')} />
      </Form.Item>
    </>
  );
};
