import { IPaginatedResponse, IPaginationParams } from './common';

export enum ECouponDrawerTabs {
  DETAILS = 'details',
  LIMIT_VISIBILITY = 'limitVisibility',
}
export enum EDiscountType {
  PERCENTAGE_BASED = 'PERCENTAGE_BASED',
  FIXED_AMOUNT = 'FIXED_AMOUNT',
}

export interface IDiscountSettings {
  id: string;
  multiple_service_discount: string;
  site_wide_discount: boolean;
  sale_price: number;
  sale_end_date: string;
}
export interface ICoupon {
  id: string;
  name: string;
  coupon_code: string;
  discount_type: EDiscountType;
  discount_amount: number;
  limit_number: boolean;
  max_redemption: number;
  limit_visibility: TransformedLimitVisibility;
  apply_discount: boolean;
}

export interface IDiscountsState {
  coupons?: IPaginatedResponse<ICoupon>;
  selectedCoupon?: ICoupon;
  discountSettings?: IDiscountSettings;
  loading: boolean;
  creating: boolean;
  updating: boolean;
}

export interface IGetCouponsPayload {
  business_id: string;
  params: IPaginationParams;
}

export interface LimitVisibilityCondition {
  entity: string;
  operator: string;
  value?: string;
  date?: string;
  start_date?: string;
  end_date?: string;
}

export interface LimitVisibilityConditionSet {
  match: string;
  conditions: LimitVisibilityCondition[];
  condition_set_operator?: string;
}
export interface TransformedLimitVisibility {
  [key: string]: unknown;
}
