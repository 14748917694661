import React from 'react';
import { WidgetType } from 'views/dashboard/data';

import { IBusinessUser, IManagementField, IPricing, IProduct, IService } from '@aduvi/types';

export enum ECurrencySignPosition {
  BEFORE = 'before',
  AFTER = 'after',
}

export enum ELoadingState {
  UNINITIALIZED = 0,
  LOADING,
  SUCCESS,
  FAILED,
}

export enum ESearchApps {
  EVERYTHING = 'everything',
  BUSINESS_JOB_FIELDS = 'jobs',
  BUSINESS_CONTACT_FIELDS = 'contacts',
  BUSINESS_VENUE_FIELDS = 'venues',
  BUSINESS_EVENT_FIELDS = 'events',
  BUSINESS_EXHIBITOR_FIELDS = 'exhibitors',
  BUSINESS_ORDER_FIELDS = 'orders',
  PRODUCTS = 'products',
  SERVICES = 'services',
  USERS = 'users',
}

export enum EOrderType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum EPictureLibrary {
  UNSPLASH = 'Unsplash',
  PIXABAY = 'Pixabay',
}

export interface ITheme {
  id?: string;
  name?: string;
  is_active?: boolean;
  logo?: string;
  icon?: string;
  dark_mode_enabled: boolean;
  body_style: {
    body_bg?: string;
    body_headings?: string;
    body_row_bg?: string;
    body_row_text?: string;
    body_text?: string;
  };
  menu_style: {
    menu_active_bg?: string;
    menu_active_text?: string;
    menu_bg?: string;
    menu_shadow?: string;
    menu_text?: string;
  };
  button_style: {
    button_bg?: string;
    button_text?: string;
  };
  dashboard: {
    dashboard_afternoon?: string;
    dashboard_morning?: string;
    dashboard_night?: string;
  };
}

export interface ILayoutResponse {
  id: string;
  trading_name: string;
  legal_name: string;
  registered_address: string;
  terms_and_conditions_url?: string;
  privacy_policy_url?: string;
  platform_domain: string;
  welcome_url: string;
  meta_description?: string;
  introduction_to_jobs: string;
  introduction_to_assets: string;
  pricing: IPricing[];
  themes: ITheme[];
  terminology: {
    unconfirmed_jobs: [];
    confirmed_jobs: [];
    welcome: string;
  }[];
  logo: string;
  icon: string;
  auth_page_img: string;
  onboarding_page_img: string;
  platform_currency_id: string;
  partner_apps: IApp[];
  created_at: string;
  updated_at: string;
  deleted_at: string;
}
export interface IStatistics {
  number_of_leads: {
    current_period_count: number;
    previous_period_count: number;
    growth_percentage: number;
  };
  conversion_rate: {
    current_period_percentage: number;
    previous_period_percentage: number;
    growth_percentage: number;
  };
  number_of_customers: {
    current_period_count: number;
    previous_period_count: number;
    growth_percentage: number;
  };
  average_transactions_per_customer: {
    current_period_avg: number;
    previous_period_avg: number;
    growth_percentage: number;
  };
}

export interface ICommonState {
  countries: {
    countries: ICountry[];
    loading: boolean;
  };
  currencies: {
    currencies: ICurrency[];
    loading: boolean;
  };
  search: {
    loading: boolean;
    data?: IGlobalSearchResponse;
  };
  songSearch: {
    loading: boolean;
    data?: ISongSearchResponse[];
  };
  apps: {
    data: IApp[];
    selectedApp?: IApp;
    installing: boolean;
  };
  appCategories: {
    loading: boolean;
    data: IAppCategory[];
    selectedCategory?: IApp[];
  };
  avatar: {
    data?: IAvatarResponse;
    loading: boolean;
  };
  palette?: ILayoutResponse;
  layout?: ILayoutResponse;
  activeTheme?: IActiveTheme;
  partnerLayout?: IPartnerLayout;
  themes: ITheme[];
  terminology?: { [key: string]: string };
  uploading: boolean;
  pricing: IPricing[];
  dashboard: {
    data?: IDashboard[];
    loading: boolean;
  };
  chat: {
    data: IChatLog[];
    creating: boolean;
  };
  statistics: {
    data?: IStatistics;
    loading: boolean;
  };
  microsoftCalendarEvents: {
    data: {
      title: string;
      start: Date;
      end: Date;
      bodyPreview: string;
    }[];
    loading: boolean;
  };
}

export interface ICountry {
  id: string;
  name: string;
  code: string;
  created_at: Date | string;
  updated_at: Date | string;
  deleted_at: Date | string | null;
}

export interface ICurrency {
  id: string;
  name: string;
  code: string;
  format: string;
  rates: {
    [currencyCode: string]: number;
  };
  created_at: Date | string;
  updated_at: Date | string;
  deleted_at: Date | string | null;
}

export interface INotificationPayload {
  title?: string;
  description?: string;
}

export interface IResponse<T> {
  data: T;
  message?: string;
}

interface IDashboard {
  id: number;
  columns: number;
  rows: number;
  name: string;
  index: string;
}

interface ILink {
  active: boolean;
  label: string;
  url: string;
}

export interface IPaginatedResponse<T> {
  current_page: number;
  data: T[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: ILink[];
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: string;
  to: number;
  total: number;
}

export interface IPaginationParams {
  page: number;
  size: number;
  search?: string;
  order?: EOrderType;
}

export interface IUploadImageResponse {
  file_name: string;
}

export interface IGlobalSearchPayload {
  businessId: string;
  params: { search: string; apps?: string };
}

export interface ISongSearchPayload {
  params: { search: string; limit?: number };
}

export interface ISongSearchResponse {
  artist: string;
  track: string;
  image: string;
  preview?: string;
  release_date: string;
  popularity: number;
  explicit: boolean;
}

export interface IGlobalSearchResponse {
  users?: IBusinessUser[];
  job_management_fields?: IManagementField[];
  services?: IService[];
  products?: IProduct[];
}

export type ISearchResult = IBusinessUser & IManagementField & IService & IProduct;

export interface ISearchResultMapper {
  title: string;
  props: string[];
  icon: React.ReactNode;
  redirectUri: string;
  params?: { [key: string]: string };
  uniqueIdentifierKey?: string;
}

export interface IPartnerLayout {
  welcomeText: string;
  legalName: string;
  authImage: string;
  onboardingImage: string;
  logo: string;
}

export interface IActiveTheme {
  activeThemeId?: string;
  buttonBg: string;
  buttonBgActive: string;
  buttonBgHover: string;
  buttonText: string;
  bodyBg?: string;
  bodyText?: string;
  bodyHeadings?: string;
  bodyRowBg?: string;
  bodyRowText?: string;
  menuBg?: string;
  menuText?: string;
  menuActiveBg?: string;
  menuActiveText?: string;
  menuItemText?: string;
  globalFieldText?: string;
  switchBg?: string;
  switchBgHover?: string;
  calendarBg?: string;
  radioBg?: string;
  radioBgHover?: string;
  tabBg?: string;
  tabBgHover?: string;
  checkboxBg?: string;
  checkboxBgHover?: string;
  spinBg?: string;
  menuShadow?: string;
  titleText?: string;
  productTypeBg?: string;
  dashboardBg: string;
  darkModeEnabled: boolean;
  logo: string;
  icon: string;
}

export interface IApp {
  app_id: string;
  key: string;
  content: string;
  created_at: string;
  deleted_at: string;
  category_id: string;
  icon: string;
  id: string;
  image: string;
  is_active: boolean;
  name: string;
  summary: string;
  updated_at: string;
  author: string;
  price: string;
  banners: string[];
  pivot: IApp;
}

export interface IAppCategory {
  id: string;
  name?: string;
  icon?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

export interface IInstallAppPayload {
  business_id: string;
  app_id?: string;
}

export interface IInstallApp {
  business_id?: string;
  app_id?: string;
  monthly_price?: string;
  yearly_price?: string;
  id?: string;
  updated_at?: string;
  created_at?: string;
}

export interface IChat {
  model?: string;
  messages?: IMessage[];
  choices: {
    index: number;
    message: {
      role: string;
      content: string;
    };
  }[];
}
export interface IMessage {
  role: string;
  content?: string;
}

export interface IChatMessage {
  id: string;
  content: string;
  created_at: Date;
  updated_at: Date;
  message_id?: string;
  business_channel_id: string;
  author_id: string;
  contact_author_id: string;
  attachments: string[];
  author_type: EAuthorType;
}

export interface ICreateMessagePayload {
  content: string;
  author_id: string;
  attachments: string[];
}

export interface IChatLog {
  type?: string;
  message?: string;
}

export interface IAvatarResponse {
  Valid: boolean;
  Success: boolean;
  Name?: string;
  Image?: string;
}

export interface ICalendarEventResponse {
  'odata.context': string;
  value: {
    subject: string;
    start: {
      dateTime: string;
    };
    end: {
      dateTime: string;
    };
    bodyPreview: string;
  }[];
}

export interface IGetMicrosoftCalendarEventsPayload {
  accessToken: string;
  startDate: string;
  endDate: string;
}

export interface IGetUnsplashPicturesParams {
  page?: number;
  per_page?: number;
  query?: string;
}

export interface IUnsplashPicture {
  id: string;
  description?: string;
  urls: {
    small: string;
    regular: string;
    full: string;
  };
  links: {
    self: string;
    html: string;
  };
  user: {
    name: string;
    username: string;
  };
}

export interface IPixabayResponse {
  total: number;
  totalHits: number;
  hits: IPixabayPicture[];
}

export interface IPixabayPicture {
  id: number;
  pageURL: string;
  type: string;
  tags: string;
  previewURL: string;
  previewWidth: number;
  previewHeight: number;
  webformatURL: string;
  webformatWidth: number;
  webformatHeight: number;
  largeImageURL: string;
  fullHDURL: string;
  imageURL: string;
  imageWidth: number;
  imageHeight: number;
  imageSize: number;
  views: number;
  downloads: number;
  likes: number;
  comments: number;
  user_id: number;
  user: string;
  userImageURL: string;
}

export interface IPixabaySearchParams {
  key?: string;
  q?: string;
  lang?: string;
  image_type?: string;
  orientation?: string;
  category?: string;
  min_width?: number;
  min_height?: number;
  colors?: string;
  editors_choice?: boolean;
  safesearch?: boolean;
  order?: string;
  page?: number;
  per_page?: number;
  callback?: string;
  pretty?: boolean;
}

export enum EAuthorType {
  USER = 'user',
  SYSTEM = 'system',
}

export interface IEditUserDashboardLayoutPayload {
  userId: string;
  businessId: string;
  body: {
    dashboard_layout: WidgetType[];
  };
}
