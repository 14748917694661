import { RefObject } from 'react';
import { Editor } from 'ckeditor5';

import { clientSendMessage } from 'store/features/conversation-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const useEditorHandler = (editorInstance: Editor | null, messageContainerRef?: RefObject<HTMLDivElement>) => {
  const dispatch = useAppDispatch();
  const { selectedClientPortal } = useAppSelector((state) => state.clientPortal);
  const { user } = useAppSelector((state) => state.clientPortalAuth);

  const scrollToBottom = () => {
    if (messageContainerRef?.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  };

  const handleSubmit = (attachments?: string[]) => {
    const content = editorInstance?.getData();

    if (!content?.trim() || !selectedClientPortal?.business_id || !user?.user?.id || !selectedClientPortal?.id || !user?.jobs?.selectedJob?.id) {
      return;
    }

    dispatch(
      clientSendMessage({
        businessId: selectedClientPortal?.business_id,
        businessClientPortalId: selectedClientPortal?.id,
        entityId: user?.jobs?.selectedJob?.id,
        payload: {
          attachments: attachments || [],
          content: editorInstance?.getData()!,
          author_id: user?.user?.id,
        },
      }),
    );

    setTimeout(() => {
      scrollToBottom();
      editorInstance?.setData('');

      if (messageContainerRef?.current) {
        messageContainerRef.current.parentElement!.scrollTop = messageContainerRef?.current?.parentElement?.offsetHeight!;
      }
    }, 50);
  };

  const handleEditorKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  return { handleEditorKeyDown, handleSubmit, scrollToBottom };
};
