import axios from '@aduvi/config/axios';
import { IPaginatedResponse, IResponse } from '@aduvi/types';
import {
  IAutomation,
  IAutomationHistory,
  IGetAutomationHistoryPayload,
  IUpsertAutomation,
  IUpsertAutomationNodesPayload,
} from '@aduvi/types/automation';

export const getAutomations = async (businessId: string): Promise<IPaginatedResponse<IAutomation>> => {
  return await axios.get(`/businesses/${businessId}/automations`);
};

export const getAutomation = async (businessId: string, automationId: string): Promise<IResponse<IAutomation>> => {
  return await axios.get(`/businesses/${businessId}/automations/${automationId}`);
};

export const getAutomationHistory = async (payload: IGetAutomationHistoryPayload): Promise<IPaginatedResponse<IAutomationHistory>> => {
  return await axios.get(`/businesses/${payload.businessId}/automations/${payload.automationId}/histories`, { params: payload.params });
};

export const getAutomationQueue = async (payload: IGetAutomationHistoryPayload): Promise<IPaginatedResponse<IAutomationHistory>> => {
  return await axios.get(`/businesses/${payload.businessId}/automations/${payload.automationId}/histories`, {
    params: { ...payload.params, status: 'ACTIVE' },
  });
};

export const createAutomation = async (payload: IUpsertAutomation): Promise<IResponse<IAutomation>> => {
  return await axios.post(`/businesses/${payload.businessId}/automations`, payload.body);
};

export const editAutomation = async (payload: IUpsertAutomation): Promise<IResponse<IAutomation>> => {
  return await axios.put(`/businesses/${payload.businessId}/automations/${payload.automationId}`, payload.body);
};

export const duplicateAutomation = async (businessId: string, automationId: string): Promise<IResponse<IAutomation>> => {
  return await axios.post(`/businesses/${businessId}/automations/${automationId}/clone`);
};

export const deleteAutomation = async (businessId: string, automationId: string) => {
  return await axios.delete(`/businesses/${businessId}/automations/${automationId}`);
};

export const createAutomationNodes = async (payload: IUpsertAutomationNodesPayload) => {
  return await axios.post(`/businesses/${payload.businessId}/automations/${payload.automationId}/automation-nodes`, payload.body);
};
