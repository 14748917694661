export enum EWidgetLayout {
  COLUMNS = 1,
  ROWS = 2,
  CARDS = 3,
}

export enum EWidgetColumns {
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
}

export enum EWidgetCards {
  MANUAL = 'manual',
  ANIMATED = 'animated',
}

export enum EWidgetApps {
  FORMS = 'FORMS',
  SERVICES = 'SERVICES',
  USERS_AND_TEAMS = 'USERS_AND_TEAMS',
  BUSINESS = 'BUSINESS',
  PRODUCTS = 'PRODUCTS',
  EVENT_TICKETING = 'EVENT_TICKETING',
}

export enum EWidgetSubApps {
  PACKAGES = 'PACKAGES',
  EXHIBITORS = 'EXHIBITORS',
  AGENDA = 'AGENDA',
}

export enum EContentType {
  TEXT = 'text',
  IMAGE = 'image',
}

export interface IContentBlock {
  type: EContentType;
  value?: string;
  url?: string;
}

export interface IWigetStyle {
  background_color: string;
  background_image: string;
  background_mode: 'colour' | 'image';
  widget_background: string;
  font_family: string;
  primary_font_color: string;
  secondary_font_color: string;
  button_font?: string;
  button_background?: string;
  layout: EWidgetLayout;
  visible_fields: string[];
  columns?: number;
  cards?: EWidgetCards;
  limit_results?: string;
  results_per_page?: number;
  price_display?: string;
  button_action_form?: string;
  form_name?: string;
  display_mode?: string;
  email_phone_as_icons?: boolean;
  group_by_team?: boolean;
  selected_teams_or_users?: string[];
  selected_service?: string;
  sorting_option?: string;
  button_action?: string;
  icon_type?: string;
  product_category?: string;
  sort_products?: string;
  entity_type_event_id?: string;
  button_text?: string;
  widget_title?: string;
  allow_title?: boolean;
  label_font?: string;
}

export interface IWidget {
  business_id?: string;
  id: string;
  title: string;
  apps: EWidgetApps;
  sub_app?: EWidgetSubApps;
  description: string;
  widget_style: IWigetStyle[];
  is_template?: boolean;
  remove_bg_embed?: string;
  embed_code?: string;
  created_at?: string;
  updated_at?: string;
  form_id?: string;
  entity_type_id?: string;
}

export interface IWidgetState {
  widgets: IWidget[];
  selectedWidget?: IWidget;
  loading: boolean;
  loadingWidget: boolean;
  creating: boolean;
  updating: boolean;
}

export interface ICreateWidget {
  business_id: string;
  title: string;
  apps: EWidgetApps;
  sub_app?: EWidgetSubApps;
  description: string;
  widget_style: IWigetStyle[];
  remove_bg_embed?: string;
  embed_code?: string;
  form_id?: string;
  entity_type_id?: string;
}

export interface IUpdateWidget extends ICreateWidget {
  id: string;
}

export interface IDeleteWidgetPayload {
  business_id: string;
  widget_id: string;
}
