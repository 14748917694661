import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Card, Spin, Table, Tag, Typography } from 'antd';
import '../EntityWidget.scss';

import { TaskWarningIcon } from '@aduvi/components/Entity/components/drawer-tabs/tasks/@components/helper';
import { useBusiness } from '@aduvi/hooks';
import { ICustomField, IEntityWithFields } from '@aduvi/types/entity';

import { useAppSelector } from 'store/hooks';
import { getEntities } from 'store/services/entity.service';

export const TasksWidget = () => {
  const { t: translate } = useTranslation();

  const selectedBusiness = useBusiness();
  const { entityTypes } = useAppSelector((state) => state.entity);

  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasks] = useState<IEntityWithFields[]>([]);

  useEffect(() => {
    const fetchEntities = async () => {
      if (!selectedBusiness?.id || !entityTypes?.data?.TASK?.id) return;
      setIsLoading(true);
      try {
        const response = await getEntities(selectedBusiness.id, entityTypes.data.TASK.id);
        setTasks(response.data.entities);
      } finally {
        setIsLoading(false);
      }
    };
    fetchEntities();
  }, [selectedBusiness?.id, entityTypes?.data]);

  return (
    <Card
      className='entity-card'
      headStyle={{ backgroundColor: '#F8F8F8', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
      title={
        <div className=' p-10'>
          <Typography.Title level={5}>Tasks</Typography.Title>
        </div>
      }>
      <div className='entity-list-wrapper'>
        <Spin spinning={isLoading}>
          {!isLoading && tasks?.length > 0 ? (
            <>
              <Table
                scroll={{ x: 350 }}
                dataSource={tasks}
                pagination={false}
                columns={[
                  {
                    title: translate('dashboard.widgets.tasks.titleColumn'),
                    dataIndex: 'custom_fields',
                    key: 'title',
                    render: (fields: ICustomField[]) => (
                      <Typography.Paragraph className='mb-0 fs-12'>
                        {fields?.find((field) => field?.title === 'Title')?.field_data?.[0]?.value}
                      </Typography.Paragraph>
                    ),
                  },
                  {
                    title: translate('dashboard.widgets.tasks.statusColumn'),
                    dataIndex: 'custom_fields',
                    key: 'status',
                    render: (fields: ICustomField[]) => (
                      <Typography.Paragraph className='mb-0 fs-14'>
                        {fields
                          ?.find((field) => field?.title === 'Status')
                          ?.field_data?.map((option) => (
                            <Tag key={option.value} color={option?.color}>
                              {option?.value}
                            </Tag>
                          ))}
                      </Typography.Paragraph>
                    ),
                  },
                  {
                    title: translate('dashboard.widgets.tasks.dueDateColumn'),
                    dataIndex: 'custom_fields',
                    key: 'dueDate',
                    render: (fields: ICustomField[]) => (
                      <TaskWarningIcon dueDate={dayjs(fields?.find((field) => field?.title === 'Due Date')?.field_data?.[0]?.value)} />
                    ),
                  },
                ]}
              />
            </>
          ) : (
            !isLoading && <Typography.Paragraph type='secondary'>{translate('dashboard.widgets.noEntity', { type: 'tasks' })}</Typography.Paragraph>
          )}
        </Spin>
      </div>
    </Card>
  );
};
