import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EllipsisOutlined, UserOutlined } from '@ant-design/icons';

import { Avatar, Card, Col, Dropdown, Row, Typography } from 'antd';

import { Button } from '@aduvi/components/Button/Button';
import { useBusiness, useUser } from '@aduvi/hooks';
import { useRelativeTime } from '@aduvi/hooks/useLocalRelativeTime';
import { IChatMessage } from '@aduvi/types';
import { IChannelMember } from '@aduvi/types/conversation';
import { formatDateTimeForMessages } from '@aduvi/utils/helper';

import { deleteMessage, setSelectedMessage } from 'store/features/conversation-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { FileList } from '../file-list/FileList';

const { Text } = Typography;

interface IProps {
  message: IChatMessage;
  channelMembers: IChannelMember[];
  isReplySection?: boolean;

  setShowThreadDrawer?: (param: boolean) => void;
}

export const SingleConversationChat = ({ message, setShowThreadDrawer, channelMembers, isReplySection = false }: IProps) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();
  const currentUser = useUser();
  const formatRelativeTime = useRelativeTime();
  const { selectedChannel, messages } = useAppSelector((state) => state.conversation);

  const messageOwner = useMemo(() => {
    return channelMembers?.find((user) => user.id === message.author_id || user.id === message.contact_author_id);
  }, [channelMembers, message.author_id]);

  const messageReplies = useMemo(() => {
    if (isReplySection) return [];

    return messages?.data?.filter((reply) => reply.message_id === message.id);
  }, [messages, message?.id]);

  const uniqueAuthors = useMemo(() => {
    if (isReplySection) return [];

    return Array.from(new Set(messageReplies?.map((message) => message.author_id)));
  }, [messageReplies, message?.author_id]);

  return (
    <Col className='message-block px-20 py-5' span={24} key={message.id}>
      <Row gutter={[8, 0]} wrap={false}>
        <Col>
          <Avatar className='b-radius-10' shape='square' size='large' src={messageOwner?.profile_picture || undefined} icon={<UserOutlined />} />
        </Col>

        <Col className='flex-grow-1'>
          <Row align='middle' gutter={8}>
            <Col>
              <Text className='mb-10' strong>
                {messageOwner?.first_name || messageOwner?.last_name
                  ? `${messageOwner?.first_name || ''} ${messageOwner?.last_name || ''}`.trim()
                  : messageOwner?.email}
              </Text>
            </Col>

            <Col>
              <Text className='fs-10' type='secondary'>
                {`${formatDateTimeForMessages(message?.created_at)}`}
              </Text>
            </Col>

            <Col className='flex-grow-1 text-right'>
              <Dropdown
                trigger={['click']}
                className='message-settings-button d-none'
                overlayClassName='channel-edit-dropdown'
                dropdownRender={() => {
                  return (
                    <Card bodyStyle={{ padding: 0 }}>
                      {!isReplySection ? (
                        <Row>
                          <Button
                            onClick={() => {
                              dispatch(setSelectedMessage(message));
                              setShowThreadDrawer?.(true);
                            }}
                            type='text'
                            className='d-block w-full text-left'>
                            {translate('conversation.reply')}
                          </Button>
                        </Row>
                      ) : (
                        <></>
                      )}
                      {message?.author_id === currentUser?.id ? (
                        <Row>
                          <Button
                            disabled={messages?.removing}
                            onClick={() => {
                              dispatch(
                                deleteMessage({
                                  businessId: selectedBusiness?.id as string,
                                  channelId: selectedChannel?.id as string,
                                  messageId: message?.id,
                                }),
                              );
                            }}
                            type='text'
                            className='d-block w-full text-left'>
                            {translate('conversation.delete')}
                          </Button>
                        </Row>
                      ) : (
                        <></>
                      )}
                    </Card>
                  );
                }}
                placement='bottomLeft'
                arrow>
                <EllipsisOutlined onClick={(e) => e.stopPropagation()} />
              </Dropdown>
            </Col>
          </Row>

          <Row className='w-full'>
            <Text className='message m-0 w-full'>
              <div dangerouslySetInnerHTML={{ __html: message.content }} />
            </Text>
          </Row>
          <Row className='file-list-read-only'>
            <FileList
              fileList={message?.attachments?.map((fileUrl, index) => {
                return {
                  uid: index?.toString(),
                  name: 'image',
                  url: fileUrl,
                  status: 'done',
                };
              })}
            />
          </Row>

          {!isReplySection && uniqueAuthors && uniqueAuthors.length > 0 ? (
            <Col>
              {uniqueAuthors.map((author) => {
                const replyOwner = channelMembers?.find((user) => user?.id === author);

                return (
                  <Avatar
                    src={replyOwner?.profile_picture}
                    key={author}
                    shape='square'
                    className='b-radius-10 mr-5'
                    size='small'
                    icon={<UserOutlined />}
                  />
                );
              })}

              <Text
                strong
                style={{ color: 'blue' }}
                onClick={() => {
                  setShowThreadDrawer?.(true);
                  dispatch(setSelectedMessage(message));
                }}
                className='cursor-pointer'>
                {messageReplies?.length} {translate('conversation.replies')}
              </Text>

              <Text className='ml-10 fs-11' type='secondary'>
                {`${translate('conversation.lastReply')} ${formatRelativeTime(String(messageReplies?.at(-1)?.created_at))}`}
              </Text>
            </Col>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Col>
  );
};
