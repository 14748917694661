import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Spin } from 'antd';

import { getPublicProposal } from 'store/features/proposals-slice';
import { useAppDispatch } from 'store/hooks';

export const FullScreenProposalPreview = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const proposalId = searchParams.get('proposalId');

  const businessId = location.pathname.replace('/pr/', '');

  useEffect(() => {
    if (!businessId || !proposalId) return navigate('/dashboard');

    dispatch(getPublicProposal({ businessId, proposalId }))
      .unwrap()
      .then(({ data }) => {
        localStorage.setItem('preview-html', data?.file_reference?.file_url);
        localStorage.setItem('preview_entity', JSON.stringify(data?.entity.custom_fields));
        navigate('/preview.html');
      })
      .catch(() => {
        navigate('/dashboard');
      });
  }, []);

  if (location.pathname === '/preview.html') {
    window.location.reload();
    return <Spin spinning size='large' fullscreen />;
  }

  return <Spin spinning size='large' fullscreen />;
};
