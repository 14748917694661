import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EllipsisOutlined } from '@ant-design/icons';

import { Card, Col, Dropdown, Empty, Row, Spin, Typography } from 'antd';

import { useBusiness } from '@aduvi/hooks';
import { IEntityForm } from '@aduvi/types/form';

import { getEntityForms } from 'store/features/entity-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { CaptureAdditionalDataModal } from './CaptureAdditionalDataModal';

export const Forms = () => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();

  const {
    entities: {
      selectedEntity,
      forms: { data, loading },
    },
  } = useAppSelector((state) => state.entity);

  const [selectedForm, setSelectedForm] = useState<IEntityForm | undefined>(undefined);

  const redirectUrl = useMemo(() => {
    const brand = selectedBusiness?.brandings?.[0];
    return brand?.vanity_domain || brand?.system_domain || window.location.origin;
  }, [selectedBusiness]);

  const onClickForm = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, form: IEntityForm) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedForm(form);
  };

  const onEditForm = (form: IEntityForm) => {
    setSelectedForm(form);
  };

  const onCopyUrl = (form: IEntityForm) => {
    navigator.clipboard.writeText(`${redirectUrl}/forms/${form?.form_url}/${selectedEntity?.id}`);
  };

  useEffect(() => {
    if (!selectedBusiness?.id || !selectedEntity?.id || !selectedEntity?.entity_type_id) return;

    dispatch(
      getEntityForms({
        businessId: selectedBusiness?.id,
        entityId: selectedEntity?.id,
        entityTypeId: selectedEntity?.entity_type_id,
      }),
    );
  }, [selectedBusiness?.id, selectedEntity?.id, selectedEntity?.entity_type_id]);

  return (
    <Spin className='p-20' spinning={loading}>
      {data?.length ? (
        <Row gutter={[18, 18]} className='p-20'>
          {data.map((item) => (
            <Col key={item.id} span={12}>
              <Card
                hoverable
                cover={
                  <Row
                    justify='center'
                    align='bottom'
                    className='w-full flex'
                    style={{
                      height: 180,
                      background: item.style?.form_style?.[0]?.background_image || item.style?.form_style?.[0]?.background_color,
                    }}>
                    <img alt='image' width={'80%'} height={'80%'} src={require('assets/images/contract-cover.png')} />
                  </Row>
                }
                onClick={(e) => onClickForm(e, item)}>
                <Card.Meta
                  title={item.name}
                  description={
                    <>
                      <Row justify='space-between' align='middle' className='mt-15' onClick={(e) => e.stopPropagation()}>
                        <Typography.Text style={{ color: item.entity_form ? '#52C41A' : '#00000073' }}>
                          {item.entity_form ? translate('forms.forms.completed') : translate('forms.forms.readyToComplete')}
                        </Typography.Text>
                        <Dropdown
                          menu={{
                            items: [
                              {
                                key: '1',
                                label: translate('buttons.edit'),
                                onClick: () => onEditForm(item),
                              },
                              {
                                key: '2',
                                label: translate('assets.eventsDrawer.forms.copyFormURL'),
                                onClick: () => onCopyUrl(item),
                              },
                            ],
                          }}
                          trigger={['click']}
                          overlayStyle={{ borderRadius: '8px' }}>
                          <EllipsisOutlined className='fs-20' />
                        </Dropdown>
                      </Row>
                    </>
                  }
                />
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
      {selectedForm?.id ? <CaptureAdditionalDataModal selectedForm={selectedForm} onCancel={() => setSelectedForm(undefined)} /> : null}
    </Spin>
  );
};
