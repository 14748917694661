import { UIEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusCircleFilled } from '@ant-design/icons';

import { Col, Row, Select, Typography } from 'antd';

import { PERMISSIONS } from '@aduvi/constants';
import { useActiveTheme, useBusiness, useUserPermissionCheck } from '@aduvi/hooks';
import { IProduct } from '@aduvi/types';

import { getProducts, resetProductState } from 'store/features/products-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { ProductsModal } from '../../upsert-quote/@components/ProductsModal';

interface IProps {
  addProduct: (item: IProduct) => void;
  existingProductIds: string[];
}

export const ProductsList = ({ addProduct, existingProductIds }: IProps) => {
  const activeTheme = useActiveTheme();
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();

  const { products, loading } = useAppSelector((state) => state.products);
  const hasPermissionToViewProducts = useUserPermissionCheck(PERMISSIONS.PRODUCTS.VIEW);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleScrollProducts = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      const target = event.target as HTMLDivElement;

      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        if (!products?.current_page || !products.next_page_url) return;
        onGetProducts(products?.current_page + 1, 10);
      }
    },
    [products],
  );

  const onGetProducts = useCallback(
    (page = 1, size = 10) => {
      if (!selectedBusiness?.id || !hasPermissionToViewProducts) return;
      dispatch(getProducts({ business_id: selectedBusiness?.id, params: { page, size } }));
    },
    [selectedBusiness?.id],
  );

  useEffect(() => {
    dispatch(resetProductState());
    onGetProducts(1, 10);
  }, [selectedBusiness?.id]);

  return (
    <>
      <Select
        bordered={false}
        style={{ color: 'red' }}
        value={translate('quotes.addProduct')}
        suffixIcon={null}
        loading={loading}
        className='w-full mt-10 mb-10'
        onPopupScroll={handleScrollProducts}>
        <Select.Option key='create-new-product' disabled style={{ cursor: 'default' }}>
          <Row
            style={{ padding: '8px 0', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              setIsModalVisible(true);
            }}>
            <PlusCircleFilled style={{ color: `${activeTheme?.menuItemText}` }} className='fs-24 mr-10' />
            <Typography.Text style={{ color: `${activeTheme?.menuItemText}` }}>{translate('quotes.createProduct')}</Typography.Text>
          </Row>
        </Select.Option>

        {products?.data
          .filter((item) => !existingProductIds?.includes(item.id))
          .map((item, index) => (
            <Select.Option key={item?.id} style={{ borderBottom: index !== products.data.length - 1 ? '1px dashed #5194D870' : '0px' }}>
              <Row justify={'space-between'} onClick={() => addProduct(item)}>
                <Col>{item.name}</Col>
                <Col>${item.price}</Col>
              </Row>
            </Select.Option>
          ))}
      </Select>

      {isModalVisible && <ProductsModal open={isModalVisible} onClose={() => setIsModalVisible(false)} addProduct={addProduct} />}
    </>
  );
};
