export enum EEmailConnectionType {
  SEND_EMAIL = 'SEND_EMAIL',
}

export enum EConnectionProvider {
  SENDGRID = 'SENDGRID',
  GOOGLE = 'GOOGLE',
  MICROSOFT = 'MICROSOFT',
}

export enum EAccountType {
  PAYMENT = 'payment',
  CONNECTED_ACCOUNT = 'connected-account',
}

export interface IEmailConnection {
  id?: string;
  business_id: string;
  user_id: string;
  name: string;
  provider: EConnectionProvider;
  type: EEmailConnectionType;
  code?: string;
  credentials?: {
    host: string;
    port: number;
    encryption: string;
    username: string;
    password: string;
    from_address: string;
    from_name: string;
    reply_to: string;
    timeout: number;
    local_domain: string;
  };
  meta_data?: {
    description: string;
    email?: string;
    name?: string;
  };
  is_connected: boolean;
}

export interface IConnectedAccountState {
  loading: boolean;
  creating: boolean;
  emails?: IEmailConnection[];
}
