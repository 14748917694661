import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';

import { Button, Col, Empty, Form, Row, Spin, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import './LiveChat.scss';

import { UserAvatar } from '@aduvi/components/UserStatusAvatar/UserStatusAvatar';
import { useBusinessId, useUser } from '@aduvi/hooks';
import Tiptap from '@aduvi/Tiptap/Tiptap';
import { IPartnerUser } from '@aduvi/types';

import { createSupportChatMessage, getSupportChatInfo } from 'store/features/support-chat-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { ChatHistory } from './@components/chat-history/ChatHistory';

export const LiveChat = () => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const [form] = useForm();
  const businessId = useBusinessId();
  const currentUser = useUser();
  const { messages: chat, selectedSupportChat } = useAppSelector((state) => state.supportChat);

  const [isScrolledUp, setIsScrolledUp] = useState(false);
  const [newMessage, setNewMessage] = useState(false);
  const [chatUsers, setChatUsers] = useState<IPartnerUser[]>([]);

  const messageContainerRef = useRef<HTMLDivElement>(null);

  const handleSubmit = () => {
    const message = form.getFieldValue('message_input');
    if (!message?.trim()) return;

    dispatch(
      createSupportChatMessage({
        businessId,
        supportChatId: selectedSupportChat?.id!,
        payload: {
          attachments: [],
          content: message,
          author_id: currentUser?.id!,
        },
      }),
    );

    form.resetFields(['message_input']);
  };

  const handleEditorKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e?.key === 'Enter' && !e?.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const scrollToBottom = useCallback(() => {
    messageContainerRef.current?.scrollTo({
      top: messageContainerRef.current.scrollHeight,
      behavior: 'smooth',
    });
    setNewMessage(false);
  }, [messageContainerRef.current]);

  const onScroll = useCallback(
    debounce(() => {
      const container = messageContainerRef.current;
      if (container && container.scrollTop + container.clientHeight < container.scrollHeight) {
        setIsScrolledUp(true);
      } else {
        setIsScrolledUp(false);
      }
    }, 300),
    [messageContainerRef],
  );

  useEffect(() => {
    const container = messageContainerRef.current;
    if (container) {
      container.addEventListener('scroll', onScroll);
    }

    if (!isScrolledUp) {
      scrollToBottom();
    } else {
      setNewMessage(true);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', onScroll);
      }
    };
  }, [chat]);

  useEffect(() => {
    if (selectedSupportChat) {
      dispatch(getSupportChatInfo({ businessId, supportChatId: selectedSupportChat.id! }));
    }
  }, [selectedSupportChat]);

  useEffect(() => {
    if (selectedSupportChat) {
      const chatUsers = selectedSupportChat.users?.filter((user) => user.id !== currentUser?.id)?.map((user) => user.partner_users?.[0]) || [];
      setChatUsers(chatUsers);
    }
  }, [selectedSupportChat]);

  return (
    <>
      <Row gutter={10}>
        <Col span={8}>
          <ChatHistory />
        </Col>
        <Col span={16}>
          {selectedSupportChat ? (
            <Spin spinning={chat.loading}>
              <Row className='chat-popover w-full' justify={'center'}>
                <div className='chat-container'>
                  <Row className='header-container'>
                    {chatUsers.map((user) => (
                      <Row key={user.id}>
                        <UserAvatar user={user} />
                        <Typography.Text className='ml-5'>{`${user.first_name} ${user.last_name}`}</Typography.Text>
                      </Row>
                    ))}
                  </Row>
                  <Row className='message-container' ref={messageContainerRef}>
                    {chat && chat.data.length ? (
                      <Col span={24}>
                        {chat.data.map((message, index) => (
                          <Row justify={message.author_id === currentUser?.id ? 'end' : 'start'} className='w-full' key={index}>
                            <Row className={`${message.author_id === currentUser?.id ? 'user-response' : 'system-response'} message-content`}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: message.content || '',
                                }}
                              />
                            </Row>
                          </Row>
                        ))}

                        {chat.creating && (
                          <Row justify={'start'} className='w-full'>
                            <Row className='typing'>{translate('layout.chatBot.typing')}</Row>
                          </Row>
                        )}
                      </Col>
                    ) : (
                      <Empty />
                    )}
                    {newMessage && isScrolledUp && (
                      <Button className='new-message-indicator' onClick={scrollToBottom} icon={<MessageOutlined />}>
                        {translate('liveChat.unreadMessage')}
                      </Button>
                    )}
                  </Row>

                  <Form form={form} onFinish={handleSubmit}>
                    <Row className='w-full' align='middle'>
                      <Form.Item
                        name='message_input'
                        className='message-form-item w-full'
                        rules={[{ required: true, message: translate('layout.chatBot.inputValidation') }]}>
                        <Tiptap
                          content={null}
                          placeholder='Message...'
                          isConversationEditor
                          onKeyDown={handleEditorKeyDown}
                          onUpdate={(editor) => form.setFieldValue('message_input', editor.getHTML())}
                          onSendMessage={handleSubmit}
                        />
                      </Form.Item>
                    </Row>
                  </Form>
                </div>
              </Row>
            </Spin>
          ) : (
            <Empty className='mt-30' description={translate('liveChat.emptyChatMessage')} />
          )}
        </Col>
      </Row>
    </>
  );
};
