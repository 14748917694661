import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Card, Col, Row, Spin, Typography } from 'antd';
import '../EntityWidget.scss';

import { useBusiness } from '@aduvi/hooks';
import { IEntityWithFields } from '@aduvi/types/entity';

import { useAppSelector } from 'store/hooks';
import { getEntities } from 'store/services/entity.service';

export const JobsWidget = ({ status }: { status: 'CONFIRMED' | 'UNCONFIRMED' }) => {
  const { t: translate } = useTranslation();

  const selectedBusiness = useBusiness();
  const { entityTypes } = useAppSelector((state) => state.entity);

  const [isLoading, setIsLoading] = useState(false);
  const [leads, setLeads] = useState<IEntityWithFields[]>([]);

  useEffect(() => {
    const fetchEntities = async () => {
      if (!selectedBusiness?.id || !entityTypes?.data?.JOB?.id) return;
      setIsLoading(true);
      try {
        const response = await getEntities(selectedBusiness.id, entityTypes.data.JOB.id, { status });
        setLeads(response.data.entities);
      } finally {
        setIsLoading(false);
      }
    };
    fetchEntities();
  }, [selectedBusiness?.id, entityTypes?.data, status]);

  return (
    <Card
      className='entity-card'
      headStyle={{ backgroundColor: '#F8F8F8', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
      title={
        <div className=' p-10'>
          <Typography.Title level={5}>
            {translate(status === 'CONFIRMED' ? 'dashboard.widgets.bookings.title' : 'dashboard.widgets.leads.title')}
          </Typography.Title>
        </div>
      }>
      <div className='entity-list-wrapper'>
        <Spin spinning={isLoading}>
          {!isLoading && leads?.length > 0 ? (
            <>
              {leads?.map((lead, index) => (
                <Row justify={'space-between'} className='entity py-10' key={index}>
                  <Col>
                    <Typography.Paragraph className='mb-0 fs-14'>
                      {lead?.custom_fields?.find((field) => field?.title === 'Event Name')?.field_data?.[0]?.value}
                    </Typography.Paragraph>
                  </Col>
                  <Col>
                    <Typography.Paragraph className='mb-0 fs-14'>
                      {dayjs(lead?.custom_fields?.find((field) => field?.title === 'Event Date')?.field_data?.[0]?.value).format('DD/MM/YYYY')}
                    </Typography.Paragraph>
                  </Col>
                </Row>
              ))}
            </>
          ) : (
            !isLoading && (
              <Typography.Paragraph type='secondary'>
                {translate('dashboard.widgets.noEntity', { type: status === 'CONFIRMED' ? 'bookings' : 'leads' })}
              </Typography.Paragraph>
            )
          )}
        </Spin>
      </div>
    </Card>
  );
};
