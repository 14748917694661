import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import Fields from 'views/forms/tabs/build/components/Fields';
import { IBookingFormFieldProps } from 'views/forms/tabs/build/helper';

import { Form, Modal, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { mapEditedEntityFieldsToPayload, mapEntityFieldsToForm, mapEntityFieldsToPayload } from '@aduvi/components/Entity/helper';
import { useBusiness } from '@aduvi/hooks';
import { EReferenceEntityType, IEntityField } from '@aduvi/types';
import { IEntityForm } from '@aduvi/types/form';
import { getRepLinkIdFromCookies } from '@aduvi/utils/helper';

import { getEntityFormData, setEntityForms } from 'store/features/entity-slice';
import { captureAdditionalDataForm, getFormById } from 'store/features/form-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface IProps {
  selectedForm: IEntityForm;
  onCancel: () => void;
}

export const CaptureAdditionalDataModal = ({ selectedForm, onCancel }: IProps) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const [form] = useForm();
  const selectedBusiness = useBusiness();

  const { loading, loadingEntity, selectedEntityForm, creating } = useAppSelector((state) => state.forms);
  const {
    entities: {
      selectedEntity,
      forms: { selectedFormData, loading: formLoading, data: entityForms },
    },
  } = useAppSelector((state) => state.entity);

  const [fields, setFields] = useState<IBookingFormFieldProps[]>([]);
  const [entityFields, setEntityFields] = useState<IEntityField[]>([]);

  const detailsFields = useMemo(() => selectedEntity?.custom_fields?.filter((item) => !item.form_only)?.map((item) => item.id), [selectedEntity]);

  const onSubmit = () => {
    form
      .validateFields()
      .then(() => {
        const packageFields = entityFields?.filter((field) => field?.reference_entity_type === EReferenceEntityType.SERVICE);
        const productFields = entityFields?.filter((field) => field?.reference_entity_type === EReferenceEntityType.PRODUCT);

        const filteredCustomFields = form.getFieldValue('custom_fields')?.filter((customFieldObj: { [key: string]: string }) => {
          if (!customFieldObj) return;

          const key = Object.keys(customFieldObj)[0];
          return ![...packageFields, ...productFields].map((field) => field?.id).includes(key);
        });

        const contactCustomFields = fields.find((item) => item?.field_instance?.field?.reference_entity_type === EReferenceEntityType.CONTACT)
          ?.field_instance?.display_settings?.custom_fields;

        const productCustomFields = form.getFieldValue('custom_fields')?.filter((customFieldObj: { [key: string]: string }) => {
          if (!customFieldObj) return;

          const key = Object.keys(customFieldObj)[0];
          return [...productFields].map((field) => field?.id)?.includes(key);
        });

        const packagesCustomFields = form.getFieldValue('custom_fields')?.filter((customFieldObj: { [key: string]: string }) => {
          if (!customFieldObj) return;

          const key = Object.keys(customFieldObj)[0];
          return [...packageFields].map((field) => field?.id)?.includes(key);
        });

        const formUrl = selectedForm?.form_url + '?entity_id=' + selectedEntity?.id;

        dispatch(
          captureAdditionalDataForm({
            formUrl,
            body: {
              custom_fields:
                selectedForm?.entity_form?.status === 'COMPLETED'
                  ? mapEditedEntityFieldsToPayload(
                      filteredCustomFields,
                      selectedEntityForm?.field_instances?.map((item) => item?.field) ?? [],
                      selectedFormData,
                    )
                  : mapEntityFieldsToPayload(filteredCustomFields, entityFields, contactCustomFields),
              product_ids: productCustomFields?.map((customFieldObj: { [key: string]: string[] }) => Object.values(customFieldObj)[0]).flat(),
              package_ids: packagesCustomFields?.map((customFieldObj: { [key: string]: string[] }) => Object.values(customFieldObj)[0]).flat(),
              rep_link_id: getRepLinkIdFromCookies(),
            },
          }),
        )
          .unwrap()
          .then(() => {
            dispatch(
              setEntityForms(
                entityForms.map((item) => {
                  if (item.id === selectedForm.id) {
                    return { ...item, entity_form: { status: 'COMPLETED', form_id: selectedForm?.id, entity_id: selectedEntity?.id || '' } };
                  }
                  return item;
                }),
              ),
            );
            form.setFieldValue('custom_fields', []);
            onCancel();
          })
          .catch(() => {});
      })
      .catch(() => {});
  };

  const onClose = () => {
    onCancel();
  };

  const onAddField = (element: IBookingFormFieldProps, index: number, isUpdate?: boolean) => {
    if (element?.field?.hidden) return;
    if (isUpdate) {
      return setFields((prev) => [...prev, element]);
    }
    const updatedArray = [...fields.slice(0, index), element, ...fields.slice(index)].map((item, index) => ({
      ...item,
      field_instance: {
        ...item?.field_instance,
        weight: index,
      },
    }));
    setFields(updatedArray);
  };

  useEffect(() => {
    if (!selectedBusiness?.id || !selectedForm?.id || !selectedForm?.entity_type_id) return;

    dispatch(getFormById({ form_id: selectedForm?.id, business_id: selectedBusiness?.id, entity_type_id: selectedForm?.entity_type_id }))
      .unwrap()
      .then(({ data }) => {
        setEntityFields(data.field_instances.map((item) => item?.field));
        form.setFieldsValue(data);
        form.setFieldValue('form_style', data?.style?.form_style);

        data?.field_instances?.map((item, index) => {
          return onAddField(
            {
              field_id: item?.field.id,
              field: item?.field,
              field_instance: item,
              isPageBreak: false,
              isTravelFee: false,
            },
            index,
            true,
          );
        });

        setFields((prev) => sortBy(prev, (field) => field?.field_instance?.weight));

        if (!selectedEntity?.id) return;

        dispatch(
          getEntityFormData({
            businessId: selectedBusiness?.id,
            entityId: selectedEntity?.id,
            entityTypeId: selectedEntity?.entity_type_id,
            formId: selectedForm?.id,
          }),
        )
          .unwrap()
          .then((response) => {
            form.setFieldValue('custom_fields', mapEntityFieldsToForm(response.data, data?.field_instances?.map((item) => item.field)));
          })
          .catch(() => {});
      })
      .catch(() => {});
  }, [selectedForm?.id, selectedBusiness?.id, selectedForm?.entity_type_id]);

  return (
    <Form form={form}>
      <Modal
        title={translate('forms.forms.purpose.CAPTURE')}
        open={Boolean(selectedForm.id)}
        onOk={onSubmit}
        onCancel={onClose}
        okText={translate('buttons.save')}
        cancelButtonProps={{ disabled: creating }}
        okButtonProps={{ loading: creating, disabled: creating }}>
        <Spin spinning={loading || loadingEntity || formLoading}>
          <Fields
            readonly
            form={form}
            isDragging={false}
            fields={fields}
            disabledFields={detailsFields ?? []}
            setIsDragging={() => void 0}
            onAddField={() => void 0}
            onCreateNewFieldDrawer={() => void 0}
            pageCounter={0}
            entityTypeHasQuotes={false}
            setFields={setFields}
            setPageCounter={() => void 0}
            onCreateAsset={() => void 0}
          />
        </Spin>
      </Modal>
    </Form>
  );
};
