import { useEffect, useState } from 'react';

import { Avatar, Col, Form, FormInstance, Image, Row, Spin, Tooltip } from 'antd';
import './AssetCustomField.scss';

import { useBusiness } from '@aduvi/hooks';
import { EReferenceEntityType, IBusiness, IEntityField, IPaginatedResponse, IProduct, IService } from '@aduvi/types';
import { EFormProductDisplayType, IFieldInstance } from '@aduvi/types/form';

import { getBusiness } from 'store/features/client-auth-slice';
import { getPublicProducts, getPublicServices } from 'store/features/form-slice';
import { getProductCategories } from 'store/features/products-slice';
import { useAppDispatch } from 'store/hooks';

import { CarouselDisplayType } from './product-display-type/CarouselDisplayType';
import { DropdownDisplayType } from './product-display-type/DropdownDisplayType';
import { GridDisplayType } from './product-display-type/GridDisplayType';
import { RowDisplayType } from './product-display-type/RowDisplayType';
import { SlideshowDisplayType } from './product-display-type/SlideshowDisplayType';

interface IProps {
  field?: IEntityField;
  index: number;
  form: FormInstance;
  fieldInstance: IFieldInstance;
}

export const AssetCustomField = ({ field, form, index, fieldInstance }: IProps) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();

  const [services, setServices] = useState<IPaginatedResponse<IService> | undefined>(undefined);
  const [products, setProducts] = useState<IPaginatedResponse<IProduct> | undefined>(undefined);

  const [selectedServices, setSelectedSerivces] = useState<string[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [selectedPackages, setSelectedPackages] = useState<string[]>([]);
  const [business, setBusiness] = useState<IBusiness | undefined>(undefined);

  const onServiceSelect = (id: string) => {
    if (selectedServices.includes(id)) {
      form.setFieldValue(['custom_fields', index, field?.id], field?.multiple ? selectedServices.filter((item) => item !== id) : []);
      setSelectedSerivces((prev) => (field?.multiple ? prev.filter((item) => item !== id) : []));
      return;
    }
    form.setFieldValue(['custom_fields', index, field?.id], field?.multiple ? [...selectedServices, id] : [id]);
    setSelectedSerivces((prev) => (field?.multiple ? [...prev, id] : [id]));
  };

  const onPackageSelect = (id: string) => {
    if (selectedPackages.includes(id)) {
      form.setFieldValue(['custom_fields', index, field?.id], field?.multiple ? selectedPackages.filter((item) => item !== id) : []);
      setSelectedPackages((prev) => (field?.multiple ? prev?.filter((item) => item !== id) : []));
      return;
    }
    form.setFieldValue(['custom_fields', index, field?.id], field?.multiple ? [...selectedServices, id] : [id]);
    setSelectedPackages((prev) => (field?.multiple ? [...prev, id] : [id]));
  };

  const onProductSelect = (id: string) => {
    if (selectedProducts.includes(id)) {
      form.setFieldValue(['custom_fields', index, field?.id], field?.multiple ? selectedProducts.filter((item) => item !== id) : []);
      setSelectedProducts((prev) => (field?.multiple ? prev.filter((item) => item !== id) : []));
      return;
    }
    form.setFieldValue(['custom_fields', index, field?.id], field?.multiple ? [...selectedProducts, id] : [id]);
    setSelectedProducts((prev) => (field?.multiple ? [...prev, id] : [id]));
  };

  const productDisplayType: Record<EFormProductDisplayType, JSX.Element> = {
    [EFormProductDisplayType.GRID]: <GridDisplayType products={products} selectedProducts={selectedProducts} onProductSelect={onProductSelect} />,
    [EFormProductDisplayType.ROWS]: <RowDisplayType products={products} selectedProducts={selectedProducts} onProductSelect={onProductSelect} />,
    [EFormProductDisplayType.CAROUSEL]: (
      <CarouselDisplayType products={products} selectedProducts={selectedProducts} onProductSelect={onProductSelect} />
    ),
    [EFormProductDisplayType.SLIDESHOW]: (
      <SlideshowDisplayType products={products} selectedProducts={selectedProducts} isSlideshow={false} onProductSelect={onProductSelect} />
    ),
    [EFormProductDisplayType.BACKDROPS]: (
      <SlideshowDisplayType products={products} selectedProducts={selectedProducts} isSlideshow={true} onProductSelect={onProductSelect} />
    ),
    [EFormProductDisplayType.DROPDOWN]: (
      <DropdownDisplayType products={products} selectedProducts={selectedProducts} onProductSelect={onProductSelect} />
    ),
  };

  useEffect(() => {
    dispatch(getBusiness())
      ?.unwrap()
      .then((res) => setBusiness(res?.data))
      .catch(() => {});

    if (!selectedBusiness?.id && !business?.id) return;
    if (field?.reference_entity_type === EReferenceEntityType.SERVICE) {
      dispatch(getPublicServices(String(selectedBusiness?.id || business?.id)))
        .unwrap()
        .then((data) => setServices(data));
    }
    if (field?.reference_entity_type === EReferenceEntityType.PRODUCT) {
      dispatch(getPublicProducts(String(selectedBusiness?.id || business?.id)))
        .unwrap()
        .then((data) => {
          setProducts(data);
          if (!selectedBusiness?.id) return;
          dispatch(getProductCategories(selectedBusiness?.id));
        });
    }
  }, [selectedBusiness?.id, business?.id]);

  if (field?.reference_entity_type === EReferenceEntityType.SERVICE) {
    return (
      <Spin spinning={!services}>
        <Row justify={'start'} className='pr-10 pl-10 service-field'>
          <Form.Item name={['custom_fields', index, field?.id]} hidden />
          {services?.data.map((item, index) => (
            <Tooltip key={item.id} title={item.name}>
              <Col span={5} className='cursor-pointer' onClick={() => onServiceSelect(item.id)}>
                {item.image ? (
                  <Image
                    style={{
                      borderRadius: '5px',
                      border: `${selectedServices.includes(item.id) ? '2px' : '0px'} solid ${selectedServices.includes(item.id) ? '#1890ff' : ''}`,
                    }}
                    className={`${index !== 0 ? 'ml-5' : ''}`}
                    key={index}
                    preview={false}
                    src={item.image}
                  />
                ) : (
                  <Avatar
                    shape='square'
                    style={{
                      borderRadius: '5px',
                      border: `${selectedServices.includes(item.id) ? '2px' : '0px'} solid ${selectedServices.includes(item.id) ? '#1890ff' : ''}`,
                    }}
                    className={`w-full h-full ${index !== 0 ? 'ml-5' : ''}`}>
                    {item.name?.slice(0, 2)?.toUpperCase()}
                  </Avatar>
                )}
              </Col>
            </Tooltip>
          ))}
        </Row>
        <Row justify={'start'} gutter={[30, 30]} className='package-field pr-10 pl-10 mt-20'>
          {selectedServices?.map((serviceId) => {
            const packages = services?.data?.find((service) => service?.id === serviceId)?.packages;
            return packages?.map((item) => (
              <Col xs={24} md={11} xxl={7} key={item?.id}>
                <div
                  className='package-wrapper mr-5 cursor-pointer'
                  style={{
                    border: `${selectedPackages?.includes(item?.id) ? '2px' : '0px'} solid ${selectedPackages?.includes(item?.id) ? '#1890ff' : ''}`,
                  }}
                  onClick={() => onPackageSelect(item?.id)}>
                  <div className='title pl-10'>{item?.name}</div>
                  {item.pricing_type === 'FLAT' && <div className='title price-tag pl-10'>${item?.flat_price}</div>}
                  <div className='mt-10 pl-10' dangerouslySetInnerHTML={{ __html: item?.description }} />
                </div>
              </Col>
            ));
          })}
        </Row>
      </Spin>
    );
  }

  if (field?.reference_entity_type === EReferenceEntityType.PRODUCT) {
    return fieldInstance.display_settings?.product_display_type ? (
      productDisplayType[fieldInstance.display_settings?.product_display_type]
    ) : (
      <GridDisplayType products={products} selectedProducts={selectedProducts} onProductSelect={onProductSelect} />
    );
  }

  return <></>;
};
