import { useState } from 'react';
import { useNavigate } from 'react-router';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { SearchPopoverMenu } from 'views/search/SearchPopoverMenu';

import { Avatar, Badge, Image, Row } from 'antd';
import './Header.scss';

import { UserProfileMenu } from '@aduvi/components/UserProfileMenu/UserProfileMenu';
import { useActiveTheme } from '@aduvi/hooks';

export const Header = () => {
  const navigate = useNavigate();
  const activeTheme = useActiveTheme();

  const [popoverVisible, setPopoverVisible] = useState(false);
  const [searchPopoverVisible, setSearchPopoverVisible] = useState(false);

  return (
    <>
      <Row align={'middle'} className='header-container w-full p-20' justify={'space-between'}>
        <Image width={40} height={40} src={activeTheme?.logo} preview={false} onClick={() => navigate('/dashboard')} />
        <div className='padding-icons'>
          <SearchOutlined className='header-icon' rotate={90} onClick={() => setSearchPopoverVisible((prevState) => !prevState)} />
          <SearchPopoverMenu
            customOverlayStyle={{ width: 400 }}
            customOverlayInnerStyle={{ marginTop: 25 }}
            onOpenChange={setSearchPopoverVisible}
            open={searchPopoverVisible}
            customPlacement='bottom'
          />
          <Badge count={99}>
            <Avatar onClick={() => setPopoverVisible((prevState) => !prevState)} size={38} icon={<UserOutlined />} />
          </Badge>
          <UserProfileMenu
            customOverlayStyle={{ width: 330 }}
            customOverlayInnerStyle={{ marginTop: 25 }}
            open={popoverVisible}
            onOpenChange={setPopoverVisible}
          />
        </div>
      </Row>
    </>
  );
};
