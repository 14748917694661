import React, { useState } from 'react';
import { SendOutlined } from '@ant-design/icons';
import { Editor } from 'ckeditor5';

import { Button, UploadFile } from 'antd';
import { useForm } from 'antd/es/form/Form';
import './ChatEditor.scss';

import { CKEditor } from '@aduvi/components/CKEditor/CKEditor';
import { MediaLibraryModal } from '@aduvi/components/MediaLibraryModal/MediaLibraryModal';
import { FileUpload } from '@aduvi/components/UploadFile/FileUpload';

import { useAppSelector } from 'store/hooks';

interface IProps {
  handleEditorKeyDown: (e: KeyboardEvent) => void;
  handleSubmit: () => void;
  setFileList?: (selectedFiles: UploadFile[]) => void;
  setEditorInstance: (editor: Editor | null) => void;
}

export const ChatEditor = ({ handleEditorKeyDown, handleSubmit, setFileList, setEditorInstance }: IProps) => {
  const [form] = useForm();

  const { loading } = useAppSelector((state) => state.conversation.messages);

  const [showUploadFileModal, setShowUploadFileModal] = useState(false);

  return (
    <div className='conversation-cke-editor-wrapper'>
      <CKEditor
        onReady={(editor) => setEditorInstance(editor)}
        onChange={(message) => form.setFieldsValue({ message })}
        handleEditorKeyDown={handleEditorKeyDown}
      />
      <Button className='send-btn' type='primary' disabled={loading} onClick={handleSubmit}>
        <SendOutlined />
      </Button>

      {showUploadFileModal && (
        <MediaLibraryModal
          open={showUploadFileModal}
          uploadedImageUrls={[]}
          onSelect={(urls) => {
            if (!urls || !urls?.length) return;
            const selectedFiles: UploadFile[] = urls?.map((url, index) => {
              return {
                url,
                uid: index?.toString(),
                name: 'image.png',
                status: 'done',
              };
            });
            setFileList?.(selectedFiles);
            setShowUploadFileModal(false);
          }}
          onCancel={() => setShowUploadFileModal(false)}
          uploadComponent={
            <FileUpload
              name='file'
              onUploadChange={(file) => {
                if (file?.url) {
                  setFileList?.([
                    {
                      url: file?.url,
                      uid: '-1',
                      name: 'image.png',
                      status: 'done',
                    },
                  ]);
                }
              }}
            />
          }
        />
      )}
    </div>
  );
};
