import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { Button, Checkbox, Form, Input, Row } from 'antd';

import { useCountdownTimer } from '@aduvi/hooks/useCountDownTimer';
import { ELoadingState, ILoginResponse } from '@aduvi/types';

import { getUserData, login, resendVerificationCode } from 'store/features/auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t: translate } = useTranslation();
  const { timer, setTimer } = useCountdownTimer(60);

  const [show2FaVerificationInput, setShow2FaVerificationInput] = useState(false);
  const [allowResendVerificationCode, setAllowResendVerificationCode] = useState(true);
  const [loginCredentials, setLoginCredentials] = useState({ email: '', password: '' });

  const { loginState, resendVerificationCodeState } = useAppSelector((state) => state.auth);

  const getAccountInformation = (data: ILoginResponse, email?: string, password?: string) => {
    if (email && data?.email_verified_at === null) {
      return navigate(`/auth/email-confirmation?email=${encodeURIComponent(email)}`);
    }
    if (data?.access_token) {
      return dispatch(getUserData())
        .unwrap()
        .then(({ data }) => {
          data && (data.businesses.length > 0 ? navigate('/dashboard') : navigate('/onboarding'));
        })
        .catch(() => {});
    }

    if (password && email) {
      twoFaRequired?.(email, password);
    }
  };

  const onResendVerificationCode = () => {
    if (!loginCredentials.email) return;
    dispatch(resendVerificationCode({ email: loginCredentials.email }))
      .unwrap()
      .then(() => {
        setTimer(60);
        setAllowResendVerificationCode(false);
      })
      .catch(() => {});
  };

  const twoFaRequired = (email: string, password: string) => {
    setLoginCredentials({ email, password });
    setShow2FaVerificationInput(true);
  };

  const onLogin = ({ email, password, otp }: { email: string; password: string; otp?: string }) => {
    dispatch(login({ auth_type: undefined, email: email ?? loginCredentials.email, password: password ?? loginCredentials.password, otp }))
      .unwrap()
      .then((response) => {
        getAccountInformation?.(response?.data, email, password);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (timer === 0) {
      setAllowResendVerificationCode(true);
    }
  }, [show2FaVerificationInput, timer]);

  return (
    <Form name='login' className='mt-20' initialValues={{ remember: true }} onFinish={onLogin}>
      {!show2FaVerificationInput && (
        <>
          <Form.Item name='email' rules={[{ required: true, message: translate('auth.login.emailValidation'), type: 'email' }]}>
            <Input prefix={<UserOutlined className={'site-form-item-icon'} />} placeholder={translate('auth.login.emailPlaceholder')} />
          </Form.Item>
          <Form.Item name='password' rules={[{ required: true, message: translate('auth.login.passwordValidation') }]}>
            <Input
              prefix={<LockOutlined className={'site-form-item-icon'} />}
              type='password'
              placeholder={translate('auth.login.passwordPlaceholder')}
            />
          </Form.Item>

          <Form.Item className={'mb-15'}>
            <Row justify='space-between' align='middle'>
              <Form.Item name='remember' valuePropName='checked' noStyle>
                <Checkbox>{translate('auth.login.rememberMe')}</Checkbox>
              </Form.Item>
              <Button type='link' className={'login-form-forgot p-0'} onClick={() => navigate('/auth/forgot-password')}>
                {translate('auth.login.forgot')}
              </Button>
            </Row>
          </Form.Item>
        </>
      )}

      {show2FaVerificationInput && (
        <>
          <Form.Item className='mb-5' name='otp' rules={[{ required: true, message: translate('auth.login.codeValidation') }]}>
            <Input prefix={<LockOutlined className={'color-blue-6'} />} placeholder={translate('auth.login.verificationCodePlaceholder')} />
          </Form.Item>
          <Row className='mb-15'>
            {allowResendVerificationCode ? (
              <a onClick={onResendVerificationCode} className='cursor-pointer'>
                {resendVerificationCodeState === ELoadingState.LOADING
                  ? translate('profile.security.resending')
                  : translate('profile.security.resendVerificationCode')}
              </a>
            ) : (
              <a style={{ cursor: 'not-allowed' }}>{translate('profile.security.resendVerificationCodeWithTimer', { timer })}</a>
            )}
          </Row>
        </>
      )}

      <Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          className='w-full'
          disabled={loginState === ELoadingState.LOADING}
          loading={loginState === ELoadingState.LOADING}>
          {translate('auth.login.signIn')}
        </Button>
      </Form.Item>

      {/* {!show2FaVerificationInput && (
        <Form.Item>
          <Row justify='center'>
            {translate('auth.login.quickSignIn')}
            <CustomLogin twoFaRequired={twoFaRequired} />
          </Row>
        </Form.Item>
      )} */}
    </Form>
  );
};

export default Login;
