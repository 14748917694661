import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Academies } from 'views/academy/@components/academies/Academies';
import { Lesson } from 'views/academy/@components/lesson/Lesson';
import { Quiz } from 'views/academy/@components/quiz/Quiz';
import { QuizResult } from 'views/academy/@components/quiz-result/QuizResult';
import { Academy } from 'views/academy/Academy';
import { Assets } from 'views/assets/Assets';
import { FullScreenAutomationsModal } from 'views/automations/FullScreenAutomationsModal';
import { Bookings } from 'views/bookings/Bookings';
import { Contacts } from 'views/contacts/Contacts';
import { Dashboard } from 'views/dashboard/Dashboard';
import { FormBuilder } from 'views/forms/FormBuilder';
import { Leads } from 'views/leads/Leads';
import { Orders as OrdersView } from 'views/orders/Orders';
import { PaymentConnect } from 'views/payment-connect/PaymentConnect';
import { Activity, Details, Password, Security } from 'views/profile/@components';
import { Profile } from 'views/profile/Profile';
import { Project } from 'views/projects/Project';
import { Projects } from 'views/projects/Projects';
import { Proposals } from 'views/proposals/Proposals';
import { BlocksEditorModal } from 'views/proposals/tabs/BuilderBlocks/BlocksEditorModal';
import { FullScreenProposal } from 'views/proposals/tabs/Proposals/FullScreenProposal';
import { ProposalEditorModal } from 'views/proposals/tabs/Proposals/ProposalEditorModal';
import { TemplateEditorModal } from 'views/proposals/tabs/Templates/TemplateEditorModal';
import { SettingsDetails } from 'views/settings/details/SettingsDetails';
import { Settings } from 'views/settings/Settings';
import { Tasks } from 'views/tasks/Tasks';
import { Venues } from 'views/venues/Venues';
import { Business } from 'views/widgets/business/Business';
import { BusinessBuilder } from 'views/widgets/business/BusinessBuilder';
import { AgendaBuilder } from 'views/widgets/event-ticketing/AgendaBuilder';
import { EventTicketing } from 'views/widgets/event-ticketing/EventTicketing';
import { EventTicketingBuilder } from 'views/widgets/event-ticketing/EventTicketingBuilder';
import { ExhibitorBuilder } from 'views/widgets/event-ticketing/ExhibitorBuilder';
import { Forms } from 'views/widgets/forms/Forms';
import { FormsBuilder } from 'views/widgets/forms/FormsBuilder';
import { Products } from 'views/widgets/products/Products';
import { ProductsBuilder } from 'views/widgets/products/ProductsBuilder';
import { PackagesBuilder } from 'views/widgets/services/PackagesBuilder';
import { Services } from 'views/widgets/services/Services';
import { ServicesBuilder } from 'views/widgets/services/ServicesBuilder';
import { UsersAndTeams } from 'views/widgets/users-and-teams/UsersAndTeams';
import { UserAndTeamsBuilder } from 'views/widgets/users-and-teams/UsersAndTeamsBuilder';
import { Widgets } from 'views/widgets/Widget';

import { UnderDevelopmentPage } from '@aduvi/components/UnderDevelopment/UnderDevelopmentPage';
import { PERMISSIONS } from '@aduvi/constants';
import { Layout } from '@aduvi/layout/Layout';

import { LazyLoadGuard } from '../../components/LazyLoadGuard/LazyLoadGuard';

import { PrivateRoute } from './PrivateRoute';

const UserManagement = React.lazy(() => import('views/user-management/UserManagement'));

const Roadmap = React.lazy(() => import('views/roadmap/Roadmap'));
const SettingsBranding = React.lazy(() => import('views/settings/branding/SettingsBranding'));
const SettingsClientPortal = React.lazy(() => import('views/settings/client-portal/ClientPortalSettings'));
const ContactManagement = React.lazy(() => import('views/settings/contact-management/ContactManagement'));
const Contracts = React.lazy(() => import('views/settings/contracts/Contracts'));
const EditContract = React.lazy(() => import('views/settings/contracts/edit-contract/EditContract'));
const Discounts = React.lazy(() => import('views/settings/discounts-coupons/Discounts'));
const EventManagement = React.lazy(() => import('views/settings/event-management/EventManagement'));
const JobManagement = React.lazy(() => import('views/settings/job-management/JobManagement'));
const ProjectManagement = React.lazy(() => import('views/settings/project/ProjectManagement'));
const TravelFees = React.lazy(() => import('views/settings/travel-fees/TravelFees'));

const SettingsLocales = React.lazy(() => import('views/settings/locale/SettingsLocales'));
const SettingsLocations = React.lazy(() => import('views/settings/locations/SettingsLocations'));
const MenuSettings = React.lazy(() => import('views/settings/menu-settings/MenuSettings'));
const OrderManagement = React.lazy(() => import('views/settings/order-management/OrderManagement'));
const Payments = React.lazy(() => import('views/settings/payments/Payments'));
const ConnectedAccounts = React.lazy(() => import('views/settings/connected-accounts/ConnectedAccounts'));
const SettingsSecurity = React.lazy(() => import('views/settings/security/SettingsSecurity'));
const Tax = React.lazy(() => import('views/settings/tax/Tax'));
const InvoicesPage = React.lazy(() => import('views/settings/invoices/InvoicesPage'));
const ShiftScheduler = React.lazy(() => import('views/settings/shift-scheduler/ShiftScheduler'));
const Translations = React.lazy(() => import('views/settings/translations/Translations'));
const SettingsTrash = React.lazy(() => import('views/settings/trash/SettingsTrash'));
const VenueManagement = React.lazy(() => import('views/settings/venue-management/VenueManagement'));
const ExhibitorScanner = React.lazy(() => import('views/ticket-scanner/ExhibitorScanner'));
const SalesScanner = React.lazy(() => import('views/ticket-scanner/SalesScanner'));
const TasksManagement = React.lazy(() => import('views/settings/task-management/TaskManagement'));
const Reps = React.lazy(() => import('views/settings/reps/Reps'));
const VerifyRep = React.lazy(() => import('views/settings/reps/VerifyRep'));

const ClientPortal = React.lazy(() => import('views/settings/client-portal/@components/client-portal/ClientPortal'));
const Files = React.lazy(() => import('views/files/Files'));
const Automations = React.lazy(() => import('views/automations/Automations'));
const Billing = React.lazy(() => import('views/billing/Billing'));

const BillingContacts = React.lazy(() => import('views/billing/billing-contacts/BillingContacts'));
const InvoiceSettings = React.lazy(() => import('views/billing/invoice-settings/InvoiceSettings'));
const Invoices = React.lazy(() => import('views/billing/invoices/Invoices'));
const PaymentMethods = React.lazy(() => import('views/billing/payment-methods/PaymentMethods'));
const Subscription = React.lazy(() => import('views/billing/subscription/Subscription'));

const Onboarding = React.lazy(() => import('views/onboarding/Onboarding'));
const Developers = React.lazy(() => import('views/developers/Developers'));
const Conversation = React.lazy(() => import('views/conversation/Conversation'));
const CheckoutPage = React.lazy(() => import('views/checkout/Checkout'));
const Calendar = React.lazy(() => import('views/calendar/Calendar'));
const Apps = React.lazy(() => import('views/apps/Apps'));

export const AuthorizedRoutes = () => {
  const location = useLocation();
  const fullScreenRoutes = ['/onboarding', '/email-confirmation'];

  if (fullScreenRoutes?.some((route) => route === location?.pathname)) {
    return (
      <Routes>
        <Route
          path='/onboarding'
          element={
            <LazyLoadGuard>
              <Onboarding />
            </LazyLoadGuard>
          }
        />
      </Routes>
    );
  }

  return (
    <Layout>
      <Routes>
        <Route>
          <Route path='/dashboard' element={<Dashboard />} />
          <Route
            path='/sales-scanner'
            element={
              <LazyLoadGuard>
                <SalesScanner />
              </LazyLoadGuard>
            }
          />
          <Route
            path='/exhibitor-scanner'
            element={
              <LazyLoadGuard>
                <ExhibitorScanner />
              </LazyLoadGuard>
            }
          />
          <Route path='/leads' element={<Leads />} />
          <Route path='/bookings' element={<Bookings />} />
          <Route path=':entityTypeId/forms/:formId' element={<FormBuilder />} />
          <Route path='/automations/:automationId' element={<FullScreenAutomationsModal />} />
          <Route path='/proposal-builder' element={<ProposalEditorModal />} />
          <Route path='/template-builder' element={<TemplateEditorModal />} />
          <Route path='/block-builder' element={<BlocksEditorModal />} />
          <Route path='/proposals/:id' element={<FullScreenProposal />} />
          <Route element={<PrivateRoute permission={[PERMISSIONS.SERVICES.VIEW, PERMISSIONS.PRODUCTS.VIEW, PERMISSIONS.EVENTS.VIEW]} />}>
            <Route path='/store/:storeTab' element={<Assets />} />
            <Route path='/store' element={<Assets />} />
          </Route>

          <Route path='/contacts' element={<Contacts />} />
          <Route path='/proposals' element={<Proposals />} />
          <Route path='/orders' element={<OrdersView />} />
          <Route path='/venues' element={<Venues />} />
          <Route path='/project'>
            <Route index element={<Projects />} />
            <Route path=':projectId' element={<Project />} />
          </Route>
          <Route path='/reports' element={<UnderDevelopmentPage />} />
          <Route element={<PrivateRoute permission={[PERMISSIONS.TASKS.VIEW]} />}>
            <Route path='/tasks' element={<Tasks />} />
          </Route>

          <Route
            path='/calendar'
            element={
              <LazyLoadGuard>
                <Calendar />
              </LazyLoadGuard>
            }
          />
          <Route
            path='/conversation'
            element={
              <LazyLoadGuard>
                <Conversation />
              </LazyLoadGuard>
            }
          />
          <Route
            path='/client-portal/:clientPortalId'
            element={
              <LazyLoadGuard>
                <ClientPortal />
              </LazyLoadGuard>
            }
          />
          <Route
            path='/files'
            element={
              <LazyLoadGuard>
                <Files />
              </LazyLoadGuard>
            }
          />
          <Route
            path='/automations'
            element={
              <LazyLoadGuard>
                <Automations />
              </LazyLoadGuard>
            }
          />
          <Route element={<PrivateRoute permission={PERMISSIONS.USERS.VIEW} />}>
            <Route
              path='/users'
              element={
                <LazyLoadGuard>
                  <UserManagement />
                </LazyLoadGuard>
              }
            />
          </Route>
          <Route path='/settings' element={<Settings />}>
            <Route path='details' element={<SettingsDetails />} />
            <Route
              path='client-portal'
              element={
                <LazyLoadGuard>
                  <SettingsClientPortal />
                </LazyLoadGuard>
              }
            />
            <Route element={<PrivateRoute permission={PERMISSIONS.BUSINESS.VIEW} />}>
              <Route
                path='locale'
                element={
                  <LazyLoadGuard>
                    <SettingsLocales />
                  </LazyLoadGuard>
                }
              />
            </Route>
            <Route element={<PrivateRoute permission={PERMISSIONS.BUSINESS.VIEW} />}>
              <Route
                path='translations'
                element={
                  <LazyLoadGuard>
                    <Translations />
                  </LazyLoadGuard>
                }
              />
            </Route>
            <Route element={<PrivateRoute permission={PERMISSIONS.LOCATIONS.VIEW} />}>
              <Route
                path='locations'
                element={
                  <LazyLoadGuard>
                    <SettingsLocations />
                  </LazyLoadGuard>
                }
              />
            </Route>
            <Route element={<PrivateRoute permission={PERMISSIONS.BUSINESS.VIEW} />}>
              <Route
                path='security'
                element={
                  <LazyLoadGuard>
                    <SettingsSecurity />
                  </LazyLoadGuard>
                }
              />
            </Route>
            <Route element={<PrivateRoute permission={PERMISSIONS.BUSINESS.VIEW} />}>
              <Route
                path='trash'
                element={
                  <LazyLoadGuard>
                    <SettingsTrash />
                  </LazyLoadGuard>
                }
              />
            </Route>

            <Route element={<PrivateRoute permission={PERMISSIONS.BRANDINGS.VIEW} />}>
              <Route
                path='branding'
                element={
                  <LazyLoadGuard>
                    <SettingsBranding />
                  </LazyLoadGuard>
                }
              />
            </Route>
            <Route element={<PrivateRoute permission={PERMISSIONS.JOB_FIELDS.VIEW} />}>
              <Route
                path='job-management'
                element={
                  <LazyLoadGuard>
                    <JobManagement />
                  </LazyLoadGuard>
                }
              />
            </Route>
            <Route element={<PrivateRoute permission={PERMISSIONS.PROJECT_FIELDS.VIEW} />}>
              <Route
                path='projects-management'
                element={
                  <LazyLoadGuard>
                    <ProjectManagement />
                  </LazyLoadGuard>
                }
              />
            </Route>

            <Route element={<PrivateRoute permission={PERMISSIONS.TRAVEL_FEES.VIEW} />}>
              <Route
                path='travel-fees'
                element={
                  <LazyLoadGuard>
                    <TravelFees />
                  </LazyLoadGuard>
                }
              />
            </Route>
            <Route element={<PrivateRoute permission={[PERMISSIONS.DISCOUNTS.VIEW, PERMISSIONS.COUPONS.VIEW]} />}>
              <Route
                path='discounts'
                element={
                  <LazyLoadGuard>
                    <Discounts />
                  </LazyLoadGuard>
                }
              />
            </Route>
            <Route element={<PrivateRoute permission={PERMISSIONS.CONTACT_FIELDS.VIEW} />}>
              <Route
                path='contact-management'
                element={
                  <LazyLoadGuard>
                    <ContactManagement />
                  </LazyLoadGuard>
                }
              />
            </Route>
            <Route element={<PrivateRoute permission={PERMISSIONS.ORDER_FIELDS.VIEW} />}>
              <Route
                path='orders-management'
                element={
                  <LazyLoadGuard>
                    <OrderManagement />
                  </LazyLoadGuard>
                }
              />
            </Route>

            <Route>
              <Route
                path='tasks-management'
                element={
                  <LazyLoadGuard>
                    <TasksManagement />
                  </LazyLoadGuard>
                }
              />
            </Route>

            <Route element={<PrivateRoute permission={PERMISSIONS.VENUE_FIELDS.VIEW} />}>
              <Route
                path='venue-management'
                element={
                  <LazyLoadGuard>
                    <VenueManagement />
                  </LazyLoadGuard>
                }
              />
            </Route>
            <Route
              path='tax'
              element={
                <LazyLoadGuard>
                  <Tax />
                </LazyLoadGuard>
              }
            />
            <Route
              path='invoices'
              element={
                <LazyLoadGuard>
                  <InvoicesPage />
                </LazyLoadGuard>
              }
            />
            <Route
              path='shift-scheduler'
              element={
                <LazyLoadGuard>
                  <ShiftScheduler />
                </LazyLoadGuard>
              }
            />
            <Route element={<PrivateRoute permission={PERMISSIONS.EVENT_FIELDS.VIEW} />}>
              <Route
                path='events-management'
                element={
                  <LazyLoadGuard>
                    <EventManagement />
                  </LazyLoadGuard>
                }
              />
            </Route>
            <Route element={<PrivateRoute permission={PERMISSIONS.CONTRACTS.VIEW} />}>
              <Route
                path='contracts'
                element={
                  <LazyLoadGuard>
                    <Contracts />
                  </LazyLoadGuard>
                }
              />
            </Route>

            <Route element={<PrivateRoute permission={PERMISSIONS.CONTRACTS.CREATE} />}>
              <Route
                path='contracts/new'
                element={
                  <LazyLoadGuard>
                    <EditContract />
                  </LazyLoadGuard>
                }
              />
            </Route>
            <Route element={<PrivateRoute permission={PERMISSIONS.CONTRACTS.EDIT} />}>
              <Route
                path='contracts/:contractId'
                element={
                  <LazyLoadGuard>
                    <EditContract />
                  </LazyLoadGuard>
                }
              />
            </Route>
            <Route element={<PrivateRoute permission={PERMISSIONS.PAYMENT_SETTING.VIEW} />}>
              <Route
                path='payments'
                element={
                  <LazyLoadGuard>
                    <Payments />
                  </LazyLoadGuard>
                }
              />
            </Route>
            <Route element={<PrivateRoute permission={PERMISSIONS.PAYMENT_SETTING.VIEW} />}>
              <Route
                path='connected-accounts'
                element={
                  <LazyLoadGuard>
                    <ConnectedAccounts />
                  </LazyLoadGuard>
                }
              />
            </Route>
            <Route element={<PrivateRoute permission={PERMISSIONS.MENU_SETTING.VIEW} />}>
              <Route
                path='menu-settings'
                element={
                  <LazyLoadGuard>
                    <MenuSettings />
                  </LazyLoadGuard>
                }
              />
            </Route>
            <Route
              path='reps'
              element={
                <LazyLoadGuard>
                  <Reps />
                </LazyLoadGuard>
              }
            />
            <Route
              path='reps/:repLinkId'
              element={
                <LazyLoadGuard>
                  <VerifyRep />
                </LazyLoadGuard>
              }
            />
          </Route>

          <Route element={<PrivateRoute permission={PERMISSIONS.BILLING_DETAILS.VIEW} />}>
            <Route
              path='/billing'
              element={
                <LazyLoadGuard>
                  <Billing />
                </LazyLoadGuard>
              }>
              <Route
                path='subscription'
                element={
                  <LazyLoadGuard>
                    <Subscription />
                  </LazyLoadGuard>
                }
              />
              <Route
                path='invoice-settings'
                element={
                  <LazyLoadGuard>
                    <InvoiceSettings />
                  </LazyLoadGuard>
                }
              />
              <Route
                path='invoices'
                element={
                  <LazyLoadGuard>
                    <Invoices />
                  </LazyLoadGuard>
                }
              />
              <Route
                path='payment-methods'
                element={
                  <LazyLoadGuard>
                    <PaymentMethods />
                  </LazyLoadGuard>
                }
              />
              <Route
                path='billing-contacts'
                element={
                  <LazyLoadGuard>
                    <BillingContacts />
                  </LazyLoadGuard>
                }
              />
            </Route>
          </Route>

          <Route
            path='/apps'
            element={
              <LazyLoadGuard>
                <Apps />
              </LazyLoadGuard>
            }
          />

          <Route
            path='/developers'
            element={
              <LazyLoadGuard>
                <Developers />
              </LazyLoadGuard>
            }
          />
          <Route
            path='/checkout/:quoteId/:entityId'
            element={
              <LazyLoadGuard>
                <CheckoutPage />
              </LazyLoadGuard>
            }
          />
          <Route path='/payment-connect' element={<PaymentConnect />} />
        </Route>

        <Route path='/profile' element={<Profile />}>
          <Route path='details' element={<Details />} />
          <Route path='password' element={<Password />} />
          <Route path='security' element={<Security />} />
          <Route path='activity' element={<Activity />} />
        </Route>

        <Route path='/search' element={<p>text</p>} />
        <Route path='/widgets' element={<Widgets />}>
          <Route
            path='users-teams'
            element={
              <LazyLoadGuard>
                <UsersAndTeams />
              </LazyLoadGuard>
            }
          />

          <Route
            path='services'
            element={
              <LazyLoadGuard>
                <Services />
              </LazyLoadGuard>
            }
          />

          <Route
            path='products'
            element={
              <LazyLoadGuard>
                <Products />
              </LazyLoadGuard>
            }
          />

          <Route
            path='business'
            element={
              <LazyLoadGuard>
                <Business />
              </LazyLoadGuard>
            }
          />

          <Route
            path='event-ticketing'
            element={
              <LazyLoadGuard>
                <EventTicketing />
              </LazyLoadGuard>
            }
          />

          <Route
            path='forms'
            element={
              <LazyLoadGuard>
                <Forms />
              </LazyLoadGuard>
            }
          />
        </Route>

        <Route path='/widgets/users-and-teams-configure' element={<UserAndTeamsBuilder />} />
        <Route path='/widgets/users-and-teams-configure/:widgetId' element={<UserAndTeamsBuilder />} />
        <Route path='/widgets/services-configure' element={<ServicesBuilder />} />
        <Route path='/widgets/services-configure/:widgetId' element={<ServicesBuilder />} />
        <Route path='/widgets/packages-configure' element={<PackagesBuilder />} />
        <Route path='/widgets/packages-configure/:widgetId' element={<PackagesBuilder />} />
        <Route path='/widgets/products-configure' element={<ProductsBuilder />} />
        <Route path='/widgets/products-configure/:widgetId' element={<ProductsBuilder />} />
        <Route path='/widgets/business-configure' element={<BusinessBuilder />} />
        <Route path='/widgets/business-configure/:widgetId' element={<BusinessBuilder />} />
        <Route path='/widgets/events-configure' element={<EventTicketingBuilder />} />
        <Route path='/widgets/events-configure/:widgetId' element={<EventTicketingBuilder />} />
        <Route path='/widgets/exhibitors-configure' element={<ExhibitorBuilder />} />
        <Route path='/widgets/exhibitors-configure/:widgetId' element={<ExhibitorBuilder />} />
        <Route path='/widgets/agenda-configure' element={<AgendaBuilder />} />
        <Route path='/widgets/agenda-configure/:widgetId' element={<AgendaBuilder />} />
        <Route path='/widgets/forms-configure/:widgetId' element={<FormsBuilder />} />

        <Route
          path='/roadmap'
          element={
            <LazyLoadGuard>
              <Roadmap />
            </LazyLoadGuard>
          }
        />
        <Route path='/rewards' element={<UnderDevelopmentPage />} />

        <Route path='/academy' element={<Academy />}>
          <Route path='all' element={<Academies />} />
          <Route path=':id' element={<Lesson />} />
          <Route path=':id/quiz' element={<Quiz />} />
          <Route path=':id/result' element={<QuizResult />} />
        </Route>

        <Route path='*' element={<Navigate to={`dashboard`} />} />
      </Routes>
    </Layout>
  );
};
