import { EFieldLabelAligment, EFieldSettings, EFontStyle, EFontWeight, ETextAlignment, ICustomFieldViewStyle } from '@aduvi/types';

import { DEFAULT_DATE_FORMAT, DEFAULT_LIST_FORMAT, DEFAULT_MAX_LENGTH, DEFAULT_NUMBER_FORMAT, DEFAULT_QUOTE_FORMAT } from './date';

export const FONT_SIZES = [10, 11, 12, 13, 14, 15, 16, 17, 18];

export const DEFAULT_CELL_STYLE: ICustomFieldViewStyle['style'] = {
  background: 'inherit',
  color: 'inherit',
  fontWeight: EFontWeight.NORMAL,
  fontStyle: EFontStyle.NORMAL,
  fontSize: 14,
  dateFormat: DEFAULT_DATE_FORMAT,
  numberFormat: DEFAULT_NUMBER_FORMAT,
  listFormat: DEFAULT_LIST_FORMAT,
  quoteFormat: DEFAULT_QUOTE_FORMAT,
  maxLength: DEFAULT_MAX_LENGTH,
  alignment: ETextAlignment.LEFT,
  fieldLabelAlignment: EFieldLabelAligment.LEFT,
  isEditable: false,
  fieldSetting: EFieldSettings.HIDDEN,
};
