import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Divider, Form, Input, Modal, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import './AddFormModal.scss';

import { SelectBrand } from '@aduvi/components/Entity/components/SelectBrand/SelectBrand';
import { INITIAL_FORM_CONFIG } from '@aduvi/constants';
import { useBusiness } from '@aduvi/hooks';
import { IEntityType } from '@aduvi/types/entity';
import { EFormPurpose, EFormType } from '@aduvi/types/form';
import { generateFormIFrame, normalToKebabCase } from '@aduvi/utils/helper';

import { createEntityForm, updateEntityForm } from 'store/features/form-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface IProps {
  entityType?: IEntityType;
  formType: string;
  onCancel: () => void;
}
export const AddFormModal = ({ entityType, formType, onCancel }: IProps) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const navigate = useNavigate();

  const { t: translate } = useTranslation();
  const [form] = useForm();

  const { creating, updating, selectedEntityForm } = useAppSelector((state) => state.forms);

  const formPurpose: EFormPurpose = Form.useWatch('purpose', form);

  const entityLabel: Record<string, string> = {
    [EFormType.BOOKINGS_FORM]: translate('forms.forms.type.booking'),
    [EFormType.LEADS_FORM]: translate('forms.forms.type.lead'),
    [EFormType.CONTACTS_FORM]: translate('forms.forms.type.contact'),
  };

  const onSubmit = () => {
    if (!selectedBusiness?.id || !entityType?.id) return;
    form
      .validateFields()
      .then(({ name, purpose, brand_id }) => {
        const selectedBrand = selectedBusiness?.brandings?.find((item) => item.id === brand_id);

        if (!selectedEntityForm) {
          dispatch(
            createEntityForm({
              payload: {
                ...INITIAL_FORM_CONFIG,
                style: {
                  ...INITIAL_FORM_CONFIG.style,
                  form_style: [
                    brand_id
                      ? {
                          ...INITIAL_FORM_CONFIG.style.form_style[0],
                          background_color: selectedBrand?.brand_color || INITIAL_FORM_CONFIG.style.form_style[0].background_color,
                          logo: selectedBrand?.logo || INITIAL_FORM_CONFIG.style.form_style[0].logo,
                        }
                      : { ...INITIAL_FORM_CONFIG.style.form_style[0] },
                  ],
                },
                name,
                form_url: normalToKebabCase(name),
                business_id: selectedBusiness?.id,
                entity_type_id: entityType.id,
                brand_id,
                purpose,
                embed_form_code: generateFormIFrame(normalToKebabCase(name), window.location.origin),
              },
              form_type: formType,
            }),
          )
            .unwrap()
            .then(({ data }) => {
              form.resetFields();
              onCancel();
              navigate(`/${entityType.id}/forms/${data.id}`);
            })
            .catch(() => {});
        } else {
          dispatch(updateEntityForm({ ...selectedEntityForm, name }))
            ?.unwrap()
            ?.then(() => onCancel())
            .catch(() => {});
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (selectedBusiness?.brandings?.length === 1) {
      form.setFieldValue('brand_id', selectedBusiness?.brandings[0]?.id);
    }
    if (!selectedEntityForm) return;
    form.setFieldsValue(selectedEntityForm);
  }, [selectedBusiness?.id, selectedEntityForm]);

  return (
    <Modal
      open={true}
      title={selectedEntityForm?.id ? translate('forms.forms.editForm', { item: selectedEntityForm?.name }) : translate('forms.forms.createNewForm')}
      onCancel={onCancel}
      className='add-form-wrapper'
      footer={[
        <Button disabled={creating || updating} loading={creating || updating} key='submit' type='primary' onClick={onSubmit}>
          {selectedEntityForm ? translate('buttons.update') : translate('forms.forms.createEdit')}
        </Button>,
      ]}>
      <Divider className='w-full m-0' />
      <Form form={form} className='m-20' layout='vertical' requiredMark={false}>
        <Form.Item name='name' label={translate('forms.forms.formName')} rules={[{ required: true }]}>
          <Input placeholder={translate('forms.forms.formName')} />
        </Form.Item>
        <Form.Item name='purpose' label={translate('forms.forms.formPurpose')} rules={[{ required: true }]}>
          <Select
            placeholder={translate('forms.forms.formPurpose')}
            options={Object.values(EFormPurpose).map((item) => ({
              label: translate(`forms.forms.purpose.${item}`, { item: entityLabel[formType] }),
              value: item,
            }))}
          />
        </Form.Item>
        {formPurpose === EFormPurpose.CREATE ? (
          <Form.Item name='brand_id' label={translate('forms.forms.brand')} rules={[{ required: true }]}>
            <SelectBrand disabled={selectedBusiness?.brandings?.length === 1} />
          </Form.Item>
        ) : null}
      </Form>
      <Divider />
    </Modal>
  );
};
