import axios from '@aduvi/config/axios';
import {
  ICloneQuotePayload,
  ICreateQuote,
  IDeleteQuotePayload,
  IQuote,
  IRecalculateTravelFeePayload,
  IRenameQuotePayload,
  IResponse,
  IUpdateQuote,
  IUpsertQuoteReponse,
} from '@aduvi/types';

export const createQuote = async (params: ICreateQuote): Promise<IResponse<IUpsertQuoteReponse>> => {
  return await axios.post(`/businesses/${params.business_id}/quotes/${params.entity_id}/quote`, params);
};

export const getQuotes = async (businessId: string, entityId: string): Promise<IResponse<IQuote[]>> => {
  return await axios.get(`/businesses/${businessId}/quotes/${entityId}`);
};

export const getQuote = async (businessId: string, entityId: string, quoteId: string, entityType: string): Promise<IResponse<IQuote>> => {
  return await axios.get(`/businesses/${businessId}/quotes/${entityId}/quote/${quoteId}?entity_type=${entityType}`);
};

export const updateQuote = async (payload: IUpdateQuote): Promise<IResponse<IUpsertQuoteReponse>> => {
  return await axios.put(`/businesses/${payload.business_id}/quotes/${payload.entity_id}/quote/${payload.id}`, payload);
};

export const deleteQuote = async (payload: IDeleteQuotePayload): Promise<void> =>
  axios.delete(`/businesses/${payload.business_id}/quotes/${payload.quote_id}?title=${payload.quote_name}`);

export const cloneQuote = async (payload: ICloneQuotePayload): Promise<IResponse<IQuote>> => {
  return await axios.post(`/businesses/${payload.business_id}/quotes/${payload.quote_id}/clone`);
};

export const renameQuote = async (payload: IRenameQuotePayload): Promise<IResponse<IQuote>> => {
  return await axios.put(`/businesses/${payload.business_id}/quotes/${payload.quote_id}`, payload);
};

export const recalculateTravelFee = async (payload: IRecalculateTravelFeePayload): Promise<IResponse<number>> => {
  let url = `/businesses/${payload.business_id}/travel-fees/calculate`;
  if (payload.quote_id) {
    url += `/${payload.quote_id}`;
  }
  return await axios.post(url, payload);
};

export const checkoutQuote = async (businessId?: string, quoteId?: string, entityId?: string): Promise<IResponse<IQuote>> => {
  return await axios.get(`/businesses/${businessId}/quotes/${entityId}/quote/${quoteId}`);
};
