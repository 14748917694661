import React, { useEffect, useMemo, useState } from 'react';
import { DraggableTableViewColumn } from 'views/contacts/edit-row-drawer/DraggableTableViewColumn';

import { Table } from 'antd';

import { CustomFieldValue } from '@aduvi/components/CustomField/CustomFieldValue/CustomFieldValue';
import { useBusiness } from '@aduvi/hooks';
import { EPersonalizedView, ICustomFieldSpecificStyle, ICustomFieldViewStyle, IEntityField, IQuote } from '@aduvi/types';
import { IEntityWithFields } from '@aduvi/types/entity';

import { useAppSelector } from 'store/hooks';
import { useGetEntityFieldsByMultipleIdsQuery } from 'store/rtk-slice/fields-slice';
import { getQuotes } from 'store/services/quote.service';

import { EditableCell } from '../EditableCell/EditableCell';
import { getQuoteFieldNames } from '../QuotesTable/helper';

interface IProps {
  data: IEntityWithFields;
  viewColumns: ICustomFieldViewStyle[];
  managementFields: IEntityField[];
  viewType?: EPersonalizedView;
  updateColumns: (column: ICustomFieldViewStyle, reRenderComponent?: boolean) => void;
  removeColumn: (column: string) => void;
}

interface IDraggableTableColumns {
  columns?: ICustomFieldViewStyle[];
  managementFields: IEntityField[];
  viewType?: EPersonalizedView;
  updateColumns: (column: ICustomFieldViewStyle) => void;
  removeColumn: (column: string) => void;
  quotes: IQuote[];
}
export const EditableTable = ({ data, viewColumns, managementFields, updateColumns, viewType, removeColumn }: IProps) => {
  const [quotes, setQuotes] = useState<IQuote[]>([]);

  useEffect(() => {
    if (!data?.business_id || !data?.id) return;

    getQuotes(data?.business_id, data?.id).then((res) => {
      setQuotes(res.data);
    });
  }, [data?.business_id, data?.id, getQuotes]);

  const draggableColumns = useDraggableTableColumns({
    columns: viewColumns,
    managementFields,
    updateColumns,
    viewType,
    removeColumn,
    quotes,
  });

  return (
    <Table
      rowKey='id'
      style={{ position: 'relative', zIndex: 20, maxHeight: '300px' }}
      columns={draggableColumns}
      pagination={false}
      dataSource={[data]}
    />
  );
};

export const useDraggableTableColumns = ({ columns, updateColumns, managementFields, viewType, removeColumn, quotes }: IDraggableTableColumns) => {
  const selectedBusiness = useBusiness();

  const { action_buttons } = useAppSelector((state) => state.entity.entityTypes);

  const quoteFields = getQuoteFieldNames(quotes);

  const referenceEntityIds = managementFields
    ?.filter((field) => field?.reference_entity_type)
    ?.map((field) => ({ entity_type_id: field?.reference_entity_type_id, entity_name: field?.place_holder || field?.reference_entity_type }));

  const { data: referenceEntities } = useGetEntityFieldsByMultipleIdsQuery(
    {
      businessId: selectedBusiness?.id!,
      entityTypeIds: referenceEntityIds!,
    },
    { skip: !selectedBusiness || !referenceEntityIds },
  );

  return useMemo(() => {
    return columns?.length
      ? [
          ...columns?.map((column) => ({
            title: () => {
              const isReferenceEntity = referenceEntities?.data?.some(
                (entity) => entity?.custom_fields?.some((field) => column?.field_id?.includes(field?.id)),
              );

              const actionButton = action_buttons?.find((button) => column?.field_id?.includes(button?.id));

              return (
                <DraggableTableViewColumn id={column?.id} column={column} updateColumns={updateColumns} viewType={viewType}>
                  {!isReferenceEntity
                    ? column?.name || actionButton?.title || quoteFields.find((quote) => column?.field_id?.includes(quote?.id))?.title
                    : ` ${referenceEntities?.data?.find((entity) => entity?.custom_fields?.some((field) => column?.field_id?.includes(field?.id)))
                        ?.entity_name} ${referenceEntities?.data
                        ?.find((entity) => entity?.custom_fields?.some((field) => column?.field_id?.includes(field?.id)))
                        ?.custom_fields?.find((field) => column?.field_id?.includes(field?.id))?.title}`}
                </DraggableTableViewColumn>
              );
            },
            render: (_: unknown, record: IEntityWithFields) => {
              return {
                props: {},
                children: column?.field_id?.map((fieldId) => {
                  const isReferenceEntity = referenceEntities?.data?.some((entity) => entity?.custom_fields?.some((field) => field?.id === fieldId));

                  const referenceEntity = referenceEntities?.data?.find((entity) => entity?.custom_fields?.find((field) => field?.id === fieldId));

                  const referenceEntityField = referenceEntities?.data
                    ?.find((entity) => entity?.custom_fields?.find((field) => field?.id === fieldId))
                    ?.custom_fields?.find((customField) => customField?.id === fieldId);

                  const field = isReferenceEntity
                    ? record?.custom_fields
                        ?.filter((field) => field?.reference_entity_type_id === referenceEntity?.entity_id)?.[0]
                        ?.field_data?.[0]?.custom_fields?.find((customField) => customField?.id === referenceEntityField?.id)
                    : record?.custom_fields?.find((field) => field?.id === fieldId);

                  const actionButton = action_buttons?.find((button) => column?.field_id?.includes(button?.id));

                  return (
                    <EditableCell
                      key={fieldId}
                      style={(column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style as unknown as ICustomFieldSpecificStyle}
                      column={column}
                      updateColumns={updateColumns}
                      removeColumn={removeColumn}
                      fieldId={fieldId}
                      field={field}
                      viewType={viewType}
                      quotes={quotes}
                      actionButton={actionButton}>
                      <CustomFieldValue
                        key={fieldId}
                        style={(column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style as unknown as ICustomFieldSpecificStyle}
                        field={field}
                        quotes={quotes}
                        showDummyData={true}
                        column={column}
                        fieldId={fieldId}
                        record={
                          isReferenceEntity
                            ? (record?.custom_fields?.filter((field) => field?.reference_entity_type_id === referenceEntity?.entity_id)?.[0]
                                ?.field_data?.[0] as unknown as IEntityWithFields)
                            : record
                        }
                      />
                    </EditableCell>
                  );
                }),
              };
            },
          })),
        ]
      : [];
  }, [columns, managementFields, referenceEntities, quotes]);
};
