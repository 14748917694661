import { useTranslation } from 'react-i18next';

import { Row, Tabs } from 'antd';

import { SupportChatSocketProvider } from '@aduvi/providers/SupportChatSocketProvider';
import { ESupportModalTabs } from '@aduvi/types/ticket';

import { Academy } from './@components/academy/Academy';
import { ContactTab } from './@components/contact/ContactTab';
import { Documentation } from './@components/documentation/Documentation';
import { LiveChat } from './@components/live-chat/LiveChat';

export const SupportModalTabs = ({ setSupportModalVisible }: { setSupportModalVisible: (value: React.SetStateAction<boolean>) => void }) => {
  const tabItems = useTabItems({ setSupportModalVisible });

  return (
    <Row className='support-modal-tabs-wrapper w-full' justify='center'>
      <Tabs items={tabItems} className='w-full support-modal-tabs' />
    </Row>
  );
};

const useTabItems = ({ setSupportModalVisible }: { setSupportModalVisible: (value: React.SetStateAction<boolean>) => void }) => {
  const { t: translate } = useTranslation();
  return [
    // {
    //   key: ESupportModalTabs.TROUBLESHOOTER,
    //   label: translate('support.tabs.troubleshooter'),
    //   children: <Troubleshooter />,
    // },
    {
      key: ESupportModalTabs.LIVE_CHAT,
      label: translate('support.tabs.liveChat'),
      children: (
        <SupportChatSocketProvider>
          <LiveChat />
        </SupportChatSocketProvider>
      ),
    },
    {
      key: ESupportModalTabs.DOCUMENTATION,
      label: translate('support.tabs.documentation'),
      children: <Documentation />,
    },
    {
      key: ESupportModalTabs.ACADEMY,
      label: translate('support.tabs.academy'),
      children: <Academy setSupportModalVisible={setSupportModalVisible} />,
    },
    {
      key: ESupportModalTabs.CONTACT,
      label: translate('support.tabs.contact'),
      children: <ContactTab />,
    },
  ];
};
