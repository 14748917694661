import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RightOutlined, SettingOutlined } from '@ant-design/icons';

import { Button, Card, Dropdown, Row, Select } from 'antd';

import { ACTION_BUTTON_SETTINGS } from '@aduvi/constants';
import { EActionButtonSettings, ICustomFieldSpecificStyle, ICustomFieldViewStyle } from '@aduvi/types';

export const ActionButtonSettings = ({
  updateStyleForFieldId,
  column,
  fieldId,
}: {
  updateStyleForFieldId: (newStyle: Partial<ICustomFieldViewStyle['style']>) => void;
  column: ICustomFieldViewStyle;
  fieldId?: string;
}) => {
  const { t: translate } = useTranslation();

  const { Option } = Select;
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleTarget = (action: EActionButtonSettings) => {
    const defaultTarget = action || EActionButtonSettings.CURRENT_TAB;
    updateStyleForFieldId({ target: defaultTarget });
    setDropdownVisible(false);
  };

  return (
    <Dropdown
      overlay={
        <Card className='style-modal'>
          <div className='flex flex-direction-column'>
            <Select
              className='settings-select w-full'
              onChange={handleTarget}
              defaultValue={
                (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.target || EActionButtonSettings.CURRENT_TAB
              }>
              {ACTION_BUTTON_SETTINGS.map((setting) => (
                <Option key={setting.value} value={setting?.value}>
                  {translate(setting?.label)}
                </Option>
              ))}
            </Select>
          </div>
        </Card>
      }
      trigger={['click']}
      open={dropdownVisible}
      onOpenChange={(visible) => setDropdownVisible(visible)}>
      <Row>
        <Button icon={<SettingOutlined />} type='text' className='w-full text-left flex align-center'>
          {translate('action-buttons.settings')}
          <RightOutlined className='fs-10' style={{ marginLeft: '20px' }} />
        </Button>
      </Row>
    </Dropdown>
  );
};
