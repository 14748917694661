import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactFlow, { Background, Edge, EdgeProps, Node, NodeProps, OnEdgesChange, OnNodesChange } from 'reactflow';
import { v4 as uuidv4 } from 'uuid';

import 'reactflow/dist/style.css';

import { useBusiness } from '@aduvi/hooks';
import { ENodeType, INode } from '@aduvi/types/automation';
import { insertNode } from '@aduvi/utils/helper';

import { setAutomationMentions, setDuplicateNodeId, setLastNodesId } from 'store/features/automation-slice';
import { getAllConnectedAccounts } from 'store/features/connected-account-slice';
import { getEntityFields } from 'store/features/fields-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import CustomEdge from './edges/ButtonEdge';
import DefaultEdge from './edges/DefaultEdge';
import { AddNodeDrawer } from './nodes/AddNodeDrawer';
import BranchNode from './nodes/BranchNode';
import { FORM_TRIGGERS, SCHEDULED_TRIGGERS, STATUS_TRIGGERS } from './nodes/constants';
import CustomNode, { CustomNodeData } from './nodes/CustomNode';
import { NodeSettingsDrawer } from './nodes/node-settings/NodeSettingsDrawer';

interface IProps {
  nodes: Node[];
  edges: Edge[];
  selectedEdge?: EdgeProps;
  setNodes: Dispatch<SetStateAction<Node[]>>;
  onNodesChange: OnNodesChange;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
  onEdgesChange: OnEdgesChange;
  setSelectedEdge: Dispatch<SetStateAction<EdgeProps | undefined>>;
}
const ACTION_TRIGGERS = [...FORM_TRIGGERS, ...SCHEDULED_TRIGGERS, ...STATUS_TRIGGERS];

const AutomationBuilder = ({ edges, nodes, selectedEdge, onEdgesChange, onNodesChange, setEdges, setNodes, setSelectedEdge }: IProps) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();

  const selectedBusiness = useBusiness();

  const { fields, loading } = useAppSelector((state) => state.fields);
  const { duplicateNodeId, lastNodesId } = useAppSelector((state) => state.automation);

  const [showNodesDrawer, setShowNodesDrawer] = useState(false);
  const [showNodesSettingsDrawer, setShowNodesSettingsDrawer] = useState(false);
  const [selectedNode, setSelectedNode] = useState<Node | undefined>(undefined);

  const onNodeClick = (node?: Node) => {
    const isTriggerWithAction = node?.data.nodeType === ENodeType.TRIGGER && ACTION_TRIGGERS.includes(node?.data.action);

    if (node?.data.nodeType === ENodeType.TRIGGER && !isTriggerWithAction) return;
    setSelectedNode(node);
    setShowNodesSettingsDrawer(true);
  };

  const nodeTypes = useMemo(() => {
    return {
      custom: (props: NodeProps<CustomNodeData>) => <CustomNode {...props} onNodeClick={onNodeClick} />,
      branch: (props: NodeProps<CustomNodeData>) => <BranchNode {...props} />,
    };
  }, [lastNodesId.length]);

  const edgeTypes = useMemo(() => {
    return {
      custom: (props: EdgeProps) => <CustomEdge {...props} onEdgeClick={onEdgeClick} onDuplicateNodeDrop={onDuplicateNodeDrop} />,
      default: (props: EdgeProps) => <DefaultEdge {...props} onEdgeClick={onEdgeClick} />,
    };
  }, [duplicateNodeId]);

  const onEdgeClick = (data: EdgeProps) => {
    setSelectedEdge(data);
    setShowNodesDrawer(true);
  };

  const onNodeSelect = (data: INode) => {
    if (data.node_type === ENodeType.TRIGGER && selectedBusiness?.id && data?.triggerable_id && data.id) {
      dispatch(getEntityFields({ businessId: selectedBusiness?.id, entityTypeId: data?.triggerable_id, ignoreFormOnly: true }))
        .unwrap()
        .then((response) => {
          dispatch(
            setAutomationMentions(
              response.data.map((item) => ({
                id: item.id,
                node_id: '',
                field_type: item?.value_type,
                name: item?.title,
                triggerId: data?.id,
              })),
            ),
          );
        })
        .catch(() => {});
    }

    if (!nodes.length) {
      setShowNodesDrawer(false);
      return setNodes([
        {
          id: data.id,
          type: 'custom',
          position: {
            x: 0,
            y: 0,
          },
          data: {
            label: data.title,
            nodeType: data.node_type,
            triggerable_type: data.triggerable_type,
            triggerable_id: data.triggerable_id,
            action: data.action,
            payload: data.payload,
          },
        },
      ]);
    }
    if (!selectedEdge) return;

    const selectedEdgeIds = selectedEdge.id.split('->');

    setShowNodesDrawer(false);

    setNodes((prev) =>
      insertNode(
        prev,
        {
          id: data.id,
          type: 'custom',
          position: {
            x: (selectedEdge?.targetX + selectedEdge?.sourceX) / 2 - 130,
            y: (selectedEdge?.targetY - selectedEdge?.sourceY) / 2 + selectedEdge?.sourceY - 30,
          },
          data: {
            label: data.title,
            nodeType: data.node_type,
            triggerable_type: data.triggerable_type,
            triggerable_id: data.triggerable_id,
            action: data.action,
            payload: data.payload,
          },
        },
        selectedEdgeIds[0],
        selectedEdgeIds[1],
      ),
    );

    setEdges((prev) =>
      [
        ...prev,
        {
          id: `${selectedEdge?.id.split('->')[0]}->${data.id}`,
          source: selectedEdge?.id.split('->')[0],
          target: data.id,
          type: 'custom',
          style: { stroke: '#69C0FF', strokeWidth: 2 },
        },
        {
          id: `${data.id}->${selectedEdge?.id.split('->')[1]}`,
          source: data.id,
          target: selectedEdge?.id.split('->')[1],
          type: 'custom',
          style: { stroke: '#69C0FF', strokeWidth: 2 },
        },
      ].filter((item) => item.id !== selectedEdge.id),
    );
  };

  const onDuplicateNodeDrop = (id: string, data: { id: string; sourceX: number; sourceY: number; targetX: number; targetY: number }) => {
    const duplicateNode = nodes?.find((item) => item?.id === duplicateNodeId);

    const selectedEdgeIds = data.id.split('->');

    const duplicatedNodeData = {
      ...duplicateNode,
      id: uuidv4(),
      position: {
        x: (data.targetX + data.sourceX) / 2 - 130,
        y: (data.targetY - data.sourceY) / 2 + data.sourceY - 30,
      },
      data: {
        ...duplicateNode?.data,
      },
    };

    setNodes((prev) => insertNode(prev, duplicatedNodeData, selectedEdgeIds[0], selectedEdgeIds[1]));
    setEdges((prev) =>
      [
        ...prev,
        {
          id: `${data?.id.split('->')[0]}->${duplicatedNodeData.id}`,
          source: data?.id.split('->')[0],
          target: duplicatedNodeData.id,
          type: 'custom',
          style: { stroke: '#69C0FF', strokeWidth: 2 },
        },
        {
          id: `${duplicatedNodeData.id}->${data?.id.split('->')[1]}`,
          source: duplicatedNodeData.id,
          target: data?.id.split('->')[1],
          type: 'custom',
          style: { stroke: '#69C0FF', strokeWidth: 2 },
        },
      ].filter((item) => item.id !== data.id),
    );

    dispatch(setDuplicateNodeId(undefined));
  };

  useEffect(() => {
    dispatch(setLastNodesId(nodes.filter((item) => !edges.some((edge) => edge.source === item.id)).map((item) => item.id)));
  }, [nodes.length, edges.length]);

  useEffect(() => {
    if (!selectedBusiness?.id) return;
    dispatch(getAllConnectedAccounts({ businessId: selectedBusiness?.id }));
  }, [selectedBusiness?.id]);

  return (
    <div>
      <div style={{ height: '100vh' }}>
        <ReactFlow
          nodes={nodes}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          edges={edges}
          edgeTypes={edgeTypes}
          onEdgesChange={onEdgesChange}
          deleteKeyCode={''}
          fitView>
          {nodes.length === 0 ? (
            <div
              style={{
                position: 'absolute',
                transform: 'translate(42vw, 5vh)',
                zIndex: 1000,
                background: 'white',
                borderRadius: '4px',
                padding: '10px',
                color: '#1890FF',
                border: '2px dashed #D8D8D8',
                width: '260px',
                height: '65px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className='cursor-pointer'
              onClick={() => setShowNodesDrawer(true)}>
              + {translate('automations.addTrigger')}
            </div>
          ) : null}
          <Background />
        </ReactFlow>
      </div>
      {showNodesDrawer && (
        <AddNodeDrawer
          open={showNodesDrawer}
          nodesLength={nodes.length}
          canBeLastNode={false}
          onClose={() => setShowNodesDrawer(false)}
          onNodeSelect={onNodeSelect}
        />
      )}
      {showNodesSettingsDrawer && (
        <NodeSettingsDrawer
          open={showNodesSettingsDrawer}
          selectedNode={selectedNode}
          edges={edges}
          nodes={nodes}
          isFetchingFields={loading}
          entityFields={fields}
          onClose={() => setShowNodesSettingsDrawer(false)}
          setNodes={setNodes}
          setEdges={setEdges}
        />
      )}
    </div>
  );
};

export default AutomationBuilder;
