import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { Button, Col, Form, Layout, Modal, notification, Row, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { EFieldDataValueType, EReferenceEntityType, IContract } from '@aduvi/types';

import { signEntityContract } from 'store/features/form-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Signature } from '../signature/Signature';

interface IProps {
  open: boolean;
  contract_template?: IContract;
  onClose: () => void;
}

export const SignContractModal = ({ open, contract_template, onClose }: IProps) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const [form] = useForm();

  const {
    entities: { selectedEntity },
  } = useAppSelector((state) => state.entity);
  const { selectedEntityForm, loading } = useAppSelector((state) => state.forms);

  const contractFields = useMemo(() => {
    return selectedEntityForm?.contract_custom_fields;
  }, [selectedEntityForm]);

  Form.useWatch(form);

  const onContractSubmit = () => {
    if (!form.getFieldValue(['contract_style', 0, 'primary_signature'])) {
      return notification.info({
        message: translate('forms.forms.contractNotSigned'),
        description: translate('forms.forms.contractNotSignedDescription'),
      });
    }

    const formUrl = location.pathname.split('/forms/')?.[1];

    const contractFormFieldId = selectedEntityForm?.field_instances?.find(
      (item) => item.field.reference_entity_type === EReferenceEntityType.CONTRACT,
    )?.field?.id;
    const contractFieldId = contractFields?.find(
      (item) => item.value_type === EFieldDataValueType.FIELD_DATA_REFERENCE && item.reference_entity_type === EReferenceEntityType.CONTRACT_TEMPLATE,
    )?.id;

    const contractSignatureFieldId = contractFields?.find((item) => item.value_type === EFieldDataValueType.FIELD_DATA_SIGNATURES)?.id;

    const signerId = selectedEntity?.custom_fields.find(
      (item) => item.value_type === EFieldDataValueType.FIELD_DATA_REFERENCE && item.reference_entity_type === EReferenceEntityType.CONTACT,
    )?.id;

    if (
      !selectedEntity?.id ||
      !formUrl ||
      !contractFormFieldId ||
      !contractSignatureFieldId ||
      !signerId ||
      !contractFieldId ||
      !selectedEntityForm?.contract_template?.id
    )
      return;

    dispatch(
      signEntityContract({
        formUrl,
        entityId: selectedEntity?.id,
        body: {
          field_id: contractFormFieldId,
          custom_fields: [
            {
              field_id: contractSignatureFieldId,
              signature: {
                signature: form.getFieldValue(['contract_style', 0, 'primary_signature']),
                signer_type: 'signer_user',
                ip: '192.168.0.12', // TO_DO
                signer_id: signerId,
              },
            },
            {
              field_id: contractFieldId,
              entity_references: [selectedEntityForm?.contract_template?.id],
            },
          ],
        },
      }),
    )
      .unwrap()
      .then(() => {
        form.resetFields();
        onClose();
      })
      .catch(() => {});
  };

  return (
    <Modal
      title={translate('forms.forms.signContract')}
      open={open}
      onCancel={onClose}
      footer={null}
      width={800}
      centered={true}
      styles={{ body: { overflowY: 'auto', maxHeight: 'calc(100vh - 100px)' } }}>
      <Row className={`edit-contract-container  'm-0'`}>
        <Layout>
          <Form layout='vertical' form={form} requiredMark={false} onFinish={onContractSubmit}>
            <Row style={{ minHeight: '100%', background: form.getFieldValue(['contract_style', 0, 'background_color']) }}>
              <Col span={24} className='mt-30 px-30'>
                <Typography.Text>{contract_template?.title}</Typography.Text>
                <Row className='w-full' justify='center'>
                  <Col span={24} className='mt-20'>
                    <Row
                      className='editor-wrapper'
                      onClick={focus}
                      style={{
                        fontFamily: contract_template?.contract_style?.[0]?.font_family,
                        color: contract_template?.contract_style?.[0]?.primary_font_color,
                        background: contract_template?.contract_style?.[0]?.form_background,
                      }}>
                      <Col>
                        <div dangerouslySetInnerHTML={{ __html: contract_template?.content ?? '' }} />
                      </Col>

                      <Col>
                        <Signature form={form} isPreviewMode={false} isSignFromForm />
                        <Typography.Text className='signature-acknowledgement'>
                          {translate('settings.contracts.signatureAcknowledgementText')}{' '}
                          <NavLink target='_blank' to='https://en.wikipedia.org/wiki/Electronic_signature' style={{ textDecoration: 'underline' }}>
                            {translate('settings.contracts.here')}
                          </NavLink>
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Row justify={'end'} className='mb-10'>
                      <Button loading={loading} disabled={loading} type='primary' htmlType='submit'>
                        {translate('settings.contracts.editContract.save')}
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Layout>
      </Row>
    </Modal>
  );
};
