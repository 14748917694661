import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CarOutlined } from '@ant-design/icons';
import { DirectionsRenderer, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import { Button, Col, Row } from 'antd';
import './Map.scss';
interface IUserLocationProps {
  lat?: number;
  lng?: number;
}

const mapContainerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '5px',
};

export const Map = ({ eventDestination, route, disabled }: { eventDestination: IUserLocationProps; route?: string; disabled?: boolean }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  });

  const { t: translate } = useTranslation();
  const [showDirections, setShowDirections] = useState(false);
  const [userLocation, setUserLocation] = useState<IUserLocationProps>({ lat: 0, lng: 0 });

  const [directions, setDirections] = useState<google.maps.DirectionsResult | null>(null);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setUserLocation({ lat: position.coords.latitude, lng: position.coords.longitude });
      });
    }
  };

  const handleGetDirections = useCallback(() => {
    const DirectionsService = new google.maps.DirectionsService();
    const origin = new google.maps.LatLng(userLocation.lat!, userLocation.lng!);

    DirectionsService.route(
      {
        origin,
        destination: route!,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === 'OK') {
          setDirections(result);
          setShowDirections(true);
        }
      },
    );

    setShowDirections(true);
  }, [userLocation, eventDestination, route]);

  useEffect(() => {
    getUserLocation();
  }, [route]);

  if (loadError) {
    return <div>{translate('maps.error')}</div>;
  }

  if (!isLoaded) {
    return <div>{translate('maps.loading')}</div>;
  }
  return (
    <>
      <Row className='map-wrapper'>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={15}
          center={{
            lng: eventDestination?.lng || userLocation?.lng!,
            lat: eventDestination?.lat || userLocation?.lat!,
          }}>
          <Marker position={{ lng: eventDestination.lng || userLocation.lng!, lat: eventDestination.lat || userLocation.lat! }} />
          {showDirections && directions && <DirectionsRenderer directions={directions} />}
        </GoogleMap>
        <Col className='get-direction-button'>
          <Button type='link' disabled={disabled} onClick={handleGetDirections}>
            {translate('maps.getDirection')} <CarOutlined />
          </Button>
        </Col>
      </Row>
    </>
  );
};
