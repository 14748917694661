import { IQuote } from '@aduvi/types';

export const draggableQuoteFields = [
  {
    id: 'quote_table',
    title: 'Quote Table',
    value: 'Example Text',
  },

  {
    id: 'remaining_balance',
    title: 'Remaining Balance',
    value: '42',
  },
  {
    id: 'total_paid',
    title: 'Total Paid',
    value: '42',
  },
  {
    id: 'unpaid_paid_stamp',
    title: 'Unpaid / Paid Stamp',
    value: 'Example Text',
  },
  {
    id: 'tax',
    title: 'Tax',
    value: '10%',
  },
  {
    id: 'subtotal',
    title: 'Subtotal',
    value: '42',
  },
  {
    id: 'packages',
    title: 'Packages',
    value: 'Example Text',
  },
  {
    id: 'products',
    title: 'Products',
    value: 'Example Text',
  },
];

export const getQuoteFieldNames = (quotes?: IQuote[]) => {
  if (!quotes) return [];

  return [
    {
      id: 'quote_table',
      title: 'Quote Table',
    },
    {
      id: 'unpaid_paid_stamp',
      title: 'Unpaid / Paid Stamp',
    },
    {
      id: 'remaining_balance',
      title: 'Remaining Balance',
    },
    {
      id: 'total_paid',
      title: 'Total Paid',
    },
    {
      id: 'services',
      title: 'Services',
    },
    {
      id: 'tax',
      title: 'Tax',
    },
    {
      id: 'subtotal',
      title: 'Subtotal',
    },
    {
      id: 'packages',
      title: 'Packages',
    },
    {
      id: 'products',
      title: 'Products',
    },
  ];
};
