import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InboxOutlined } from '@ant-design/icons';

import type { UploadFile as AntUploadFileType, UploadProps } from 'antd';
import { Upload } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/es/upload';

import { Paragraph } from '@aduvi/components/Paragraph';
import { EFileExtensions, IUploadFile } from '@aduvi/types';
import { Notification } from '@aduvi/utils/notification';

import { uploadImage } from 'store/features/common-slice';
import { useAppDispatch } from 'store/hooks';

interface IProps {
  name: string | string[];
  url?: string;
  uploadSettings?: {
    maximum_upload_size: string;
    allowed_extensions: EFileExtensions[];
  };
  folderName?: string;
  onUploadChange: (file: IUploadFile | null) => void;
  skipUploadToServer?: boolean;
  onFileUploaded?: (file: RcFile) => void;
}

export const FileUpload = ({
  name,
  url,
  uploadSettings,
  folderName,
  onUploadChange,
  onFileUploaded,
  skipUploadToServer = false,
  ...rest
}: UploadProps & IProps) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const [file, setFile] = useState<AntUploadFileType[]>([]);

  const onFileUpload = (file: RcFile) => {
    onFileUploaded?.(file);

    if (uploadSettings?.allowed_extensions) {
      if (!uploadSettings.allowed_extensions.some((extension) => file.type.toUpperCase().endsWith(extension))) {
        return Notification.error({
          title: translate('common.errorWhileUploading'),
          description: translate('common.allowedExtensions', { item: uploadSettings.allowed_extensions.map((item) => item).join(', ') }),
        });
      }
      if (Number(uploadSettings.maximum_upload_size) < file.size / 1024 / 1024) {
        return Notification.error({
          title: translate('common.errorWhileUploading'),
          description: translate('common.allowedSize', { item: uploadSettings.maximum_upload_size + 'MB' }),
        });
      }
    }

    const formData = new FormData();

    formData.append('file', file);

    if (folderName) {
      formData.append('folder', folderName);
    }

    setFile([
      {
        uid: '1',
        name: skipUploadToServer ? file.name : translate('common.uploading'),
        status: skipUploadToServer ? 'done' : 'uploading',
      },
    ]);

    if (skipUploadToServer) return;

    dispatch(uploadImage(formData))
      .unwrap()
      .then((res) => {
        onUploadChange({ url: res.data.file_name, size: (file.size / 1000000).toString(), extension: file.type.split('/').at(-1)?.toUpperCase() });
        setFile([
          {
            uid: res.data.file_name,
            name: 'file',
            url: res.data.file_name,
            thumbUrl: res.data.file_name,
          },
        ]);
      })
      .catch(() => {
        setFile([
          {
            uid: '1',
            name: translate('common.errorWhileUploading'),
            status: 'error',
          },
        ]);
      });
    return false;
  };

  const initializeFileAfterUpload = (url?: string) => {
    if (!url) return setFile([]);

    setFile([
      {
        name,
        url,
        uid: '1',
        status: 'done',
        thumbUrl: url,
      },
    ]);
  };

  const onFileUploadChange = (event: UploadChangeParam) => {
    if (!event.fileList?.length) {
      setFile([]);
      return onUploadChange(null);
    }
  };

  useEffect(() => {
    initializeFileAfterUpload(url);
  }, [url]);

  return (
    <Upload.Dragger
      name={'file'}
      fileList={file}
      disabled={rest.disabled}
      {...rest}
      beforeUpload={(file) => {
        onFileUploaded?.(file);
        onFileUpload(file);
      }}
      onChange={(event) => onFileUploadChange(event)}>
      <Paragraph className='ant-upload-drag-icon'>
        <InboxOutlined />
      </Paragraph>
      <Paragraph className='ant-upload-text'>{translate('components.customFieldsDrawer.fileUploadText')}</Paragraph>
      <Paragraph className='ant-upload-hint'>{translate('components.customFieldsDrawer.fileUploadHint')}</Paragraph>
    </Upload.Dragger>
  );
};
