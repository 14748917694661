import { EFieldLabelAligment } from '@aduvi/types';

export const getFieldLayoutStyles = (isLabelOnTop: boolean, isLabelInline: boolean): React.CSSProperties => {
  return {
    display: isLabelOnTop ? 'block' : isLabelInline ? 'flex' : undefined,
    flexDirection: isLabelOnTop ? 'column' : 'row',
    alignItems: isLabelOnTop ? 'flex-start' : isLabelInline ? 'center' : undefined,
  };
};

export const getFieldInlineStyles = (isLabelInline: boolean): React.CSSProperties => ({
  display: isLabelInline ? 'flex' : undefined,
  alignItems: isLabelInline ? 'center' : undefined,
  gap: isLabelInline ? '8px' : undefined,
});

export const getLabelAlignmentStyles = (alignment: EFieldLabelAligment): React.CSSProperties => ({
  order: alignment === EFieldLabelAligment.RIGHT ? 2 : 1,
  marginLeft: alignment === EFieldLabelAligment.RIGHT ? '8px' : undefined,
  marginRight: alignment === EFieldLabelAligment.RIGHT ? undefined : '8px',
});
