import { useState } from 'react';
import ColorPicker from 'react-best-gradient-color-picker';
import { useTranslation } from 'react-i18next';
import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  DesktopOutlined,
  FontSizeOutlined,
  LayoutOutlined,
  MobileOutlined,
  PictureOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { DragHandleIcon } from 'views/widgets/assets/DragHandleIcon';

import { Button, Col, Divider, Form, FormInstance, Input, Popover, Radio, Row, Slider, Switch, Typography } from 'antd';
import './RightSidebar.scss';

import { ColorPicker as AntColorPicker } from '@aduvi/components/ColorPicker/ColorPicker';
import { GradientColorPicker } from '@aduvi/components/ColorPicker/GradientColorPicker';
import { UploadImage } from '@aduvi/components/UploadImage/UploadImage';
import { FONT_SELECTION } from '@aduvi/constants/client-portal-constants';
import { useActiveTheme } from '@aduvi/hooks';
import { determinePickerColor, isValidUrl } from '@aduvi/utils/helper';

interface IProps {
  form: FormInstance;
  onMobileView: () => void;
  isMobileView: boolean;
}

const alignOptions = [
  { label: <AlignLeftOutlined style={{ color: '#00000040' }} />, value: 'left_side' },
  { label: <AlignCenterOutlined style={{ color: '#00000040' }} />, value: 'center' },
  { label: <AlignRightOutlined style={{ color: '#00000040' }} />, value: 'right_side' },
];

export const RightSidebar = ({ form, isMobileView, onMobileView }: IProps) => {
  const activeTheme = useActiveTheme();
  const { t: translate } = useTranslation();

  const [backgroundMode, setBackgroundMode] = useState('colour');

  Form.useWatch('widget_style', form);

  return (
    <Form.Item name={['widget_style']} className='right-sidebar-wrapper'>
      <Popover
        trigger={'click'}
        overlayClassName='widget-fields-popover'
        content={
          <>
            <Typography.Text type='secondary'>{translate('widgets.fields')}</Typography.Text>
            <Row className='mt-10' align={'middle'}>
              <DragHandleIcon />
              <Form.Item name={['widget_style', 0, 'allow_title']} hidden />
              <Switch
                size='small'
                className='ml-10'
                onChange={(checked) => form.setFieldValue(['widget_style', 0, 'allow_title'], checked)}
                checked={Boolean(form.getFieldValue(['widget_style', 0, 'allow_title']))}
              />
              <Typography.Text className='ml-10'>{translate('widgets.titleText')}</Typography.Text>
            </Row>
            <Divider className='mb-10' />
            <Typography.Text type='secondary'>{translate('widgets.titleText')}</Typography.Text>
            <Form.Item name={['widget_style', 0, 'widget_title']}>
              <Input size='small' />
            </Form.Item>
            <Typography.Text type='secondary'>{translate('widgets.buttonText')}</Typography.Text>
            <Form.Item name={['widget_style', 0, 'button_text']}>
              <Input size='small' />
            </Form.Item>
          </>
        }
        placement='leftTop'>
        <SettingOutlined className='icon' />
      </Popover>

      <Popover
        trigger={'click'}
        content={
          <>
            <Form.Item name={['widget_style', 0, 'background_mode']} initialValue='colour'>
              <Radio.Group
                onChange={(event) => {
                  setBackgroundMode(event.target.value);
                  form.setFieldValue(['widget_style', 0, 'background_mode'], event.target.value);
                }}
                className='flex-center mb-10'
                value={backgroundMode}
                defaultValue={form.getFieldValue(['widget_style', 0, 'background_mode'])}>
                <Radio.Button style={{ width: '100px', textAlign: 'center' }} value='colour'>
                  {translate('forms.forms.colour')}
                </Radio.Button>
                <Radio.Button style={{ width: '100px', textAlign: 'center' }} value='image'>
                  {translate('forms.forms.image')}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            {backgroundMode === 'colour' ? (
              <Form.Item name={['widget_style', 0, 'background_color']} className='flex-center' getValueFromEvent={determinePickerColor}>
                <ColorPicker onChange={() => {}} width={230} height={150} />
              </Form.Item>
            ) : (
              <>
                <Form.Item name={['widget_style', 0, 'background_image']}>
                  <UploadImage
                    name={'image'}
                    onUploadChange={(value) => form.setFieldValue(['widget_style', 0, 'background_image'], value?.url)}
                    text={translate('forms.forms.uploadYourOwn')}
                    onPreview={(value) => form.setFieldValue(['widget_style', 0, 'background_image'], value.thumbUrl)}
                    fileList={
                      isValidUrl(form.getFieldValue(['widget_style', 0, 'background_image']))
                        ? [
                            {
                              name: form.getFieldValue(['widget_style', 0, 'background_image']).split('/uploads/')[1],
                              url: form.getFieldValue(['widget_style', 0, 'background_image']),
                              uid: '-1',
                              status: 'done',
                            },
                          ]
                        : []
                    }
                  />
                  <Typography.Text type='secondary'>{translate('forms.forms.selectFromLibrary')}</Typography.Text>
                  {Array.from({ length: 4 }).map((_, index) => (
                    <Col
                      style={{
                        border:
                          form.getFieldValue(['widget_style', 0, 'background_image']) === `assets/images/theme-${index + 1}.png`
                            ? `2px dashed #7cc0ff `
                            : '',
                      }}
                      key={index}
                      className='mt-10 cursor-pointer'
                      onClick={() => form.setFieldValue(['widget_style', 0, 'background_image'], `assets/images/theme-${index + 1}.png`)}>
                      <img src={require(`assets/images/theme-${index + 1}.png`)} />
                    </Col>
                  ))}
                </Form.Item>
              </>
            )}
          </>
        }
        placement='leftTop'>
        <PictureOutlined className='icon' />
      </Popover>

      <Popover
        trigger={'click'}
        content={
          <div style={{ minWidth: '235px' }}>
            <Form.Item name={['widget_style', 0, 'border_radius']} hidden />
            <Typography.Text type='secondary'>{translate('widgets.borderRadius')}</Typography.Text>
            <Row className='w-full mb-10' justify={'space-between'} align={'middle'}>
              <Col style={{ border: '1px solid #D9D9D9', borderRadius: '2px' }} className='pl-10 pr-10'>
                {form.getFieldValue(['widget_style', 0, 'border_radius']) ?? 0}px
              </Col>
              <Col span={18}>
                <Slider min={1} max={20} onChange={(value) => form.setFieldValue(['widget_style', 0, 'border_radius'], value)} />
              </Col>
            </Row>
            <Typography.Text type='secondary'>{translate('widgets.align')}</Typography.Text>
            <Form.Item name={['widget_style', 0, 'align_fields']} className='w-full'>
              <Radio.Group optionType='button' className='w-full mt-10 flex'>
                {alignOptions.map((item) => (
                  <Radio.Button key={item.value} value={item.value} style={{ flex: 1, textAlign: 'center' }}>
                    {item.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          </div>
        }
        placement='leftTop'>
        <LayoutOutlined className='icon' />
      </Popover>

      <Popover
        trigger={'click'}
        overlayClassName='colour-popover'
        content={
          <Col style={{ width: 200 }}>
            <Typography.Text type='secondary'>{translate('settings.contracts.editContract.colours')}</Typography.Text>

            <Row gutter={8} align={'middle'} className='mb-10 mt-10'>
              <Col>
                <Form.Item
                  name={['widget_style', 0, 'primary_font_color']}
                  initialValue={'#515151'}
                  getValueFromEvent={determinePickerColor}
                  className='mb-0'>
                  <AntColorPicker />
                </Form.Item>
              </Col>
              <Col>
                <Typography.Text>{translate('settings.contracts.editContract.primaryFont')}</Typography.Text>
              </Col>
            </Row>

            <Row gutter={8} className='mb-10' align={'middle'}>
              <Col>
                <Form.Item
                  name={['widget_style', 0, 'label_font']}
                  initialValue={'#515151'}
                  getValueFromEvent={determinePickerColor}
                  className='mb-0'>
                  <AntColorPicker />
                </Form.Item>
              </Col>
              <Col>
                <Typography.Text>{translate('widgets.labelFont')}</Typography.Text>
              </Col>
            </Row>

            <Row gutter={8} className='mb-10' align={'middle'}>
              <Col>
                <Form.Item
                  name={['widget_style', 0, 'field_font']}
                  initialValue={'#515151'}
                  getValueFromEvent={determinePickerColor}
                  className='mb-0'>
                  <AntColorPicker />
                </Form.Item>
              </Col>
              <Col>
                <Typography.Text>{translate('widgets.fieldFont')}</Typography.Text>
              </Col>
            </Row>

            <Row gutter={8} className='mb-10' align={'middle'}>
              <Col>
                <Form.Item
                  name={['widget_style', 0, 'field_background']}
                  initialValue={'#FFFFFF'}
                  getValueFromEvent={determinePickerColor}
                  className='mb-0'>
                  <AntColorPicker />
                </Form.Item>
              </Col>
              <Col>
                <Typography.Text>{translate('widgets.fieldBackground')}</Typography.Text>
              </Col>
            </Row>

            <Row gutter={8} className='mb-10' align={'middle'}>
              <Col>
                <Form.Item
                  name={['widget_style', 0, 'widget_background']}
                  initialValue={'#FFFFFF'}
                  getValueFromEvent={determinePickerColor}
                  className='mb-0'>
                  <GradientColorPicker />
                </Form.Item>
              </Col>
              <Col>
                <Typography.Text>{translate('widgets.widgetBackground')}</Typography.Text>
              </Col>
            </Row>

            <Row gutter={8} className='mb-10' align={'middle'}>
              <Col>
                <Form.Item
                  name={['widget_style', 0, 'button_font']}
                  initialValue={'#FFFFFF'}
                  getValueFromEvent={determinePickerColor}
                  className='mb-0'>
                  <GradientColorPicker />
                </Form.Item>
              </Col>
              <Col>
                <Typography.Text>{translate('widgets.buttonFont')}</Typography.Text>
              </Col>
            </Row>

            <Row gutter={8} className='mb-10' align={'middle'}>
              <Col>
                <Form.Item
                  name={['widget_style', 0, 'button_background']}
                  initialValue={'#1890FF'}
                  getValueFromEvent={determinePickerColor}
                  className='mb-0'>
                  <GradientColorPicker />
                </Form.Item>
              </Col>
              <Col>
                <Typography.Text>{translate('widgets.buttonBackground')}</Typography.Text>
              </Col>
            </Row>

            <Divider className='w-full' />

            <Typography.Text type='secondary' className='pl-5'>
              {translate('settings.contracts.editContract.font')}
            </Typography.Text>

            {FONT_SELECTION.map((font, index) => (
              <Row key={font} gutter={8} className={`mb-5 ${index == 0 && 'mt-10'}`} align={'middle'}>
                <Col>
                  <Form.Item name={['widget_style', 0, 'font_family']} initialValue={'Roboto'} className='mb-0'>
                    <Button
                      className='pl-5'
                      type='link'
                      style={{
                        fontFamily: font,
                        color: font === form.getFieldValue(['widget_style', 0, 'font_family']) ? activeTheme?.menuActiveText : activeTheme?.menuText,
                      }}
                      onClick={() => {
                        form.setFieldValue(['widget_style', 0, 'font_family'], font);
                      }}>
                      {font}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            ))}
          </Col>
        }
        placement='leftTop'>
        <FontSizeOutlined className='icon' />
      </Popover>

      {isMobileView ? <DesktopOutlined className='icon' onClick={onMobileView} /> : <MobileOutlined className='icon' onClick={onMobileView} />}
    </Form.Item>
  );
};
