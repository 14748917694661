import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportOutlined } from '@ant-design/icons';
import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { Button, Col, Collapse, CollapseProps, Form, FormInstance, FormListFieldData, Row } from 'antd';
import './OptionList.scss';

import { Paragraph } from '@aduvi/components/Paragraph';
import { useTerminology } from '@aduvi/hooks';
import { ICustomFieldOption, IManagementFieldOption } from '@aduvi/types';
import { replaceJobWithTerminology } from '@aduvi/utils/helper';

import { SortableFormItem } from './SortableFormItem';

export const OptionList = ({ form }: { form: FormInstance }) => {
  Form.useWatch('typed_options', form);

  const { t: translate } = useTranslation();
  const terminology = useTerminology();

  const onDataDragEnd = ({ active, over }: DragEndEvent, field: FormListFieldData) => {
    if (active.id === over?.id) return;
    const options = form.getFieldValue(['typed_options', field.key, 'options']);

    const activeIndex = Number(active.id) - 1;
    const overIndex = Number(over?.id) - 1;

    if (activeIndex < 0 || overIndex < 0) return;

    const updatedData: ICustomFieldOption[] = arrayMove(options, activeIndex, overIndex);

    form.setFieldValue(
      ['typed_options', field.key, 'options'],
      updatedData.map((item, index) => ({ ...item, weight: index + 1 })).sort((a, b) => a.weight - b.weight),
    );
  };

  const archivedItems = useMemo(() => {
    const typedOptions = form.getFieldValue('typed_options') as { options: ICustomFieldOption[] }[];

    return typedOptions?.map((option) => option?.options?.filter((option) => option?.is_archived)) || [];
  }, [form.getFieldValue('typed_options')]);

  const collapseItems: CollapseProps['items'] = [
    {
      key: '1',
      label: `${translate('components.customFieldsDrawer.optionList.archived')} (${archivedItems?.reduce((acc, curr) => {
        acc += curr?.length;
        return acc;
      }, 0)})`,

      children: (
        <>
          {archivedItems.map((items: ICustomFieldOption[]) => {
            return items?.map((item) => {
              return (
                <Row key={item.id} className='units-users-list-item w-full' align={'middle'} justify={'space-between'}>
                  <Col>{item?.value}</Col>
                  <Col>
                    <Button
                      type='text'
                      icon={
                        <Col className='icon-wrapper'>
                          <ExportOutlined className='fs-22' rotate={270} />
                        </Col>
                      }
                      onClick={() => {
                        form.setFieldValue(
                          ['typed_options', 0, 'options'],
                          form
                            .getFieldValue(['typed_options', 0, 'options'])
                            .map((option: ICustomFieldOption) => (item.id === option.id ? { ...option, is_archived: false } : option)),
                        );
                      }}
                    />
                  </Col>
                </Row>
              );
            });
          })}
        </>
      ),
    },
  ];

  const valueTypes = useMemo(() => {
    const uniqueValueTypes = Array.from(
      new Set(form.getFieldValue('typed_options')?.[0]?.options?.map((option: IManagementFieldOption) => option.value_type)),
    );

    if (uniqueValueTypes?.length === 0) {
      uniqueValueTypes?.push('VALUES');
    }

    return uniqueValueTypes;
  }, [form.getFieldValue('typed_options')]);

  return (
    <Col className='job-management-status-wrapper mt-10'>
      <Form.Item hidden={true} name='options' />
      <Form.List name={'typed_options'} initialValue={[{ name: 'VALUES', options: [] }]}>
        {(fields) => {
          return valueTypes?.map((valueType) => {
            return fields?.map((field) => (
              <div key={field.key}>
                <Col span={24} className='mt-15'>
                  <Row className='w-full'>
                    <Col span={18}>
                      <Paragraph>{replaceJobWithTerminology(translate('settings.jobManagement.jobStatuses'), terminology, true)}</Paragraph>
                    </Col>
                    <Col className='flex flex-direction-row' span={6}>
                      <Paragraph className='ml-15'>{translate('components.customFieldsDrawer.optionList.default')}</Paragraph>
                      <Paragraph className='ml-10'>{translate('components.customFieldsDrawer.optionList.color')}</Paragraph>
                    </Col>
                  </Row>

                  <DndContext
                    collisionDetection={closestCenter}
                    onDragEnd={(event) => onDataDragEnd(event, field)}
                    modifiers={[restrictToVerticalAxis]}>
                    <Form.List name={[field.name, 'options']} initialValue={[]}>
                      {(subFields, subOptions) => {
                        return (
                          <SortableContext items={subFields.map((item) => ({ id: item.key + 1 }))} strategy={verticalListSortingStrategy}>
                            {subFields
                              ?.filter((subField) => {
                                const field2 = form.getFieldValue(['typed_options', field.key])?.options?.[subField?.key];

                                return field2?.value_type === valueType;
                              })
                              .map((subField) => (
                                <SortableFormItem
                                  field={field}
                                  key={subField.key}
                                  subField={subField}
                                  subOptions={subOptions}
                                  id={subField.key + 1}
                                  form={form}
                                />
                              ))}
                            <Col className='mt-15 w-full flex-end'>
                              <Button
                                type='default'
                                onClick={() => {
                                  subOptions.add({
                                    weight: subFields.length + 1,
                                    value_type: valueType,
                                  });
                                }}>
                                {translate('components.customFieldsDrawer.optionList.add')}
                              </Button>
                            </Col>
                          </SortableContext>
                        );
                      }}
                    </Form.List>
                  </DndContext>
                </Col>
              </div>
            ));
          });
        }}
      </Form.List>
      <Collapse ghost items={collapseItems} />
    </Col>
  );
};
