import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ShiftPlanner } from 'views/settings/shift-scheduler/@components/ShiftPlanner';

import { FormInstance, useForm } from 'antd/es/form/Form';

import { Quotes } from '@aduvi/components/Quotes/Quotes';
import { useBusiness } from '@aduvi/hooks';
import { EPersonalizedViewOrigin, EReferenceEntityType, IEntityField } from '@aduvi/types';
import { EntityDrawerTabs, IEntityType, IEntityWithFields } from '@aduvi/types/entity';

import { getEntity } from 'store/features/entity-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { mapEntityFieldsToForm } from '../helper';

import { Activity } from './drawer-tabs/activity/Activity';
import { Contacts } from './drawer-tabs/contacts/Contacts';
import { Details } from './drawer-tabs/details/Details';
import { Files } from './drawer-tabs/files/Files';
import { Forms } from './drawer-tabs/forms/Forms';
import { Tasks } from './drawer-tabs/tasks/Tasks';

interface IProps {
  origin: EPersonalizedViewOrigin;
  entityType?: IEntityType;
  fields: IEntityField[];
  formOnlyFields: IEntityField[];
}

export const useUpsertEntityDrawer = ({ origin, entityType, fields, formOnlyFields }: IProps) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();

  const [form] = useForm();

  const {
    entities: { selectedEntity, creating, updating, loadingEntity },
  } = useAppSelector((state) => state.entity);

  const tabItems = useMemo(() => {
    return getTabItems(form, translate, Boolean(selectedEntity?.id), selectedEntity, origin, fields, formOnlyFields, entityType?.has_quotes);
  }, [translate, form, selectedEntity, origin, fields, entityType?.has_quotes]);

  useEffect(() => {
    if (!selectedBusiness?.id || !entityType?.id || !selectedEntity?.id) return;

    dispatch(
      getEntity({
        businessId: selectedBusiness?.id,
        entityTypeId: entityType?.id,
        entityId: selectedEntity?.id,
      }),
    )
      .unwrap()
      .then(({ data }) => {
        form.setFieldValue('custom_fields', mapEntityFieldsToForm(data, [...fields, ...formOnlyFields]));
      })
      .catch(() => {});
  }, [selectedBusiness?.id, entityType?.id, selectedEntity?.id]);

  return {
    dispatch,
    tabItems,
    selectedEntity,
    creating,
    updating,
    loadingEntity,
    fields,
    formOnlyFields,
    form,
    selectedBusiness,
  };
};

const getTabItems = (
  form: FormInstance,
  translate: (key: string) => string,
  isEdit: boolean,
  selectedEntity: IEntityWithFields | undefined,
  origin: EPersonalizedViewOrigin,
  fields: IEntityField[],
  formOnlyFields: IEntityField[],
  hasQuotes: boolean | undefined,
) => {
  const hasTaskField = fields.some((field) => [EReferenceEntityType.TASK]?.includes(field?.reference_entity_type));
  const hasShiftField = fields.some((field) => [EReferenceEntityType.SHIFT]?.includes(field?.reference_entity_type));

  const tabs = [
    {
      key: EntityDrawerTabs.DETAILS,
      label: translate('leads.layoutDrawer.details'),
      children: <Details fields={fields} formOnlyFields={formOnlyFields} form={form} />,
      disabled: false,
    },
  ];

  switch (origin) {
    case EPersonalizedViewOrigin.LEADS:
    case EPersonalizedViewOrigin.PROJECTS:
    case EPersonalizedViewOrigin.BOOKINGS:
    case EPersonalizedViewOrigin.ORDERS:
      if (hasQuotes) {
        tabs.push({
          key: EntityDrawerTabs.QUOTES,
          label: translate('leads.editJob.quotes'),
          children: <Quotes entityId={selectedEntity?.id} origin={origin} />,
          disabled: !isEdit,
        });
      }
      if (selectedEntity?.is_confirmed && hasShiftField) {
        tabs.push({
          key: EntityDrawerTabs.SHIFTS,
          label: translate('leads.editJob.shifts'),
          children: <ShiftPlanner entityId={selectedEntity?.id} fields={fields} form={form} />,
          disabled: !isEdit,
        });
      }
      tabs.push(
        {
          key: EntityDrawerTabs.CONTACTS,
          label: translate('leads.editJob.contacts'),
          children: <Contacts origin={origin} />,
          disabled: !isEdit,
        },
        {
          key: EntityDrawerTabs.FORMS,
          label: translate('leads.editJob.forms'),
          children: <Forms />,
          disabled: !isEdit,
        },
        {
          key: EntityDrawerTabs.FILES,
          label: translate('leads.editJob.files'),
          children: <Files />,
          disabled: !isEdit,
        },
        {
          key: EntityDrawerTabs.ACTIVITY,
          label: translate('leads.editJob.activity'),
          children: <Activity />,
          disabled: !isEdit,
        },
      );
      break;

    case EPersonalizedViewOrigin.VENUES:
      tabs.push(
        {
          key: EntityDrawerTabs.CONTACTS,
          label: translate('leads.editJob.contacts'),
          children: <Contacts />,
          disabled: !isEdit,
        },
        {
          key: EntityDrawerTabs.FILES,
          label: translate('leads.editJob.files'),
          children: <Files />,
          disabled: !isEdit,
        },
        {
          key: EntityDrawerTabs.ACTIVITY,
          label: translate('leads.editJob.activity'),
          children: <Activity />,
          disabled: !isEdit,
        },
      );
      break;
  }

  if (hasTaskField) {
    tabs.push({
      key: EntityDrawerTabs.TASKS,
      label: translate('leads.editJob.tasks'),
      children: <Tasks />,
      disabled: !isEdit,
    });
  }

  return tabs;
};
