import axios from '@aduvi/config/axios';
import {
  IBuilderBlock,
  IBuilderBlockPayload,
  IBuilderCategory,
  IBuilderCategoryEditPayload,
  IBuilderCategoryPayload,
  IBuilderTemplate,
  IBuilderTemplatesPayload,
  IProposal,
  IProposalPayload,
  IResponse,
} from '@aduvi/types';

export const createBuilderCategory = async (payload: IBuilderCategoryPayload): Promise<IResponse<IBuilderCategory>> => {
  return await axios.post(`/businesses/${payload.businessId}/builder-categories`, payload.body);
};

export const getBuilderCategories = async (businessId: string): Promise<IResponse<IBuilderCategory[]>> => {
  return await axios.get(`/businesses/${businessId}/builder-categories`);
};

export const editBuilderCategory = async (payload: IBuilderCategoryEditPayload): Promise<IResponse<IBuilderCategory>> => {
  return await axios.put(`/businesses/${payload.businessId}/builder-categories/${payload.builderCategoryId}`, payload.body);
};

export const createBuilderTemplates = async (payload: IBuilderTemplatesPayload): Promise<IResponse<IBuilderTemplate>> => {
  return await axios.post(`/businesses/${payload.business_id}/builder-templates`, payload.body);
};

export const editBuilderTemplates = async (templateId: string, payload: IBuilderTemplatesPayload): Promise<IResponse<IBuilderTemplate>> => {
  return await axios.put(`/businesses/${payload.business_id}/builder-templates/${templateId}`, payload.body);
};

export const cloneBuilderTemplates = async (businessId: string, templateId: string): Promise<IResponse<IBuilderTemplate>> => {
  return await axios.post(`/businesses/${businessId}/builder-templates/${templateId}/clone`);
};

export const getBuilderTemplates = async (businessId: string): Promise<IResponse<IBuilderTemplate[]>> => {
  return await axios.get(`/businesses/${businessId}/builder-templates`);
};

export const getBuilderTemplateById = async (businessId: string, builderTemplateId: string): Promise<IResponse<IBuilderTemplate>> => {
  return await axios.get(`/businesses/${businessId}/builder-templates/${builderTemplateId}`);
};

export const deleteBuilderTemplate = async (businessId: string, builderTemplateId: string) => {
  return await axios.delete(`/businesses/${businessId}/builder-templates/${builderTemplateId}`);
};

export const createProposal = async (payload: IProposalPayload): Promise<IResponse<IProposal>> => {
  return await axios.post(`businesses/${payload.business_id}/proposals`, payload);
};

export const getProposals = async (businessId: string): Promise<IResponse<IProposal[]>> => {
  return await axios.get(`businesses/${businessId}/proposals`);
};

export const getProposalById = async (businessId: string, proposalId: string): Promise<IResponse<IProposal>> => {
  return await axios.get(`businesses/${businessId}/proposals/${proposalId}`);
};

export const editProposal = async (proposalId: string, payload: IProposalPayload): Promise<IResponse<IProposal>> => {
  return await axios.put(`businesses/${payload.business_id}/proposals/${proposalId}`, payload);
};

export const deleteProposal = async (businessId: string, proposalId: string) => {
  return await axios.delete(`businesses/${businessId}/proposals/${proposalId}`);
};

export const getBuilderBlocks = async (business_id: string): Promise<IResponse<IBuilderBlock[]>> => {
  return await axios.get(`businesses/${business_id}/builder-blocks`);
};

export const createBuilderBlock = async (payload: IBuilderBlockPayload): Promise<IResponse<IBuilderBlock>> => {
  return await axios.post(`businesses/${payload.business_id}/builder-blocks`, payload.body);
};

export const getBuilderBlockById = async (business_id: string, blockId: string): Promise<IResponse<IBuilderBlock>> => {
  return await axios.get(`businesses/${business_id}/builder-blocks/${blockId}`);
};

export const editBuilderBlock = async (builderBlockId: string, payload: IBuilderBlockPayload) => {
  return await axios.put(`businesses/${payload?.business_id}/builder-blocks/${builderBlockId}`, payload.body);
};

export const deleteBuilderBlock = async (businessId: string, blockId: string) => {
  return await axios.delete(`businesses/${businessId}/builder-blocks/${blockId}`);
};

export const getPublicProposal = async (businessId: string, proposalId: string): Promise<IResponse<IProposal>> => {
  return await axios.get(`public/businesses/${businessId}/proposals/${proposalId}`);
};
