import { IApp } from './common';

export enum EBillingCycle {
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY',
}

export interface IBillingContact {
  id: string;
  business_id: string;
  user_id: string;
  invitation_code: string;
  invitation_status: string;
  position: string | null;
  dashboard_layout: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  partner_theme_id: string | null;
  pivot: {
    business_billing_details_id: string;
    business_user_id: string;
    created_at: string;
    updated_at: string;
  };
  business_user: {
    user_id: string;
    first_name: string;
    last_name: string;
    user: {
      id: string;
      email: string;
      deleted_at: string | null;
      created_at: string;
      updated_at: string;
    };
  };
}

export interface IBillingDetails {
  id: string;
  first_name: string;
  last_name: string;
  company_name: string;
  address_name: string;
  business_user_id: string;
  city_name: string;
  vat_id: string;
  country_id: string;
  business_id: string;
  billing_contacts?: IBillingContact[];
}

export interface IChangePlanResponse {
  id: string;
  business_id: string;
  partner_pricing_id: string;
  previous_partner_pricing_id: string;
  status: string;
  updated_at: string;
  created_at: string;
  plan: IPricing;
}

export interface IBillingState {
  selectedPlan: {
    data?: IPricing;
    businessId?: string;
  };
  previewPlan?: ISubscriptionDetails;
  activePlan?: ISubscriptionDetails;
  billingDetails?: IBillingDetails;
  cards?: ICardsData[];
  businessCards?: ICardsData[];
  default_card?: string;
  defaultBusinessCard?: string;
  loadingCards: boolean;
  creatingCard: boolean;
  deletingCard: boolean;
  editingCard: boolean;
  loading: boolean;
  creating: boolean;
  editing: boolean;
}

export interface IPricing {
  id: string;
  is_active: boolean;
  plan_name: string;
  base_price_monthly: number;
  base_price_yearly: number;
  included_users: number;
  price_per_additional_user_monthly: number;
  price_per_additional_user_yearly: number;
  card_transaction_fee: number;
  remove_branding: boolean;
  apps: IApp[];
}

export interface ICreateCardPayload {
  user_id: string;
  business_id: string;
  stripe_token: string;
}

export interface ICardsData {
  id: string;
  billing_details: {
    address: {
      city: string;
      country: string;
      line1: string;
      line2: string;
      postal_code: string;
      state: string;
    };
    email: string;
    name: string;
    phone: string;
  };
  card: {
    brand: string;
    country: string;
    exp_month: number;
    exp_year: number;
    fingerprint: string;
    last4: string;
  };
}

export interface ICards {
  default_card: string;
  cards: {
    data: ICardsData[];
  };
}

export interface ICreateBusinessCardPayload {
  business_id: string;
  stripe_token: string | null;
}

export interface IPlan {
  id: string;
  name: string;
  price_monthly: number;
  price_yearly: number;
  billing_cycle: EBillingCycle;
  total_amount: number;
  additional_charges: {
    additional_users: {
      monthly_price: number;
      yearly_price: number;
      count: number;
      charge_amount: number;
    };
    additional_apps: {
      count: number;
      charge_amount: number;
    };
    card_transaction_fee: {
      rate: number;
      charge_amount: number;
    };
  };
}

export interface ISubscriptionDetails {
  is_upgrade: boolean;
  current_plan: IPlan;
  new_plan: IPlan;
  next_billing_date: string;
  change_effective_date: string | null;
}
