import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';

import { apiSlice } from './api/apiSlice';
import { academyReducer } from './features/academy-slice';
import { authReducer } from './features/auth-slice';
import { automationReducer } from './features/automation-slice';
import { availabilityReducer } from './features/availability-slice';
import { billingReducer } from './features/billing-slice';
import { businessReducer } from './features/business-slice';
import { supportCallTrackingReducer } from './features/call-tracking-slice';
import { clientPortalAuthReducer } from './features/client-auth-slice';
import { clientPortalReducer } from './features/client-portal-slice';
import { commonReducer } from './features/common-slice';
import { connectedAccountReducer } from './features/connected-account-slice';
import { conversationReducer } from './features/conversation-slice';
import { discountReducer } from './features/discount-slice';
import { documentationReducer } from './features/documentation-slice';
import { entityReducer } from './features/entity-slice';
import { eventsReducer } from './features/events-slice';
import { fieldsReducer } from './features/fields-slice';
import { fileReducer } from './features/file-slice';
import { formReducer } from './features/form-slice';
import { InvoiceReducer } from './features/invoice-settings-slice';
import { manualPaymentReducer } from './features/manual-payment-option-slice';
import { paymentReducer } from './features/payment-gateway-settings-slice';
import { permissionReducer } from './features/permissions-slice';
import { personalizedViewsReducer } from './features/personalized-views-slice';
import { presetBusinessesReducer } from './features/preset-businesses-slice';
import { productReducer } from './features/products-slice';
import { proposalsReducer } from './features/proposals-slice';
import { quoteReducer } from './features/quote-slice';
import { repReducer } from './features/rep-slice';
import { roadmapReducer } from './features/roadmap-slice';
import { servicesReducer } from './features/services-slice';
import { shiftSchedulerReducer } from './features/shift-scheduler-slice';
import { supportChatReducer } from './features/support-chat-slice';
import { taxReducer } from './features/tax-slice';
import { ticketReducer } from './features/ticket-slice';
import { trashReducer } from './features/trash-slice';
import { travelFeesReducer } from './features/travel-fees-slice';
import { userReducer } from './features/user-slice';
import { widgetReducer } from './features/widget-slice';

export const defaultReducers = {
  // reducers
  [apiSlice.reducerPath]: apiSlice.reducer,

  auth: authReducer,
  user: userReducer,
  common: commonReducer,
  business: businessReducer,
  presetBusiness: presetBusinessesReducer,
  permissions: permissionReducer,
  products: productReducer,
  services: servicesReducer,
  billing: billingReducer,
  discounts: discountReducer,
  events: eventsReducer,
  personalizedViews: personalizedViewsReducer,
  tax: taxReducer,
  roadmaps: roadmapReducer,
  quotes: quoteReducer,
  paymentGatewaySettings: paymentReducer,
  manualPaymentOption: manualPaymentReducer,
  entity: entityReducer,
  fields: fieldsReducer,
  conversation: conversationReducer,
  connectedAccount: connectedAccountReducer,
  supportChat: supportChatReducer,
  clientPortal: clientPortalReducer,
  clientPortalAuth: clientPortalAuthReducer,
  file: fileReducer,
  forms: formReducer,
  automation: automationReducer,
  trash: trashReducer,
  academy: academyReducer,
  ticket: ticketReducer,
  travelFees: travelFeesReducer,
  rep: repReducer,
  supportCallTracking: supportCallTrackingReducer,
  proposals: proposalsReducer,
  shiftScheduler: shiftSchedulerReducer,
  invoice: InvoiceReducer,
  availability: availabilityReducer,
  widget: widgetReducer,
  documentation: documentationReducer,
};
const rootReducer = combineReducers(defaultReducers);

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    preloadedState,
    reducer: rootReducer,
  });

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

// eslint-disable-next-line no-unused-vars
export const createParameterSelector = <T, R>(selector: (params: T) => R) => {
  return (_: unknown, params: T) => selector(params);
};

export default store;
