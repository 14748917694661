import { RefObject } from 'react';
import { Editor } from '@tiptap/react';

import { useBusiness, useUser } from '@aduvi/hooks';

import { createMessage, createReply } from 'store/features/conversation-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const useEditorHandler = (
  editorInstance: Editor | null,
  channelId?: string,
  messageContainerRef?: RefObject<HTMLDivElement>,
  isReply?: boolean,
) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const currentUser = useUser();
  const { selectedMessage } = useAppSelector((state) => state.conversation);

  const scrollToBottom = () => {
    if (messageContainerRef?.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  };

  const handleSubmit = (attachments?: string[]) => {
    const content = editorInstance?.getText();

    if (!content?.trim() || !channelId || !selectedBusiness?.id || !currentUser) return;

    if (!isReply) {
      dispatch(
        createMessage({
          businessId: selectedBusiness.id,
          channelId,
          payload: {
            attachments: attachments || [],
            content: editorInstance?.getHTML()!,
            author_id: currentUser.id,
          },
        }),
      );
    } else if (selectedMessage) {
      dispatch(
        createReply({
          businessId: selectedBusiness.id,
          channelId,
          messageId: selectedMessage.id,
          payload: {
            attachments: attachments || [],
            content: editorInstance?.getHTML()!,
            author_id: currentUser.id,
          },
        }),
      );
    }

    setTimeout(() => {
      scrollToBottom();
      editorInstance?.commands.setContent('<p></p>');

      if (messageContainerRef?.current) {
        messageContainerRef.current.parentElement!.scrollTop = messageContainerRef?.current?.parentElement?.offsetHeight!;
      }
    }, 50);
  };

  const handleEditorKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  return { handleEditorKeyDown, handleSubmit, scrollToBottom };
};
