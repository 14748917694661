import React, { useState } from 'react';

import { Image, Row, Upload, UploadFile, UploadProps } from 'antd';

export const FileList = ({ fileList, setFileList }: { fileList: UploadFile[]; setFileList?: (fileList: UploadFile[]) => void }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const handlePreview = async (file: UploadFile) => {
    file.preview = file?.url;

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileList?.(newFileList);

  return (
    <Row>
      <Upload style={{ height: 80 }} listType='picture-card' fileList={fileList} onPreview={handlePreview} onChange={handleChange}>
        {null}
      </Upload>

      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </Row>
  );
};
