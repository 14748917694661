import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyOutlined, DeleteOutlined, MailOutlined, MessageOutlined } from '@ant-design/icons';

import { Button, Col, Pagination, PaginationProps, Row, Table as AntTable, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import './Table.scss';

import { useActiveTheme, useBusiness, useLongPress } from '@aduvi/hooks';
import { EPersonalizedView, EPersonalizedViewOrigin } from '@aduvi/types';
import { IEntityType, IEntityWithFields } from '@aduvi/types/entity';
import { toHumanReadable } from '@aduvi/utils/helper';

import { bulkDelete, bulkDuplicate, getEntities, setCurrentPage, setCurrentPageSize } from 'store/features/entity-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { getStatusForEntities } from '../helper';
import { DraggableRow } from '../Pipeline/DraggableRow';

import { EmailModal } from './EmailModal';

interface IViewTableProps {
  data: IEntityWithFields[];
  columns?: ColumnsType<IEntityWithFields>;
  origin: EPersonalizedViewOrigin;
  entityType?: IEntityType;
  onRowClick?: (data: IEntityWithFields) => void;
  setShowEntityDrawer?: (value: React.SetStateAction<boolean>) => void;
  viewId?: string;
}

export const Table = ({ data, columns, origin, entityType, onRowClick, setShowEntityDrawer, viewId }: IViewTableProps) => {
  const dispatch = useAppDispatch();
  const activeTheme = useActiveTheme();
  const selectedBusiness = useBusiness();

  const { t: translate } = useTranslation();

  const { selectedView } = useAppSelector((state) => state.personalizedViews);
  const { pageSize, total, currentPage } = useAppSelector((state) => state.entity.entities);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const { handleMouseDown, handleMouseUp, checkLongPress } = useLongPress();

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onDuplicate = () => {
    if (!selectedBusiness?.id) return;

    dispatch(bulkDuplicate({ businessId: selectedBusiness?.id, entity_ids: selectedRowKeys as string[], entityTypeId: data?.[0]?.entity_type_id }));
    setSelectedRowKeys([]);
  };

  const onDelete = () => {
    if (!selectedBusiness?.id || !entityType) return;

    dispatch(
      bulkDelete({
        businessId: selectedBusiness?.id,
        entity_ids: selectedRowKeys as string[],
        entityTypeId: data?.[0]?.entity_type_id,
        entityTypeName: entityType?.name,
      }),
    );
    setSelectedRowKeys([]);
  };

  const handlePaginationChange: PaginationProps['onChange'] = (currentPagePagination: number, pageSizePagination: number) => {
    dispatch(setCurrentPage(currentPagePagination));
    dispatch(setCurrentPageSize(pageSizePagination));
  };

  useEffect(() => {
    if (selectedBusiness?.id && entityType?.id && viewId) {
      dispatch(
        getEntities({
          businessId: selectedBusiness?.id,
          entityTypeId: entityType?.id,
          params: {
            status: getStatusForEntities(origin),
            view_display: EPersonalizedView.TABLE,
            page: currentPage,
            size: pageSize,
            paginate: true,
            view_id: viewId,
          },
        }),
      );
    }
  }, [currentPage, pageSize, selectedBusiness?.id, entityType?.id, selectedView?.id]);

  return (
    <Col className='w-full customizable-table-wrapper'>
      {selectedRowKeys.length ? (
        <Row className='bulk-actions-wrapper'>
          <Row className={'table-selected-rows'}>
            <Col className='table-selected-col' style={{ background: activeTheme?.buttonBg }}>
              <Typography.Text className='fs-22'>{selectedRowKeys.length}</Typography.Text>
              <Typography.Text>
                {selectedRowKeys.length === 1 ? translate('personalizedView.item') : translate('personalizedView.items')}
              </Typography.Text>
            </Col>
            <Button type='text' className='selected-buttons' onClick={() => setShowEmailModal(true)}>
              <MailOutlined className='fs-20' />
              {translate('personalizedView.sendEmail')}
            </Button>
            <Button type='text' className='selected-buttons'>
              <MessageOutlined className='fs-20' />
              {translate('personalizedView.sendSms')}
            </Button>
            {origin === EPersonalizedViewOrigin.CONTACTS ? (
              <></>
            ) : (
              <Button type='text' className='selected-buttons' onClick={onDuplicate}>
                <CopyOutlined className='fs-20' />
                {translate('personalizedView.duplicate')}
              </Button>
            )}
            <Button type='text' className='selected-buttons' onClick={onDelete}>
              <DeleteOutlined className='fs-20' />
              {translate('personalizedView.delete')}
            </Button>
          </Row>
        </Row>
      ) : null}
      <Col className={`lead-table-wrapper ${selectedView?.row_height?.toLowerCase()}-table  w-full mt-20`}>
        <AntTable
          rowKey={'id'}
          className='leads-table-table-wrapper w-full'
          rowClassName='cursor-pointer'
          scroll={{ x: 'calc(400px + 50%)' }}
          pagination={false}
          columns={columns}
          dataSource={data}
          rowSelection={rowSelection}
          components={{ body: { row: DraggableRow } }}
          locale={{
            emptyText: (
              <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
                <Button style={{ width: '200px', margin: '30px 0' }} onClick={() => setShowEntityDrawer?.(true)}>
                  {translate('entity.empty.addYourFirst')} {origin && toHumanReadable(origin).replace(/s$/, '')}
                </Button>
              </Col>
            ),
          }}
          onRow={(record) => ({
            onMouseDown: handleMouseDown,
            onMouseUp: handleMouseUp,
            onClick: (e) => {
              if (!checkLongPress()) {
                e.stopPropagation();
                e.preventDefault();
                onRowClick?.(record);
              }
            },
          })}
        />
      </Col>
      {selectedView?.view_display === EPersonalizedView.TABLE && (
        <Pagination
          size='small'
          style={{ float: 'right' }}
          className='mt-20 py-10'
          onChange={handlePaginationChange}
          total={total}
          current={currentPage}
          pageSize={pageSize}
          showSizeChanger
        />
      )}
      {showEmailModal && (
        <EmailModal
          data={data}
          selectedRowKeys={selectedRowKeys as string[]}
          entityTypeId={data[0].entity_type_id}
          setShowEmailModal={setShowEmailModal}
          setSelectedRowKeys={setSelectedRowKeys}
          origin={origin}
        />
      )}
    </Col>
  );
};
