import React, { JSX, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BgColorsOutlined, BoldOutlined, DeleteOutlined, EllipsisOutlined, FontColorsOutlined, ItalicOutlined } from '@ant-design/icons';
import { DraggableTableViewColumnContent } from 'views/contacts/edit-row-drawer/DraggableTableViewColumnContent';

import { Button, Card, Col, Divider, Dropdown, Row, Select, Switch, Typography } from 'antd';
import './EditableCell.scss';

import { ColorPicker } from '@aduvi/components/ColorPicker/ColorPicker';
import { DEFAULT_CELL_STYLE, FONT_SIZES } from '@aduvi/constants/draggable-fields';
import {
  EColor,
  EFieldDataValueType,
  EFontStyle,
  EFontWeight,
  EPersonalizedView,
  EQuoteField,
  ICustomFieldSpecificStyle,
  ICustomFieldViewStyle,
  IQuote,
} from '@aduvi/types';
import { IActionButton, ICustomField } from '@aduvi/types/entity';
import { determinePickerColor } from '@aduvi/utils/helper';

import { ActionButtonSettings } from '../ActionButtonSettings/ActionButtonSettings';
import { DateFormat } from '../DateFormatComponent/DateFormatComponent';
import { LabelSettings } from '../LabelSettings/LabelSettings';
import { ListFormat } from '../ListFormatComponent/ListFormatComponent';
import { NumberFormat } from '../NumberFormatComponent/NumberFormatComponent';
import { QuoteFieldFormat } from '../QuoteFieldFormat/QuoteFieldFormat';
import { getQuoteFieldNames } from '../QuotesTable/helper';
import { TextLengthTrim } from '../TextLengthTrimComponent/TextLengthTrimComponent';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  children: JSX.Element;
  column: ICustomFieldViewStyle;
  viewType?: EPersonalizedView;
  fieldId?: string;
  field?: ICustomField;
  style?: ICustomFieldSpecificStyle;
  actionButton?: IActionButton;
  quotes?: IQuote[];
  removeColumn: (column: string) => void;
  updateColumns: (column: ICustomFieldViewStyle) => void;
}

export const EditableCell = ({
  column,
  fieldId,
  updateColumns,
  field,
  viewType,
  actionButton,
  quotes,
  removeColumn,
  style,
  children,
  ...restProps
}: IProps) => {
  const { t: translate } = useTranslation();

  const quoteFields = getQuoteFieldNames(quotes);
  const quoteField = useMemo(() => quoteFields.find((field) => field?.id === fieldId), [quoteFields, fieldId]);

  const fieldData = field?.field_data?.[0];

  const editableQuoteFields = [EQuoteField.PRODUCTS, EQuoteField.PACKAGES, EQuoteField.SERVICES];

  const updateStyleForFieldId = (newStyle: Partial<ICustomFieldViewStyle['style']> & { isEditable?: boolean }) => {
    if (!fieldId) return;

    const updatedStyle = {
      ...(column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style,
      ...newStyle,
    };

    updateColumns({
      ...column,
      [fieldId]: {
        style: updatedStyle,
      },
    });
  };

  const handleDeleteField = () => {
    if (column?.field_id?.length && column?.field_id?.length > 1) {
      const filteredFields = column?.field_id?.filter((item) => item !== fieldId);
      updateColumns({
        ...column,
        field_id: filteredFields,
        style: {
          ...(column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style,
        },
      });
    } else {
      removeColumn && removeColumn(column?.id);
    }
  };

  return (
    <div className={`editable-cell-wrapper ${restProps.className}`}>
      <Row justify={'space-between'} className='editable-cell' align={'middle'}>
        {viewType && viewType === EPersonalizedView.TABLE ? (
          <DraggableTableViewColumnContent id={`column_${column.id}_${fieldId}`}>
            <div className='cell-value' style={field && (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style}>
              {children}
            </div>
          </DraggableTableViewColumnContent>
        ) : (
          <div className='cell-value' style={(column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style}>
            {children}
          </div>
        )}

        <Dropdown
          className='edit-button'
          overlayClassName='editable-cell-dropdown-options-wrapper'
          dropdownRender={() => (
            <Card className='style-modal'>
              <Col>
                <Typography.Paragraph className='mb-10' type='secondary'>
                  {translate('personalizedView.style')}
                </Typography.Paragraph>
              </Col>
              <Select
                className='mr-5'
                defaultValue={(column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.fontSize || DEFAULT_CELL_STYLE.fontSize}
                onChange={(e) => {
                  updateStyleForFieldId({ fontSize: e });
                }}>
                {FONT_SIZES.map((size) => (
                  <Select.Option key={size} value={size}>{`${size}px`}</Select.Option>
                ))}
              </Select>

              <BoldOutlined
                className='style-button'
                style={{
                  backgroundColor:
                    (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.fontWeight === EFontWeight.BOLD
                      ? 'lightblue'
                      : 'transparent',
                }}
                onClick={() => {
                  updateStyleForFieldId({
                    fontWeight:
                      (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.fontWeight === EFontWeight.BOLD
                        ? EFontWeight.NORMAL
                        : EFontWeight.BOLD,
                  });
                }}
              />
              <ItalicOutlined
                className='style-button'
                style={{
                  backgroundColor:
                    (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.fontStyle === EFontStyle.ITALIC
                      ? 'lightblue'
                      : 'transparent',
                }}
                onClick={() => {
                  updateStyleForFieldId({
                    fontStyle:
                      (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.fontStyle === EFontStyle.ITALIC
                        ? EFontStyle.NORMAL
                        : EFontStyle.ITALIC,
                  });
                }}
              />
              <ColorPicker
                defaultValue={(column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.color || EColor.BLACK}
                onChange={(e) => {
                  const newColor = determinePickerColor(e) || EColor.BLACK;
                  updateStyleForFieldId({ color: newColor });
                }}>
                <FontColorsOutlined
                  className='style-button'
                  style={{ color: (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.color || EColor.BLACK }}
                />
              </ColorPicker>
              <ColorPicker
                defaultValue={(column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.background || EColor.BLACK}
                onChange={(e) => {
                  const newColor = determinePickerColor(e) || EColor.BLACK;
                  updateStyleForFieldId({ background: newColor });
                }}>
                <BgColorsOutlined
                  className='style-button'
                  style={{ color: (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.background || EColor.BLACK }}
                />
              </ColorPicker>

              {!(
                field?.value_type === EFieldDataValueType.FIELD_DATA_DATE_TIMES ||
                field?.value_type === EFieldDataValueType.FIELD_DATA_IMAGES ||
                field?.value_type === EFieldDataValueType.FIELD_DATA_FILES ||
                field?.value_type === EFieldDataValueType.FIELD_DATA_LISTS ||
                field?.value_type === EFieldDataValueType.FIELD_DATA_SONGS ||
                actionButton ||
                quoteField
              ) && (
                <Row className='my-10'>
                  <Col>
                    <Switch
                      checked={!!style?.isEditable}
                      onChange={(checked) => {
                        updateStyleForFieldId({ isEditable: checked });
                      }}
                    />
                  </Col>
                  <Col>
                    <Typography.Text className='fs-18 ml-15'>{translate('fieldsEditing.editable')}</Typography.Text>
                  </Col>
                </Row>
              )}

              <Divider />

              <Row>
                <Col>
                  <Typography.Paragraph className='mb-10' type='secondary'>
                    {translate('personalizedView.optionsPlaceholder')}
                  </Typography.Paragraph>
                </Col>
                <Col span={24}>
                  <Row style={{ display: 'flex', flexDirection: 'column' }}>
                    <LabelSettings updateStyleForFieldId={updateStyleForFieldId} fieldData={fieldData} fieldId={fieldId} column={column} />

                    {quoteField && editableQuoteFields.includes(quoteField?.id as EQuoteField) && (
                      <QuoteFieldFormat updateStyleForFieldId={updateStyleForFieldId} />
                    )}

                    {!field && actionButton && (
                      <ActionButtonSettings updateStyleForFieldId={updateStyleForFieldId} column={column} fieldId={fieldId} />
                    )}

                    {field && field?.value_type === EFieldDataValueType.FIELD_DATA_DATE_TIMES && fieldData?.value && (
                      <DateFormat updateStyleForFieldId={updateStyleForFieldId} fieldData={fieldData} />
                    )}

                    {field &&
                      (field?.value_type === EFieldDataValueType.FIELD_DATA_TEXTS || field?.value_type === EFieldDataValueType.FIELD_DATA_NUMBERS) &&
                      fieldData?.value &&
                      !field?.core && <NumberFormat updateStyleForFieldId={updateStyleForFieldId} fieldData={fieldData} />}

                    {field && field?.value_type === EFieldDataValueType.FIELD_DATA_LISTS && !field.core && (
                      <ListFormat updateStyleForFieldId={updateStyleForFieldId} />
                    )}

                    {field && field?.value_type === EFieldDataValueType.FIELD_DATA_LONG_TEXTS && (
                      <TextLengthTrim updateStyleForFieldId={updateStyleForFieldId} column={column} fieldId={fieldId} />
                    )}
                    <Button icon={<DeleteOutlined />} type='text' style={{ textAlign: 'left' }} className='w-full' onClick={handleDeleteField}>
                      {translate('personalizedView.delete')}
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Card>
          )}
          trigger={['click']}>
          <Typography.Text onClick={(e) => e.stopPropagation}>
            <EllipsisOutlined />
          </Typography.Text>
        </Dropdown>
      </Row>
    </div>
  );
};
