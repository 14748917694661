import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Card, Col, Form, Row, Spin, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { CustomField } from '@aduvi/components/CustomField/CustomField';
import { mapEntityFieldsToPayload } from '@aduvi/components/Entity/helper';
import { Colors } from '@aduvi/constants';
import { EReferenceEntityType, IEntityField } from '@aduvi/types';
import { getRepLinkIdFromCookies } from '@aduvi/utils/helper';

import { createEntityFromFormUrl, getFormById } from 'store/features/form-slice';
import { getWidget } from 'store/features/widget-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const WidgetEmbed = () => {
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const { businessId, widgetId } = useParams();

  const { loadingWidget, selectedWidget } = useAppSelector((state) => state.widget);
  const { selectedEntityForm, loadingEntity, loading, creatingEntity } = useAppSelector((state) => state.forms);

  const [entityFields, setEntityFields] = useState<IEntityField[]>([]);

  const onFormSubmit = () => {
    const formUrl = selectedEntityForm?.form_url;

    form
      .validateFields()
      .then(() => {
        const packageFields = entityFields?.filter((field) => field?.reference_entity_type === EReferenceEntityType.SERVICE);
        const productFields = entityFields?.filter((field) => field?.reference_entity_type === EReferenceEntityType.PRODUCT);

        const filteredCustomFields = form.getFieldValue('custom_fields')?.filter((customFieldObj: { [key: string]: string }) => {
          if (!customFieldObj) return;

          const key = Object.keys(customFieldObj)[0];
          return ![...packageFields, ...productFields].map((field) => field?.id).includes(key);
        });

        const contactCustomFields = selectedEntityForm?.field_instances.find(
          (item) => item?.field?.reference_entity_type === EReferenceEntityType.CONTACT,
        )?.display_settings?.custom_fields;

        const productCustomFields = form.getFieldValue('custom_fields')?.filter((customFieldObj: { [key: string]: string }) => {
          if (!customFieldObj) return;

          const key = Object.keys(customFieldObj)[0];
          return [...productFields].map((field) => field?.id)?.includes(key);
        });

        const packagesCustomFields = form.getFieldValue('custom_fields')?.filter((customFieldObj: { [key: string]: string }) => {
          if (!customFieldObj) return;

          const key = Object.keys(customFieldObj)[0];
          return [...packageFields].map((field) => field?.id)?.includes(key);
        });

        if (!formUrl) return;

        dispatch(
          createEntityFromFormUrl({
            formUrl,
            body: {
              custom_fields: mapEntityFieldsToPayload(filteredCustomFields, entityFields, contactCustomFields) ?? [],
              product_ids: productCustomFields?.map((customFieldObj: { [key: string]: string[] }) => Object.values(customFieldObj)[0]).flat(),
              package_ids: packagesCustomFields?.map((customFieldObj: { [key: string]: string[] }) => Object.values(customFieldObj)[0]).flat(),
              rep_link_id: getRepLinkIdFromCookies(),
            },
          }),
        )
          .unwrap()
          .then(({ data }) => {
            form.resetFields();
            window.open(location.origin + '/forms/' + formUrl + '/' + data?.id, '_blank');
          })
          .catch(() => {});
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (!widgetId || !businessId) return;

    dispatch(getWidget({ businessId, widgetId }))
      .unwrap()
      .then(({ data }) => {
        if (!data?.form_id || !selectedWidget?.entity_type_id) return;
        dispatch(getFormById({ business_id: businessId, form_id: data?.form_id, entity_type_id: selectedWidget?.entity_type_id }))
          .unwrap()
          .then(({ data }) => {
            setEntityFields(data.field_instances.map((item) => item?.field));
          })
          .catch(() => {});
      })
      .catch(() => {});
  }, [businessId, widgetId, selectedWidget?.entity_type_id]);

  return (
    <Spin spinning={loadingWidget || loading || loadingEntity}>
      <Row className='forms-design-tab-wrapper'>
        <Col span={10}>
          <Card style={{ borderStyle: 'none', marginTop: '60px', background: selectedWidget?.widget_style?.[0]?.widget_background }}>
            {selectedWidget?.widget_style?.[0]?.allow_title ? (
              <Typography.Title
                level={3}
                style={{
                  textAlign: 'center',
                  marginBottom: '20px',
                  fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                  color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                }}>
                {selectedWidget?.widget_style?.[0]?.widget_title}
              </Typography.Title>
            ) : null}

            <Form
              form={form}
              layout='vertical'
              style={{
                margin: '0 auto',
              }}
              onFinish={onFormSubmit}>
              {selectedEntityForm?.field_instances
                ?.filter((item) => item.display_settings?.is_widget_field)
                ?.map((item, index) => (
                  <CustomField
                    key={item.id}
                    field={item.field}
                    index={index}
                    form={form}
                    disabledFields={[]}
                    labelStyle={{
                      color: selectedWidget?.widget_style?.[0]?.label_font,
                      fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                    }}
                  />
                ))}

              <Form.Item className='flex-center'>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={creatingEntity}
                  disabled={creatingEntity}
                  style={{
                    background: selectedWidget?.widget_style?.[0]?.button_background ?? Colors.BLUE_6,
                    color: selectedWidget?.widget_style?.[0]?.button_font ?? Colors.WHITE,
                    fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                  }}>
                  {selectedWidget?.widget_style?.[0]?.button_text ?? 'Submit'}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};
