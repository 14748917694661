import { useState } from 'react';
import { useDesign } from 'views/widgets/@components/design-hook/Design.hook';

import { Button, Card, Col, Row, Typography } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import './Design.scss';

import { CustomField } from '@aduvi/components/CustomField/CustomField';
import { Colors } from '@aduvi/constants';

import { useAppSelector } from 'store/hooks';

import { RightSidebar } from './right-sidebar/RightSidebar';

export const Design = ({ form, selectedTab }: { form: FormInstance; selectedTab: string }) => {
  const { widgetBackgroundStyle, backgroundStyle } = useDesign({ form });

  const { selectedEntityForm } = useAppSelector((state) => state.forms);

  const [isMobileView, setIsMobileView] = useState(false);

  return (
    <Row className='forms-design-tab-wrapper' style={{ ...backgroundStyle, display: selectedTab !== '1' ? 'none' : '' }}>
      <Col span={10}>
        <Card style={{ borderStyle: 'dashed', marginTop: '60px', ...widgetBackgroundStyle }}>
          <Typography.Title
            level={3}
            style={{
              textAlign: 'center',
              marginBottom: '20px',
              fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
              color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
            }}>
            {form.getFieldValue(['widget_style', 0, 'allow_title']) ? form.getFieldValue(['widget_style', 0, 'widget_title']) : ''}
          </Typography.Title>

          <Col
            style={{
              margin: '0 auto',
              fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
              color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
            }}>
            {selectedEntityForm?.field_instances
              ?.filter((item) => item.display_settings?.is_widget_field)
              ?.map((item, index) => (
                <CustomField
                  key={item.id}
                  field={item.field}
                  index={index}
                  form={form}
                  disabledFields={[]}
                  labelStyle={{
                    color: form.getFieldValue(['widget_style', 0, 'label_font']),
                    fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                  }}
                />
              ))}

            <Row justify={'center'}>
              <Button
                type='primary'
                htmlType='submit'
                style={{
                  background: form.getFieldValue(['widget_style', 0, 'button_background']) ?? Colors.BLUE_6,
                  color: form.getFieldValue(['widget_style', 0, 'button_font']) ?? Colors.WHITE,
                  fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                }}>
                {form.getFieldValue(['widget_style', 0, 'button_text'])}
              </Button>
            </Row>
          </Col>
        </Card>
      </Col>
      <Col span={1} className='icons-container'>
        <RightSidebar form={form} onMobileView={() => setIsMobileView((prevState) => !prevState)} isMobileView={isMobileView} />
      </Col>
    </Row>
  );
};
