import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Breadcrumb, Button, Col, Form, Row, Tabs, TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import './BusinessBuilder.scss';

import { useBusiness } from '@aduvi/hooks';
import { EWidgetApps, ICreateWidget, IUpdateWidget } from '@aduvi/types/widget';

import { createWidget, getWidget, setSelectedWidget, updateWidget } from 'store/features/widget-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Configure } from './tabs/configure/Configure';
import { Design } from './tabs/design/Design';
import { Embed } from './tabs/embed/Embed';

export const BusinessBuilder = () => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const [form] = useForm();
  const { widgetId } = useParams();
  const selectedBusiness = useBusiness();

  const { selectedWidget } = useAppSelector((state) => state.widget);

  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState('1');

  const tabs: TabsProps['items'] = [
    {
      key: '1',
      label: translate('widgets.tabs.design'),
    },
    {
      key: '2',
      label: translate('widgets.tabs.configure'),
    },
    {
      key: '3',
      label: translate('widgets.tabs.embed'),
    },
  ];

  const onActiveTabChange = (tabKey: string) => {
    dispatch(
      setSelectedWidget({
        ...selectedWidget,
        ...form.getFieldsValue(),
        widget_style: { ...selectedWidget?.widget_style?.[0], ...form.getFieldValue(['widget_style']) },
      }),
    );
    setSelectedTab(tabKey);
  };

  const activeTab: Record<string, ReactNode> = {
    '1': <Design form={form} />,
    '2': (
      <Col className='flex-center mt-30'>
        <Configure form={form} />
      </Col>
    ),
    '3': (
      <Col className='flex-center mt-30'>
        <Embed form={form} />
      </Col>
    ),
  };

  const onSave = () => {
    const formValues = form.getFieldsValue();

    if (!widgetId) {
      const createParams: ICreateWidget = {
        business_id: selectedBusiness?.id || '',
        apps: EWidgetApps.BUSINESS,
        ...formValues,
        widget_style: formValues.widget_style,
      };
      dispatch(createWidget(createParams))
        .unwrap()
        .then(() => navigate('/widgets/services'))
        .catch(() => {});
    } else {
      const updateParams: IUpdateWidget = {
        id: widgetId,
        business_id: selectedBusiness?.id || '',
        apps: EWidgetApps.BUSINESS,
        ...selectedWidget,
        ...formValues,
        widget_style: formValues.widget_style ?? selectedWidget?.widget_style,
      };

      dispatch(updateWidget(updateParams))
        .unwrap()
        .then(() => {
          navigate('/widgets/business');
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    if (!selectedBusiness?.id || !widgetId || !selectedWidget) return;
    dispatch(getWidget({ businessId: selectedBusiness.id, widgetId }))
      .unwrap()
      .then(({ data }) => {
        dispatch(setSelectedWidget(data));
        form.setFieldsValue({ ...data });
      });
  }, [selectedBusiness?.id, widgetId]);

  return (
    <Form form={form} layout='vertical' requiredMark={false}>
      <Col span={24} className='widget-business-wrapper'>
        <Col span={24} className='header'>
          <Row justify={'space-between'} align={'middle'} className='px-15 pt-15 business-menu'>
            <Breadcrumb
              className='ml-10'
              items={[
                { title: translate('widgets.title'), onClick: () => navigate(`/widgets/business`), className: 'cursor-pointer' },
                { title: translate('widgets.business'), onClick: () => navigate(`/widgets/business-configure`), className: 'cursor-pointer' },
              ]}
            />

            <Col className='business-tabs'>
              <Tabs defaultActiveKey='1' activeKey={selectedTab} className='flex-center' items={tabs} onChange={onActiveTabChange} />
            </Col>

            <Row justify={'space-around'}>
              <Button onClick={onSave} type='primary'>
                {translate('buttons.save')}
              </Button>
            </Row>
          </Row>
        </Col>
        <Col span={24}>{activeTab[selectedTab]}</Col>
      </Col>
    </Form>
  );
};
