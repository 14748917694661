import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { Col, Row, Typography } from 'antd';

import { BOOKING_LIST } from '@aduvi/constants/client-portal-constants';
import { IEntityWithFields } from '@aduvi/types/entity';

import { setSelectedOrder } from 'store/features/client-auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const OrderList = ({ readonly = true }: { bookings?: IEntityWithFields[]; readonly?: boolean }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t: translate } = useTranslation();

  const { user } = useAppSelector((state) => state.clientPortalAuth);

  const navigateToOrder = (order: IEntityWithFields) => {
    dispatch(setSelectedOrder(order));
    navigate(`../portal/order/${order.id}`);
  };

  return (
    <div className='client-booking-list-wrapper'>
      {readonly ? (
        <>
          {user?.orders?.loading && <Typography.Paragraph type='secondary'>{translate('clientPortal.loggedUser.loadingJobs')}</Typography.Paragraph>}
          {user?.orders?.data &&
            !user?.orders?.loading &&
            user?.orders?.data?.map((order) => (
              <Row
                justify={'space-between'}
                onClick={() => readonly && navigateToOrder(order)}
                style={{ cursor: readonly ? 'pointer' : 'auto' }}
                className='booking py-10'
                key={order?.id}>
                <Col>
                  <Typography.Paragraph className='mb-0 fs-14'>
                    {order?.custom_fields?.find((field) => field?.title === 'Order Id')?.field_data?.[0]?.value}
                  </Typography.Paragraph>
                </Col>
                <Col>
                  <Typography.Paragraph className='mb-0 fs-14'>
                    {order?.custom_fields?.find((field) => field?.title === 'Status')?.field_data?.[0]?.value}
                  </Typography.Paragraph>
                </Col>
              </Row>
            ))}
          {!user?.orders?.loading && !user?.orders?.data && (
            <Typography.Paragraph type='secondary'>{translate('clientPortal.loggedUser.noOrders')}</Typography.Paragraph>
          )}
        </>
      ) : (
        BOOKING_LIST.map((booking) => (
          <Row
            justify={'space-between'}
            onClick={() => readonly && navigate(`/portal/portal/order/${booking.id}`)}
            style={{ cursor: readonly ? 'pointer' : 'auto' }}
            className='booking py-10'
            key={booking.id}>
            <Col>
              <Typography.Paragraph className='mb-0 fs-14'>{booking.name}</Typography.Paragraph>
              <Typography.Paragraph type='secondary' className='mb-0 fs-11'>
                {booking.category}
              </Typography.Paragraph>
            </Col>
            <Col>
              <Typography.Paragraph className='mb-0 fs-14'>{dayjs(booking.date).format('DD/MM/YYYY')}</Typography.Paragraph>
              <Typography.Paragraph type='secondary' className='mb-0 fs-11'>
                {booking.time}
              </Typography.Paragraph>
            </Col>
          </Row>
        ))
      )}
    </div>
  );
};
