import React, { useEffect, useMemo, useRef } from 'react';
import dayjs from 'dayjs';
import { DataGroup, DataItem, DataSet, DateType, Timeline as VisTimeline, TimelineOptions } from 'vis-timeline/standalone';

import { Col } from 'antd';
import './Timeline.scss';

import { useBusiness } from '@aduvi/hooks';
import { EFieldDataValueType, EPersonalizedViewOrigin } from '@aduvi/types';
import { ICustomField, IEntityType, IEntityWithFields } from '@aduvi/types/entity';

import { getEntities } from 'store/features/entity-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { getStatusForEntities } from '../helper';

interface IProps {
  data: {
    groups: DataGroup[];
    items: DataItem[];
    startDate: DateType;
    endDate: DateType;
  };
}

const TimelineComponent = ({ data }: IProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  let timeline: VisTimeline | null = null;

  useEffect(() => {
    if (!containerRef.current || !data) return;

    const { groups, items, startDate, endDate } = data;

    const groupsDataSet = new DataSet(groups);
    const itemsDataSet = new DataSet(items);

    const options: TimelineOptions = {
      start: startDate,
      end: endDate,
      horizontalScroll: false,
      orientation: 'both',
      showWeekScale: true,
      zoomMin: 1000 * 60 * 60 * 240,
      editable: {
        add: false,
        updateTime: false,
        updateGroup: false,
        remove: false,
        overrideItems: false,
      },
      format: {
        minorLabels: {
          day: 'dd',
        },
        majorLabels: {
          week: 'w',
        },
      },
    };

    timeline = new VisTimeline(containerRef.current, itemsDataSet, groupsDataSet, options);

    return () => {
      if (timeline) {
        timeline.destroy();
        timeline = null;
      }
    };
  }, [data]);

  return <div ref={containerRef} className='timeline-container' />;
};

export const Timeline = ({
  data,
  origin,
  entityType,
  viewId,
}: {
  data: IEntityWithFields[];
  origin: EPersonalizedViewOrigin;
  entityType?: IEntityType;
  viewId?: string;
}) => {
  const dispatch = useAppDispatch();
  const { selectedView } = useAppSelector((state) => state.personalizedViews);
  const selectedBusiness = useBusiness();

  const groups = useMemo(() => {
    return data?.map((item) => {
      const name = item.custom_fields?.find((field: ICustomField) => field?.value_type === EFieldDataValueType.FIELD_DATA_TEXTS);
      return {
        id: item?.id,
        content: name?.title || '',
      } as DataGroup;
    });
  }, [data]);

  const items = useMemo(() => {
    return data
      .map((item) => {
        const name = item.custom_fields.find((field: ICustomField) => field?.value_type === EFieldDataValueType.FIELD_DATA_TEXTS)?.field_data?.[0]
          ?.value;
        const date = item.custom_fields?.find((field: ICustomField) => field?.id === selectedView?.start_date);

        return {
          id: item?.id,
          group: item?.id,
          content: name,
          start: dayjs(date?.field_data?.[0]?.value)?.toString(),
          end: dayjs(date?.field_data?.[0]?.end_date_time)?.toString(),
        } as DataItem;
      })
      .sort((a, b) => dayjs(a.start).diff(dayjs(b.start)));
  }, [data]);

  const timelineData = useMemo(() => {
    return {
      groups,
      items,
      startDate: items[0]?.start || new Date().toString(),
      endDate: items[items?.length - 1]?.end || new Date().toString(),
    };
  }, [groups, items]);

  useEffect(() => {
    if (selectedBusiness?.id && entityType?.id) {
      dispatch(
        getEntities({
          businessId: selectedBusiness?.id,
          entityTypeId: entityType?.id,
          params: {
            status: getStatusForEntities(origin),
            view_id: viewId,
          },
        }),
      );
    }
  }, [selectedBusiness?.id, entityType?.id]);

  return (
    <Col>
      <TimelineComponent data={timelineData} />
    </Col>
  );
};
