import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FilePdfTwoTone, FolderOpenTwoTone, HighlightTwoTone, HomeOutlined, MessageTwoTone, PictureTwoTone } from '@ant-design/icons';

import { Col, Image, Row, Typography } from 'antd';
import './Portal.scss';

import { initialClientPortalSettings } from '@aduvi/constants/client-portal-constants';
import { ClientPortalSocketProvider } from '@aduvi/providers/ClientPortalSocketProvider';
import { EPortalComponent } from '@aduvi/types/client-portal';

import { useAppSelector } from 'store/hooks';

import { Chat } from '../chat/Chat';
import { Files } from '../files/Files';
import { Invoice } from '../invoice/Invoice';
import { Invoices } from '../invoice/Invoices';

import { AllComponents } from './AllComponents';

const PortalPage = ({ componentToRender = EPortalComponent.ALL }: { componentToRender?: EPortalComponent }) => {
  const navigate = useNavigate();

  const { t: translate } = useTranslation();
  const { selectedClientPortal } = useAppSelector((state) => state.clientPortal);
  const { user } = useAppSelector((state) => state.clientPortalAuth);

  const portalComponents = {
    [EPortalComponent.ALL]: <AllComponents />,
    [EPortalComponent.FILES]: <Files />,
    [EPortalComponent.INVOICE]: <Invoice />,
    [EPortalComponent.INVOICES]: <Invoices />,
    [EPortalComponent.CHAT]: (
      <ClientPortalSocketProvider>
        <Chat />
      </ClientPortalSocketProvider>
    ),
  };

  const entityLink = useMemo(
    () => `/portal/portal/${user?.jobs?.selectedJob ? `job/${user?.jobs?.selectedJob?.id}` : `order/${user?.orders?.selectedOrder?.id}`}`,
    [selectedClientPortal, user?.jobs?.selectedJob?.id, user?.orders?.selectedOrder?.id],
  );

  return selectedClientPortal ? (
    <Row className='client-readonly-portal-tab-wrapper'>
      <Col span={24}>
        <Col
          className='client-portal-header'
          style={{
            background:
              (selectedClientPortal?.client_portal_settings?.portal_layout || initialClientPortalSettings?.client_portal_settings?.portal_layout) ===
              2
                ? 'transparent'
                : selectedClientPortal?.client_portal_settings?.portal_style?.[0]?.background_color ??
                  (selectedClientPortal?.brand?.brand_color || '#7cc0ff'),
          }}>
          <Row justify={'space-between'}>
            <Col span={1} />

            <Col
              span={22}
              style={{
                backgroundColor:
                  (selectedClientPortal?.client_portal_settings?.portal_layout ||
                    initialClientPortalSettings?.client_portal_settings?.portal_layout) === 2
                    ? selectedClientPortal?.client_portal_settings?.portal_style[0].background_color ??
                      (selectedClientPortal?.brand?.brand_color || '#7cc0ff')
                    : 'transparent',
              }}>
              <Row>
                {(selectedClientPortal?.client_portal_settings?.portal_layout ||
                  initialClientPortalSettings?.client_portal_settings?.portal_layout) !== 3 && (
                  <Col span={22} className='clinetportal-header'>
                    {selectedClientPortal?.brand?.logo ? (
                      <Row className='w-full' justify={'center'}>
                        <Image preview={false} width={120} src={selectedClientPortal?.brand?.logo} style={{ borderRadius: '50%' }} />
                      </Row>
                    ) : (
                      <Typography.Title>{translate('clientPortal.portal.logo')}</Typography.Title>
                    )}
                    <Typography.Paragraph className='mt-15 color-white'>
                      {selectedClientPortal?.client_portal_settings?.description}
                    </Typography.Paragraph>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>

        <Col className='client-portal-body-wrapper'>
          <Row justify={'space-between'}>
            <Col span={1}>
              {(selectedClientPortal?.client_portal_settings?.portal_layout || initialClientPortalSettings?.client_portal_settings?.portal_layout) ===
                3 && (
                <Row gutter={16} justify={'center'} className='m-20 absolute-icons'>
                  <Col className='client-portal-body-icons-container'>
                    <HomeOutlined
                      className={`icon ${componentToRender === EPortalComponent.ALL ? 'active' : ''}`}
                      onClick={() => navigate(entityLink, { replace: true })}
                    />
                    <Typography.Paragraph className='mb-0'>{translate('clientPortal.portal.home')}</Typography.Paragraph>
                  </Col>
                  <Col className='client-portal-body-icons-container'>
                    <FolderOpenTwoTone
                      className={`icon ${componentToRender === EPortalComponent.FILES ? 'active' : ''}`}
                      onClick={() => navigate(`${entityLink}/files`, { replace: true })}
                    />
                    <Typography.Paragraph className='mb-0'>{translate('clientPortal.portal.files')}</Typography.Paragraph>
                  </Col>
                  <Col className='client-portal-body-icons-container'>
                    <FilePdfTwoTone
                      className={`icon ${
                        componentToRender === EPortalComponent.INVOICES || componentToRender === EPortalComponent.INVOICE ? 'active' : ''
                      }`}
                      onClick={() => navigate(`${entityLink}/invoices`, { replace: true })}
                    />
                    <Typography.Paragraph className='mb-0'>{translate('clientPortal.portal.invoice')}</Typography.Paragraph>
                  </Col>
                  <Col className='client-portal-body-icons-container'>
                    <HighlightTwoTone className='icon' />
                    <Typography.Paragraph className='mb-0'>{translate('clientPortal.portal.contracts')}</Typography.Paragraph>
                  </Col>
                  <Col className='client-portal-body-icons-container'>
                    <MessageTwoTone
                      className={`icon ${componentToRender === EPortalComponent.CHAT ? 'active' : ''}`}
                      onClick={() => navigate(`${entityLink}/chat`, { replace: true })}
                    />
                    <Typography.Paragraph className='mb-0'>{translate('clientPortal.portal.chat')}</Typography.Paragraph>
                  </Col>
                  <Col className='client-portal-body-icons-container'>
                    <PictureTwoTone className='icon' />
                    <Typography.Paragraph className='mb-0'>{translate('clientPortal.portal.template')}</Typography.Paragraph>
                  </Col>
                </Row>
              )}
            </Col>
            <Col span={22}>
              <Row>
                <Col span={22} className='m-10'>
                  {(selectedClientPortal?.client_portal_settings?.portal_layout ||
                    initialClientPortalSettings?.client_portal_settings?.portal_layout) !== 3 && (
                    <Row gutter={16} justify={'center'} className='m-20'>
                      <Col className='client-portal-body-icons-container'>
                        <HomeOutlined
                          className={`icon ${componentToRender === EPortalComponent.ALL ? 'active' : ''}`}
                          onClick={() => navigate(entityLink, { replace: true })}
                        />
                        <Typography.Paragraph className='mb-0'>{translate('clientPortal.portal.home')}</Typography.Paragraph>
                      </Col>
                      <Col className='client-portal-body-icons-container '>
                        <FolderOpenTwoTone
                          className={`icon ${componentToRender === EPortalComponent.FILES ? 'active' : ''}`}
                          onClick={() => navigate(`${entityLink}/files`, { replace: true })}
                        />
                        <Typography.Paragraph className='mb-0'>{translate('clientPortal.portal.files')}</Typography.Paragraph>
                      </Col>
                      <Col className='client-portal-body-icons-container'>
                        <FilePdfTwoTone
                          className={`icon ${
                            componentToRender === EPortalComponent.INVOICES || componentToRender === EPortalComponent.INVOICE ? 'active' : ''
                          }`}
                          onClick={() => navigate(`${entityLink}/invoices`, { replace: true })}
                        />
                        <Typography.Paragraph className='mb-0'>{translate('clientPortal.portal.invoice')}</Typography.Paragraph>
                      </Col>
                      <Col className='client-portal-body-icons-container'>
                        <HighlightTwoTone className='icon' />
                        <Typography.Paragraph className='mb-0'>{translate('clientPortal.portal.contracts')}</Typography.Paragraph>
                      </Col>
                      <Col className='client-portal-body-icons-container'>
                        <MessageTwoTone
                          className={`icon ${componentToRender === EPortalComponent.CHAT ? 'active' : ''}`}
                          onClick={() => navigate(`${entityLink}/chat`, { replace: true })}
                        />
                        <Typography.Paragraph className='mb-0'>{translate('clientPortal.portal.chat')}</Typography.Paragraph>
                      </Col>
                      <Col className='client-portal-body-icons-container'>
                        <PictureTwoTone className='icon' />
                        <Typography.Paragraph className='mb-0'>{translate('clientPortal.portal.template')}</Typography.Paragraph>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>

              {portalComponents[componentToRender]}
            </Col>
          </Row>
        </Col>
      </Col>
    </Row>
  ) : (
    <></>
  );
};

export default PortalPage;
