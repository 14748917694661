import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, Select, Spin, Tag } from 'antd';

import { useDebounce } from '@aduvi/hooks';
import { ISongSearchResponse } from '@aduvi/types';

import { resetSongSearch, songSearch } from 'store/features/common-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface ISongFieldProps {
  value?: { song: ISongSearchResponse }[];
  onChange?: (value: { song: ISongSearchResponse }[]) => void;
}

export const SongField: React.FC<ISongFieldProps> = ({ value = [], onChange }) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const { loading, data: songs } = useAppSelector((state) => state.common.songSearch);

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleMultiSelectChange = (selectedValues: string[]) => {
    const selectedSongs: { song: ISongSearchResponse }[] = selectedValues.map((val) => {
      const parsedSong = JSON.parse(val);
      // Check if the song is already wrapped and unwrap if necessary
      return { song: parsedSong.song || parsedSong };
    });
    onChange?.(selectedSongs);
  };

  const renderSongOption = (song: ISongSearchResponse) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar src={song.image} shape='circle' size='small' style={{ marginRight: 8 }} />
      <span>
        {song.artist} - {song.track}
      </span>
    </div>
  );

  const selectValue = value.map((song) => JSON.stringify(song));

  useEffect(() => {
    if (!debouncedSearchTerm) {
      dispatch(resetSongSearch());
      return;
    }
    if (debouncedSearchTerm.length > 2) {
      dispatch(songSearch({ params: { search: debouncedSearchTerm, limit: 10 } }));
    }
  }, [debouncedSearchTerm, dispatch]);

  return (
    <div>
      <div style={{ marginBottom: 8 }}>
        {value.map((song) => (
          <Tag
            key={`${song.song.artist}-${song.song.track}`}
            closable
            onClose={(e) => {
              e.preventDefault();
              onChange?.(value.filter((s) => s.song.track !== song.song.track || s.song.artist !== song.song.artist));
            }}
            style={{ display: 'inline-flex', alignItems: 'center' }}>
            <Avatar src={song.song.image} shape='circle' size='large' style={{ marginRight: 4 }} />
            {song.song.artist} - {song.song.track}
          </Tag>
        ))}
      </div>

      <Select
        mode='multiple'
        showSearch
        allowClear
        placeholder={translate('components.customFieldsDrawer.song.search')}
        notFoundContent={loading ? <Spin size='small' /> : null}
        onSearch={setSearchTerm}
        onChange={handleMultiSelectChange}
        filterOption={false}
        style={{ width: '100%' }}
        value={selectValue}
        tagRender={() => <span />}>
        {songs?.map((song) => {
          const optionValue = JSON.stringify(song);
          return (
            <Select.Option key={optionValue} value={optionValue}>
              {renderSongOption(song)}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};
