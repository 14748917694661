import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LogoutOutlined } from '@ant-design/icons';

import { Card, Col, Flex, Row, Tabs } from 'antd';
import { TabsProps } from 'antd/lib';
import './LoggedUser.scss';

import { Button } from '@aduvi/components/Button/Button';
import { Title } from '@aduvi/components/Title';
import { UploadImage } from '@aduvi/components/UploadImage/UploadImage';
import { Constants } from '@aduvi/constants';

import { getClientJobs, getClientOrders, logout, resetAuthState } from 'store/features/client-auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { BookingList } from './@components/BookingList';
import { OrderList } from './@components/OrderList';

export const LoggedUser = ({ readonly = true }: { readonly?: boolean }) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();

  const { user } = useAppSelector((state) => state.clientPortalAuth);
  const { selectedClientPortal } = useAppSelector((state) => state.clientPortal);
  const [selectedTab, setSelectedTab] = useState('1');
  const [userImageLink, setUserImageLink] = useState<string | null>(null);

  const tabs: TabsProps['items'] = [
    {
      key: '1',
      label: translate('clientPortal.loggedUser.bookings', { bookings: readonly ? user.jobs.data?.length : 3 }),
    },
    {
      key: '2',
      label: translate('clientPortal.loggedUser.orders', { orders: readonly ? user.orders.data?.length : 3 }),
    },
  ];

  const activeTab: Record<string, ReactNode> = {
    '1': <BookingList readonly={readonly} />,
    '2': <OrderList readonly={readonly} />,
  };

  const onLogout = () => {
    if (!readonly) return;
    localStorage.removeItem(Constants.LocalStorage.Authorization.ACCESS_TOKEN);
    localStorage.removeItem(Constants.LocalStorage.Authorization.REFRESH_TOKEN);
    dispatch(resetAuthState());
    dispatch(logout());
  };

  useEffect(() => {
    if (!selectedClientPortal?.business_id || !readonly) return;
    dispatch(getClientJobs(selectedClientPortal?.business_id));
    dispatch(getClientOrders(selectedClientPortal?.business_id));
  }, [selectedClientPortal?.business_id]);

  return (
    <Card
      className='logged-user-card'
      headStyle={{ backgroundColor: '#F8F8F8', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
      title={
        <div className='card-header p-10'>
          <Row justify={'center'} style={{ position: 'relative' }}>
            <Col>
              <div style={{ height: 50 }} className='text-center'>
                <UploadImage
                  disabled={!readonly}
                  className='user-image'
                  listType='picture-circle'
                  url={readonly ? userImageLink || user.user?.custom_fields?.find((field) => field?.title === 'Picture')?.field_data?.[0]?.url : ''}
                  showUploadList={{ showPreviewIcon: false }}
                  name={'image'}
                  onUploadChange={(link) => {
                    if (link) {
                      setUserImageLink(link?.url);
                    }
                  }}
                />
              </div>
            </Col>
          </Row>

          <Title level={3} className='mb-20'>
            {!readonly ? 'John Smith' : user.user?.custom_fields?.find((field) => field?.title === 'Name')?.field_data?.[0]?.value}
          </Title>

          <Tabs
            rootClassName='user-tabs'
            defaultActiveKey='1'
            activeKey={selectedTab}
            className='flex-center'
            items={tabs}
            onChange={setSelectedTab}
          />
        </div>
      }>
      <Flex vertical justify='space-between'>
        <Col span={24}> {activeTab[selectedTab]}</Col>
        <Col span={24}>
          <Row className='mt-20' justify={'center'}>
            <Button onClick={onLogout} disabled={!readonly} type='text' icon={<LogoutOutlined />}>
              {translate('clientPortal.loggedUser.logout')}
            </Button>
          </Row>
        </Col>
      </Flex>
    </Card>
  );
};
