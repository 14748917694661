import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { EEmailConnectionType, IConnectedAccountState, IEmailConnection } from '@aduvi/types/connected-account';

import * as ConnectedAccountService from 'store/services/connected-account.service';

const initialState: IConnectedAccountState = {
  loading: false,
  creating: false,
};

export const getAllConnectedAccounts = createAsyncThunk(
  'connected-account/get-all',
  async ({ businessId }: { businessId: string }, { rejectWithValue }) => {
    try {
      return await ConnectedAccountService.getAllConnectedAccounts(businessId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const connectEmail = createAsyncThunk(
  'connected-account/create',
  async ({ businessId, body }: { businessId: string; body: IEmailConnection }, { rejectWithValue }) => {
    try {
      return await ConnectedAccountService.connectEmail(businessId, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const connectedAccountSlice = createSlice({
  name: 'connected-account',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(connectEmail.pending, (state) => {
        state.creating = true;
      })
      .addCase(connectEmail.fulfilled, (state, { payload }) => {
        state.emails?.push(payload.data);
        state.creating = false;
      })
      .addCase(connectEmail.rejected, (state) => {
        state.creating = false;
      })
      .addCase(getAllConnectedAccounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllConnectedAccounts.fulfilled, (state, { payload }) => {
        state.emails = payload.data.filter((email) => email.type === EEmailConnectionType.SEND_EMAIL);
        state.loading = false;
      })
      .addCase(getAllConnectedAccounts.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const connectedAccountReducer = connectedAccountSlice.reducer;
