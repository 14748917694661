import React from 'react';
import { CloseOutlined } from '@ant-design/icons';

import { Button, Modal } from 'antd';

interface ActionButtonModalProps {
  visible: boolean;
  url: string;
  onClose: (e: React.MouseEvent<HTMLElement>) => void;
}

export const ActionButtonModal = ({ visible, url, onClose }: ActionButtonModalProps) => {
  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClose(e);
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={handleCancel}
      width='90%'
      bodyStyle={{
        padding: 0,
        height: '70vh',
      }}
      closable={false}
      title={
        <div onClick={(e) => e.stopPropagation()} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button type='text' onClick={handleCancel} icon={<CloseOutlined />} className='fs-16' />
        </div>
      }>
      <iframe width='100%' height='100%' src={url} style={{ border: 'none' }} />
    </Modal>
  );
};
