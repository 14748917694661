import React, { useRef, useState } from 'react';

import type { UploadProps } from 'antd';
import { Col, Row } from 'antd';
import { RcFile, UploadFile as AntUploadFile } from 'antd/es/upload';

import { EFileExtensions, IUploadFile } from '@aduvi/types';

import { MediaLibraryModal } from '../MediaLibraryModal/MediaLibraryModal';

import { FileUpload } from './FileUpload';

interface IProps {
  name: string | string[];
  url?: string;
  uploadSettings?: {
    maximum_upload_size: string;
    allowed_extensions: EFileExtensions[];
  };
  folderName?: string;
  onUploadChange: (file: IUploadFile | null) => void;
  skipUploadToServer?: boolean;
  onFileUploaded?: (file: RcFile) => void;
}

export const UploadFile = ({
  name,
  url,
  uploadSettings,
  folderName,
  onUploadChange,
  onFileUploaded,
  skipUploadToServer = false,
  ...rest
}: UploadProps & IProps) => {
  const preventClickRef = useRef(false);

  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [uploadedFileUrls, setUploadFileUrls] = useState<string[]>([]);

  const blockModalClickEvent = (file?: AntUploadFile) => {
    if (file !== undefined) {
      onUploadChange({ url: '' });
    }
    preventClickRef.current = true;
    setTimeout(() => (preventClickRef.current = false), 5);
  };

  const openMediaLibraryModal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (preventClickRef?.current) {
      return event.stopPropagation();
    }
    if (rest.disabled) return;
    setShowUploadFileModal(true);
  };

  const onPreview = (url?: string) => {
    if (!url) return;
    blockModalClickEvent();
    window.open(url, '_blank');
  };

  return (
    <Row className={url ? 'mb-20' : ''}>
      <Col className='w-full' onClick={openMediaLibraryModal}>
        <FileUpload
          disabled={rest.disabled}
          openFileDialogOnClick={false}
          name={name}
          onUploadChange={() => {}}
          url={selectedFile || url}
          onRemove={blockModalClickEvent}
          onPreview={(file) => onPreview(file?.url)}
        />
      </Col>

      {showUploadFileModal && (
        <MediaLibraryModal
          open={showUploadFileModal}
          uploadedImageUrls={uploadedFileUrls}
          onSelect={(urls) => {
            if (!urls || !urls?.length) return;

            setShowUploadFileModal(false);
            onUploadChange({ url: urls?.[0] });
            setSelectedFile(urls?.[0]);
          }}
          onCancel={() => setShowUploadFileModal(false)}
          uploadComponent={
            <FileUpload
              name={name}
              url={url}
              uploadSettings={uploadSettings}
              folderName={folderName}
              skipUploadToServer={skipUploadToServer}
              disabled={rest.disabled}
              onRemove={blockModalClickEvent}
              onUploadChange={(file) => {
                onUploadChange?.(file);
                if (file?.url) {
                  setUploadFileUrls([file?.url]);
                }
              }}
              onFileUploaded={(file) => {
                onFileUploaded?.(file);
              }}
              {...rest}
            />
          }
        />
      )}
    </Row>
  );
};
