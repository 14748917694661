import { CSSProperties, useMemo } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { isArray } from 'lodash';
import { ContactCustomField } from 'views/forms/tabs/build/components/ContactCustomField';
import { IBookingFormFieldProps } from 'views/forms/tabs/build/helper';

import { Checkbox, Form, Input, InputNumber, Rate, Select, TimePicker, Tooltip } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { FormInstance } from 'antd/lib/form/Form';

import Tiptap from '@aduvi/Tiptap/Tiptap';
import { EEntityFieldListType, EFieldDataValueType, EReferenceEntityType, IEntityField } from '@aduvi/types';
import { disableChild, toHumanReadable } from '@aduvi/utils/helper';

import { SelectEntityByReference } from '../SelectEntityByReference/SelectEntityByReference';
import { SelectProduct } from '../SelectProduct/SelectProduct';
import { SelectService } from '../SelectService/SelectService';
import { SelectTeam } from '../SelectTeam/SelectTeam';
import { SelectUser } from '../SelectUser/SelectUser';
import { UploadFile } from '../UploadFile/UploadFile';
import { UploadImage } from '../UploadImage/UploadImage';

import { AddressField } from './Address/AddressField';
import { CustomDateTime } from './DateTime/DateTime';
import { SongField } from './Song/SongField';

interface ICustomFieldProps {
  field: IEntityField;
  index: number;
  form: FormInstance;
  isFormField?: boolean;
  isRequired?: boolean;
  name?: Array<string | number>;
  contactFields?: IBookingFormFieldProps;
  labelStyle?: CSSProperties;
  disabledFields: string[];
}

const fieldWithOptions = [EFieldDataValueType.FIELD_DATA_LISTS];

const inputFields = [
  EFieldDataValueType.FIELD_DATA_EMAILS,
  EFieldDataValueType.FIELD_DATA_URLS,
  EFieldDataValueType.FIELD_DATA_TEXTS,
  EFieldDataValueType.FIELD_DATA_LONG_TEXTS,
  EFieldDataValueType.FIELD_DATA_SEQUENTIAL_IDS,
];

const useDetermineReferenceCustomField = (
  form: FormInstance,
  field: IEntityField | undefined,
  index: number,
  isFormField: boolean = false,
  name?: Array<number | string>,
  contactFields?: IBookingFormFieldProps,
  disabled?: boolean,
) => {
  if (field?.reference_entity_type === EReferenceEntityType.SERVICE) {
    return (
      <SelectService
        disabled={disabled}
        onChange={(value) => form.setFieldValue(name || ['custom_fields', index, field?.id], isArray(value) ? value : Array(value))}
        mode={field?.multiple ? 'multiple' : undefined}
      />
    );
  }
  if (field?.reference_entity_type === EReferenceEntityType.PRODUCT) {
    return <SelectProduct mode={field?.multiple ? 'multiple' : undefined} disabled={disabled} />;
  }
  if (field?.reference_entity_type === EReferenceEntityType.TEAM) {
    return <SelectTeam mode={field?.multiple ? 'multiple' : undefined} disabled={disabled} />;
  }
  if (field?.reference_entity_type === EReferenceEntityType.TEAM_USER) {
    return <SelectUser mode={field?.multiple ? 'multiple' : undefined} disabled={disabled} />;
  }
  if (isFormField && field?.reference_entity_type === EReferenceEntityType.CONTACT) {
    return <ContactCustomField field={field} form={form} fieldIndex={index} contactFields={contactFields} />;
  }
  if (Object.values(EReferenceEntityType)?.includes(field?.reference_entity_type as EReferenceEntityType)) {
    if (field?.reference_entity_type === EReferenceEntityType.CONTRACT) return;
    return (
      <SelectEntityByReference
        disabled={disabled}
        referenceEntityTypeId={field?.reference_entity_type_id}
        onAutoSelect={(data) => {
          const createdEntityId = data.id;

          if (field?.multiple) {
            const currentValue = form?.getFieldValue(name || ['custom_fields', index, field?.id]);
            form.setFieldValue(
              name || ['custom_fields', index, field?.id],
              currentValue?.length ? [...currentValue, createdEntityId] : [createdEntityId],
            );
          } else {
            form.setFieldValue(name || ['custom_fields', index, field?.id], createdEntityId);
          }
        }}
        mode={field?.multiple ? 'multiple' : undefined}
      />
    );
  }
  return <></>;
};

export const CustomField = ({ field, index, form, name, isFormField, isRequired, contactFields, labelStyle, disabledFields }: ICustomFieldProps) => {
  const { t: translate } = useTranslation();
  Form.useWatch('custom_fields', form);

  const checkBoxOptions = useMemo(() => {
    return field?.list_values
      ?.filter((item) => !item?.is_archived)
      ?.map((item) => ({ label: item?.value, value: item?.id, is_default: item?.is_default }));
  }, [field?.list_values]);

  const options = useMemo(() => {
    if (field?.value_type === EFieldDataValueType.FIELD_DATA_LISTS) {
      const uniqueValueTypes = Array.from(new Set(field?.list_values?.map((value) => value.value_type)));

      if (uniqueValueTypes?.length > 1) {
        return uniqueValueTypes?.map((valueType) => {
          return {
            key: valueType,
            label: toHumanReadable(valueType),
            options: field?.list_values
              ?.filter((item) => item.value_type === valueType)
              ?.map((item) => ({
                key: `option-${item?.id}`,
                label: item?.value,
                value: item?.id,
                is_default: item?.is_default,
              })),
          };
        });
      }
    }

    return field?.list_values
      ?.filter((item) => !item?.is_archived)
      ?.map((item) => ({ label: item?.value, value: item?.id, is_default: item?.is_default }));
  }, [field?.list_values]);

  const initialValue = form?.getFieldValue(name || ['custom_fields', index, field?.id]);

  const TiptapComponent = useMemo(() => {
    return (
      <Tiptap
        disabled={disabledFields.includes(field?.id)}
        content={initialValue}
        onUpdate={(editorInstance) => form.setFieldValue(name || ['custom_fields', index, field?.id], editorInstance.getHTML())}
      />
    );
  }, [initialValue, form, name, index, field?.id]);

  const useCustomField = useMemo(() => {
    return {
      [EFieldDataValueType.FIELD_DATA_TEXTS]: <Input />,
      [EFieldDataValueType.FIELD_DATA_LONG_TEXTS]: TiptapComponent,
      [EFieldDataValueType.FIELD_DATA_EMAILS]: <Input />,
      [EFieldDataValueType.FIELD_DATA_URLS]: <Input />,
      [EFieldDataValueType.FIELD_DATA_NUMBERS]: <InputNumber className='w-full' />,
      [EFieldDataValueType.FIELD_DATA_SEQUENTIAL_IDS]: <InputNumber className='w-full' />,
      [EFieldDataValueType.FIELD_DATA_ADDRESSES]: <AddressField fieldId={field?.id} index={index} form={form} />,
      [EFieldDataValueType.FIELD_DATA_TIMES]: <TimePicker className='w-full' />,
      [EFieldDataValueType.FIELD_DATA_DATE_TIMES]: <CustomDateTime field={field} index={index} />,
      [EFieldDataValueType.FIELD_DATA_FILES]: (
        <UploadFile
          name={'file'}
          url={form?.getFieldValue(name || ['custom_fields', index, field?.id])?.file?.url}
          onUploadChange={(value) => {
            form?.setFieldValue(name || ['custom_fields', index, field?.id], value);
          }}
          uploadSettings={field?.settings}
        />
      ),
      [EFieldDataValueType.FIELD_DATA_IMAGES]: (
        <UploadImage
          name={'file'}
          url={form?.getFieldValue(name || ['custom_fields', index, field?.id])?.file?.url}
          onUploadChange={(value) => {
            form?.setFieldValue(name || ['custom_fields', index, field?.id], value);
          }}
          uploadSettings={field?.settings}
        />
      ),
      [EFieldDataValueType.FIELD_DATA_LISTS]:
        field?.list_type === EEntityFieldListType.CHECK_BOX ? (
          <Checkbox.Group onChange={(value) => form?.setFieldValue(name || ['custom_fields', index, field?.id], value)}>
            {checkBoxOptions.map((item) => (
              <Checkbox key={item.value} value={item.value}>
                {item.label}
              </Checkbox>
            ))}
          </Checkbox.Group>
        ) : (
          <Select options={options as DefaultOptionType[]} mode={field?.multiple ? 'multiple' : undefined} />
        ),
      [EFieldDataValueType.FIELD_DATA_PHONES]: <PhoneInput inputStyle={{ width: '100%' }} country={'mk'} />,
      [EFieldDataValueType.FIELD_DATA_SIGNATURES]: <></>,
      [EFieldDataValueType.FIELD_DATA_SONGS]: (
        <SongField
          value={form.getFieldValue(name || ['custom_fields', index, field?.id])}
          onChange={(songs) => {
            form.setFieldValue(name || ['custom_fields', index, field?.id], songs);
          }}
        />
      ),
      [EFieldDataValueType.FIELD_DATA_STAR_RATINGS]: <Rate />,
      [EFieldDataValueType.FIELD_DATA_REFERENCE]: useDetermineReferenceCustomField(
        form,
        field,
        index,
        isFormField,
        name,
        contactFields,
        disabledFields.includes(field?.id),
      ),
    };
  }, [contactFields, form, name, index, field, checkBoxOptions, options, TiptapComponent, isFormField]);

  return (
    <Tooltip title={disabledFields.includes(field?.id) ? translate('entity.pleaseFillData') : ''}>
      <Form.Item
        hidden={field?.hidden}
        name={name || ['custom_fields', index, field?.id]}
        label={isFormField ? '' : <span style={labelStyle}>{field?.title}</span>}
        initialValue={initialValue}
        rules={[{ required: !!isRequired, message: translate('common.requiredField') }]}
        getValueFromEvent={(value) => {
          if (fieldWithOptions?.includes(field?.value_type)) {
            return Array.isArray(value) ? value : [value];
          }
          if (inputFields?.includes(field?.value_type)) {
            return value?.target?.value || '';
          }
          return value;
        }}>
        {disabledFields.includes(field?.id) ? disableChild(useCustomField[field?.value_type]) : useCustomField[field?.value_type]}
      </Form.Item>
    </Tooltip>
  );
};
