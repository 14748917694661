import { useEffect, useState } from 'react';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import type { CalendarProps } from 'antd';
import { Badge, Calendar as AntdCalendar, Row } from 'antd';
import './Calendar.scss';

import { CustomFieldValue } from '@aduvi/components/CustomField/CustomFieldValue/CustomFieldValue';
import { useBusiness } from '@aduvi/hooks';
import { EPersonalizedView, EPersonalizedViewOrigin } from '@aduvi/types';
import { ICustomField, IEntityType, IEntityWithFields } from '@aduvi/types/entity';

import { getEntities } from 'store/features/entity-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { getStatusForEntities } from '../helper';

export interface IProps {
  data: IEntityWithFields[];
  onRowClick?: (data: IEntityWithFields) => void;
  entityType?: IEntityType;
  origin: EPersonalizedViewOrigin;
  viewId?: string;
}

export const Calendar = ({ data, onRowClick, entityType, origin, viewId }: IProps) => {
  const { selectedView } = useAppSelector((state) => state.personalizedViews);
  const [currentMonth, setCurrentMonth] = useState<Dayjs>(dayjs());
  const selectedBusiness = useBusiness();
  const dispatch = useAppDispatch();

  const prepareListData = (value: Dayjs, compareFn: (item: IEntityWithFields) => boolean) => {
    return [...data].filter(compareFn).map((item, index: number) => (
      <Row key={index} onClick={() => onRowClick && onRowClick(item)} style={{ cursor: 'pointer' }}>
        <div className='flex flex-direction-column'>
          <Badge
            status={'success'}
            text={
              <span style={{ display: 'inline-flex' }}>
                <CustomFieldValue
                  field={item.custom_fields.find((field) => field.id === selectedView?.display_label_field_id)}
                  record={item}
                  origin={origin}
                  entityType={entityType}
                />
              </span>
            }
          />
        </div>
      </Row>
    ));
  };

  const monthCellRender = (value: Dayjs) => {
    return prepareListData(value, (data) => dayjs(value).isSame(data.created_at, 'month'));
  };

  const dateCellRender = (value: Dayjs) => {
    return prepareListData(value, (data) => {
      if (!selectedView) {
        return false;
      }

      return dayjs(value).isSame(
        data?.custom_fields.find((item: ICustomField) => item.id === selectedView?.default_date_values)?.field_data?.[0]?.value || data.created_at,
        'day',
      );
    });
  };

  const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    if (info.type === 'month') return monthCellRender(current);
    return info.originNode;
  };

  const onPanelChange = (value: Dayjs) => {
    setCurrentMonth(value);
  };

  useEffect(() => {
    if (selectedBusiness?.id && entityType?.id) {
      const startOfMonth = currentMonth.startOf('month').format('YYYY-MM-DD HH:mm:ss');
      const endOfMonth = currentMonth.endOf('month').format('YYYY-MM-DD HH:mm:ss');

      dispatch(
        getEntities({
          businessId: selectedBusiness?.id,
          entityTypeId: entityType?.id,
          params: {
            status: getStatusForEntities(origin),
            view_display: EPersonalizedView.CALENDAR,
            paginate: true,
            field_id: selectedView?.default_date_values,
            start_date: startOfMonth,
            end_date: endOfMonth,
            view_id: viewId,
          },
        }),
      );
    }
  }, [selectedBusiness?.id, entityType?.id, currentMonth]);

  return (
    <div className='calendar-wrapper'>
      <AntdCalendar cellRender={cellRender} onPanelChange={onPanelChange} />;
    </div>
  );
};
