import React, { useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';

import { Input, Row } from 'antd';

import { useIsMobile } from '@aduvi/hooks';

interface IProp {
  placeholder: string;
  value?: string;
  onChange?: (value: string) => void;
}

export const SearchInput = ({ placeholder, value, onChange }: IProp) => {
  const isMobile = useIsMobile();

  const [searchInputVisible, setSearchInputVisible] = useState(false);

  const onSearchIconClick = () => {
    setSearchInputVisible(true);
  };
  return (
    <>
      {isMobile ? (
        searchInputVisible ? (
          <Input.Search
            value={value}
            onChange={(e) => onChange?.(e.target.value)}
            onBlur={() => setSearchInputVisible(false)}
            style={{ width: searchInputVisible ? 'auto' : 0 }}
            autoFocus
            placeholder={placeholder}
            allowClear
          />
        ) : (
          <Row justify='center' align='middle'>
            <SearchOutlined style={{ cursor: 'pointer', fontSize: 16 }} className='mr-10' onClick={onSearchIconClick} />
          </Row>
        )
      ) : (
        <Input.Search
          value={value}
          onChange={(e) => onChange?.(e.target.value)}
          className='mr-10'
          style={{ maxWidth: 'auto' }}
          placeholder={placeholder}
          allowClear
        />
      )}
    </>
  );
};
