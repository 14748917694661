import { useState } from 'react';

import { Select } from 'antd';

import { useBusiness } from '@aduvi/hooks';
import { EFieldDataValueType, IEntityField, IKeyValueEditedEntityCustomFields } from '@aduvi/types';
import { IEntityType, IEntityWithFields, IFieldData } from '@aduvi/types/entity';
import { ICustomField } from '@aduvi/types/entity';

import { editEntity } from 'store/features/entity-slice';
import { setSelectedEntity } from 'store/features/entity-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { selectOptionsColor } from '../Entity/components/drawer-tabs/tasks/@components/helper';
import { mapEditedEntityFieldsToPayload, mapEntityFieldsToForm } from '../Entity/helper';

interface IProps {
  option: IFieldData;
  record: IEntityWithFields | undefined;
  managementFields: IEntityField[] | undefined;
  field: ICustomField;
  entityType?: IEntityType;
  setIsEditing: (arg: boolean) => void;
}

export const EditableSelect = ({ option, record, managementFields, field, entityType, setIsEditing }: IProps) => {
  const selectedBusiness = useBusiness();
  const dispatch = useAppDispatch();

  const [updatedFieldIds, setUpdatedFieldIds] = useState<string[]>([]);

  const { fields } = useAppSelector((state) => state.fields);

  const { entities } = useAppSelector((state) => state.entity);

  return (
    <Select
      className={option?.color && selectOptionsColor[option?.color?.trim()]}
      style={{ width: 115, height: 26, borderRadius: 5 }}
      defaultValue={option.value}
      optionLabelProp='label'
      loading={entities.updating && updatedFieldIds.includes(field?.id)}
      options={managementFields
        ?.filter((managementField) => managementField.value_type === EFieldDataValueType.FIELD_DATA_LISTS && managementField?.title === field?.title)
        .flatMap(({ list_values }) => list_values.map(({ value, id }) => ({ label: value, value: id })))}
      onChange={(value) => {
        if (!record || !managementFields) return;

        const mappedEntityFields = mapEntityFieldsToForm(record, managementFields);

        const changedCustomFields = mappedEntityFields?.map((fields) => {
          if (fields?.[field.id]) return { [field.id]: [value] };
          return fields;
        });

        setUpdatedFieldIds((prev) => [...prev, field?.id]);

        if (!selectedBusiness?.id || !entityType) return;

        dispatch(
          editEntity({
            id: record?.id,
            business_id: selectedBusiness?.id,
            entity_type_id: entityType?.id,
            custom_fields: mapEditedEntityFieldsToPayload(changedCustomFields as IKeyValueEditedEntityCustomFields[], fields, record),
          }),
        )
          .then(() => {
            dispatch(setSelectedEntity(undefined));
            setUpdatedFieldIds((prev) => prev.filter((id) => id !== field?.id));
            setIsEditing(false);
          })
          .catch(() => {});
      }}
    />
  );
};
