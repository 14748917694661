import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { Button, Col, Divider, Dropdown, Row } from 'antd';
import { FormInstance } from 'antd/lib';
import '../Build.scss';

import { IEntityType } from '@aduvi/types/entity';

import { setIsHoveringWidgetArea } from 'store/features/form-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { bookingFormDropdownItems, IBookingFormFieldProps } from '../helper';

import { SortableItem } from './SortableItem';

interface IProps {
  form: FormInstance;
  isDragging: boolean;
  fields: IBookingFormFieldProps[];
  readonly?: boolean;
  entityTypeHasQuotes: boolean;
  setFields: (value: React.SetStateAction<IBookingFormFieldProps[]>) => void;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  onAddField: (element: IBookingFormFieldProps, index: number) => void;
  onCreateNewFieldDrawer: (index: number) => void;
  onCreateAsset: (assetType: IEntityType, index: number) => void;
}

export const WidgetFields = ({
  form,
  isDragging,
  fields,
  readonly,
  entityTypeHasQuotes,
  setFields,
  onAddField,
  onCreateNewFieldDrawer,
  onCreateAsset,
}: IProps) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const { fields: entityFields } = useAppSelector((state) => state.fields);

  const {
    entityTypes: { entityTypes },
  } = useAppSelector((state) => state.entity);

  return (
    <SortableContext items={fields?.map((item) => item?.field_id)} strategy={verticalListSortingStrategy} id='widget-container-id'>
      {fields?.filter((item) => item?.field_instance?.display_settings?.is_widget_field)?.length ? (
        fields?.map((item, index) => {
          if (!item?.field_instance?.display_settings?.is_widget_field) return;
          return (
            <Col key={item?.field_id} className='w-full'>
              <SortableItem
                readonly={readonly}
                fields={fields}
                form={form}
                id={item?.field_id}
                isDragging={isDragging}
                index={index}
                isPageBreak={item?.isPageBreak}
                setFields={setFields}
                disabledFields={[]}
              />

              {isDragging || readonly ? null : (
                <Row className='add-field-wrapper '>
                  <Divider dashed orientation='center' className='add-field-divider'>
                    <Dropdown
                      overlayClassName='booking-form-dropdown'
                      menu={{
                        items: bookingFormDropdownItems(
                          index + 1,
                          fields,
                          entityFields,
                          form,
                          entityTypeHasQuotes,
                          entityTypes,
                          true,
                          onAddField,
                          onCreateNewFieldDrawer,
                          onCreateAsset,
                        ),
                      }}
                      trigger={['click']}
                      placement='bottomCenter'>
                      <Button icon={<PlusOutlined />} type='primary' style={{ borderRadius: '5px' }} size='small' />
                    </Dropdown>
                  </Divider>
                </Row>
              )}
            </Col>
          );
        })
      ) : (
        <Row
          style={{ height: '100px', border: '1px dashed black ', zIndex: 999 }}
          justify={'center'}
          align={'middle'}
          className='w-full mt-10 mb-10'
          onMouseEnter={() => dispatch(setIsHoveringWidgetArea(true))}
          onMouseOver={() => dispatch(setIsHoveringWidgetArea(true))}
          onMouseLeave={() => dispatch(setIsHoveringWidgetArea(false))}>
          {translate('widgets.dropFields')}
        </Row>
      )}
    </SortableContext>
  );
};
