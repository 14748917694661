import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Spin, Table, Typography } from 'antd';
import '../EntityWidget.scss';

import { useBusiness } from '@aduvi/hooks';
import { ICustomField, IEntityWithFields } from '@aduvi/types/entity';

import { useAppSelector } from 'store/hooks';
import { getEntities } from 'store/services/entity.service';

export const ProjectsWidget = () => {
  const { t: translate } = useTranslation();

  const selectedBusiness = useBusiness();
  const { entityTypes } = useAppSelector((state) => state.entity);

  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState<IEntityWithFields[]>([]);

  useEffect(() => {
    const fetchEntities = async () => {
      if (!selectedBusiness?.id || !entityTypes?.data?.PROJECT?.id) return;
      setIsLoading(true);
      try {
        const response = await getEntities(selectedBusiness.id, entityTypes.data.PROJECT.id);
        setProjects(response.data.entities);
      } finally {
        setIsLoading(false);
      }
    };
    fetchEntities();
  }, [selectedBusiness?.id, entityTypes?.data]);

  return (
    <Card
      className='entity-card'
      headStyle={{ backgroundColor: '#F8F8F8', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
      title={
        <div className=' p-10'>
          <Typography.Title level={5}>{translate('dashboard.widgets.projects.title')}</Typography.Title>
        </div>
      }>
      <div className='entity-list-wrapper'>
        <Spin spinning={isLoading} style={{ height: '100%' }}>
          {!isLoading && projects?.length > 0 ? (
            <Table
              scroll={{ x: 350 }}
              dataSource={projects}
              pagination={false}
              columns={[
                {
                  title: translate('dashboard.widgets.projects.name'),
                  dataIndex: 'custom_fields',
                  key: 'name',
                  render: (fields) => (
                    <Typography.Paragraph className='mb-0 fs-12'>
                      {fields?.find((field: ICustomField) => field?.title === 'Name')?.field_data?.[0]?.value}
                    </Typography.Paragraph>
                  ),
                },
                {
                  title: translate('dashboard.widgets.projects.jobs'),
                  dataIndex: 'custom_fields',
                  key: 'jobs',
                  render: (fields) => (
                    <>
                      {fields
                        ?.find((field: ICustomField) => field?.title === 'Jobs')
                        ?.field_data?.map((job: IEntityWithFields, index: number) => (
                          <Typography.Paragraph className='mb-0 fs-10' key={index}>
                            {index + 1}. {job.custom_fields?.find((field) => field?.title === 'Event Name')?.field_data?.[0]?.value}
                          </Typography.Paragraph>
                        ))}
                    </>
                  ),
                },
              ]}
            />
          ) : (
            !isLoading && (
              <Typography.Paragraph type='secondary'>{translate('dashboard.widgets.noEntity', { type: 'projects' })}</Typography.Paragraph>
            )
          )}
        </Spin>
      </div>
    </Card>
  );
};
