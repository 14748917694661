import React, { useState } from 'react';
import { SaveOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { Button, Input, InputNumber, Rate } from 'antd';
import { TimePicker } from 'antd/lib';

import { useBusiness } from '@aduvi/hooks';
import { EditableCustomFieldProps, EFieldDataValueType, IKeyValueEditedEntityCustomFields } from '@aduvi/types';

import { editEntity, setSelectedEntity } from 'store/features/entity-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { mapEditedEntityFieldsToPayload, mapEntityFieldsToForm } from '../Entity/helper';

export const EditableCustomField = ({ fieldType, value, field, record, managementFields, entityType, setIsEditing }: EditableCustomFieldProps) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();

  const [currentValue, setCurrentValue] = useState(value);
  const [isSaving, setIsSaving] = useState(false);

  const { fields } = useAppSelector((state) => state.fields);

  const handleSave = () => {
    if (!record || !managementFields) return;

    const mappedEntityFields = mapEntityFieldsToForm(record, managementFields);

    const changedCustomFields = mappedEntityFields?.map((fields) => {
      if (field && field.id && fields?.[field.id]) {
        if (fieldType === EFieldDataValueType.FIELD_DATA_ADDRESSES && typeof currentValue === 'string') {
          const [street_one, city, country, province, zip_code] = currentValue?.split(',').map((item) => item.trim());
          return {
            [field.id]: {
              street_one,
              city,
              country,
              province,
              zip_code,
            },
          };
        }

        return { [field.id]: currentValue };
      }
      return fields;
    });

    setIsSaving(true);

    if (!selectedBusiness?.id || !entityType) return;

    dispatch(
      editEntity({
        id: record?.id,
        business_id: selectedBusiness?.id,
        entity_type_id: entityType?.id,
        custom_fields: mapEditedEntityFieldsToPayload(changedCustomFields as IKeyValueEditedEntityCustomFields[], fields, record),
      }),
    )
      .then(() => {
        dispatch(setSelectedEntity(undefined));
      })
      .catch(() => {})
      .finally(() => {
        setIsSaving(false);
        setIsEditing(false);
      });
  };

  const renderField = () => {
    switch (fieldType) {
      case EFieldDataValueType.FIELD_DATA_ADDRESSES:
      case EFieldDataValueType.FIELD_DATA_PHONES:
      case EFieldDataValueType.FIELD_DATA_EMAILS:
      case EFieldDataValueType.FIELD_DATA_TEXTS:
      case EFieldDataValueType.FIELD_DATA_URLS:
        if (typeof currentValue !== 'string') return;
        return (
          <Input
            autoFocus
            value={currentValue}
            onChange={(e) => {
              setCurrentValue(e.target.value);
              e.stopPropagation();
            }}
            onPressEnter={(e) => {
              e.preventDefault();
              handleSave();
              setIsEditing(false);
            }}
            onClick={(e) => e.stopPropagation()}
            disabled={isSaving}
          />
        );

      case EFieldDataValueType.FIELD_DATA_LONG_TEXTS:
        if (typeof currentValue !== 'string') return;
        return (
          <Input.TextArea
            value={currentValue}
            onChange={(e) => setCurrentValue(e.target.value)}
            onPressEnter={(e) => {
              e.preventDefault();
              handleSave();
              setIsEditing(false);
            }}
            autoSize={{ minRows: 2, maxRows: 6 }}
            onClick={(e) => e.stopPropagation()}
            disabled={isSaving}
            style={{ width: '100%' }}
          />
        );

      case EFieldDataValueType.FIELD_DATA_STAR_RATINGS:
        if (typeof currentValue !== 'number') return;
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <Rate
              value={currentValue}
              onChange={(value) => {
                setCurrentValue(value);
              }}
            />
          </div>
        );

      case EFieldDataValueType.FIELD_DATA_TIMES:
        if (typeof currentValue !== 'string') return null;

        return (
          <div onClick={(e) => e.stopPropagation()}>
            <TimePicker
              onClick={(e) => e.stopPropagation()}
              value={currentValue ? dayjs(currentValue, 'HH:mm:ss') : null}
              onChange={(time, timeString) => setCurrentValue(timeString)}
              format='HH:mm:ss'
            />
          </div>
        );

      case EFieldDataValueType.FIELD_DATA_NUMBERS:
      case EFieldDataValueType.FIELD_DATA_SEQUENTIAL_IDS:
        if (typeof currentValue !== 'number') return;
        return (
          <InputNumber
            value={currentValue}
            onChange={(value) => setCurrentValue(value || 0)}
            onPressEnter={(e) => {
              e.preventDefault();
              handleSave();
              setIsEditing(false);
            }}
            disabled={isSaving}
            onClick={(e) => e.stopPropagation()}
            style={{ width: '100%' }}
          />
        );
    }
  };

  return (
    <div className='editable-custom-field'>
      <div className='field-wrapper flex align-center '>
        {renderField()}
        <Button
          icon={<SaveOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            handleSave();
            setIsEditing(false);
          }}
          loading={isSaving}
          disabled={isSaving}
          size='small'
          className='save-btn ml-10'
          style={{ padding: '15px' }}
        />
      </div>
    </div>
  );
};
