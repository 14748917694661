import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

import { Col, Typography } from 'antd';
import './DraggableColumn.scss';

import { CustomFieldValue } from '@aduvi/components/CustomField/CustomFieldValue/CustomFieldValue';
import { ICustomField, IEntityWithFields } from '@aduvi/types/entity';

import DisableWrapper from './DisableWrapper';

export const DraggableColumn = ({
  id,
  title,
  field,
  record,
  disabled = true,
}: {
  id: string;
  title: string;
  field?: ICustomField;
  record?: IEntityWithFields;
  disabled?: boolean;
}) => {
  const { attributes, listeners, transform, isDragging, setNodeRef } = useDraggable({
    id,
    disabled: !disabled,
  });

  return (
    <Col span={4}>
      <DisableWrapper disabled={!disabled}>
        <div
          className={`draggable-column-wrapper ${isDragging && 'dragged'}`}
          ref={setNodeRef}
          {...(disabled ? { ...attributes, ...listeners } : {})}
          style={{
            transform: CSS.Translate.toString(transform),
            cursor: disabled ? 'grab' : 'not-allowed',
            overflowX: 'auto',
            minHeight: '62px',
            borderRadius: '5px',
            border: disabled ? '1px dashed #70b6fc' : '1px solid #d9d9d9',
            backgroundColor: isDragging ? 'rgba(255,255,255,0.6)' : disabled ? 'transparent' : '#f5f5f5',
            rotate: isDragging ? '5deg' : '0deg',
          }}>
          <div>
            <Typography.Paragraph className='fs-12 mb-5 color-gray-5'>{title}</Typography.Paragraph>
            <CustomFieldValue showDummyData={true} field={field} record={record} />
          </div>
        </div>
      </DisableWrapper>
    </Col>
  );
};
