import React from 'react';
import { useTranslation } from 'react-i18next';
import { CopyOutlined } from '@ant-design/icons';

import { Button, Form, Input, Row, Switch, Typography } from 'antd';
import { FormInstance } from 'antd/lib';

export const Embed = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();
  return (
    <span style={{ width: '35%' }}>
      <Typography.Text type='secondary'>{translate('widgets.embedWidget')}</Typography.Text>
      <Row className='mt-15' justify={'start'}>
        <Form.Item name='embed_form_remove_styling'>
          <Switch />
        </Form.Item>
        <Typography.Text className='mt-5 ml-15'>{translate('forms.forms.removeBackgroundStyling')}</Typography.Text>
      </Row>
      <Form.Item name='embed_code' label={translate('forms.forms.embedCode')}>
        <Input.TextArea rows={4} placeholder='<div id=”...”></div><script>....</script>' readOnly />
      </Form.Item>
      <Row justify={'end'}>
        <Button type='link' onClick={() => navigator.clipboard.writeText(form.getFieldValue('embed_code'))}>
          <CopyOutlined /> {translate('forms.forms.copyWidgetCode')}
        </Button>
      </Row>
    </span>
  );
};
