import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from 'ckeditor5';
import { ChatEditor } from 'views/conversation/@components/chat-editor/ChatEditor';
import { FileList } from 'views/conversation/@components/file-list/FileList';
import { ThreadDrawer } from 'views/conversation/@components/threads-drawer/ThreadDrawer';

import { Col, Form, Row, Spin, Typography, UploadFile } from 'antd';
import { useForm } from 'antd/es/form/Form';
import './Chat.scss';

import { EFieldDataValueType } from '@aduvi/types';
import { IChannelMember } from '@aduvi/types/conversation';

import { clearMessages, clientGetChannelById, clientGetMessagesByChannelId } from 'store/features/conversation-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { ChannelHeader } from '../@components/ChannelHeader';
import { SingleChat } from '../single-chat/SingleChat';

import { useEditorHandler } from './EditorHandleHook';

export const Chat = () => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const [form] = useForm();

  const { messages } = useAppSelector((state) => state.conversation);
  const { selectedClientPortal, loading } = useAppSelector((state) => state.clientPortal);
  const { user } = useAppSelector((state) => state.clientPortalAuth);
  const { clientChannel } = useAppSelector((state) => state.conversation);

  const messagesContainerRef = useRef<HTMLDivElement | null>(null);

  const [showThreadDrawer, setShowThreadDrawer] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [editorInstance, setEditorInstance] = useState<Editor | null>(null);

  const { handleEditorKeyDown, handleSubmit, scrollToBottom } = useEditorHandler(editorInstance, messagesContainerRef);

  const channelId = useMemo(
    () => (user.jobs.selectedJob ? user.jobs.selectedJob.channel?.id : user?.orders?.selectedOrder?.channel?.id),
    [user, user.jobs?.selectedJob?.channel?.id, user?.orders?.selectedOrder?.channel?.id, user?.jobs?.selectedJob],
  );

  const channelMembers = useMemo<IChannelMember[]>(() => {
    if (!clientChannel) return [];

    const partnerUsers =
      clientChannel.users?.flatMap((user) =>
        user.partner_users.map((partnerUser) => ({
          id: user.id,
          first_name: partnerUser.first_name ? String(partnerUser.first_name) : null,
          last_name: partnerUser.last_name ? String(partnerUser.last_name) : null,
          email: partnerUser.email || '',
          profile_picture: partnerUser.profile_picture || null,
        })),
      ) || [];

    const contacts =
      clientChannel.contacts?.map((contact) => ({
        id: contact.id,
        first_name: contact.entityDetails?.custom_fields?.find((field) => field?.value_type === EFieldDataValueType.FIELD_DATA_TEXTS)?.field_data?.[0]
          ?.value
          ? String(
              contact.entityDetails?.custom_fields?.find((field) => field?.value_type === EFieldDataValueType.FIELD_DATA_TEXTS)?.field_data?.[0]
                ?.value,
            )
          : null,
        last_name: null,
        email: contact.email || '',
        profile_picture:
          contact.entityDetails?.custom_fields?.find((field) => field?.value_type === EFieldDataValueType.FIELD_DATA_IMAGES)?.field_data?.[0]?.url ||
          null,
      })) || [];

    return [...partnerUsers, ...contacts];
  }, [clientChannel]);

  useEffect(() => {
    if (
      !selectedClientPortal?.business_id ||
      !selectedClientPortal?.id ||
      !user?.jobs?.selectedJob?.id ||
      !user?.jobs?.selectedJob?.channel?.id ||
      !channelId
    ) {
      dispatch(clearMessages());
      return;
    }

    dispatch(
      clientGetMessagesByChannelId({
        businessId: selectedClientPortal.business_id,
        businessClientPortalId: selectedClientPortal?.id,
        channelId: user?.jobs?.selectedJob?.channel?.id,
      }),
    )
      .unwrap()
      .then(() => {
        scrollToBottom();
      })
      .catch(() => {});
  }, [selectedClientPortal?.business_id, user?.jobs?.selectedJob?.channel?.id, user?.jobs?.selectedJob?.id]);

  useEffect(() => {
    if (!selectedClientPortal?.business_id || !selectedClientPortal?.id || !user?.jobs?.selectedJob?.id || !user?.jobs?.selectedJob?.channel?.id)
      return;

    dispatch(
      clientGetChannelById({
        businessId: selectedClientPortal.business_id,
        businessClientPortalId: selectedClientPortal?.id,
        channelId: user?.jobs?.selectedJob?.channel?.id,
      }),
    )
      .unwrap()
      .then(() => {
        scrollToBottom();
      })
      .catch(() => {});
  }, [selectedClientPortal?.business_id, user?.jobs?.selectedJob?.channel?.id, user?.jobs?.selectedJob?.id]);

  return (
    <Spin className='min-h-100vh' spinning={loading} tip='Initializing conversation...'>
      <Row wrap={false}>
        <Col span={showThreadDrawer ? 17 : 22} className='conversation-chat-wrapper'>
          <Row className='w-full p-20 ' align={'middle'}>
            <ChannelHeader channelMembers={channelMembers} />
          </Row>

          <Row
            className='chat-message-container'
            style={{
              maxHeight: `calc(80vh - ${fileList?.length ? '140px' : '65px'})`,
              minHeight: `calc(80vh - ${fileList?.length ? '140px' : '65px'})`,
            }}
            ref={messagesContainerRef}>
            <Col className='w-full message-content'>
              <Row>
                {messages.loading ? (
                  <Typography.Text type='secondary' className='p-20'>
                    {translate('conversation.loadingMessages')}
                  </Typography.Text>
                ) : messages.data && messages.data.length > 0 ? (
                  messages.data.map((message, index) => {
                    return <SingleChat key={index} message={message} setShowThreadDrawer={setShowThreadDrawer} channelUsers={channelMembers} />;
                  })
                ) : (
                  <Typography.Text className='p-20' type='secondary'>
                    {translate('conversation.noMessages')}
                  </Typography.Text>
                )}
              </Row>
            </Col>
          </Row>

          <Row className='mx-20'>
            <Form style={{ width: '100%' }} form={form}>
              <Form.Item className='mb-0' name='message'>
                {fileList?.length > 0 ? (
                  <Row>
                    <FileList fileList={fileList} setFileList={setFileList} />
                  </Row>
                ) : (
                  <></>
                )}
                <ChatEditor
                  key={'0'}
                  setFileList={setFileList}
                  handleEditorKeyDown={handleEditorKeyDown}
                  handleSubmit={() => {
                    handleSubmit(fileList?.map((file) => file?.url) as string[]);
                    setFileList([]);
                  }}
                  setEditorInstance={setEditorInstance}
                />
              </Form.Item>
            </Form>
          </Row>
        </Col>

        {showThreadDrawer && (
          <Col className='conversation-wrapper' span={7}>
            <ThreadDrawer setShowThreadDrawer={setShowThreadDrawer} />
          </Col>
        )}
      </Row>
    </Spin>
  );
};
