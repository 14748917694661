import dayjs from 'dayjs';

import { EListFormat, ENumberFormat, ETextAlignment } from '@aduvi/types';

export const applyNumberFormat = (
  number: number,
  format: string | undefined,
  alignment: ETextAlignment.LEFT | ETextAlignment.RIGHT,
  currency?: { code: string; format: string },
): string => {
  if (!format) {
    return number.toString();
  }

  let formattedValue: string;

  switch (format) {
    case ENumberFormat.PLAIN:
      formattedValue = number.toString();
      break;
    case ENumberFormat.COMMA:
      formattedValue = new Intl.NumberFormat('en-US').format(number);
      break;
    case ENumberFormat.CURRENCY:
      formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency?.code,
        minimumFractionDigits: 2,
      }).format(number);

      break;
    case ENumberFormat.PERCENTAGE:
      formattedValue = `${number}%`;
      break;
    default:
      formattedValue = new Intl.NumberFormat('en-US', {
        useGrouping: format?.includes(','),
        minimumFractionDigits: format?.includes('.') ? format?.split('.')[1].length : 0,
      }).format(number);

      if (format.includes('$')) {
        formattedValue = alignment === ETextAlignment.LEFT ? `$${formattedValue}` : `${formattedValue}$`;
      }
      if (format.includes('%')) {
        formattedValue = alignment === ETextAlignment.LEFT ? `%${formattedValue}` : `${formattedValue}%`;
      }
      break;
  }

  return formattedValue;
};

export const formatListItem = (value: string, index: number, format: string): string => {
  switch (format) {
    case EListFormat.COMMA:
      return index === 0 ? value : `, ${value}`;

    case EListFormat.NUMBERED:
      return `${index + 1}. ${value}`;

    case EListFormat.BULLET:
      return `• ${value}`;

    case EListFormat.BRACKETS:
      return `[${value}]`;

    default:
      return value;
  }
};

export const trimText = (text: string, maxLength: number): string => {
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength)}...`;
  }
  return text;
};

export const formatWithOrdinal = (date: string, format: string): string => {
  if (!date) return '';

  const getOrdinal = (day: number): string => {
    if (day % 10 === 1 && day % 100 !== 11) return 'st';
    if (day % 10 === 2 && day % 100 !== 12) return 'nd';
    if (day % 10 === 3 && day % 100 !== 13) return 'rd';
    return 'th';
  };

  const day = dayjs(date).date();
  const ordinal = getOrdinal(day);

  const formattedDate = dayjs(date)?.format(format?.replace('[th]', `[${ordinal}]`));
  return formattedDate?.replace(/\[(st|nd|rd|th)\]/g, (_, suffix) => suffix);
};

export const formatQuoteItem = (value: string, index: number, format: string): string => {
  switch (format) {
    case 'bullet_list':
      return `• ${value}`;
    case 'numbered':
      return `${index + 1}. ${value}`;
    case 'comma_separated':
      return value;
    default:
      return value;
  }
};

export const dummyFieldValues = {
  textSingleLine: 'Example Text',
  textMultiLine: 'An example of a multiple line text field…',
  time: '5pm',
  date: 'January 1st 2025',
  email: 'john.smith@example.com',
  phone: '+1 234 567 890',
  url: 'https://google.com',
  checkbox: '',
  list: 'First Value',
  address: '123 Main Street, Town, City',
  number: '42',
  sequentialId: '123',
  file: 'example.jpg',
  image: 'example.jpg',
  starRating: { value: 3, max: 5 },
  song: 'Never Gonna Give You Up - Rick Astley',
  signature: '',
};
