import React, { useCallback, useEffect, useMemo } from 'react';
import { AudioOutlined, GifOutlined, PaperClipOutlined, SendOutlined, SmileOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { EditorOptions } from '@tiptap/core';
import CharacterCount from '@tiptap/extension-character-count';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import Youtube from '@tiptap/extension-youtube';
import { Editor, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import { Button, Divider, Row, Typography } from 'antd';
import './Tiptap.scss';

import { AutomationMention } from './AutomationMention';
// import { CustomMention } from './Mention';
import { useSuggestion } from './mentionSuggestions';
import MenuBar from './MenuBar';

interface Props {
  style?: React.CSSProperties;
  content: EditorOptions['content'];
  isConversationEditor?: boolean;
  showCounts?: boolean | ((characters: number, words: number) => React.ReactNode);
  placeholder?: string;
  disabled?: boolean;
  onUpdate?: (editor: Editor) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  setEditorInstance?: (editor: Editor | null) => void;
  onSendMessage?: () => void;
}

const Tiptap = ({
  style,
  content,
  showCounts,
  placeholder,
  isConversationEditor,
  disabled,
  onKeyDown,
  onUpdate,
  setEditorInstance,
  onSendMessage,
}: Props) => {
  const extensions = useMemo(
    () => [
      StarterKit.configure({
        heading: {
          levels: [2, 3, 4, 5],
        },
      }),
      Image,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      TextStyle,
      Underline,
      Link.configure({
        openOnClick: false,
        validate: (href: string) => /^https?:\/\//.test(href),
      }),
      CharacterCount,
      Placeholder.configure({
        placeholder: placeholder ?? 'Write something here...',
      }),
      AutomationMention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        suggestion: useSuggestion(),
      }),
      Youtube.configure({
        inline: false,
        nocookie: true,
      }),
    ],
    [placeholder],
  );

  const handleUpdate = useCallback(
    ({ editor: _editor }: { editor: Editor }) => {
      localStorage.setItem('_editorContent', JSON.stringify(_editor.getJSON()));
      onUpdate?.(_editor);
    },
    [onUpdate],
  );

  const editor = useEditor({
    editorProps: {
      attributes: {
        class: 'awesome-wysiwyg-editor',
      },
    },
    onUpdate: handleUpdate,
    extensions,
    content,
    editable: !disabled,
  });

  useEffect(() => {
    if (editor && content !== undefined) {
      if (content !== editor.getHTML()) {
        editor.commands.setContent(content || '', false);
      }
    }
  }, [editor, content]);

  const isContentEmpty = useMemo(() => editor?.getHTML().trim() === '<p></p>', [editor?.getHTML()]);

  if (!editor) {
    return null;
  }

  useEffect(() => {
    if (editor.isInitialized) {
      setEditorInstance?.(editor);
    }
  }, [editor.isInitialized]);

  return (
    <div
      className='tip-tap-wrapper'
      style={{ border: `1px solid ${editor.isFocused ? '#cccccc' : '#e6e6e6'}` }}
      onClick={() => editor.commands.focus()}>
      <MenuBar editor={editor} disabled={disabled} />
      <EditorContent editor={editor} placeholder='Message' onKeyDown={onKeyDown} style={style} />
      {isConversationEditor ? (
        <Row justify={'space-between'} className='mb-5'>
          <Row style={{ columnGap: '4px', marginLeft: '6px' }}>
            <Button disabled={disabled} icon={<PaperClipOutlined style={{ color: '#868686' }} />} type='link' />
            <Button disabled={disabled} icon={<SmileOutlined style={{ color: '#868686' }} />} type='link' />
            <Button disabled={disabled} icon={<GifOutlined style={{ color: '#868686' }} />} type='link' />
            <Divider type='vertical' style={{ height: '24px', marginTop: '8px' }} />
            <Button disabled={disabled} icon={<VideoCameraOutlined style={{ color: '#868686' }} />} type='link' />
            <Button disabled={disabled} icon={<AudioOutlined style={{ color: '#868686' }} />} type='link' />
          </Row>
          <Button
            disabled={disabled}
            icon={<SendOutlined style={{ color: isContentEmpty ? '#868686' : 'white', fontSize: '1.3em' }} />}
            type={isContentEmpty ? 'link' : 'primary'}
            className='mr-5'
            style={{ cursor: isContentEmpty ? 'text' : 'pointer', borderRadius: '4px' }}
            onClick={() => {
              if (isContentEmpty) return;
              return onSendMessage?.();
            }}
          />
        </Row>
      ) : null}
      {showCounts === true ? (
        <Typography color='disabled'>
          {editor.storage.characterCount.characters()} characters ({editor.storage.characterCount.words()} words)
        </Typography>
      ) : (
        typeof showCounts === 'function' && showCounts(editor.storage.characterCount.characters(), editor.storage.characterCount.words())
      )}
    </div>
  );
};

export default Tiptap;
