import axios from '@aduvi/config/axios';
import { IResponse } from '@aduvi/types';
import { IEmailConnection } from '@aduvi/types/connected-account';

export const getAllConnectedAccounts = async (businessId: string): Promise<IResponse<IEmailConnection[]>> => {
  return await axios.get(`/businesses/${businessId}/connections`);
};

export const connectEmail = async (businessId: string, body: IEmailConnection) => {
  return await axios.post(`/businesses/${businessId}/connections`, body);
};
