import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { Col, Row } from 'antd';
import './Widget.scss';

import { CommonSubMenu } from '@aduvi/components/CommonSubMenu/CommonSubMenu';

import { WidgetMenu } from './@components/widget-menu/WidgetMenu';

export const Widgets = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/widgets') {
      return navigate('/widgets/business');
    }
  }, [location.pathname]);

  return (
    <Row className='h-full'>
      <CommonSubMenu style={{ borderRight: '1px solid #e7e9f5', boxShadow: ' 0px 2px 8px 0px #5194D826' }}>
        <WidgetMenu />
      </CommonSubMenu>

      <Col xs={24} md={17} xl={19} xxl={20} className='widgets-container'>
        <Outlet />
      </Col>
    </Row>
  );
};
