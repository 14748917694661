import { LockOutlined, MessageOutlined } from '@ant-design/icons';

import { Avatar, Col, Tooltip, Typography } from 'antd';

import { EChannelType, IChannelMember } from '@aduvi/types/conversation';

import { useAppSelector } from 'store/hooks';

export const ChannelHeader = ({ channelMembers }: { channelMembers: IChannelMember[] }) => {
  const { clientChannel } = useAppSelector((state) => state.conversation);

  return (
    <>
      <Col style={{ gap: '5px', alignItems: 'center' }} lg={20}>
        <Typography.Text>
          {clientChannel?.type === EChannelType.PRIVATE ? <LockOutlined /> : <MessageOutlined />} {clientChannel?.name}
        </Typography.Text>
      </Col>

      <Col lg={4} className='flex-grow-1' style={{ justifyContent: 'end', display: 'flex' }}>
        <Avatar.Group
          maxCount={2}
          maxPopoverTrigger='click'
          size='default'
          maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}>
          {channelMembers?.map((user) => (
            <Tooltip key={user?.id} title={`${user?.first_name} ${user?.last_name}`} placement='top'>
              <Avatar src={user?.profile_picture}>{`${user?.first_name?.[0]?.toUpperCase()}${user?.last_name?.[0]?.toUpperCase()}`}</Avatar>
            </Tooltip>
          ))}
        </Avatar.Group>
      </Col>
    </>
  );
};
