import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Col, Row, Spin } from 'antd';

import { useBusiness } from '@aduvi/hooks';
import { EWidgetApps } from '@aduvi/types/widget';

import { deleteWidget, getWidgets } from 'store/features/widget-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { WidgetCard } from '../@components/WidgetCard/WidgetCard';

export const Forms = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedBusiness = useBusiness();

  const { widgets, loading } = useAppSelector((state) => state.widget);

  const onDeleteWidget = (id: string) => {
    if (!selectedBusiness?.id) return;
    dispatch(deleteWidget({ business_id: selectedBusiness?.id, widget_id: id }));
  };

  useEffect(() => {
    if (!selectedBusiness?.id) return;
    dispatch(getWidgets({ businessId: selectedBusiness.id, app: EWidgetApps.FORMS }));
  }, [selectedBusiness?.id, dispatch]);

  return (
    <Spin spinning={loading}>
      <Row gutter={[24, 24]} className='w-full'>
        {widgets?.map((form, index) => (
          <Col className='mb-15' key={index} span={12} xs={24} sm={12} lg={8}>
            <WidgetCard
              id={form?.id}
              title={form?.title}
              description={form?.description}
              action={'Leads'}
              backgroundColor={form?.widget_style?.[0]?.widget_background ?? form?.widget_style?.[0]?.background_color}
              backgroundImage={''}
              onConfigure={() => navigate(`/widgets/forms-configure/${form.id}`)}
              onDuplicate={() => {}}
              onDelete={() => onDeleteWidget(form?.id)}
            />
          </Col>
        ))}
      </Row>
    </Spin>
  );
};
