import { forwardRef, useCallback } from 'react';
import {
  BoldOutlined,
  CheckSquareOutlined,
  // CodeOutlined,
  // EnterOutlined,
  ItalicOutlined,
  LinkOutlined,
  // MinusOutlined,
  OrderedListOutlined,
  // PictureOutlined,
  StrikethroughOutlined,
  UnderlineOutlined,
  UnorderedListOutlined,
  // YoutubeFilled,
} from '@ant-design/icons';
import { Editor } from '@tiptap/react';

import { Button, ButtonProps, Divider, Row } from 'antd';

const ToolbarButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
  <Button
    ref={ref}
    {...props}
    size='small'
    style={{
      ...props.style,
      fontSize: '1.15em',
      display: 'flex',
      justifyContent: 'center',
      justifyItems: 'center',
      textAlign: 'center',
      alignContent: 'center',
      alignItems: 'center',
    }}
    type='link'
  />
));

ToolbarButton.displayName = 'ToolbarButton';

const MenuBar = ({ editor, disabled }: { editor: Editor; disabled?: boolean }) => {
  const setUnsetLink = useCallback(() => {
    if (editor.isActive('link')) {
      editor.chain().focus().unsetLink().run();
      return;
    }
    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('URL', previousUrl);

    if (url === null) {
      return;
    }

    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }

    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  }, [editor]);

  const isActiveStyle = (value: string, attributes?: {}) => {
    if (editor.isActive(value, attributes))
      return {
        backgroundColor: '#cccccc',
        color: 'black',
      };
    return {
      color: '#868686',
    };
  };

  // const addImage = useCallback(() => {
  //   const url = window.prompt('URL');

  //   if (url === null) {
  //     return;
  //   }

  //   if (url === '') {
  //     editor.chain().focus().extendMarkRange('image').clearContent().run();
  //     return;
  //   }

  //   editor?.chain?.()?.focus?.()?.setImage?.({ src: url })?.run?.();
  // }, [editor]);

  // const addYoutubeVideo = useCallback(() => {
  //   const url = prompt('Enter YouTube URL');

  //   if (url === null) {
  //     return;
  //   }

  //   if (url === '') {
  //     editor.chain().focus().extendMarkRange('youtube').clearContent().run();
  //     return;
  //   }

  //   editor.commands.setYoutubeVideo({
  //     src: url,
  //     width: 320,
  //     height: 180,
  //   });
  // }, [editor]);

  return (
    <Row className='menu-bar-wrapper'>
      <Row aria-label='Text formatting' style={{ columnGap: '4px' }}>
        <ToolbarButton
          disabled={disabled}
          value='bold'
          aria-label='Toggle Bold selection'
          onClick={() => editor.chain().focus().toggleBold().run()}
          style={{ ...isActiveStyle('bold') }}>
          <BoldOutlined />
        </ToolbarButton>
        <ToolbarButton
          disabled={disabled}
          value='italic'
          aria-label='Toggle Italic selection'
          onClick={() => editor.chain().focus().toggleItalic().run()}
          style={{ ...isActiveStyle('italic') }}>
          <ItalicOutlined />
        </ToolbarButton>
        <ToolbarButton
          disabled={disabled}
          value='underline'
          aria-label='Toggle Italic selection'
          onClick={() => editor?.chain?.()?.focus?.()?.toggleUnderline?.()?.run?.()}
          style={{ ...isActiveStyle('underline') }}>
          <UnderlineOutlined />
        </ToolbarButton>
        <ToolbarButton
          disabled={disabled}
          value='strike'
          aria-label='Toggle Strike through selection'
          onClick={() => editor.chain().focus().toggleStrike().run()}
          style={{ ...isActiveStyle('strike') }}>
          <StrikethroughOutlined />
        </ToolbarButton>
      </Row>
      <Divider type='vertical' style={{ height: '24px', top: 0 }} />
      <Row style={{ columnGap: '4px' }}>
        {/* <ToolbarButton
          value='code'
          aria-label='Toggle Code block'
          onClick={() => editor.chain().focus().toggleCode().run()}
          style={{ ...isActiveStyle('code') }}>
          <CodeOutlined />
        </ToolbarButton> */}
        <ToolbarButton disabled={disabled} value='link' aria-label='Add Link' onClick={setUnsetLink} style={{ ...isActiveStyle('default_style') }}>
          <LinkOutlined />
        </ToolbarButton>
        {/* <ToolbarButton value='image' aria-label='Insert an image' onClick={addImage} style={{ ...isActiveStyle('default_style') }}>
            <PictureOutlined />
          </ToolbarButton> */}
        {/* <ToolbarButton value='youtube' aria-label='Insert a Youtube video' onClick={addYoutubeVideo} style={{ ...isActiveStyle('default_style') }}>
          <YoutubeFilled />
        </ToolbarButton> */}
      </Row>
      <Divider type='vertical' style={{ height: '24px', top: 0 }} />
      <Row style={{ columnGap: '4px' }}>
        <ToolbarButton
          disabled={disabled}
          value='heading2'
          aria-label='Toggle Heading 2 selection'
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          style={{ ...isActiveStyle('heading', { level: 2 }) }}>
          h2
        </ToolbarButton>
        <ToolbarButton
          disabled={disabled}
          value='toggleHeading-3'
          aria-label='Toggle Heading 3 selection'
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          style={{ ...isActiveStyle('heading', { level: 3 }) }}>
          h3
        </ToolbarButton>
        <ToolbarButton
          disabled={disabled}
          value='toggleHeading-4'
          aria-label='Toggle Heading 4 selection'
          onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
          style={{ ...isActiveStyle('heading', { level: 4 }) }}>
          h4
        </ToolbarButton>
      </Row>
      <Divider type='vertical' style={{ height: '24px', top: 0 }} />
      <Row style={{ columnGap: '4px' }}>
        <ToolbarButton
          disabled={disabled}
          value='toggleBulletList'
          aria-label='Toggle Bullet List selection'
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          style={{ ...isActiveStyle('bulletList') }}>
          <UnorderedListOutlined />
        </ToolbarButton>
        <ToolbarButton
          disabled={disabled}
          value='toggleOrderedList'
          aria-label='Toggle Ordered List selection'
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          style={{ ...isActiveStyle('orderedList') }}>
          <OrderedListOutlined />
        </ToolbarButton>
        <ToolbarButton
          disabled={disabled}
          value='checkSquare'
          aria-label='Check square selection'
          onClick={() => /*editor.chain().focus().toggleOrderedList().run()*/ void 0}
          style={{ ...isActiveStyle('checkSquare') }}>
          <CheckSquareOutlined />
        </ToolbarButton>

        {/* <ToolbarButton
          value='setHardBreak'
          aria-label='Set hard break to current line'
          onClick={() => editor.chain().focus().setHardBreak().run()}
          style={{ ...isActiveStyle('default_style') }}>
          <EnterOutlined />
        </ToolbarButton>
        <ToolbarButton
          value='setHorizontalRule'
          aria-label='Add Horizontal Rule to current line'
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
          style={{ ...isActiveStyle('default_style') }}>
          <MinusOutlined />
        </ToolbarButton> */}
      </Row>
    </Row>
  );
};

export default MenuBar;
