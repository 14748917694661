import { useMemo } from 'react';

import { FormInstance, Typography } from 'antd';

import { CustomField } from '@aduvi/components/CustomField/CustomField';
import { IEntityField } from '@aduvi/types';

import { IBookingFormFieldProps } from '../helper';

interface IProps {
  form: FormInstance;
  field: IEntityField;
  fieldIndex: number;
  contactFields?: IBookingFormFieldProps;
}

export const ContactCustomField = ({ form, field, contactFields, fieldIndex }: IProps) => {
  const fields: IEntityField[] | undefined = useMemo(
    () => contactFields?.field_instance?.display_settings?.custom_fields,
    [field?.id, contactFields?.field_instance.display_settings.custom_fields],
  );

  return (
    <>
      {fields &&
        fields.map((singleField, index) => (
          <>
            <Typography.Paragraph>{singleField.title}</Typography.Paragraph>
            <CustomField
              key={singleField.id}
              field={singleField}
              form={form}
              index={index}
              isFormField
              name={['custom_fields', fieldIndex, field.id, index, singleField.id]}
              disabledFields={[]}
            />
          </>
        ))}
    </>
  );
};
