export const DragHandleIcon = () => {
  return (
    <svg width='10' height='20' viewBox='0 0 10 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6 3.76C6 4.09417 6.13275 4.41466 6.36905 4.65095C6.60534 4.88725 6.92583 5.02 7.26 5.02C7.59417 5.02 7.91466 4.88725 8.15095 4.65095C8.38725 4.41466 8.52 4.09417 8.52 3.76C8.52 3.42583 8.38725 3.10534 8.15095 2.86905C7.91466 2.63275 7.59417 2.5 7.26 2.5C6.92583 2.5 6.60534 2.63275 6.36905 2.86905C6.13275 3.10534 6 3.42583 6 3.76ZM6 10.06C6 10.3942 6.13275 10.7147 6.36905 10.951C6.60534 11.1872 6.92583 11.32 7.26 11.32C7.59417 11.32 7.91466 11.1872 8.15095 10.951C8.38725 10.7147 8.52 10.3942 8.52 10.06C8.52 9.72583 8.38725 9.40534 8.15095 9.16904C7.91466 8.93275 7.59417 8.8 7.26 8.8C6.92583 8.8 6.60534 8.93275 6.36905 9.16904C6.13275 9.40534 6 9.72583 6 10.06ZM6 16.36C6 16.6942 6.13275 17.0147 6.36905 17.251C6.60534 17.4872 6.92583 17.62 7.26 17.62C7.59417 17.62 7.91466 17.4872 8.15095 17.251C8.38725 17.0147 8.52 16.6942 8.52 16.36C8.52 16.0258 8.38725 15.7053 8.15095 15.469C7.91466 15.2327 7.59417 15.1 7.26 15.1C6.92583 15.1 6.60534 15.2327 6.36905 15.469C6.13275 15.7053 6 16.0258 6 16.36Z'
        fill='black'
        fillOpacity='0.25'
      />
      <path
        d='M1 3.76C1 4.09417 1.13275 4.41466 1.36905 4.65095C1.60534 4.88725 1.92583 5.02 2.26 5.02C2.59417 5.02 2.91466 4.88725 3.15095 4.65095C3.38725 4.41466 3.52 4.09417 3.52 3.76C3.52 3.42583 3.38725 3.10534 3.15095 2.86905C2.91466 2.63275 2.59417 2.5 2.26 2.5C1.92583 2.5 1.60534 2.63275 1.36905 2.86905C1.13275 3.10534 1 3.42583 1 3.76ZM1 10.06C1 10.3942 1.13275 10.7147 1.36905 10.951C1.60534 11.1872 1.92583 11.32 2.26 11.32C2.59417 11.32 2.91466 11.1872 3.15095 10.951C3.38725 10.7147 3.52 10.3942 3.52 10.06C3.52 9.72583 3.38725 9.40534 3.15095 9.16904C2.91466 8.93275 2.59417 8.8 2.26 8.8C1.92583 8.8 1.60534 8.93275 1.36905 9.16904C1.13275 9.40534 1 9.72583 1 10.06ZM1 16.36C1 16.6942 1.13275 17.0147 1.36905 17.251C1.60534 17.4872 1.92583 17.62 2.26 17.62C2.59417 17.62 2.91466 17.4872 3.15095 17.251C3.38725 17.0147 3.52 16.6942 3.52 16.36C3.52 16.0258 3.38725 15.7053 3.15095 15.469C2.91466 15.2327 2.59417 15.1 2.26 15.1C1.92583 15.1 1.60534 15.2327 1.36905 15.469C1.13275 15.7053 1 16.0258 1 16.36Z'
        fill='black'
        fillOpacity='0.25'
      />
    </svg>
  );
};
