import React from 'react';

import { Col, Row } from 'antd';

import { IBookingFormFieldProps } from '../helper';

interface ITravelFeeProps {
  field: IBookingFormFieldProps;
}

export const TravelFee: React.FC<ITravelFeeProps> = ({ field }) => {
  const { display_settings } = field.field_instance;

  const style: React.CSSProperties = {
    color: display_settings.textColor || 'inherit',
    background: display_settings.backgroundTextColor || 'transparent',
    fontSize: display_settings.fontSize ? `${display_settings.fontSize}px` : '14px',
    fontStyle: display_settings.italic ? 'italic' : 'normal',
    fontWeight: display_settings.bold ? 'bold' : 'normal',
    textAlign: display_settings.alignment || 'center',
    padding: '10px',
  };

  return (
    <Row justify='center'>
      <Col style={{ width: '100%', ...style }}></Col>
    </Row>
  );
};
