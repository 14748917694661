import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ClientPortalAuthWrapper from 'views/client-portal/authentication/ClientPortalAuthWrapper';
import PortalPage from 'views/client-portal/portal/Portal';

import { EClientPortalAuthComponent, EPortalComponent } from '@aduvi/types/client-portal';

export const AuthorizedClientPortalRoutes = () => {
  return (
    <Routes>
      <Route path='/portal'>
        <Route path='profile' element={<ClientPortalAuthWrapper componentToRender={EClientPortalAuthComponent.LOGGED_USER} />} />
        <Route path='portal'>
          <Route path='order/:orderId'>
            <Route index element={<PortalPage />} />
            <Route path='files' element={<PortalPage componentToRender={EPortalComponent.FILES} />} />
            <Route path='chat' element={<PortalPage componentToRender={EPortalComponent.CHAT} />} />
            <Route path='invoices'>
              <Route index element={<PortalPage componentToRender={EPortalComponent.INVOICES} />} />
              <Route path=':invoiceId' element={<PortalPage componentToRender={EPortalComponent.INVOICE} />} />
            </Route>
          </Route>
          <Route path='job/:jobId'>
            <Route index element={<PortalPage />} />
            <Route path='files' element={<PortalPage componentToRender={EPortalComponent.FILES} />} />
            <Route path='chat' element={<PortalPage componentToRender={EPortalComponent.CHAT} />} />
            <Route path='invoices'>
              <Route index element={<PortalPage componentToRender={EPortalComponent.INVOICES} />} />
              <Route path=':invoiceId' element={<PortalPage componentToRender={EPortalComponent.INVOICE} />} />
            </Route>
          </Route>
        </Route>

        <Route path='*' element={<Navigate to={`profile`} />} />
      </Route>
    </Routes>
  );
};
