import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Authentication from 'views/authentication/Authentication';
import ForgotPassword from 'views/authentication/forgot-password/ForgotPassword';
import Login from 'views/authentication/login/Login';
import Register from 'views/authentication/register/Register';

import { LazyLoadGuard } from '@aduvi/components/LazyLoadGuard/LazyLoadGuard';

const AcceptInvitation = React.lazy(() => import('views/authentication/accept-invitation/AcceptInvitation'));
const EmailConfirmation = React.lazy(() => import('views/authentication/email-confirmation/EmailConfirmation'));
const ResetPassword = React.lazy(() => import('views/authentication/reset-password/ResetPassword'));
const VerifyEmail = React.lazy(() => import('views/authentication/verify-email/VerifyEmail'));

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_LOGIN_CLIENT_ID!,
    redirectUri: process.env.REACT_APP_BASE_URL,
    authority: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  },
  cache: {
    storeAuthStateInCookie: false,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export const NonAuthorizedRoutes = () => {
  return (
    <Routes>
      <Route path='/auth' element={<Authentication />}>
        <Route
          path='login'
          element={
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID!}>
              <MsalProvider instance={msalInstance}>
                <Login />
              </MsalProvider>
            </GoogleOAuthProvider>
          }
        />
        <Route path='register' element={<Register />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route
          path='reset-password'
          element={
            <LazyLoadGuard>
              <ResetPassword />
            </LazyLoadGuard>
          }
        />
      </Route>

      <Route
        path='auth/email-confirmation'
        element={
          <LazyLoadGuard>
            <EmailConfirmation />
          </LazyLoadGuard>
        }
      />
      <Route
        path='auth/verify-email'
        element={
          <LazyLoadGuard>
            <VerifyEmail />
          </LazyLoadGuard>
        }
      />
      <Route
        path='invite'
        element={
          <LazyLoadGuard>
            <AcceptInvitation />
          </LazyLoadGuard>
        }
      />

      <Route path='*' element={<Navigate to='auth/login' />} />
    </Routes>
  );
};
