import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSettingsLocations } from 'views/settings/locations/SettingsLocations.hooks';
import { useFetchEntityFields } from 'views/settings/travel-fees/Route/DefaultDestination.hook';

import { Button, Form, Modal, Select, Typography } from 'antd';

import { useBusinessId } from '@aduvi/hooks';

import { recalculateTravelFee } from 'store/features/quote-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const RecalculateTravelFee = () => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const [form] = Form.useForm();
  const selectedBusinessId = useBusinessId();
  const { locations } = useSettingsLocations();
  const { isLoading, venueOptions, otherOptions } = useFetchEntityFields();

  const { selectedQuote, recalculating } = useAppSelector((state) => state.quotes);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const combinedOptions = [...venueOptions, ...otherOptions];

  const handleOk = async () => {
    const values = await form.validateFields();
    const { field_id, business_location_id } = values;

    if (!selectedQuote) return;

    handleRecalculateTravelFee({ field_id, business_location_id }, handleCancel, handleCancel);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const onRecalculate = () => {
    if (!selectedQuote) return;

    handleRecalculateTravelFee({ field_id: null, business_location_id: null }, handleCancel, () => setIsModalVisible(true));
  };

  const handleRecalculateTravelFee = (
    params: { field_id: string | null; business_location_id: string | null },
    onSuccess: () => void,
    onError: () => void,
  ) => {
    dispatch(
      recalculateTravelFee({
        business_id: selectedBusinessId,
        quote_id: selectedQuote?.id!,
        entity_id: selectedQuote?.entity_id as string,
        field_id: params.field_id,
        business_location_id: params.business_location_id,
      }),
    )
      .unwrap()
      .then(() => onSuccess())
      .catch(() => onError());
  };

  return (
    <>
      <div className='flex-space-between align-center'>
        <div>
          <Typography.Text>{translate('quotes.travelFee')}</Typography.Text>
          <Button loading={recalculating} disabled={recalculating} type='link' onClick={() => onRecalculate()}>
            {translate('quotes.recalculate')}
          </Button>
        </div>
        <Typography.Text>{selectedQuote?.travel_fee && `$${selectedQuote?.travel_fee}`}</Typography.Text>
      </div>

      <Modal confirmLoading={recalculating} title={translate('quotes.recalculate')} open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form form={form} layout='vertical'>
          <Form.Item name='business_location_id' label={translate('quotes.origin')}>
            <Select>
              {locations.data?.map((loc) => (
                <Select.Option key={loc.id} value={loc.id}>
                  {loc.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='field_id' label={translate('quotes.destination')}>
            <Select options={combinedOptions} loading={isLoading} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
