import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Modal, Row, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { UploadImage } from '@aduvi/components/UploadImage/UploadImage';
import Tiptap from '@aduvi/Tiptap/Tiptap';

import { setSelectedTicket } from 'store/features/ticket-slice';

import { ChatModal } from '../@components/ticket-chat-modal/ChatModal';
import { TicketFooterModal } from '../@components/ticket-footer-modal/TicketFooterModal';
import { TicketHeaderModal } from '../@components/ticket-header-modal/TicketHeaderModal';
import { extractFileNameFromUrl } from '../helper';

export const TicketChatModal = ({ setIsTicketConfirmationModalOpen }: { setIsTicketConfirmationModalOpen: Dispatch<SetStateAction<boolean>> }) => {
  const { t: translate } = useTranslation();
  const [form] = useForm();

  Form.useWatch('message', form);

  const onCloseModal = () => {
    setSelectedTicket(undefined);
    setIsTicketConfirmationModalOpen(false);
  };

  return (
    <Modal
      width={800}
      open={true}
      styles={{ content: { borderRadius: 10 } }}
      bodyStyle={{ height: 550 }}
      centered
      title={<TicketHeaderModal />}
      footer={<TicketFooterModal form={form} />}
      onCancel={onCloseModal}>
      <div className='w-full'>
        <ChatModal style={{ maxHeight: '270px', minHeight: '270px', overflowY: 'auto' }} />
        <Row style={{ flexDirection: 'column' }} gutter={[10, 10]}>
          <Typography.Text className='mt-20'>{translate('support.contact.addAnUpdate')}</Typography.Text>
          <Form form={form}>
            <Form.Item name='message' className='mb-30'>
              <Tiptap content={form.getFieldValue('message')} onUpdate={(value) => form.setFieldValue('message', value.getHTML())} />
            </Form.Item>

            <Row style={{ flexDirection: 'column', marginTop: '-15px' }}>
              <Typography.Text className='mb-5'>{translate('support.contact.attachments')}</Typography.Text>
              <Form.Item name='attachments'>
                <UploadImage
                  name={form.getFieldValue('attachments') && extractFileNameFromUrl(form.getFieldValue('attachments'))}
                  onUploadChange={(value) => form.setFieldValue('attachments', value?.url)}
                  listType='text'
                  text={translate('support.contact.upload')}
                />
              </Form.Item>
            </Row>
          </Form>
        </Row>
      </div>
    </Modal>
  );
};
