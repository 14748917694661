import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, ColorPicker as AntColorPicker, ColorPickerProps, Divider, Row } from 'antd';
import './ColorPicker.scss';

import { PresetColorPicker } from '@aduvi/constants/style';

export const ColorPicker = (props: ColorPickerProps) => {
  const { t: translate } = useTranslation();

  const customPanelRender: ColorPickerProps['panelRender'] = (_, { components: { Picker, Presets } }) => (
    <Row justify='space-between' wrap={false} style={{ width: '300px' }}>
      <Col flex='auto'>
        <Picker />
      </Col>
      <Divider type='vertical' style={{ height: 'auto' }} />

      <Col span={10}>
        <Presets />
      </Col>
    </Row>
  );
  return (
    <AntColorPicker
      {...props}
      presets={[
        {
          label: translate('colorPicker.recommended'),
          colors: PresetColorPicker,
        },
      ]}
      panelRender={customPanelRender}
      rootClassName='custom-color-picker-popover'
    />
  );
};
