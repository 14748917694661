import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CopyOutlined,
  DeleteOutlined,
  DragOutlined,
  EditOutlined,
  EllipsisOutlined,
  ImportOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { AssetsType } from 'views/assets/Assets.hook';

import { Avatar, Col, Dropdown, MenuProps, Popconfirm, Row, Typography } from 'antd';
import './ServicesTable.scss';

import { Button } from '@aduvi/components/Button/Button';
import { PriceDisplay } from '@aduvi/components/PriceDisplay/PriceDisplay';
import { PERMISSIONS } from '@aduvi/constants';
import { useActiveTheme, useBusiness, useUserPermissionCheck } from '@aduvi/hooks';
import { IPackage, IService } from '@aduvi/types/services';

import { archiveService, clonePackage, cloneService, deletePackage, setSelectedPackage, setSelectedService } from 'store/features/services-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

type ResultObject = Record<string, Partial<IPackage>[]>;

export function createObjectFromArray(services: IService[]): ResultObject {
  const result: ResultObject = {};

  for (const service of services) {
    result[service?.id] = service?.packages?.map((item) => {
      return {
        ...item,
        key: item?.id,
        order_number: Number(item?.order_number),
        visibility: item?.visibility === '1' ? 'Public' : 'Private',
      };
    });
  }

  return result;
}

export const usePackagesTableColumns = (setShowPackgesDrawer: (prop: boolean) => void) => {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { selectedPackage } = useAppSelector((state) => state.services);

  const getPackageDropdownItems: MenuProps['items'] = [
    {
      key: '0',
      icon: <EditOutlined />,
      label: translate('userManagement.permissionColumn.edit'),
      onClick: () => {
        setShowPackgesDrawer(true);
      },
    },
    {
      key: '1',
      icon: <CopyOutlined />,
      label: translate('assets.services.clone'),
      onClick: (e) => {
        e.domEvent.stopPropagation();
        if (selectedBusiness?.id && selectedPackage?.id && selectedPackage.business_service_id) {
          dispatch(
            clonePackage({
              businessId: selectedBusiness.id,
              serviceId: selectedPackage.business_service_id,
              packageId: selectedPackage.id,
            }),
          );
        }
      },
    },
    {
      key: '2',
      icon: <DeleteOutlined />,
      label: (
        <Popconfirm
          title={translate('assets.services.archive')}
          description={translate('assets.services.deleteConfirmation')}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          disabled={!useUserPermissionCheck(PERMISSIONS.SERVICES.DELETE)}
          onCancel={(e) => e?.stopPropagation()}
          onConfirm={(e) => {
            e?.stopPropagation();
            if (!selectedPackage || !selectedBusiness) return;
            dispatch(
              deletePackage({
                business_id: selectedBusiness?.id,
                package_id: selectedPackage?.id,
                service_id: selectedPackage?.business_service_id,
                package_name: selectedPackage?.name,
              }),
            );
          }}>
          {translate('assets.productTable.delete')}
        </Popconfirm>
      ),
      onClick: (e) => e.domEvent.stopPropagation(),
      disabled: !useUserPermissionCheck(PERMISSIONS.SERVICES.DELETE),
    },
  ];

  return [
    {
      key: 'sort',
      render: () => <DragOutlined />,
    },
    {
      title: 'Name',
      width: 400,
      render: (record: IPackage) => {
        return (
          <Row style={{ alignItems: 'center' }}>
            {record.image ? (
              <Avatar shape='square' size={36} src={record.image} />
            ) : (
              <Avatar shape='square' size={36}>
                {record?.name?.substring(2, 0)}
              </Avatar>
            )}
            <span className='ml-15'>{record.name}</span>
          </Row>
        );
      },
    },
    {
      title: 'Price',
      render: (record: IPackage) =>
        record.base_price || record.flat_price ? <PriceDisplay price={record?.base_price || record?.flat_price || 'N/A'} /> : 'N/A',
    },
    {
      title: 'Duration',
      render: (record: IPackage) => {
        if (record.included_duration) {
          const duration = record.duration_type ?? 'MINUTES';
          return (record.included_duration ?? 0) + ' ' + duration.at(0) + duration.slice(1).toLowerCase();
        }
        return '';
      },
    },
    {
      title: 'Duration',
      dataIndex: 'visibility',
    },
    {
      key: 'action',
      render: (record: IPackage) => {
        return (
          <Row className='w-full' justify='end' onClick={(e) => e.stopPropagation()}>
            <Dropdown menu={{ items: getPackageDropdownItems }} trigger={['click']}>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(setSelectedService({ id: record.business_service_id }));
                  dispatch(setSelectedPackage({ id: record.id, name: record.name, business_service_id: record.business_service_id }));
                }}
                icon={<EllipsisOutlined />}
                type='text'
              />
            </Dropdown>
          </Row>
        );
      },
    },
    {
      title: 'business_service_id',
      dataIndex: 'business_service_id',
      hidden: true,
    },
    {
      title: 'order_number',
      dataIndex: 'order_number',
      hidden: true,
    },
  ];
};

export const useServicesTableColumns = (setShowServicesDrawer: (prop: boolean) => void) => {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const activeTheme = useActiveTheme();

  const { services, selectedService } = useAppSelector((state) => state.services);

  const getServiceDropdownItems: MenuProps['items'] = [
    {
      key: '0',
      icon: <EditOutlined />,
      label: translate('userManagement.permissionColumn.edit'),
      onClick: () => {
        setShowServicesDrawer(true);
      },
    },
    {
      key: '1',
      icon: <CopyOutlined />,
      label: translate('assets.services.clone'),
      onClick: (e) => {
        e.domEvent.stopPropagation();
        if (selectedBusiness?.id && selectedService?.id)
          dispatch(
            cloneService({
              businessId: selectedBusiness.id,
              serviceId: selectedService.id,
            }),
          );
      },
    },
    {
      key: '2',
      icon: <ImportOutlined rotate={270} />,
      label: (
        <Popconfirm
          title={translate('assets.services.archive')}
          description={translate('assets.services.deleteConfirmation')}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          disabled={!useUserPermissionCheck(PERMISSIONS.SERVICES.DELETE)}
          onCancel={(e) => e?.stopPropagation()}
          onConfirm={(e) => {
            e?.stopPropagation();
            if (!selectedService?.id || !selectedService.business_id) return;
            dispatch(
              archiveService({
                business_id: selectedService?.business_id,
                service_id: selectedService?.id,
              }),
            );
          }}>
          {translate('assets.services.archive')}
        </Popconfirm>
      ),
      onClick: (e) => e.domEvent.stopPropagation(),
      disabled: !useUserPermissionCheck(PERMISSIONS.SERVICES.DELETE),
    },
  ];

  return [
    {
      key: 'sort',
    },
    {
      title: 'Name',
      render: (record: Partial<IService>) => {
        return (
          <Row style={{ alignItems: 'center' }}>
            {record.image ? (
              <Avatar shape='square' size={36} src={record.image} />
            ) : (
              <Avatar shape='square' size={36}>
                {record?.name?.substring(2, 0)}
              </Avatar>
            )}
            <span className='ml-15'>{record.name}</span>
          </Row>
        );
      },
    },

    {
      title: 'Availability',
      render: (record: IService) => {
        return <span style={{ color: `${activeTheme?.buttonBg}` }}>{record.availability}</span>;
      },
    },
    {
      key: 'action',
      render: (record: IService) => {
        return (
          <Row className='w-full' justify='end' onClick={(e) => e.stopPropagation()}>
            <Dropdown menu={{ items: getServiceDropdownItems }} trigger={['click']}>
              <Button
                style={{ width: '32px' }}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(setSelectedService(services?.data.find((item) => item.id === record.key)));
                }}
                icon={<EllipsisOutlined />}
                type='text'
              />
            </Dropdown>
          </Row>
        );
      },
    },
  ];
};

export const StoreEmptyText = ({ currentTab, setShowDrawer }: { currentTab?: AssetsType; setShowDrawer: Dispatch<SetStateAction<boolean>> }) => {
  const { t: translate } = useTranslation();

  const storeAddFirstBtn = {
    [AssetsType.SERVICES]: 'assets.services.addService',
    [AssetsType.PRODUCTS]: 'assets.services.addProduct',
    [AssetsType.EVENTS]: 'assets.events.addEvent',
  };

  const storeEmptyText = {
    [AssetsType.SERVICES]: 'assets.services.youDontHaveAnyService',
    [AssetsType.PRODUCTS]: 'assets.services.youDontHaveAnyProduct',
    [AssetsType.EVENTS]: 'assets.events.youDontHaveAnyEvent',
  };

  return (
    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '8px', height: '100px' }}>
      <Typography.Text type='secondary'>{currentTab ? translate(storeEmptyText[currentTab]) : ''}</Typography.Text>
      <Button style={{ width: '200px' }} onClick={() => setShowDrawer(true)}>
        + {currentTab ? translate(storeAddFirstBtn[currentTab]) : ''}
      </Button>
    </Col>
  );
};
