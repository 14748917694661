import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined, RightOutlined } from '@ant-design/icons';

import { Button, Card, Dropdown, Input, Row, Select } from 'antd';

import { PREDEFINED_LABEL_SETTINGS } from '@aduvi/constants';
import { EFieldLabelAligment, EFieldSettings, ICustomFieldSpecificStyle, ICustomFieldViewStyle } from '@aduvi/types';
import { IFieldData } from '@aduvi/types/entity';

export const LabelSettings = ({
  updateStyleForFieldId,
  column,
  fieldId,
}: {
  updateStyleForFieldId: (newStyle: Partial<ICustomFieldViewStyle['style']>) => void;
  column: ICustomFieldViewStyle;
  fieldId?: string;
  fieldData?: IFieldData;
  fieldSetting?: string;
  setFieldSetting?: (value: string) => void;
}) => {
  const { t: translate } = useTranslation();
  const { Option } = Select;

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [fieldLabelAlignment, setFieldLabelAlignment] = useState<EFieldLabelAligment.LEFT | EFieldLabelAligment.RIGHT>(EFieldLabelAligment.LEFT);

  const handleFieldSettings = (setting: EFieldSettings) => {
    updateStyleForFieldId({ fieldSetting: setting });
  };

  const handleAlignmentChange = (newAlignment: EFieldLabelAligment) => {
    setFieldLabelAlignment(newAlignment);
    updateStyleForFieldId({ fieldLabelAlignment: newAlignment });
  };

  const handleFieldLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newLabel = e.target.value;
    updateStyleForFieldId({
      fieldLabel: newLabel,
    });
  };

  return (
    <Dropdown
      overlay={
        <Card className='style-modal'>
          <div className='flex flex-direction-column'>
            <Select
              className='settings-select'
              onChange={handleFieldSettings}
              defaultValue={(column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.fieldSetting || EFieldSettings.HIDDEN}>
              {PREDEFINED_LABEL_SETTINGS.map((setting) => (
                <Option key={setting.value} value={setting.value as EFieldSettings}>
                  {translate(setting.label)}
                </Option>
              ))}
            </Select>
            <div className='flex align-center'>
              {((column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.fieldSetting === EFieldSettings.INLINE ||
                (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.fieldSetting === EFieldSettings.ON_TOP) && (
                <>
                  <Input
                    value={(column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.fieldLabel}
                    onChange={handleFieldLabelChange}
                    className='mt-5'
                    placeholder={translate('Enter your label text')}
                  />
                  <div className='flex mt-5 ml-5'>
                    <Button
                      onClick={() => handleAlignmentChange(EFieldLabelAligment.LEFT)}
                      type={fieldLabelAlignment === EFieldLabelAligment.LEFT ? 'primary' : 'default'}>
                      L
                    </Button>
                    <Button
                      onClick={() => handleAlignmentChange(EFieldLabelAligment.RIGHT)}
                      type={fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 'primary' : 'default'}>
                      R
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </Card>
      }
      trigger={['click']}
      open={dropdownVisible}
      onOpenChange={(visible) => setDropdownVisible(visible)}>
      <Row>
        <Button icon={<InfoCircleOutlined />} type='text' className='w-full text-left flex align-center'>
          {translate('formatFields.label')}
          <RightOutlined className='fs-10' style={{ marginLeft: '20px' }} />
        </Button>
      </Row>
    </Dropdown>
  );
};
