import React, { CSSProperties } from 'react';

interface DisableWrapperProps {
  disabled: boolean;
  children: React.ReactNode;
}

const DisableWrapper: React.FC<DisableWrapperProps> = ({ disabled, children }) => {
  const style: CSSProperties = {
    pointerEvents: disabled ? 'none' : 'auto',
    opacity: disabled ? 0.5 : 1,
  };

  return <div style={style}>{children}</div>;
};

export default DisableWrapper;
