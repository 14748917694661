import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';

import { Button, Col, Input, Menu, Row } from 'antd';
import './SettingsMenu.scss';

import { Paragraph } from '@aduvi/components/Paragraph';
import { Title } from '@aduvi/components/Title';
import { appSettingsItemsConfig, brandItemsConfig } from '@aduvi/constants/navigation-config';
import {
  useActiveTheme,
  useBusiness,
  useBusinessId,
  useIsBusinessOwner,
  useSelectedPlan,
  useTerminology,
  useUserBusinessPermissions,
} from '@aduvi/hooks';
import { useIsMobile } from '@aduvi/hooks/useIsMobile';
import { INavigationConfig } from '@aduvi/types/constants';
import { hasUserPermission, isAppIncludedInPlan, replaceJobWithTerminology } from '@aduvi/utils/helper';

export const SettingsMenu = ({ onClose }: { onClose?: () => void }) => {
  const isMobile = useIsMobile();
  const businessId = useBusinessId();
  const navigate = useNavigate();
  const location = useLocation();
  const isBusinessOwner = useIsBusinessOwner();
  const userBusinessPermissions = useUserBusinessPermissions();
  const activeTheme = useActiveTheme();
  const terminology = useTerminology();
  const selectedBusiness = useBusiness();

  const { t: translate } = useTranslation();
  const { selectedPlan, planApps } = useSelectedPlan();

  const [selectedPath, setSelectedPath] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const buildMenu = (menu: INavigationConfig) => {
    return {
      key: `/${menu.path}`,
      label: replaceJobWithTerminology(menu.label, terminology, true),
      icon: menu.icon,
      onClick: () => onNavigate(menu.path),
    };
  };

  const filterAndMapConfigItems = (configItems: INavigationConfig[], skipPermissionCheck = false) => {
    return configItems
      .filter(
        (item) =>
          skipPermissionCheck ||
          (hasUserPermission(isBusinessOwner, userBusinessPermissions, item.permission) &&
            isAppIncludedInPlan(selectedPlan?.data, planApps, item?.key)),
      )

      .map((setting) => buildMenu(setting as INavigationConfig))
      .filter((item) => !item?.label || item.label.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const brandItems = useMemo(() => filterAndMapConfigItems(brandItemsConfig, true), [searchTerm, businessId, selectedPlan?.data?.id]);
  const appSettingsItems = useMemo(() => filterAndMapConfigItems(appSettingsItemsConfig), [searchTerm, businessId, selectedPlan?.data?.id]);

  const onNavigate = (path: string) => {
    navigate(`/${path}`);
    setSelectedPath(`/${path}`);
    onClose?.();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setSelectedPath(location?.pathname);
  }, [location.pathname]);

  return (
    <Col className='settings-menu-wrapper'>
      <Row justify='space-between'>
        <Title className='title' style={{ color: activeTheme?.menuText }}>
          {translate('settings.menu.title')}
        </Title>
        {isMobile && <Button icon={<CloseOutlined style={{ color: 'gray' }} />} type='link' onClick={onClose} />}
      </Row>
      <Input.Search placeholder={translate('settings.menu.searchPlaceholder')} onChange={onSearchChange} enterButton className='settings-search' />

      {brandItems.length > 0 && (
        <>
          <Paragraph className='settings-group-title' style={{ color: activeTheme?.menuText }}>
            {selectedBusiness?.name || translate('settings.menu.businessName')}
          </Paragraph>
          <Menu mode='vertical' selectedKeys={[selectedPath]} items={brandItems} style={{ border: '0px', background: 'transparent' }} />
        </>
      )}

      {appSettingsItems.length > 0 && (
        <>
          <Paragraph className='settings-group-title' style={{ color: activeTheme?.menuText }}>
            {translate('settings.menu.appSettings')}
          </Paragraph>
          <Menu
            mode='vertical'
            selectedKeys={[selectedPath]}
            items={appSettingsItems}
            style={{ border: '0px', background: 'transparent', borderRadius: '10px' }}
          />
        </>
      )}

      {!brandItems.length && !appSettingsItems.length && (
        <Paragraph className='settings-group-title' style={{ color: activeTheme?.menuText }}>
          {translate('settings.menu.noItem')}
        </Paragraph>
      )}
    </Col>
  );
};
