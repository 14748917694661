import React, { createContext, ReactNode, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Pusher from 'pusher-js';
import { Channel } from 'pusher-js';

import { IChatMessage } from '@aduvi/types';
import { EPusherEvents, IAssignUserResponse, IChannel } from '@aduvi/types/conversation';

import { appendChannel, appendMessage, appendUserToChannel, modifyChannel, removeChannel, removeMessage } from 'store/features/conversation-slice';
import { useAppSelector } from 'store/hooks';

interface ConversationSocketProviderProps {
  children: ReactNode;
}

const PusherContext = createContext<Pusher | undefined>(undefined);

export const ClientPortalSocketProvider: React.FC<ConversationSocketProviderProps> = ({ children }) => {
  const dispatch = useDispatch();

  const { user } = useAppSelector((state) => state.clientPortalAuth);

  const channelId = useMemo(
    () => (user.jobs.selectedJob ? user.jobs.selectedJob.channel?.id : user?.orders?.selectedOrder?.channel?.id),
    [user, user.jobs.selectedJob, user?.jobs?.selectedJob?.channel?.id, user?.orders?.selectedOrder],
  );

  const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY!, {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER!,
  });

  useEffect(() => {
    let channelSubscriptions: Channel[] = [];

    if (channelId) {
      const channel = pusher.subscribe(channelId);
      channelSubscriptions.push(channel);

      channel.bind(EPusherEvents.MESSAGE_SENT, (data: { message: IChatMessage }) => {
        dispatch(appendMessage(data.message));
      });

      channel.bind(EPusherEvents.USER_ADDED_TO_CHANNEL, (data: IAssignUserResponse) => {
        dispatch(appendUserToChannel(data));
      });

      channel.bind(EPusherEvents.MESSAGE_DELETED, (data: { message: IChatMessage }) => {
        dispatch(removeMessage(data.message));
      });
    }

    if (user?.user?.business_id) {
      const businessChannel = pusher.subscribe(user.user?.business_id);
      channelSubscriptions.push(businessChannel);

      businessChannel.bind(EPusherEvents.CHANNEL_CREATED, (data: { channel: IChannel }) => {
        dispatch(appendChannel({ channel: data.channel, currentUserId: user?.user?.id as string }));
      });

      businessChannel.bind(EPusherEvents.CHANNEL_EDITED, (data: { channel: IChannel }) => {
        dispatch(modifyChannel(data.channel));
      });

      businessChannel.bind(EPusherEvents.CHANNEL_DELETED, (data: { channel: IChannel }) => {
        dispatch(removeChannel(data.channel));
      });
    }

    return () => {
      channelSubscriptions.forEach((channel) => {
        channel.unbind_all();
        channel.unsubscribe();
      });
    };
  }, [channelId, user.user?.business_id]);

  return <PusherContext.Provider value={pusher}>{children}</PusherContext.Provider>;
};
