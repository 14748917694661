import { useMemo } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { ConfigProvider, theme } from 'antd';
import './App.scss';

import { useActiveTheme } from '@aduvi/hooks';
import { MainRouter } from '@aduvi/routes/MainRouter';

import { ADUVI_DEFAULT_ACTIVE_THEME } from './@aduvi/constants';
import i18n from './translation/i18n';

const App = () => {
  const activeTheme = useActiveTheme();

  const componentsStyle = useMemo(() => {
    if (!activeTheme) return {};

    return {
      Button: {
        colorPrimary: activeTheme?.buttonBg || ADUVI_DEFAULT_ACTIVE_THEME.buttonBg,
        colorPrimaryHover: activeTheme?.buttonBgHover,
        colorPrimaryActive: activeTheme?.buttonBgActive,
        colorPrimaryText: activeTheme?.buttonText,
        colorTextLightSolid: activeTheme?.buttonText || ADUVI_DEFAULT_ACTIVE_THEME.buttonText,
        colorBorderSecondary: activeTheme?.buttonBg,
        colorLink: activeTheme?.buttonBg || ADUVI_DEFAULT_ACTIVE_THEME.buttonBg,
        colorLinkHover: activeTheme?.buttonBgHover,
      },
      Menu: {
        itemSelectedBg: `${activeTheme?.menuActiveBg} !important`,
        itemSelectedColor: activeTheme?.menuActiveText,
        itemColor: activeTheme?.menuText,
        itemBorderRadius: 8,
      },
      Tree: {
        colorBgContainer: 'inherit',
        nodeSelectedBg: 'inherit',
      },
      Pagination: {
        colorPrimary: activeTheme?.buttonText,
        colorPrimaryHover: activeTheme?.buttonBgHover,
        itemActiveBg: activeTheme?.buttonBg,
      },
      Switch: {
        colorPrimary: activeTheme?.switchBg,
        colorPrimaryHover: activeTheme?.switchBgHover,
      },
      Calendar: {
        colorPrimary: activeTheme?.calendarBg,
      },
      Radio: {
        colorPrimary: activeTheme?.radioBg,
        colorPrimaryHover: activeTheme?.radioBgHover,
      },
      Tabs: {
        colorPrimary: activeTheme?.tabBg,
        colorPrimaryHover: activeTheme?.tabBgHover,
      },
      Checkbox: {
        colorPrimary: activeTheme?.checkboxBg,
        colorPrimaryHover: activeTheme?.checkboxBgHover,
      },
      Modal: {
        titleColor: activeTheme?.titleText,
      },
      Spin: {
        colorPrimary: activeTheme?.spinBg,
      },
    };
  }, [activeTheme]);

  return (
    <ConfigProvider
      theme={{
        token: { borderRadius: 2 },
        algorithm: activeTheme?.darkModeEnabled ? theme.darkAlgorithm : undefined,
        components: { ...componentsStyle },
      }}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <MainRouter />
        </I18nextProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
