import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { Button, Col, Divider, Dropdown, Form, Row, Steps } from 'antd';
import { FormInstance } from 'antd/lib';
import '../Build.scss';

import { IEntityType } from '@aduvi/types/entity';
import { EFormFormat } from '@aduvi/types/form';

import { useAppSelector } from 'store/hooks';

import { bookingFormDropdownItems, IBookingFormFieldProps } from '../helper';

import { SortableItem } from './SortableItem';

interface IProps {
  form: FormInstance;
  isDragging: boolean;
  fields: IBookingFormFieldProps[];
  readonly?: boolean;
  pageCounter: number;
  entityTypeHasQuotes: boolean;
  disabledFields: string[];
  setPageCounter: (value: React.SetStateAction<number>) => void;
  setFields: (value: React.SetStateAction<IBookingFormFieldProps[]>) => void;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  onAddField: (element: IBookingFormFieldProps, index: number) => void;
  onCreateNewFieldDrawer: (index: number) => void;
  onCreateAsset: (assetType: IEntityType, index: number) => void;
}

export const Fields = ({
  form,
  isDragging,
  fields,
  readonly,
  pageCounter,
  entityTypeHasQuotes,
  disabledFields,
  setPageCounter,
  setFields,
  onAddField,
  onCreateNewFieldDrawer,
  onCreateAsset,
}: IProps) => {
  const { fields: entityFields } = useAppSelector((state) => state.fields);

  const {
    entityTypes: { entityTypes },
  } = useAppSelector((state) => state.entity);

  const { selectedEntityForm } = useAppSelector((state) => state.forms);

  const pageBreaks = fields.filter((item) => item.isPageBreak);

  const format: EFormFormat | undefined = readonly
    ? selectedEntityForm?.style.form_style?.[0]?.format
    : Form.useWatch(['form_style', 0, 'format'], form);

  const onChange = (value: number) => {
    form
      .validateFields()
      .then(() => {
        if (Math.abs(pageCounter - value) === 1) {
          setPageCounter(value);
        }
      })
      .catch(() => {});
  };

  return (
    <Row>
      {(readonly && pageBreaks.length && format === 2) || (readonly && format === 3) ? (
        <Row className='w-full' justify={'center'}>
          <Col span={20}>
            <Steps
              className='mt-20'
              current={pageCounter}
              onChange={onChange}
              items={Array.from({ length: format === 2 ? pageBreaks?.length + 1 : fields?.filter((item) => !item?.isPageBreak)?.length })?.map(
                () => ({
                  title: '',
                }),
              )}
            />
          </Col>
        </Row>
      ) : null}
      <SortableContext items={fields?.map((item) => item?.field_id)} strategy={verticalListSortingStrategy} id='fields-container-id'>
        {fields
          ?.filter((item) => (!item?.isPageBreak && readonly) || !readonly)
          ?.map((item, index) => {
            if (
              readonly &&
              pageBreaks?.[pageCounter]?.field_instance?.weight < item?.field_instance?.weight &&
              format === EFormFormat.MULTI_PAGE_OPTIONAL
            )
              return;
            if (
              readonly &&
              pageCounter > 0 &&
              pageBreaks?.[pageCounter - 1]?.field_instance?.weight > item?.field_instance?.weight &&
              format === EFormFormat.MULTI_PAGE_OPTIONAL
            )
              return;
            if (readonly && format === EFormFormat.MULTI_PAGE_INDIVIDUAL && index !== pageCounter) return;

            if (!readonly && format !== EFormFormat.MULTI_PAGE_OPTIONAL && item?.isPageBreak) return;
            if (item?.field_instance?.display_settings?.is_widget_field && !readonly) return;

            return (
              <Col key={item?.field_id} span={item?.field_instance?.half_width && format !== 3 ? 12 : 24}>
                <SortableItem
                  readonly={readonly}
                  fields={fields?.filter((item) => (!item?.isPageBreak && readonly) || !readonly)}
                  form={form}
                  id={item?.field_id}
                  isDragging={isDragging}
                  index={index}
                  isPageBreak={item?.isPageBreak}
                  setFields={setFields}
                  disabledFields={disabledFields}
                />

                {isDragging || readonly ? null : (
                  <Row className='add-field-wrapper '>
                    <Divider dashed orientation='center' className='add-field-divider'>
                      <Dropdown
                        overlayClassName='booking-form-dropdown'
                        menu={{
                          items: bookingFormDropdownItems(
                            index + 1,
                            fields,
                            entityFields,
                            form,
                            entityTypeHasQuotes,
                            entityTypes,
                            false,
                            onAddField,
                            onCreateNewFieldDrawer,
                            onCreateAsset,
                          ),
                        }}
                        trigger={['click']}
                        placement='bottomCenter'>
                        <Button icon={<PlusOutlined />} type='primary' style={{ borderRadius: '5px' }} size='small' />
                      </Dropdown>
                    </Divider>
                  </Row>
                )}
              </Col>
            );
          })}
      </SortableContext>
    </Row>
  );
};

export default Fields;
