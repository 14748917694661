import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EAuthorType, IChatMessage, ICreateMessagePayload } from '@aduvi/types';
import { EChannelType, IAssignUserResponse, IChannel, IConversationState, ICreateChannelPayload } from '@aduvi/types/conversation';

import * as ConversationService from 'store/services/conversation.service';

const initialState: IConversationState = {
  channels: undefined,
  teams: undefined,
  selectedChannel: undefined,
  clientChannel: undefined,
  individualChannelsMap: undefined,
  selectedMessage: undefined,
  messages: {
    data: [],
    creating: false,
    loading: false,
    removing: false,
  },
  loading: false,
  creating: false,
  updating: false,
};

export const createChannel = createAsyncThunk(
  'conversation/create-channel',
  async ({ payload }: { payload: ICreateChannelPayload }, { rejectWithValue }) => {
    try {
      return await ConversationService.createChannel(payload?.business_id, payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getChannels = createAsyncThunk(
  'conversation/get-channels',
  async (payload: { businessId: string; userId: string }, { rejectWithValue }) => {
    try {
      const channels = await ConversationService.getChannels(payload.businessId);

      return { userId: payload.userId, data: channels?.data };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getChannelById = createAsyncThunk(
  'conversation/fetch-channel',
  async ({ businessId, channelId }: { businessId: string; channelId: string }, { rejectWithValue }) => {
    try {
      return await ConversationService.getChannelById(businessId, channelId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const editChannel = createAsyncThunk(
  'conversation/edit-channel',
  async ({ channelId, payload }: { channelId: string; payload: ICreateChannelPayload }, { rejectWithValue }) => {
    try {
      return await ConversationService.editChannel(payload?.business_id, channelId, payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteChannel = createAsyncThunk(
  'conversation/delete-channel',
  async ({ businessId, channelId, channelName }: { businessId: string; channelId: string; channelName: string }, { rejectWithValue }) => {
    try {
      await ConversationService.deleteChannel(businessId, channelId, channelName);
      return channelId;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const assignUserToChannel = createAsyncThunk(
  'conversation/assign-user-to-channel',
  async ({ businessId, channelId, userId }: { businessId: string; channelId: string; userId: string }, { rejectWithValue }) => {
    try {
      return await ConversationService.assignUserToChannel(businessId, channelId, userId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getChannelMessages = createAsyncThunk(
  'conversation/fetch-channel-messages',
  async ({ businessId, channelId }: { businessId: string; channelId: string }, { rejectWithValue }) => {
    try {
      return await ConversationService.getChannelMessages(businessId, channelId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createMessage = createAsyncThunk(
  'conversation/create-message',
  async ({ businessId, channelId, payload }: { businessId: string; channelId: string; payload: ICreateMessagePayload }, { rejectWithValue }) => {
    try {
      return await ConversationService.createMessage(businessId, channelId, payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createReply = createAsyncThunk(
  'conversation/create-reply',
  async (
    { businessId, channelId, messageId, payload }: { businessId: string; channelId: string; messageId: string; payload: ICreateMessagePayload },
    { rejectWithValue },
  ) => {
    try {
      return await ConversationService.createReply(businessId, channelId, messageId, payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteMessage = createAsyncThunk(
  'conversation/delete-message',
  async ({ businessId, channelId, messageId }: { businessId: string; channelId: string; messageId: string }, { rejectWithValue }) => {
    try {
      return await ConversationService.deleteMessage(businessId, channelId, messageId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createJobChannel = createAsyncThunk(
  'conversation/create-job-channel',
  async ({ businessId, entityId }: { businessId: string; entityId: string }, { rejectWithValue }) => {
    try {
      const createdResponse = await ConversationService.createJobChannel(businessId, entityId);
      const fullChannelResponse = await ConversationService.getChannelById(businessId, createdResponse.data.id);
      return fullChannelResponse;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const clientGetChannelById = createAsyncThunk(
  'conversation/client-get-channel-by-channelId',
  async (
    { businessId, businessClientPortalId, channelId }: { businessId: string; businessClientPortalId: string; channelId: string },
    { rejectWithValue },
  ) => {
    try {
      return await ConversationService.clientGetChannelById(businessId, businessClientPortalId, channelId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const clientGetMessagesByChannelId = createAsyncThunk(
  'conversation/client-get-messages-by-channelId',
  async (
    { businessId, businessClientPortalId, channelId }: { businessId: string; businessClientPortalId: string; channelId: string },
    { rejectWithValue },
  ) => {
    try {
      return await ConversationService.clientGetMessagesByChannelId(businessId, businessClientPortalId, channelId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const clientSendMessage = createAsyncThunk(
  'conversation/client-send-message',
  async (
    {
      businessId,
      businessClientPortalId,
      entityId,
      payload,
    }: {
      businessId: string;
      businessClientPortalId: string;
      entityId: string;
      payload: { content: string; attachments: string[]; author_id: string };
    },
    { dispatch },
  ) => {
    const response = await ConversationService.clientSendMessage(businessId, businessClientPortalId, entityId, payload);

    const channelResponse = await dispatch(
      clientGetChannelById({
        businessId,
        businessClientPortalId,
        channelId: response?.data?.business_channel_id,
      }),
    ).unwrap();

    return { message: response.data, channel: channelResponse };
  },
);

const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    setSelectedChannel(state, action: PayloadAction<IChannel | undefined>) {
      state.selectedChannel = action.payload;
    },
    setSelectedMessage(state, action: PayloadAction<IChatMessage | undefined>) {
      state.selectedMessage = action.payload;
    },
    clearMessages(state) {
      state.clientChannel = undefined;
      state.messages.data = [];
    },
    appendMessage(state, { payload }: PayloadAction<IChatMessage>) {
      const existingMessageIndex = state.messages.data?.findIndex(
        (message) => message.id?.includes('inserted-') && message.content === payload?.content,
      );

      if (existingMessageIndex > -1) {
        state.messages.data = [
          ...state.messages?.data?.slice(0, existingMessageIndex),
          payload,
          ...state.messages?.data?.slice(existingMessageIndex + 1),
        ];
      } else {
        state.messages.data = state.messages.data ? [...state.messages.data, payload] : [payload];
      }
    },
    appendChannel(state, action: PayloadAction<{ channel: IChannel; currentUserId: string }>) {
      state.channels = [...(state.channels ?? []), action.payload?.channel];

      if (action?.payload?.channel?.type === EChannelType.INDIVIDUAL) {
        const userId = action?.payload?.channel?.users?.find((user) => user?.id !== action?.payload?.currentUserId)?.id;
        if (!userId) return;

        state.individualChannelsMap = {
          ...state.individualChannelsMap,
          [userId]: action.payload?.channel,
        };
      }
    },
    modifyChannel(state, action: PayloadAction<IChannel>) {
      if (state.channels) {
        state.channels = state.channels?.map((item) => (item?.id === action.payload.id ? action.payload : item));
      }
      state.selectedChannel = action.payload;
    },
    removeChannel(state, action: PayloadAction<IChannel>) {
      state.channels = state.channels?.filter((channel) => channel?.id !== action.payload.id);
      state.selectedChannel = undefined;
    },
    removeMessage(state, action: PayloadAction<IChatMessage>) {
      state.messages.data = state.messages?.data?.filter((message) => message?.id !== action.payload.id);
    },
    appendUserToChannel(state, action: PayloadAction<IAssignUserResponse>) {
      const assignedUser = {
        id: action.payload.partnerUser?.user_id,
        email: action.payload.partnerUser?.email,
        partner_users: [action.payload?.partnerUser],
      };
      if (!state.selectedChannel) return;
      state.selectedChannel.users = [...state.selectedChannel.users, assignedUser];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChannels.pending, (state) => {
        state.loading = true;
      })
      .addCase(getChannels.fulfilled, (state, { payload }: PayloadAction<{ data: IChannel[]; userId: string }>) => {
        state.loading = false;
        const channels = payload.data?.filter((channel) => [EChannelType.PUBLIC, EChannelType.PRIVATE, EChannelType.GROUP]?.includes(channel?.type));
        state.channels = channels;
        const teams = payload.data?.filter((channel) => channel?.type === EChannelType.TEAM_GROUP);
        state.teams = teams;
        state.selectedChannel = channels?.[0] ?? undefined;

        state.individualChannelsMap = payload?.data?.reduce((acc: { [key: string]: IChannel }, item) => {
          if (item?.type !== EChannelType.INDIVIDUAL) return acc;
          let userId = item?.users?.find((user) => user.id !== payload.userId)?.id;

          if (!userId) return acc;
          acc[userId] = item;

          return acc;
        }, {});
      })
      .addCase(getChannels.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getChannelById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getChannelById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.selectedChannel = payload.data;
      })
      .addCase(getChannelById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createChannel.pending, (state) => {
        state.creating = true;
      })
      .addCase(createChannel.fulfilled, (state, { payload }) => {
        state.creating = false;
        state.selectedChannel = payload?.data;
      })
      .addCase(createChannel.rejected, (state) => {
        state.creating = false;
      })
      .addCase(editChannel.pending, (state) => {
        state.updating = true;
      })
      .addCase(editChannel.fulfilled, (state) => {
        state.updating = false;
      })
      .addCase(editChannel.rejected, (state) => {
        state.updating = false;
      })
      .addCase(deleteChannel.pending, (state) => {
        state.updating = true;
      })
      .addCase(deleteChannel.fulfilled, (state) => {
        state.updating = false;
      })
      .addCase(deleteChannel.rejected, (state) => {
        state.creating = false;
      })

      .addCase(assignUserToChannel.pending, (state) => {
        state.updating = true;
      })
      .addCase(assignUserToChannel.fulfilled, (state) => {
        state.updating = false;
      })
      .addCase(assignUserToChannel.rejected, (state) => {
        state.updating = false;
      })
      .addCase(getChannelMessages.pending, (state) => {
        state.messages.loading = true;
      })
      .addCase(getChannelMessages.fulfilled, (state, { payload }) => {
        state.messages.loading = false;
        state.messages.data = payload.data;
      })
      .addCase(getChannelMessages.rejected, (state) => {
        state.messages.loading = false;
      })

      .addCase(createMessage.pending, (state, { meta }) => {
        state.messages.creating = true;

        state.messages.data = [
          ...state.messages.data,
          {
            id: `inserted-${meta.requestId}`,
            content: meta?.arg?.payload?.content,
            attachments: meta?.arg?.payload?.attachments || [],
            author_id: meta?.arg?.payload?.author_id,
            author_type: EAuthorType.USER,
          } as IChatMessage,
        ];
      })
      .addCase(createMessage.fulfilled, (state) => {
        state.messages.creating = false;
      })
      .addCase(createMessage.rejected, (state) => {
        state.messages.creating = false;
      })
      .addCase(createReply.pending, (state, { meta }) => {
        state.messages.creating = true;
        state.messages.data = [
          ...state.messages.data,
          {
            id: `inserted-${meta.requestId}`,
            content: meta?.arg?.payload?.content,
            author_id: meta?.arg?.payload?.author_id,
            author_type: EAuthorType.USER,
            message_id: meta?.arg?.messageId,
          } as IChatMessage,
        ];
      })
      .addCase(createReply.fulfilled, (state) => {
        state.messages.creating = false;
      })
      .addCase(createReply.rejected, (state) => {
        state.messages.creating = false;
      })
      .addCase(deleteMessage.pending, (state) => {
        state.messages.removing = true;
      })
      .addCase(deleteMessage.fulfilled, (state) => {
        state.messages.removing = false;
      })
      .addCase(deleteMessage.rejected, (state) => {
        state.messages.removing = false;
      })
      .addCase(createJobChannel.pending, (state) => {
        state.creating = true;
      })
      .addCase(createJobChannel.fulfilled, (state, { payload }) => {
        state.creating = false;
        state.selectedChannel = payload.data;
      })
      .addCase(createJobChannel.rejected, (state) => {
        state.creating = false;
      })
      .addCase(clientGetChannelById.pending, (state) => {
        state.loading = true;
      })
      .addCase(clientGetChannelById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.clientChannel = payload.data;
      })
      .addCase(clientGetChannelById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(clientGetMessagesByChannelId.pending, (state) => {
        state.messages.loading = true;
      })
      .addCase(clientGetMessagesByChannelId.fulfilled, (state, { payload }) => {
        state.messages.loading = false;
        state.messages.data = payload.data;
      })
      .addCase(clientGetMessagesByChannelId.rejected, (state) => {
        state.messages.loading = false;
      })
      .addCase(clientSendMessage.pending, (state) => {
        state.messages.creating = true;
      })
      .addCase(clientSendMessage.fulfilled, (state, { payload }) => {
        state.messages.creating = false;
        state.messages.data.push(payload.message);
      })
      .addCase(clientSendMessage.rejected, (state) => {
        state.messages.creating = false;
      });
  },
});

export const conversationReducer = conversationSlice.reducer;
export const {
  setSelectedChannel,
  setSelectedMessage,
  appendMessage,
  appendChannel,
  appendUserToChannel,
  modifyChannel,
  removeChannel,
  removeMessage,
  clearMessages,
} = conversationSlice.actions;
