import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';

import { Col, Form, Modal, Row, Table, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ColumnsType } from 'antd/es/table';
import './Exhibitors.scss';

import { Button } from '@aduvi/components/Button/Button';
import { CustomField } from '@aduvi/components/CustomField/CustomField';
import { PERMISSIONS } from '@aduvi/constants';
import { useActiveTheme, useBusiness, useUserPermissionCheck } from '@aduvi/hooks';
import CustomAndManagementFieldsAdjuster from '@aduvi/patterns/adjuster/CustomAndManagementFieldsAdjuster';
import { EManagementFieldType, EPersonalizedViewOrigin, IEntityField, IExhibitor } from '@aduvi/types';
import { EEntityType } from '@aduvi/types/entity';

import { createExhibitor, deleteExhibitor, setSelectedExhibitor, updateExhibitor } from 'store/features/events-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import * as EntityFieldsServices from 'store/services/fields.service';

interface GroupedExhibitors {
  letter: string;
  items: IExhibitor[];
}

const origin = EPersonalizedViewOrigin.EXHIBITORS;

export const Exhibitors = () => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();
  const [form] = useForm();
  const activeTheme = useActiveTheme();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fields, setFields] = useState<IEntityField[]>([]);

  const { selectedEvent, selectedExhibitor, exhibitorCustomFields } = useAppSelector((state) => state.events);
  const {
    entityTypes: { data },
  } = useAppSelector((state) => state.entity);

  const customFieldsAdjuster = new CustomAndManagementFieldsAdjuster(origin);
  const hasPermissionToFetchExhibitorFields = useUserPermissionCheck(PERMISSIONS.EXHIBITOR_FIELDS.VIEW);

  const closeModal = () => {
    dispatch(setSelectedExhibitor(undefined));
    form.resetFields();
    setIsModalOpen(false);
  };

  const onSubmit = () => {
    if (!selectedExhibitor) return onCreateExhibitor();
    onEditExhibitor();
  };
  const onDeleteExhibitor = () => {
    if (!selectedBusiness || !selectedExhibitor || !selectedEvent) return;
    dispatch(
      deleteExhibitor({
        business_id: selectedBusiness.id,
        business_event_id: selectedEvent.id,
        exhibitor_id: selectedExhibitor.id,
      }),
    ).then(() => {
      closeModal();
    });
  };

  const onCreateExhibitor = () => {
    if (!selectedBusiness?.id || !selectedEvent?.id) return;
    const customFields = customFieldsAdjuster.mapFormDataToPayload(form.getFieldValue('custom_fields'));
    dispatch(
      createExhibitor({
        business_event_id: selectedEvent?.id,
        business_id: selectedBusiness?.id,
        body: {
          business_event_id: selectedEvent?.id,
          ...form.getFieldsValue(),
          custom_fields: customFields,
        },
      }),
    ).then(() => {
      closeModal();
    });
  };

  const onEditExhibitor = () => {
    if (!selectedBusiness?.id || !selectedEvent?.id || !selectedExhibitor) return;
    const customFields = customFieldsAdjuster.mapFormDataToPayload(form.getFieldValue('custom_fields'), selectedExhibitor?.custom_fields);
    dispatch(
      updateExhibitor({
        business_event_id: selectedEvent?.id,
        business_id: selectedBusiness?.id,
        body: {
          ...selectedExhibitor,
          ...form.getFieldsValue(),
          custom_fields: customFields,
        },
      }),
    ).then(() => {
      closeModal();
    });
  };

  const columns = useTableColumns(setIsModalOpen);

  const groupedData: GroupedExhibitors[] = useMemo(() => {
    return (selectedEvent?.exhibitors ?? [])
      .reduce<GroupedExhibitors[]>((acc, item) => {
        const letter = item?.custom_fields?.[0]?.value?.[0]?.toUpperCase();
        const existingEntry = acc.find((entry) => entry.letter === letter);
        if (existingEntry) {
          existingEntry.items.push(item);
        } else {
          acc.push({ letter, items: [item] });
        }
        return acc;
      }, [])
      .sort((a, b) => (a.letter < b.letter ? -1 : a.letter > b.letter ? 1 : 0));
  }, [selectedEvent?.exhibitors]);

  useEffect(() => {
    if (!selectedExhibitor) return;
    const customFields = customFieldsAdjuster.mapFieldsToForm(selectedExhibitor, exhibitorCustomFields.exhibitorFields);

    form.setFieldsValue({
      ...selectedExhibitor,
      custom_fields: customFields,
    });
  }, [selectedExhibitor, selectedBusiness?.id]);

  useEffect(() => {
    if (!selectedBusiness?.id || !hasPermissionToFetchExhibitorFields || !data?.[EEntityType.EXHIBITOR]?.id) return;
    try {
      EntityFieldsServices.getEntityFields(selectedBusiness.id, data?.[EEntityType.EXHIBITOR]?.id).then((response) => setFields(response.data));
    } catch (err) {
      return;
    }
  }, [selectedBusiness?.id]);

  return (
    <Col span={24} className='exhibitor-form-wrapper'>
      <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
        {groupedData.map((contact) => (
          <div key={contact.letter}>
            <Typography.Title level={3} className='list-letter mb-0' style={{ color: activeTheme?.titleText }}>
              {contact.letter}
            </Typography.Title>

            <Table
              size='small'
              className='agenda-table w-full mb-10'
              rowKey='id'
              loading={false}
              columns={columns}
              pagination={false}
              dataSource={contact.items}
            />
          </div>
        ))}

        <Row>
          <Button
            requiredPermission={PERMISSIONS.EXHIBITORS.CREATE}
            disabledButton
            onClick={() => {
              setIsModalOpen(true);
            }}
            icon={<PlusOutlined />}>
            {translate('assets.eventsDrawer.exhibitors.add')}
          </Button>
        </Row>

        <Modal
          title={translate('assets.eventsDrawer.exhibitors.add')}
          open={isModalOpen}
          onOk={onSubmit}
          onCancel={closeModal}
          className='event-exhibitor-modal'
          footer={[
            selectedExhibitor && (
              <Button
                key='back'
                style={{ marginRight: 'auto' }}
                disabledButton
                requiredPermission={PERMISSIONS.EXHIBITORS.DELETE}
                onClick={onDeleteExhibitor}>
                {translate('assets.eventsDrawer.exhibitors.remove')}
              </Button>
            ),
            <Button
              key='submit'
              style={{ marginLeft: 'auto' }}
              type='primary'
              disabledButton
              requiredPermission={selectedExhibitor ? PERMISSIONS.EXHIBITORS.EDIT : PERMISSIONS.EXHIBITORS.CREATE}
              onClick={onSubmit}>
              {translate('assets.eventsDrawer.exhibitors.addExhibitor')}
            </Button>,
          ]}>
          <Form form={form} layout='vertical' requiredMark={false}>
            <Row wrap={true}>
              <Col span={24} className='mt-15'>
                <Form.Item name='custom_fields'>
                  {fields?.map((item, index) => <CustomField form={form} field={item} key={item.id} index={index} disabledFields={[]} />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </Col>
  );
};

const useTableColumns = (setShowAgendaModal: (prop: boolean) => void): ColumnsType<IExhibitor> => {
  const dispatch = useAppDispatch();

  return [
    {
      title: 'Name',
      render: (_, record) => (
        <Row className='w-full'>{record.custom_fields.find((item) => item.field.field_type === EManagementFieldType.NAME)?.value}</Row>
      ),
    },

    {
      title: ' ',
      render: (_, record) => (
        <Row className='w-full' justify='end'>
          <EllipsisOutlined
            onClick={() => {
              dispatch(setSelectedExhibitor(record));
              setShowAgendaModal(true);
            }}
          />
        </Row>
      ),
    },
  ];
};
