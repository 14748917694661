import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { Button, Dropdown, MenuProps, Popconfirm, Row } from 'antd';

import { CustomTableCell } from '@aduvi/components/PersonalizedViewComponents/CustomTableCell/CustomTableCell';
import { deleteEntityPermission } from '@aduvi/constants/entity-type';
import { useBusiness, useUserPermissionCheck } from '@aduvi/hooks';
import { EPersonalizedViewOrigin, ICustomFieldSpecificStyle, ICustomFieldViewStyle, IQuote } from '@aduvi/types';
import { EEntityType, IActionButton, IEntityWithFields, IUseEntity, IUseTableColumns } from '@aduvi/types/entity';
import { EFormType } from '@aduvi/types/form';
import { getDefaultPersonalizedViewColumns } from '@aduvi/utils/custom-fields-helper';

import { getClientPortals } from 'store/features/client-portal-slice';
import { getActionButtons } from 'store/features/entity-slice';
import { getEntityFields } from 'store/features/fields-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useGetEntityFieldsByMultipleIdsQuery } from 'store/rtk-slice/fields-slice';
import { getQuotes } from 'store/services/quote.service';

import { useManagementFields } from '../../hooks/useManagementFields';
import { CustomFieldValue } from '../CustomField/CustomFieldValue/CustomFieldValue';
import { DroppableMenu } from '../PersonalizedViewComponents/DroppableMenu/DroppableMenu';
import { getQuoteFieldNames } from '../PersonalizedViewComponents/QuotesTable/helper';

import { ActionButton } from './components/actions-buttons/ActionButton';
import { resolveUrl } from './helper';

export const useEntitiesTableColumns = ({
  columns,
  origin,
  entityType,
  fields,
  droppedActions,
  setDroppedActions,
  onEdit,
  onDelete,
  onTableRowAction,
}: IUseTableColumns) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();

  const { entities } = useAppSelector((state) => state.entity);
  const { action_buttons } = useAppSelector((state) => state.entity.entityTypes);

  const [quotes, setQuotes] = useState<IQuote[]>();
  const quoteFields = getQuoteFieldNames(quotes);

  const selectedBusiness = useBusiness();

  const referenceEntityIds = fields
    ?.filter((field) => field?.reference_entity_type)
    .map((field) => ({ entity_type_id: field?.reference_entity_type_id, entity_name: field?.place_holder || field?.reference_entity_type }));

  const { data: referenceEntities } = useGetEntityFieldsByMultipleIdsQuery(
    {
      businessId: selectedBusiness?.id!,
      entityTypeIds: referenceEntityIds!,
    },
    { skip: !selectedBusiness || !referenceEntityIds },
  );

  const getEntitiesDropdownItems: MenuProps['items'] = [
    {
      key: '0',
      icon: <EditOutlined />,
      label: translate('leads.edit'),
      onClick: onEdit,
    },
    {
      key: '1',
      icon: <DeleteOutlined />,
      label: (
        <Popconfirm
          title={translate('leads.delete')}
          disabled={!useUserPermissionCheck(entityType?.name && deleteEntityPermission[entityType?.name])}
          description={translate('leads.deleteConfirmation')}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          onCancel={(e) => e?.stopPropagation()}
          onConfirm={(e) => {
            e?.stopPropagation();
            return onDelete();
          }}>
          {translate('leads.delete')}
        </Popconfirm>
      ),
      onClick: (e) => e?.domEvent?.stopPropagation(),
      disabled: !useUserPermissionCheck(entityType?.name && deleteEntityPermission[entityType?.name]),
    },
    ...(entityType?.name === EEntityType.JOB || entityType?.name === EEntityType.ORDER
      ? entities?.selectedEntity?.is_confirmed
        ? [
            {
              key: '2',
              icon: <DashboardOutlined />,
              label: (
                <a href={`/portal/portal/${entityType.name.toLowerCase()}/${entities.selectedEntity?.id}`} target='_blank' rel='noreferrer'>
                  {translate('clientPortal.viewInClientPortal')}
                </a>
              ),
              onClick: (e: { domEvent?: { stopPropagation: () => void } }) => {
                e?.domEvent?.stopPropagation();
              },
            },
          ]
        : []
      : []),
  ];

  const dynamicMenuItems: MenuProps['items'] = droppedActions?.map((droppedAction) => ({
    key: droppedAction?.id,
    label: (
      <div className='flex align-center'>
        <DeleteOutlined
          className='color-red cursor-pointer fs-12'
          onClick={(e) => {
            e.stopPropagation();
            setDroppedActions(droppedActions?.filter((action) => action?.id !== droppedAction?.id));
          }}
        />
        <a
          href={resolveUrl(droppedAction?.url)}
          target='_blank'
          rel='noopener noreferrer'
          onClick={(e) => e.stopPropagation()}
          className='ml-10 color-black'>
          {droppedAction?.title}
        </a>
      </div>
    ),
  }));
  const combinedMenuItems: MenuProps['items'] = [...getEntitiesDropdownItems, ...dynamicMenuItems];

  useEffect(() => {
    if (!selectedBusiness?.id) return;

    dispatch(getClientPortals(selectedBusiness?.id));
  }, [selectedBusiness?.id]);

  useEffect(() => {
    const fetchQuotes = async () => {
      if (!selectedBusiness?.id || !entities?.data?.length) return;

      try {
        const quotePromises = entities.data.map((entity) => getQuotes(selectedBusiness.id, entity.id));

        const results = await Promise.all(quotePromises);

        setQuotes(results.flatMap((result) => result.data));
      } catch (error) {
        return;
      }
    };

    fetchQuotes();
  }, [selectedBusiness?.id, entities?.data, getQuotes]);

  useEffect(() => {
    if (selectedBusiness?.id && entityType?.id) {
      dispatch(
        getActionButtons({
          businessId: selectedBusiness?.id,
          entityTypeId: entityType?.id,
        }),
      );
    }
  }, [selectedBusiness?.id, entityType?.id]);

  return [
    ...(columns?.map((column) => {
      const isReferenceEntity = referenceEntities?.data?.some(
        (entity) => entity?.custom_fields?.some((field) => column?.field_id?.includes(field?.id)),
      );

      return {
        title: !isReferenceEntity
          ? column?.name ||
            action_buttons?.find((button) => button?.id === column?.field_id?.[0])?.title ||
            quoteFields.find((quote) => quote?.id === column?.field_id?.[0])?.title
          : ` ${referenceEntities?.data?.find((entity) => entity?.custom_fields?.some((field) => column?.field_id?.includes(field?.id)))
              ?.entity_name} ${referenceEntities?.data
              ?.find((entity) => entity?.custom_fields?.some((field) => column?.field_id?.includes(field?.id)))
              ?.custom_fields?.find((field) => column?.field_id?.includes(field?.id))?.title}`,
        render: (_: unknown, record: IEntityWithFields) => {
          return {
            children: column?.field_id?.map((fieldId, index) => {
              const isReferenceEntity = referenceEntities?.data?.some(
                (entity) => entity?.custom_fields?.some((field) => column?.field_id?.includes(field?.id)),
              );
              const referenceEntity = referenceEntities?.data?.find((entity) => entity?.custom_fields?.find((field) => field?.id === fieldId));
              const referenceEntityField = referenceEntities?.data
                ?.find((entity) => entity?.custom_fields?.find((field) => field?.id === fieldId))
                ?.custom_fields?.find((customField) => customField?.id === fieldId);

              const actionButton = action_buttons?.find((button) => button?.id === fieldId);

              const applyCellStyles = (record: IEntityWithFields, fieldId: string) => {
                if (!record?.custom_fields && !quotes) return {};

                const quoteField = quotes?.find((quote) => quote?.entity_id === record?.id);
                if (quoteField) {
                  return { ...(column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style };
                }

                const customField = record?.custom_fields?.find((field) => field?.id === fieldId);

                if (customField) {
                  return Array.isArray(customField?.field_data) &&
                    customField?.field_data?.length > 0 &&
                    customField?.field_data?.some((data) => data?.value !== null)
                    ? { ...(column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style }
                    : {};
                }
              };

              if (actionButton) {
                return (
                  <ActionButton
                    key={actionButton?.id}
                    actionButton={actionButton}
                    column={column as unknown as ICustomFieldSpecificStyle}
                    fieldId={fieldId}
                  />
                );
              }

              return (
                <CustomTableCell
                  key={index}
                  style={{
                    minWidth: '200px',
                    ...applyCellStyles(record, fieldId),
                  }}>
                  <CustomFieldValue
                    key={index}
                    style={{
                      ...applyCellStyles(record, fieldId),
                    }}
                    fieldId={fieldId}
                    column={column}
                    quotes={quotes?.filter((quote) => quote?.entity_id === record?.id)}
                    field={
                      isReferenceEntity
                        ? record?.custom_fields
                            ?.filter((field) => field?.reference_entity_type_id === referenceEntity?.entity_id)?.[0]
                            ?.field_data?.[0]?.custom_fields?.find((customField) => customField?.id === referenceEntityField?.id)
                        : record?.custom_fields?.find((field) => field?.id === fieldId)
                    }
                    record={
                      isReferenceEntity
                        ? (record?.custom_fields?.filter((field) => field?.reference_entity_type_id === referenceEntity?.entity_id)?.[0]
                            ?.field_data?.[0] as unknown as IEntityWithFields)
                        : record
                    }
                    managementFields={fields}
                    origin={origin}
                    entityType={entityType}
                  />
                </CustomTableCell>
              );
            }),
          };
        },
      };
    }) || []),
    {
      key: 'action',
      render: (_: unknown, record: IEntityWithFields) => (
        <Row className='w-full' justify='end' onClick={(e) => e?.stopPropagation()}>
          <DroppableMenu id='droppable-menu'>
            <Dropdown menu={{ items: combinedMenuItems }} trigger={['click', 'hover']}>
              <Button
                onClick={(e) => {
                  e?.stopPropagation();
                  return onTableRowAction(record);
                }}
                icon={<EllipsisOutlined />}
                type='text'
                style={{ zIndex: 20 }}
              />
            </Dropdown>
          </DroppableMenu>
        </Row>
      ),
    },
  ];
};

export const useEntity = ({ entityType, origin }: IUseEntity) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();

  const { fields, formOnlyFields } = useManagementFields({ entityType, origin });

  const { selectedView } = useAppSelector((state) => state?.personalizedViews);

  const [editRowLayoutDrawer, setEditRowLayoutDrawer] = useState(false);
  const [showEntityDrawer, setShowEntityDrawer] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [entityColumns, setEntityColumns] = useState<ICustomFieldViewStyle[]>([]);
  const [droppedActions, setDroppedActions] = useState<IActionButton[]>([]);

  const formType: Record<EPersonalizedViewOrigin, EFormType | string> = {
    BOOKINGS: EFormType.BOOKINGS_FORM,
    [EPersonalizedViewOrigin.LEADS]: EFormType.LEADS_FORM,
    [EPersonalizedViewOrigin.FORMS]: EFormType.CONTACTS_FORM,
    [EPersonalizedViewOrigin.EVENTS]: '',
    [EPersonalizedViewOrigin.ORDERS]: '',
    [EPersonalizedViewOrigin.CONTACTS]: '',
    [EPersonalizedViewOrigin.VENUES]: '',
    [EPersonalizedViewOrigin.EXHIBITORS]: '',
    [EPersonalizedViewOrigin.TASKS]: '',
    [EPersonalizedViewOrigin.PROJECTS]: '',
  };

  const initialColumns: ICustomFieldViewStyle[] = useMemo(() => {
    return getDefaultPersonalizedViewColumns(fields);
  }, [fields]);

  useEffect(() => {
    if (!selectedView?.style) return setEntityColumns(initialColumns);

    const columns = JSON.parse(selectedView?.style);
    if (columns) {
      return setEntityColumns(columns);
    } else {
      setEntityColumns(initialColumns);
    }
  }, [selectedView?.style, initialColumns]);

  useEffect(() => {
    if (selectedBusiness?.id && entityType?.id) {
      dispatch(getEntityFields({ businessId: selectedBusiness?.id, entityTypeId: entityType?.id })).catch(() => {});
    }
  }, [selectedBusiness?.id, entityType?.id]);

  useEffect(() => {
    if (selectedView?.style) {
      setEntityColumns(JSON.parse(selectedView?.style));
    }
  }, [selectedView?.style]);

  useEffect(() => {
    if (selectedView?.menu_items) {
      setDroppedActions(JSON.parse(selectedView?.menu_items));
    }
  }, [selectedView?.style]);

  useEffect(() => {
    setDroppedActions([]);
  }, [origin]);

  return {
    dispatch,
    selectedBusiness,
    editRowLayoutDrawer,
    showEntityDrawer,
    entityColumns,
    droppedActions,
    showFormModal,
    initialColumns,
    fields,
    formOnlyFields,
    formType: formType[origin],
    setShowFormModal,
    setEntityColumns,
    setShowEntityDrawer,
    setEditRowLayoutDrawer,
    setDroppedActions,
  };
};
