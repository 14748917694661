import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IApp } from '@aduvi/types';
import { EBillingCycle, IBillingDetails, IBillingState, ICreateBusinessCardPayload, ICreateCardPayload } from '@aduvi/types/billing';

import * as BillingServices from 'store/services/billing.service';

const initialState: IBillingState = {
  selectedPlan: {
    data: undefined,
    businessId: undefined,
  },
  previewPlan: undefined,
  billingDetails: undefined,
  cards: undefined,
  default_card: undefined,
  loadingCards: false,
  creatingCard: false,
  deletingCard: false,
  editingCard: false,
  loading: false,
  creating: false,
  editing: false,
};

export const getBillingDetails = createAsyncThunk('billing-details/get', async (params: { businessId: string }, { rejectWithValue }) => {
  try {
    return await BillingServices.getBillingDetails(params?.businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createBillingDetails = createAsyncThunk(
  'billing-details/create',
  async (params: Omit<IBillingDetails, 'id'> & { business_id: string }, { rejectWithValue }) => {
    try {
      return await BillingServices.createBillingDetails(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const editBillingDetails = createAsyncThunk(
  'billing-details/edit',
  async (params: IBillingDetails & { business_id: string }, { rejectWithValue }) => {
    try {
      return await BillingServices.editBillingDetails(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getBusinessPlanInfo = createAsyncThunk(
  'billing-plan-info/put',
  async (
    { business_id, partner_pricing_id, billingCycle }: { business_id: string; partner_pricing_id: string; billingCycle: EBillingCycle },
    { rejectWithValue },
  ) => {
    try {
      return await BillingServices.getBusinessPlanInfo({ business_id, partner_pricing_id, billingCycle });
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const businessPlanUpgrade = createAsyncThunk(
  'billing-plan/upgrade',
  async (
    { business_id, partner_pricing_id, billingCycle }: { business_id: string; partner_pricing_id: string; billingCycle: EBillingCycle },
    { rejectWithValue },
  ) => {
    try {
      return await BillingServices.businessPlanUpgrade({ business_id, partner_pricing_id, billingCycle });
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getActiveBusinessPlan = createAsyncThunk('billing-plan/get', async (params: { business_id: string }, { rejectWithValue }) => {
  try {
    return await BillingServices.getActiveBusinessPlan(params.business_id);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getCards = createAsyncThunk(
  'cards/get',
  async ({ user_id, business_id }: Omit<ICreateCardPayload, 'stripe_token'>, { rejectWithValue }) => {
    try {
      return await BillingServices.getCards(user_id, business_id);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createCard = createAsyncThunk(
  'cards/create',
  async ({ user_id, business_id, stripe_token }: ICreateCardPayload, { rejectWithValue }) => {
    try {
      return await BillingServices.createCard(user_id, business_id, stripe_token);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteCard = createAsyncThunk(
  'cards/delete',
  async ({ user_id, business_id, card_id }: Omit<ICreateCardPayload, 'stripe_token'> & { card_id: string }, { rejectWithValue }) => {
    try {
      return await BillingServices.deleteCard(user_id, business_id, card_id);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const setDefaultCard = createAsyncThunk(
  'set-default-card/put',
  async ({ user_id, business_id, card_id }: Omit<ICreateCardPayload, 'stripe_token'> & { card_id: string }, { rejectWithValue }) => {
    try {
      return await BillingServices.setDefaultCard(user_id, business_id, card_id);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getBusinessCards = createAsyncThunk('business-card/get-all', async ({ business_id }: { business_id: string }, { rejectWithValue }) => {
  try {
    return await BillingServices.getBusinessCards(business_id);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createBusinessCard = createAsyncThunk(
  'business-card/create',
  async ({ stripe_token, business_id }: ICreateBusinessCardPayload, { rejectWithValue }) => {
    try {
      return await BillingServices.createBusinessCard(business_id, stripe_token);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const setDefaultBusinessCard = createAsyncThunk(
  'business-card/set-default',
  async ({ business_id, card_id }: Omit<ICreateCardPayload, 'stripe_token' | 'user_id'> & { card_id: string }, { rejectWithValue }) => {
    try {
      return await BillingServices.setDefaultBusinessCard(business_id, card_id);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteBusinessCard = createAsyncThunk(
  'business-card/delete',
  async ({ business_id, card_id }: Omit<ICreateCardPayload, 'stripe_token' | 'user_id'> & { card_id: string }, { rejectWithValue }) => {
    try {
      return await BillingServices.deleteBusinessCard(business_id, card_id);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const addBillingContacts = createAsyncThunk(
  'billing-contacts/add',
  async (payload: { business_id: string; billing_contacts: string[] }, { rejectWithValue }) => {
    try {
      return await BillingServices.addBillingContacts(payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    resetBillingState: () => initialState,
    extendAppsOfSelectedPlan: (state, { payload }: PayloadAction<IApp>) => {
      if (state.selectedPlan?.data) {
        state.selectedPlan.data.apps = [...(state.selectedPlan?.data?.apps || []), payload];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBillingDetails.pending, (state) => {
        state.billingDetails = undefined;
        state.loading = true;
      })
      .addCase(getBillingDetails.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getBillingDetails.fulfilled, (state, { payload }) => {
        state.billingDetails = payload.data;
        state.loading = false;
      })
      .addCase(createBillingDetails.pending, (state) => {
        state.creating = true;
      })
      .addCase(createBillingDetails.fulfilled, (state, { payload }) => {
        state.billingDetails = payload.data;
        state.creating = false;
      })
      .addCase(createBillingDetails.rejected, (state) => {
        state.creating = false;
      })
      .addCase(editBillingDetails.pending, (state) => {
        state.editing = true;
      })
      .addCase(editBillingDetails.fulfilled, (state, { payload }) => {
        state.editing = false;
        state.billingDetails = { ...state.billingDetails, ...payload.data };
      })
      .addCase(editBillingDetails.rejected, (state) => {
        state.editing = false;
      })
      .addCase(getCards.pending, (state) => {
        state.loadingCards = true;
      })
      .addCase(getCards.fulfilled, (state, { payload }) => {
        state.loadingCards = false;
        state.cards = payload.data.cards.data;
        state.default_card = payload.data.default_card;
      })
      .addCase(getCards.rejected, (state) => {
        state.loadingCards = false;
      })
      .addCase(createCard.pending, (state) => {
        state.creatingCard = true;
      })
      .addCase(createCard.fulfilled, (state) => {
        state.creatingCard = false;
      })
      .addCase(createCard.rejected, (state) => {
        state.creatingCard = false;
      })
      .addCase(deleteCard.pending, (state) => {
        state.deletingCard = true;
      })
      .addCase(deleteCard.fulfilled, (state, { meta }) => {
        state.deletingCard = false;
        state.cards = state.cards?.filter((card) => card.id !== meta.arg.card_id);
      })
      .addCase(deleteCard.rejected, (state) => {
        state.deletingCard = false;
      })
      .addCase(setDefaultCard.pending, (state) => {
        state.editingCard = true;
      })
      .addCase(setDefaultCard.fulfilled, (state, { meta }) => {
        state.editingCard = false;
        state.default_card = meta.arg.card_id;
      })
      .addCase(setDefaultCard.rejected, (state) => {
        state.editingCard = false;
      })
      .addCase(getBusinessPlanInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBusinessPlanInfo.fulfilled, (state, { payload, meta }) => {
        state.loading = false;
        state.previewPlan = payload.data;
        state.selectedPlan.businessId = meta.arg.business_id;
      })
      .addCase(getBusinessPlanInfo.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getActiveBusinessPlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(getActiveBusinessPlan.fulfilled, (state, { payload, meta }) => {
        state.loading = false;
        state.selectedPlan.data = payload.data?.plan;
        state.selectedPlan.businessId = meta.arg.business_id;
      })
      .addCase(getActiveBusinessPlan.rejected, (state) => {
        state.loading = false;
        state.selectedPlan.data = undefined;
      })
      .addCase(createBusinessCard.pending, (state) => {
        state.creatingCard = true;
      })
      .addCase(createBusinessCard.fulfilled, (state) => {
        state.creatingCard = false;
      })
      .addCase(createBusinessCard.rejected, (state) => {
        state.creatingCard = false;
      })
      .addCase(getBusinessCards.pending, (state) => {
        state.loadingCards = true;
      })
      .addCase(getBusinessCards.fulfilled, (state, { payload }) => {
        state.loadingCards = false;
        state.businessCards = payload.data.cards;
        state.defaultBusinessCard = payload.data.default_card;
      })
      .addCase(getBusinessCards.rejected, (state) => {
        state.loadingCards = false;
      })
      .addCase(setDefaultBusinessCard.pending, (state) => {
        state.editingCard = true;
      })
      .addCase(setDefaultBusinessCard.fulfilled, (state, { meta }) => {
        state.editingCard = false;
        state.defaultBusinessCard = meta.arg.card_id;
      })
      .addCase(setDefaultBusinessCard.rejected, (state) => {
        state.editingCard = false;
      })
      .addCase(deleteBusinessCard.pending, (state) => {
        state.deletingCard = true;
      })
      .addCase(deleteBusinessCard.fulfilled, (state, { meta }) => {
        state.deletingCard = false;
        state.businessCards = state.businessCards?.filter((card) => card.id !== meta.arg.card_id);
      })
      .addCase(deleteBusinessCard.rejected, (state) => {
        state.deletingCard = false;
      })
      .addCase(businessPlanUpgrade.pending, (state) => {
        state.loading = true;
      })
      .addCase(businessPlanUpgrade.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(businessPlanUpgrade.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addBillingContacts.pending, (state) => {
        state.creating = true;
      })
      .addCase(addBillingContacts.fulfilled, (state, { payload }) => {
        state.creating = false;
        state.billingDetails = payload.data;
      })
      .addCase(addBillingContacts.rejected, (state) => {
        state.creating = false;
      });
  },
});

export const billingReducer = billingSlice.reducer;
export const { resetBillingState, extendAppsOfSelectedPlan } = billingSlice.actions;
