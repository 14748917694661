import React, { SetStateAction, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, UserOutlined } from '@ant-design/icons';
import { Editor } from '@tiptap/react';

import { Avatar, Col, Divider, Row, Typography, UploadFile } from 'antd';

import Tiptap from '@aduvi/Tiptap/Tiptap';
import { EFieldDataValueType } from '@aduvi/types';
import { IChannelMember } from '@aduvi/types/conversation';
import { formatDateTimeForMessages } from '@aduvi/utils/helper';

import { setSelectedMessage } from 'store/features/conversation-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { useEditorHandler } from '../EditorHandler.hook';
import { FileList } from '../file-list/FileList';
import { SingleConversationChat } from '../single-conversation-chat/SingleConversationChat';

interface IProps {
  setShowThreadDrawer: (value: SetStateAction<boolean>) => void;
}

export const ThreadDrawer = ({ setShowThreadDrawer }: IProps) => {
  const dispatch = useAppDispatch();

  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const { t: translate } = useTranslation();

  const { selectedMessage, selectedChannel, messages } = useAppSelector((state) => state.conversation);
  const partner = useAppSelector((state) => state.common.layout);

  const [editorInstance, setEditorInstance] = useState<Editor | null>(null);

  const { handleEditorKeyDown, handleSubmit } = useEditorHandler(editorInstance, selectedChannel?.id, messagesContainerRef, true);

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const channelUsers = useMemo(() => {
    return selectedChannel?.users?.map((user) => {
      if (!partner?.id) return;
      return user.partner_users.find((partner_user) => partner_user.partner_id === partner.id);
    });
  }, [selectedChannel?.id, partner?.id]);

  const channelMembers = useMemo<IChannelMember[]>(() => {
    if (!selectedChannel) return [];

    const partnerUsers =
      selectedChannel.users?.flatMap((user) =>
        user.partner_users.map((partnerUser) => ({
          id: user.id,
          first_name: partnerUser.first_name ? String(partnerUser.first_name) : null,
          last_name: partnerUser.last_name ? String(partnerUser.last_name) : null,
          email: partnerUser.email || '',
          profile_picture: partnerUser.profile_picture || null,
        })),
      ) || [];

    const contacts =
      selectedChannel.contacts?.map((contact) => ({
        id: contact.id,
        first_name: contact.entityDetails?.custom_fields?.find((field) => field?.value_type === EFieldDataValueType.FIELD_DATA_TEXTS)?.field_data?.[0]
          ?.value
          ? String(
              contact.entityDetails?.custom_fields?.find((field) => field?.value_type === EFieldDataValueType.FIELD_DATA_TEXTS)?.field_data?.[0]
                ?.value,
            )
          : null,
        last_name: null,
        email: contact.email || '',
        profile_picture:
          contact.entityDetails?.custom_fields?.find((field) => field?.value_type === EFieldDataValueType.FIELD_DATA_IMAGES)?.field_data?.[0]?.url ||
          null,
      })) || [];

    return [...partnerUsers, ...contacts];
  }, [selectedChannel]);

  const messageOwner = useMemo(() => channelUsers?.find((user) => user?.user_id === selectedMessage?.author_id), [selectedMessage, channelUsers]);

  const replies = useMemo(() => {
    return messages?.data?.filter((message) => message.message_id === selectedMessage?.id);
  }, [messages.data, selectedMessage?.id]);

  return (
    <Row wrap={false} className='pl-5' style={{ height: '91vh' }}>
      <Col span={24}>
        <Row className='w-full p-20 ' justify='space-between' align={'middle'}>
          <Col style={{ gap: '5px', alignItems: 'center' }} lg={20}>
            <Typography.Text className='fw-bold'>{translate('conversation.thread')}</Typography.Text>
          </Col>
          <Col
            className='text-right'
            onClick={() => {
              setShowThreadDrawer(false);
              dispatch(setSelectedMessage(undefined));
            }}>
            <CloseOutlined />
          </Col>
        </Row>

        {selectedMessage && (
          <>
            <Col style={{ maxHeight: '100%', overflowY: 'auto' }} className='message-block' span={24}>
              <Row wrap={false}>
                <Col>
                  <Avatar className='b-radius-10' shape='square' size='large' src={messageOwner?.profile_picture} icon={<UserOutlined />} />
                </Col>
                <Col className='flex-grow-1 ml-5'>
                  <Row align={'middle'}>
                    <Col>
                      <Typography.Text className='mb-10' strong>
                        {`${messageOwner?.first_name}  ${messageOwner?.last_name}`}
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className='fs-10 ml-10' type='secondary'>
                        {`${formatDateTimeForMessages(selectedMessage?.created_at)}`}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Typography.Text className='message m-0 w-full'>
                    <div dangerouslySetInnerHTML={{ __html: selectedMessage.content }} />
                  </Typography.Text>
                </Col>
              </Row>

              {replies && replies?.length > 0 && (
                <>
                  <Divider orientation='left' orientationMargin='0' plain={true}>
                    {replies?.length} replies
                  </Divider>
                  {replies.map((reply, index) => {
                    return (
                      <SingleConversationChat key={index} message={reply} isReplySection={true} channelMembers={channelMembers as IChannelMember[]} />
                    );
                  })}
                </>
              )}

              <Tiptap
                key={'1'}
                content={null}
                placeholder='Message...'
                isConversationEditor
                onKeyDown={handleEditorKeyDown}
                setEditorInstance={setEditorInstance}
                onSendMessage={() => {
                  handleSubmit(fileList?.map((file) => file?.url) as string[]);
                  setFileList([]);
                }}
              />

              {fileList?.length > 0 ? (
                <Row>
                  <FileList fileList={fileList} setFileList={setFileList} />
                </Row>
              ) : (
                <></>
              )}
            </Col>
          </>
        )}
      </Col>
    </Row>
  );
};
