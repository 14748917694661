import React, { useRef, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';

import { Col, Row } from 'antd';
import { UploadFile, UploadProps } from 'antd/es/upload';
import './UploadImage.scss';

import { IUploadFile } from '../../types';
import { MediaLibraryModal } from '../MediaLibraryModal/MediaLibraryModal';

import { ImageUpload } from './ImageUpload';

interface IProps {
  name: string | string[];
  onUploadChange: (value: IUploadFile | null) => void;
  url?: string;
  icon?: React.ReactNode;
  text?: string;
  uploadSettings?: {
    allowed_extensions: string[];
    maximum_upload_size: string;
    size_unit: string;
  };
}

export const UploadImage = ({
  onUploadChange,
  uploadSettings,
  name = 'image',
  text = 'common.uploadButton',
  icon = <UploadOutlined />,
  ...rest
}: UploadProps & IProps) => {
  const preventClickRef = useRef(false);
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [uploadedImageUrls, setUploadedImageUrls] = useState<string[]>([]);

  const onSelect = (urls: string | string[]) => {
    if (!urls || !urls?.length) return;

    setShowUploadImageModal(false);
    onUploadChange({ url: urls?.[0] });
    setSelectedFile(urls?.[0]);
  };

  const blockModalClickEvent = (file?: UploadFile) => {
    if (file !== undefined) {
      onUploadChange({ url: '' });
    }
    preventClickRef.current = true;
    setTimeout(() => (preventClickRef.current = false), 5);
  };

  const openMediaLibraryModal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (preventClickRef?.current) {
      return event.stopPropagation();
    }
    setShowUploadImageModal(true);
  };

  const onPreview = (url?: string) => {
    if (!url) return;
    blockModalClickEvent();
    window.open(url, '_blank');
  };

  return (
    <Row>
      <Col onClick={openMediaLibraryModal}>
        <ImageUpload
          icon={icon}
          text={text}
          name={name}
          uploadSettings={uploadSettings}
          openFileDialogOnClick={false}
          onUploadChange={() => {}}
          disabled={rest.disabled}
          {...rest}
          onRemove={blockModalClickEvent}
          onPreview={(file) => onPreview(file?.url)}
          url={selectedFile || rest?.url}
        />
      </Col>

      {showUploadImageModal && (
        <MediaLibraryModal
          open={showUploadImageModal}
          uploadedImageUrls={uploadedImageUrls}
          onSelect={onSelect}
          onCancel={() => setShowUploadImageModal(false)}
          uploadComponent={
            <ImageUpload
              key={'dragger'}
              uploadType='dragger'
              multiple={true}
              icon={icon}
              text={text}
              name={name}
              uploadSettings={uploadSettings}
              onUploadChange={(item) => {
                onUploadChange(item);
                if (!item?.url) return setUploadedImageUrls([]);
                setUploadedImageUrls([item?.url]);
              }}
              disabled={rest.disabled}
              {...rest}
              url={undefined}
            />
          }
        />
      )}
    </Row>
  );
};
