import { Route, Routes } from 'react-router-dom';

import { BusinessEmbed } from './embed-widgets/business/BusinessEmbed';
import { EventEmbed } from './embed-widgets/events/EventEmbed';
import { PackageEmbed } from './embed-widgets/packages/PackageEmbed';
import { ProductEmbed } from './embed-widgets/products/ProductEmbed';
import { ServiceEmbed } from './embed-widgets/services/ServiceEmbed';
import { UserEmbed } from './embed-widgets/users/UserEmbed';
import { WidgetEmbed } from './embed-widgets/widget/WidgetEmbed';

export const EmbedRoutes = () => {
  return (
    <Routes>
      <Route path='/embed/:businessId/services/:widgetId' element={<ServiceEmbed />}></Route>
      <Route path='/embed/:businessId/users/:widgetId' element={<UserEmbed />}></Route>
      <Route path='/embed/:businessId/packages/:widgetId' element={<PackageEmbed />}></Route>
      <Route path='/embed/:businessId/products/:widgetId' element={<ProductEmbed />}></Route>
      <Route path='/embed/:businessId/business/:widgetId' element={<BusinessEmbed />}></Route>
      <Route path='/embed/:businessId/event-ticketing/:widgetId' element={<EventEmbed />}></Route>
      <Route path='/embed/:businessId/widgets/:widgetId' element={<WidgetEmbed />}></Route>
    </Routes>
  );
};
