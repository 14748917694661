import { IChatMessage } from './common';
import { ICustomField } from './entity';
import { IPartnerUser } from './user';

export interface IChannel {
  id: string;
  name: string;
  type: EChannelType;
  users: {
    id: string;
    email: string;
    partner_users: IPartnerUser[];
  }[];
  business_id: string;
  contacts: IContact[];
}

interface IEntityDetails {
  id: string;
  business_id: string;
  entity_type_id: string;
  is_confirmed: boolean;
  brand_id: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  form_id: string | null;
  custom_fields: ICustomField[];
}

export interface IContact {
  id: string;
  entity_id: string;
  login_token?: string;
  magic_link_token?: string;
  email: string;
  entityDetails: IEntityDetails;
  business_id: string;
  deleted_at?: string;
  created_at?: string;
  updated_at?: string;
}

export interface IChannelMember {
  id: string;
  first_name?: string | null;
  last_name?: string | null;
  email: string;
  profile_picture?: string | null;
}

export interface IClientChannel {
  id: string;
  name: string;
  type: string;
  business_id: string;
  created_by?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  created_by_contact_id: string;
  entity_id: string;
  users: {
    id: string;
    email: string;
    partner_users: IPartnerUser[];
  }[];
  contacts: IContact[];
}

export interface IAssignUserResponse {
  business_channel_user: {
    business_channel_id: string;
    user_id: string;
    id: string;
  };
  partnerUser: IPartnerUser;
}

export interface ICreateChannelPayload {
  business_id: string;
  name: string;
  type: EChannelType;
  user_id?: string;
}

export interface IConversationState {
  channels?: IChannel[];
  teams?: IChannel[];
  individualChannelsMap?: { [key: string]: IChannel };
  selectedChannel?: IChannel;
  clientChannel?: IClientChannel;
  selectedMessage?: IChatMessage;

  messages: {
    data: IChatMessage[];
    creating: boolean;
    loading: boolean;
    removing: boolean;
  };
  loading: boolean;
  creating: boolean;
  updating: boolean;
}

export enum EChannelType {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  GROUP = 'GROUP',
  INDIVIDUAL = 'INDIVIDUAL',
  TEAM_GROUP = 'TEAM_GROUP',
}

export enum EPusherEvents {
  MESSAGE_SENT = 'message-sent',
  USER_ADDED_TO_CHANNEL = 'user-added-to-channel',
  CHANNEL_CREATED = 'channel-created',
  CHANNEL_EDITED = 'channel-edited',
  CHANNEL_DELETED = 'channel-deleted',
  MESSAGE_DELETED = 'message-deleted',
}
