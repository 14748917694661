import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'antd';

import { getFieldInlineStyles, getLabelAlignmentStyles } from '@aduvi/components/CustomField/CustomFieldValue/helper';
import { EActionButtonSettings, EFieldLabelAligment, EFieldSettings, ICustomFieldSpecificStyle } from '@aduvi/types';

import { resolveUrl } from '../../helper';

import { ActionButtonModal } from './ActionButtonModal';

interface ActionButtonProps {
  actionButton: {
    id: string;
    title: string;
    url: string;
  };
  column: ICustomFieldSpecificStyle;
  fieldId: string;
}

export const ActionButton = ({ actionButton, column, fieldId }: ActionButtonProps) => {
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(false);
  const [modalUrl, setModalUrl] = useState('');

  const fieldLabel = (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.fieldLabel;
  const fieldSetting = (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style.fieldSetting;

  const isLabelHidden = fieldSetting === EFieldSettings.HIDDEN;
  const isLabelOnTop = fieldSetting === EFieldSettings.ON_TOP;
  const isLabelInline = fieldSetting === EFieldSettings.INLINE;
  const fieldLabelAlignment = (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.fieldLabelAlignment;

  const handleClick = () => {
    const resolvedUrl = resolveUrl(actionButton?.url || '');
    const target = (column?.[fieldId]?.style?.target as EActionButtonSettings) || EActionButtonSettings.CURRENT_TAB;

    switch (target) {
      case EActionButtonSettings.CURRENT_TAB:
        if (resolvedUrl.startsWith('/')) {
          try {
            navigate(resolvedUrl);
          } catch (err) {
            return;
          }
        }
        break;

      case EActionButtonSettings.NEW_TAB:
        window.open(resolvedUrl, '_blank');
        break;

      case EActionButtonSettings.MODAL:
        setModalUrl(resolvedUrl);
        setModalVisible(true);
        break;
    }
  };

  const containerContent = (
    <div style={getFieldInlineStyles(isLabelInline)}>
      {!isLabelHidden && isLabelInline && <span style={getLabelAlignmentStyles(fieldLabelAlignment)}>{fieldLabel}</span>}

      <span
        style={{
          order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2,
          flexShrink: 0,
        }}>
        <Button
          style={column?.[fieldId]?.style}
          onClick={(e) => {
            e.stopPropagation();
            handleClick();
          }}>
          {actionButton?.title}
        </Button>
      </span>
    </div>
  );

  return (
    <div
      style={{
        display: isLabelOnTop ? 'flex' : undefined,
        flexDirection: isLabelOnTop ? 'column' : undefined,
        alignItems: isLabelOnTop ? 'flex-start' : undefined,
      }}
      className='editable-container'>
      {isLabelOnTop && !isLabelHidden && (
        <span
          style={{
            display: 'block',
            marginBottom: '8px',
          }}>
          {fieldLabel}
        </span>
      )}
      {containerContent}
      <ActionButtonModal visible={modalVisible} url={modalUrl} onClose={() => setModalVisible(false)} />
    </div>
  );
};
