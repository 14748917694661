import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

import { Col, Input, Radio, Row } from 'antd';
import './Assets.scss';

import { Button } from '@aduvi/components/Button/Button';
import { SearchInput } from '@aduvi/components/SearchInput/SearchInput';
import { Title } from '@aduvi/components/Title';

import { EventsDrawer } from './events/events-drawer/EventsDrawer';
import { ProductsDrawer } from './products/products-drawer/ProductsDrawer';
import { ServicesDrawer } from './services/services-drawer/ServicesDrawer';
import { useAssets } from './Assets.hook';
import { AssetsType } from './Assets.hook';

export const Assets = () => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const [showDrawer, setShowDrawer] = useState(false);
  const [currentTab, setCurrentTab] = useState<AssetsType | undefined>(undefined);

  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get('search') || '';

  const {
    storeSearchPlaceholder,
    storeCreatePermissions,
    storeAddBtn,
    tabPermissions,
    AssetsComponents,
    tabItems,
    setCurrentTabAndClearSearch,
    onSearchChange,
    onOpenCloseDrawer,
  } = useAssets({ searchValue, currentTab, setCurrentTab, setShowDrawer });

  useEffect(() => {
    const tabsByPermissions = Object.values(AssetsType)?.map((value) => ({
      key: value,
      value: tabPermissions[value],
    }));

    if (!tabsByPermissions.some((item) => item.key === location.pathname.toLowerCase()) && location.pathname.toLowerCase().startsWith('/store')) {
      if (tabsByPermissions.some((item) => item.value)) return navigate(tabsByPermissions.find((item) => item.value)?.key as string);
      return navigate('/dashboard');
    }

    if (tabPermissions[location.pathname.toLowerCase() as AssetsType]) {
      setCurrentTab(location.pathname.toLowerCase() as AssetsType);
      return navigate(location.pathname.toLowerCase());
    }
    return navigate(tabsByPermissions.find((item) => item.value)?.key as string);
  }, [location.pathname]);

  return (
    <div className='settings-assets-wrapper'>
      <Row className='w-full mb-30'>
        <Col span={12} xs={12} md={12}>
          <Row className='mobile-view-title'>
            <Title className='aduvi-title mb-10 mr-10'>{translate('assets.services.title')}</Title>
            <Radio.Group
              className='assets-radio-group'
              value={currentTab}
              options={tabItems()}
              optionType='button'
              onChange={(e) => setCurrentTabAndClearSearch(e?.target?.value)}
            />
          </Row>
        </Col>
        <Col span={12} xs={12} md={12}>
          <Row justify='end' align='middle' className='mobile-view-search'>
            <Col>
              <SearchInput
                value={searchValue}
                onChange={onSearchChange}
                placeholder={currentTab ? translate(storeSearchPlaceholder[currentTab]) : ''}
              />
            </Col>
            <Col>
              <Button
                requiredPermission={currentTab && storeCreatePermissions[currentTab]}
                disabledButton
                className='ml-10'
                type='primary'
                onClick={() => onOpenCloseDrawer(true)}>
                {currentTab ? (
                  <>
                    <PlusOutlined style={{ fontSize: 10, marginRight: 5 }}  />
                    {translate(storeAddBtn[currentTab])}
                  </>
                ) : (
                  ''
                )}
              </Button>
            </Col>

            {showDrawer && currentTab === AssetsType.EVENTS && <EventsDrawer open={showDrawer} onClose={() => onOpenCloseDrawer(false)} />}
            {showDrawer && currentTab === AssetsType.PRODUCTS && <ProductsDrawer open={showDrawer} onClose={() => onOpenCloseDrawer(false)} />}
            {showDrawer && currentTab === AssetsType.SERVICES && <ServicesDrawer open={showDrawer} onClose={() => onOpenCloseDrawer(false)} />}
          </Row>
        </Col>
      </Row>

      {currentTab && AssetsComponents[currentTab]}
    </div>
  );
};
