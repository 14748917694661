import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CodeOutlined,
  EyeOutlined,
  FacebookOutlined,
  LinkedinOutlined,
  MailOutlined,
  QrcodeOutlined,
  TwitterOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';

import { MenuProps, TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { EFormPurpose } from '@aduvi/types/form';

export const useBookingForm = (formPurpose: EFormPurpose | undefined) => {
  const [form] = useForm();
  const { t: translate } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('1');

  const formLinkDropdownItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div>
          <CodeOutlined /> {translate('forms.forms.embedForm')}
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div>
          <QrcodeOutlined /> {translate('forms.forms.qrCode')}
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div>
          <EyeOutlined /> {translate('forms.forms.previewForm')}
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      type: 'group',
      label: translate('forms.forms.shareVia'),
      children: [
        {
          key: '4-1',
          label: (
            <div>
              <TwitterOutlined /> Twitter (X)
            </div>
          ),
        },
        {
          key: '4-2',
          label: (
            <div>
              <FacebookOutlined /> Facebook
            </div>
          ),
        },
        {
          key: '4-3',
          label: (
            <div>
              <MailOutlined /> E-mail
            </div>
          ),
        },
        {
          key: '4-4',
          label: (
            <div>
              <LinkedinOutlined /> Linked In
            </div>
          ),
        },
        {
          key: '4-5',
          label: (
            <div>
              <WhatsAppOutlined /> WhatsApp
            </div>
          ),
        },
      ],
    },
  ];

  const tabs: TabsProps['items'] = [
    {
      key: '1',
      label: translate('forms.forms.build'),
    },
    {
      key: '2',
      label: translate('forms.forms.configure'),
    },
    {
      key: '3',
      label: formPurpose === EFormPurpose.CAPTURE ? translate('forms.forms.visibility') : translate('forms.forms.share'),
    },
  ];

  return { form, formLinkDropdownItems, selectedTab, tabs, setSelectedTab };
};
