import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import axios from '@aduvi/config/axios';
import { useBusinessId } from '@aduvi/hooks';
import { EFieldDataValueType, EReferenceEntityType, IEntityField } from '@aduvi/types';

import { getEntityFields } from 'store/features/fields-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const useFetchEntityFields = () => {
  const dispatch = useAppDispatch();
  const businessId = useBusinessId();
  const { t: translate } = useTranslation();

  const {
    entityTypes: { data },
  } = useAppSelector((state) => state.entity);

  const { fields } = useAppSelector((state) => state.fields);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [neededFields, setNeededFields] = useState<IEntityField[]>([]);

  const localAddressFields = fields?.filter((f) => f.value_type === EFieldDataValueType.FIELD_DATA_ADDRESSES);

  const allFields = [...neededFields, ...localAddressFields].filter(Boolean);

  const venueFields = allFields?.filter(
    (field) => field?.reference_entity_type === EReferenceEntityType.VENUE || field?.entity_type_id === data?.VENUE?.id,
  );

  const otherFields = allFields?.filter(
    (field) =>
      field?.value_type === EFieldDataValueType.FIELD_DATA_ADDRESSES &&
      field?.reference_entity_type !== EReferenceEntityType.VENUE &&
      field?.entity_type_id !== data?.VENUE?.id,
  );

  const venueOptions = useMemo(() => {
    if (!venueFields?.length) return [];
    return [
      {
        label: translate('settings.travelFees.venues.title'),
        options: venueFields?.map((field) => ({
          label: field?.title ?? '',
          value: field.id ?? '',
        })),
      },
    ];
  }, [venueFields]);

  const otherOptions = useMemo(() => {
    if (!otherFields?.length) return [];
    return [
      {
        label: translate('settings.travelFees.otherAddresses'),
        options: otherFields?.map((field) => ({
          label: field?.title ?? '',
          value: field.id ?? '',
        })),
      },
    ];
  }, [otherFields]);

  useEffect(() => {
    if (!businessId || !data?.JOB?.id) return;
    dispatch(getEntityFields({ businessId, entityTypeId: data?.JOB?.id }));
  }, [businessId, data?.JOB?.id]);

  useEffect(() => {
    if (!businessId || !data?.JOB?.id || !fields || fields?.length === 0) {
      setIsLoading(false);
      return;
    }

    const fetchFields = async () => {
      setIsLoading(true);

      const uniqueFields = fields
        .filter(
          (field) =>
            field?.reference_entity_type_id && ![EReferenceEntityType.SERVICE, EReferenceEntityType.PRODUCT].includes(field?.reference_entity_type),
        )
        .reduce((unique: IEntityField[], item) => {
          const isDuplicate = unique?.some((u: IEntityField) => u?.reference_entity_type === item?.reference_entity_type);
          if (!isDuplicate) unique.push(item);
          return unique;
        }, []);

      if (uniqueFields.length === 0) {
        setNeededFields([]);
        setIsLoading(false);
        return;
      }

      const referencePromises = uniqueFields.map(async (field) => {
        const { reference_entity_type_id } = field;
        try {
          const response = await axios.get(`/businesses/${businessId}/entity-types/${reference_entity_type_id}/fields`);
          const entityFieldData: IEntityField[] = response.data;
          return entityFieldData.filter((subField) => subField.value_type === EFieldDataValueType.FIELD_DATA_ADDRESSES);
        } catch (err) {
          () => {};
          return [];
        }
      });

      const nestedResults = await Promise.all(referencePromises);
      const allNeededFields = nestedResults.flat();

      setNeededFields(allNeededFields);
      setIsLoading(false);
    };

    fetchFields();
  }, [businessId, data, fields]);

  return { neededFields: allFields, isLoading, venueOptions, otherOptions };
};
