import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, Input, Typography } from 'antd';

export const Configure = ({ selectedTab }: { selectedTab: string }) => {
  const { t: translate } = useTranslation();

  return (
    <Col className='flex-center mt-30' style={{ display: selectedTab !== '2' ? 'none' : '' }}>
      <span style={{ width: '35%' }}>
        <Typography.Text type='secondary'>{translate('widgets.widgetSettings')}</Typography.Text>
        <Form.Item label={translate('widgets.widgetName')} name='title' className='mt-20'>
          <Input />
        </Form.Item>
        <Form.Item label={translate('widgets.description')} name={'description'}>
          <Input.TextArea rows={4} />
        </Form.Item>
      </span>
    </Col>
  );
};
