import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  DeleteOutlined,
  ForkOutlined,
  RightOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useSettingsLocations } from 'views/settings/locations/SettingsLocations.hooks';

import { Button, Col, Divider, Form, Input, InputRef, Popover, Radio, Row, Select, Switch, Typography } from 'antd';
import { FormInstance } from 'antd/lib';
import './EditField.scss';

import { TextStyleSettings } from '@aduvi/components/TextStyleSettings/TextStyleSettings';
import { useBusinessId } from '@aduvi/hooks';
import { EReferenceEntityType } from '@aduvi/types';
import { IDestinationAddressField } from '@aduvi/types/entity';
import { EAlignment, IFieldInstance } from '@aduvi/types/form';

import { recalculateTravelFee } from 'store/features/quote-slice';
import { useAppDispatch } from 'store/hooks';

import { IBookingFormFieldProps } from '../helper';

import { ContactManagementFieldsSettings } from './ContactManagementFieldsSettings';
import { ProductManagementFieldsSettings } from './ProductManagementFieldsSettings';

interface IProps {
  formSettings: FormInstance;
  children: ReactNode;
  id: string;
  isDragging: boolean;
  fields: IBookingFormFieldProps[];
  index: number;
  readonly?: boolean;
  setFields: (value: React.SetStateAction<IBookingFormFieldProps[]>) => void;
}

export const EditField = ({ formSettings, children, id, isDragging, fields, index, readonly, setFields }: IProps) => {
  const dispatch = useAppDispatch();
  const selectedBusinessId = useBusinessId();
  const { t: translate } = useTranslation();
  const { locations } = useSettingsLocations();
  const destinationAddressRef = useRef<InputRef>(null);

  const [isHovered, setIsHovered] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const formField = fields?.find((item) => item.field_id === id);
  const isTravelFeeField = formField?.isTravelFee;

  const [typedAddress, setTypedAddress] = useState(formField?.field_instance?.display_settings?.destination_address?.address || '');

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    marginTop: readonly ? '24px' : '',
  };

  const alignmentMap: Record<EAlignment, React.CSSProperties['textAlign']> = {
    [EAlignment.LEFT]: 'left',
    [EAlignment.CENTER]: 'center',
    [EAlignment.RIGHT]: 'right',
  };

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFields(
      (prev) =>
        prev?.map((item, idx) =>
          idx === index
            ? {
                ...item,
                field_instance: {
                  ...item.field_instance,
                  title: e.target.value,
                },
              }
            : item,
        ),
    );
  };

  const updateDisplaySettings = (partial: Partial<IFieldInstance['display_settings']>) => {
    setFields((prev) =>
      prev.map((item, idx) =>
        idx === index
          ? {
              ...item,
              field_instance: {
                ...item.field_instance,
                display_settings: {
                  ...item.field_instance.display_settings,
                  ...partial,
                },
              },
            }
          : item,
      ),
    );
  };

  useEffect(() => {
    if (window.google && destinationAddressRef.current?.input) {
      const autoComplete = new window.google.maps.places.Autocomplete(destinationAddressRef.current.input, {
        types: ['geocode'],
        fields: ['address_components', 'formatted_address', 'geometry'],
      });

      autoComplete.addListener('place_changed', () => {
        const place = autoComplete.getPlace();
        if (!place) return;

        const addressComponents = place.address_components || [];

        const route = addressComponents.find((comp) => comp.types.includes('route'))?.long_name || '';
        const city = addressComponents.find((comp) => comp.types.includes('locality'))?.long_name || '';
        const state = addressComponents.find((comp) => comp.types.includes('administrative_area_level_1'))?.long_name || '';
        const postCode = addressComponents.find((comp) => comp.types.includes('postal_code'))?.long_name || '';

        updateDisplaySettings({
          destination_address: {
            address: place.formatted_address || '',
            post_code: postCode,
            state,
            street_one: route,
            street_two: '',
            zip_code: postCode,
            city,
          },
        });
        setTypedAddress(place.formatted_address || '');
      });
    }
  }, [destinationAddressRef, updateDisplaySettings]);

  useEffect(() => {
    if (isTravelFeeField) {
      const origin_field_id = formField?.field_instance?.display_settings?.origin_field_id;
      const destination_address = formField?.field_instance?.display_settings?.destination_address;

      if (origin_field_id && destination_address) {
        calculateTravelFee(origin_field_id, destination_address);
      }
    }
  }, [formField?.field_instance?.display_settings?.origin_field_id, formField?.field_instance?.display_settings?.destination_address]);

  const calculateTravelFee = async (business_location_id: string, destination_address: IDestinationAddressField) => {
    if (readonly) return;
    dispatch(
      recalculateTravelFee({
        business_id: selectedBusinessId,
        business_location_id,
        destination_address,
      }),
    )
      .unwrap()
      .then((fee) => {
        setFields((prev) =>
          prev.map((item, idx) =>
            idx === index
              ? {
                  ...item,
                  field_instance: {
                    ...item.field_instance,
                    title: `Travel Fee: $${fee.data}`,
                  },
                }
              : item,
          ),
        );
      })
      .catch(() => {});
  };

  const dateSettings = (
    <Row className='flex-direction-column'>
      <Typography.Text type='secondary'>{translate('forms.forms.dateDisplay')}</Typography.Text>
      <Radio.Group className='mt-5'>
        <Radio.Button value='pop_up'>{translate('forms.forms.popUp')}</Radio.Button>
        <Radio.Button value='inline'>{translate('forms.forms.inline')}</Radio.Button>
      </Radio.Group>
      <Typography.Text type='secondary' className='mt-10'>
        {translate('forms.forms.timeSlotDisplay')}
      </Typography.Text>
      <Radio.Group className='mt-5'>
        <Radio.Button value='manual'>{translate('forms.forms.manual')}</Radio.Button>
        <Radio.Button value='slots'>{translate('forms.forms.slots')}</Radio.Button>
      </Radio.Group>
      <Typography.Text type='secondary' className='mt-10'>
        {translate('forms.forms.timeIntervals')}
      </Typography.Text>
      <Select size='small' placeholder='15 minutes' />
      <Typography.Text type='secondary' className='mt-10'>
        {translate('forms.forms.minDate')}
      </Typography.Text>
      <Select size='small' placeholder='Today' />
      <Typography.Text type='secondary' className='mt-10'>
        {translate('forms.forms.maxDate')}
      </Typography.Text>
      <Select size='small' placeholder='+3 Years' />
    </Row>
  );

  const conditionals = (
    <Row className='flex-direction-column'>
      <Row align={'middle'} className='w-full'>
        <Typography.Text type='secondary'>{translate('forms.forms.if')}</Typography.Text>
        <Select placeholder='Event Type' className='ml-10' />
      </Row>
      <Select placeholder='Is' className='mt-10' />
      <Select placeholder='Wedding' className='mt-10' />
      <Radio.Group className='mt-10' defaultValue={'show'}>
        <Radio.Button value='show'>{translate('forms.forms.show')}</Radio.Button>
        <Radio.Button value='hide'>{translate('forms.forms.hide')}</Radio.Button>
      </Radio.Group>
    </Row>
  );

  const defaultContent = (
    <>
      <TextStyleSettings
        values={formField?.field_instance?.display_settings}
        onFontChange={(value) =>
          setFields(
            (prev) =>
              prev?.map((item, idx) => {
                if (idx === index)
                  return {
                    ...item,
                    field_instance: {
                      ...item.field_instance,
                      display_settings: {
                        ...item.field_instance.display_settings,
                        fontSize: value,
                      } as IFieldInstance['display_settings'],
                    },
                  };
                return item;
              }),
          )
        }
        onBoldChange={(value) =>
          setFields(
            (prev) =>
              prev?.map((item, idx) => {
                if (idx === index)
                  return {
                    ...item,
                    field_instance: {
                      ...item.field_instance,
                      display_settings: {
                        ...item.field_instance.display_settings,
                        bold: value,
                      } as IFieldInstance['display_settings'],
                    },
                  };
                return item;
              }),
          )
        }
        onItalicChange={(value) =>
          setFields(
            (prev) =>
              prev?.map((item, idx) => {
                if (idx === index)
                  return {
                    ...item,
                    field_instance: {
                      ...item.field_instance,
                      display_settings: {
                        ...item.field_instance.display_settings,
                        italic: value,
                      } as IFieldInstance['display_settings'],
                    },
                  };
                return item;
              }),
          )
        }
        onTextColorChange={(value) =>
          setFields(
            (prev) =>
              prev?.map((item, idx) => {
                if (idx === index)
                  return {
                    ...item,
                    field_instance: {
                      ...item.field_instance,
                      display_settings: {
                        ...item.field_instance.display_settings,
                        textColor: value,
                      } as IFieldInstance['display_settings'],
                    },
                  };
                return item;
              }),
          )
        }
        onBackgroundTextColorChange={(value) =>
          setFields(
            (prev) =>
              prev?.map((item, idx) => {
                if (idx === index)
                  return {
                    ...item,
                    field_instance: {
                      ...item.field_instance,
                      display_settings: {
                        ...item.field_instance.display_settings,
                        backgroundTextColor: value,
                      } as IFieldInstance['display_settings'],
                    },
                  };
                return item;
              }),
          )
        }
      />
      <Row align={'middle'} className='mb-5'>
        <Switch
          size='small'
          checked={formField?.field_instance?.half_width}
          onChange={(value) =>
            setFields(
              (prev) =>
                prev?.map((item, idx) => {
                  if (idx === index)
                    return {
                      ...item,
                      field_instance: {
                        ...item.field_instance,
                        half_width: value,
                      },
                    };
                  return item;
                }),
            )
          }
        />{' '}
        <span className='ml-10'>{translate('forms.forms.halfWidth')}</span>
      </Row>
      <Row align={'middle'} className='mb-5'>
        <Switch
          size='small'
          checked={formField?.field_instance.required}
          onChange={(value) =>
            setFields(
              (prev) =>
                prev?.map((item, idx) => {
                  if (idx === index)
                    return {
                      ...item,
                      field_instance: {
                        ...item.field_instance,
                        required: value,
                      },
                    };
                  return item;
                }),
            )
          }
        />
        <span className='ml-10'>{translate('forms.forms.required')}</span>
      </Row>
      <Row align={'middle'} className='mb-5'>
        <Switch
          size='small'
          checked={formField?.field_instance.hidden}
          onChange={(value) =>
            setFields(
              (prev) =>
                prev?.map((item, idx) => {
                  if (idx === index)
                    return {
                      ...item,
                      field_instance: {
                        ...item.field_instance,
                        hidden: value,
                      },
                    };
                  return item;
                }),
            )
          }
        />
        <span className='ml-10'>{translate('forms.forms.hideField')}</span>
      </Row>
      <Divider style={{ margin: '12px 0' }} />
      <Typography.Text type='secondary'>{translate('forms.forms.options')}</Typography.Text>
      <Popover
        placement='right'
        content={
          formField?.field?.reference_entity_type === EReferenceEntityType.CONTACT ? (
            <ContactManagementFieldsSettings selectedField={formField} setFields={setFields} />
          ) : formField?.field?.reference_entity_type === EReferenceEntityType.PRODUCT ? (
            <ProductManagementFieldsSettings selectedField={formField} setFields={setFields} />
          ) : (
            dateSettings
          )
        }>
        <Button className='w-full flex align-center p-0' type='text' icon={<UserOutlined />}>
          {translate('forms.forms.settings')} <RightOutlined style={{ marginLeft: 'auto' }} />
        </Button>
      </Popover>
      <Popover placement='right' content={conditionals}>
        <Button className='w-full flex align-center p-0' type='text' icon={<ForkOutlined />}>
          {translate('forms.forms.conditionals')} <RightOutlined style={{ marginLeft: 'auto' }} />
        </Button>
      </Popover>
      <Button
        className='w-full flex align-center p-0'
        type='text'
        icon={<DeleteOutlined />}
        onClick={() => setFields((prev) => prev.filter((_, idx) => idx !== index))}>
        {translate('buttons.delete')}
      </Button>
    </>
  );

  const travelFeeContent = (
    <>
      <TextStyleSettings
        values={formField?.field_instance?.display_settings}
        onFontChange={(value) =>
          setFields(
            (prev) =>
              prev?.map((item, idx) => {
                if (idx === index)
                  return {
                    ...item,
                    field_instance: {
                      ...item.field_instance,
                      display_settings: {
                        ...item.field_instance.display_settings,
                        fontSize: value,
                      } as IFieldInstance['display_settings'],
                    },
                  };
                return item;
              }),
          )
        }
        onBoldChange={(value) =>
          setFields(
            (prev) =>
              prev?.map((item, idx) => {
                if (idx === index)
                  return {
                    ...item,
                    field_instance: {
                      ...item.field_instance,
                      display_settings: {
                        ...item.field_instance.display_settings,
                        bold: value,
                      } as IFieldInstance['display_settings'],
                    },
                  };
                return item;
              }),
          )
        }
        onItalicChange={(value) =>
          setFields(
            (prev) =>
              prev?.map((item, idx) => {
                if (idx === index)
                  return {
                    ...item,
                    field_instance: {
                      ...item.field_instance,
                      display_settings: {
                        ...item.field_instance.display_settings,
                        italic: value,
                      } as IFieldInstance['display_settings'],
                    },
                  };
                return item;
              }),
          )
        }
        onTextColorChange={(value) =>
          setFields(
            (prev) =>
              prev?.map((item, idx) => {
                if (idx === index)
                  return {
                    ...item,
                    field_instance: {
                      ...item.field_instance,
                      display_settings: {
                        ...item.field_instance.display_settings,
                        textColor: value,
                      } as IFieldInstance['display_settings'],
                    },
                  };
                return item;
              }),
          )
        }
        onBackgroundTextColorChange={(value) =>
          setFields(
            (prev) =>
              prev?.map((item, idx) => {
                if (idx === index)
                  return {
                    ...item,
                    field_instance: {
                      ...item.field_instance,
                      display_settings: {
                        ...item.field_instance.display_settings,
                        backgroundTextColor: value,
                      } as IFieldInstance['display_settings'],
                    },
                  };
                return item;
              }),
          )
        }
      />

      <Radio.Group
        className='w-full'
        onChange={(e) => updateDisplaySettings({ alignment: e.target.value })}
        value={formField?.field_instance?.display_settings?.alignment}
        style={{ textAlign: 'center', marginTop: '8px' }}>
        <Row>
          <Col span={8}>
            <Radio.Button value={EAlignment.LEFT} className='w-full'>
              <AlignLeftOutlined />
            </Radio.Button>
          </Col>
          <Col span={8}>
            <Radio.Button value={EAlignment.CENTER} className='w-full'>
              <AlignCenterOutlined />
            </Radio.Button>
          </Col>
          <Col span={8}>
            <Radio.Button value={EAlignment.RIGHT} className='w-full'>
              <AlignRightOutlined />
            </Radio.Button>
          </Col>
        </Row>
      </Radio.Group>

      <Button
        className='w-full flex align-center p-0 my-5'
        type='text'
        icon={<DeleteOutlined />}
        onClick={() => setFields((prev) => prev.filter((_, idx) => idx !== index))}>
        {translate('buttons.delete')}
      </Button>

      <Typography.Text type='secondary' className='mt-10'>
        {translate('forms.forms.origin')}
      </Typography.Text>
      <Select
        className='mt-5 mb-10 w-full'
        placeholder={translate('forms.forms.selectOriginField')}
        value={formField?.field_instance?.display_settings?.origin_field_id}
        onChange={(value) => updateDisplaySettings({ origin_field_id: value })}>
        {locations.data?.map((loc) => (
          <Select.Option key={loc.id} value={loc.id}>
            {loc.name}
          </Select.Option>
        ))}
      </Select>

      <Typography.Text type='secondary' className='mt-10'>
        {translate('forms.forms.destination')}
      </Typography.Text>
      <Input
        className='mt-5 w-full'
        placeholder={translate('forms.forms.selectDestinationField')}
        value={typedAddress}
        onChange={(e) => setTypedAddress(e.target.value)}
        ref={destinationAddressRef}
      />
    </>
  );

  const popoverContent = (
    <Form form={formSettings} layout='vertical' requiredMark={false}>
      {isTravelFeeField ? travelFeeContent : defaultContent}
    </Form>
  );

  const handleMouseEnter = () => {
    if (!readonly) setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Col
      span={24}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ ...style }}
      className={`${isHovered || isDragging || isDropdownVisible ? 'pl-30' : 'pl-10 '} edit-field-form-wrapper pr-10`}
      {...attributes}>
      {isHovered || isDragging || isDropdownVisible ? (
        <Button
          ref={setNodeRef}
          {...listeners}
          type='link'
          className='cursor-grab'
          style={{ color: 'black', position: 'absolute', marginLeft: '-30px', marginTop: '50px' }}
          ghost
          icon={<UnorderedListOutlined />}
        />
      ) : null}
      <Row align={'middle'} style={{ flexFlow: 'nowrap' }}>
        <Col span={24} className={isHovered || isDragging || isDropdownVisible ? 'edit-field-wrapper' : ''}>
          <Row align={'middle'}>
            {formField?.field_instance?.required && (
              <Typography.Text className='pl-5' style={{ color: 'red' }}>
                *
              </Typography.Text>
            )}
            <Input
              bordered={false}
              readOnly={readonly || isTravelFeeField}
              placeholder={translate('forms.forms.addALabel')}
              value={formField?.field_instance.title}
              onChange={handleLabelChange}
              style={{
                flex: 1,
                textAlign: alignmentMap[formField?.field_instance?.display_settings?.alignment ?? EAlignment.LEFT],
                color: formField?.field_instance?.display_settings?.textColor
                  ? formField?.field_instance?.display_settings?.textColor
                  : formSettings.getFieldValue(['form_style', 0, 'primary_font_color']),
                fontFamily: formSettings.getFieldValue(['form_style', 0, 'font_family']),
                background: formField?.field_instance?.display_settings?.backgroundTextColor,
                fontSize: formField?.field_instance?.display_settings?.fontSize + 'px',
                fontStyle: formField?.field_instance?.display_settings?.italic ? 'italic' : 'normal',
                fontWeight: formField?.field_instance?.display_settings?.bold ? 'bold' : 'normal',
              }}
            />
          </Row>
          {!formField?.field_instance?.hidden && children}
          {!(readonly && formField?.field_instance?.description) ? (
            <Input
              className='mb-10'
              style={{
                textAlign: alignmentMap[formField?.field_instance?.display_settings?.alignment ?? EAlignment.LEFT],
                color: formSettings.getFieldValue(['form_style', 0, 'secondary_font_color']),
                fontFamily: formSettings.getFieldValue(['form_style', 0, 'font_family']),
                opacity: 0.65,
              }}
              bordered={false}
              readOnly={readonly}
              disabled={readonly}
              value={formField?.field_instance?.description}
              placeholder={isHovered ? translate('forms.forms.addADescription') : ''}
              onChange={(e) =>
                setFields(
                  (prev) =>
                    prev?.map((item) => {
                      if (item.field_id === id) {
                        return {
                          ...item,
                          field_instance: {
                            ...item.field_instance,
                            description: e.target.value,
                          },
                        };
                      }
                      return item;
                    }),
                )
              }
            />
          ) : null}

          {isHovered || isDragging || isDropdownVisible ? (
            <>
              <Divider />
              <Row justify={'end'}>
                <Popover
                  arrow={false}
                  content={popoverContent}
                  trigger='click'
                  placement='bottomLeft'
                  onOpenChange={(value) => setIsDropdownVisible(value)}>
                  <Button type='text' ghost icon={<SettingOutlined />} onClick={(e) => e.preventDefault()} />
                </Popover>
              </Row>
            </>
          ) : readonly && formField?.field_instance?.description ? (
            <span
              className='ml-10'
              style={{
                textAlign: alignmentMap[formField?.field_instance?.display_settings?.alignment ?? EAlignment.LEFT],
                display: 'block',
                fontFamily: formSettings.getFieldValue(['form_style', 0, 'font_family']),
                color: formSettings.getFieldValue(['form_style', 0, 'secondary_font_color']),
                opacity: 0.65,
              }}>
              {formField?.field_instance?.description}
            </span>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Col>
  );
};
