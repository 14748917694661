import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';

import { Button, Form, Input, Row, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { useActiveTheme, useBusiness } from '@aduvi/hooks';
import { ThemeAdapter } from '@aduvi/patterns/adapter/ThemeAdapter';
import { IUser } from '@aduvi/types';
import { getFormErrors } from '@aduvi/utils/helper';

import { updateUserData } from 'store/features/auth-slice';
import { setActiveTheme } from 'store/features/common-slice';
import { updateTheme } from 'store/features/user-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const Details = () => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const activeTheme = useActiveTheme();

  const [form] = useForm();
  const { t: translate } = useTranslation();

  const { user, updating } = useAppSelector((state) => state.auth.user);
  const { uploading, themes } = useAppSelector((state) => state.common);

  const onSave = (values: IUser) => {
    if (!user?.id || !selectedBusiness?.id) return;

    const body = {
      ...values,
      profile_picture: user?.profile_picture,
    };

    dispatch(updateUserData({ id: user?.id, body }))
      .unwrap()
      .then(() => {
        if (!form.getFieldValue('partner_theme_id')) return;

        dispatch(updateTheme({ userId: user?.id, businessId: selectedBusiness?.id, partnerThemeId: form.getFieldValue('partner_theme_id') }))
          .unwrap()
          .then((res) => {
            const newActiveTheme = new ThemeAdapter(user, selectedBusiness, res.data);
            dispatch(setActiveTheme(newActiveTheme.getActiveTheme()));
          })
          .catch((error) => form.setFields(getFormErrors(error)));
      })
      .catch((error) => form.setFields(getFormErrors(error)));
  };

  useEffect(() => {
    form.setFieldsValue(user);

    if (activeTheme?.activeThemeId) {
      form.setFieldsValue({ partner_theme_id: activeTheme?.activeThemeId });
    } else if (themes.length > 0) {
      form.setFieldsValue({ partner_theme_id: themes?.[0]?.id });
      const newActiveTheme = new ThemeAdapter(user, selectedBusiness, themes?.[0]);
      dispatch(setActiveTheme(newActiveTheme.getActiveTheme()));
    }
  }, [user?.id, activeTheme?.activeThemeId, themes, selectedBusiness]);

  return (
    <Form layout='vertical' requiredMark={false} form={form} onFinish={onSave}>
      <Form.Item
        name='first_name'
        label={translate('profile.details.firstName')}
        rules={[{ required: true, message: translate('profile.details.firstNameValidation') }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name='last_name'
        label={translate('profile.details.lastName')}
        rules={[{ required: true, message: translate('profile.details.lastNameValidation') }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name='email'
        label={translate('profile.details.email')}
        rules={[{ required: true, message: translate('profile.details.emailValidation'), type: 'email' }]}>
        <Input disabled={true} />
      </Form.Item>
      <Form.Item name='phone_number' label={translate('profile.details.phone')}>
        <PhoneInput inputStyle={{ width: '100%' }} country={'mk'} />
      </Form.Item>
      <Form.Item name='partner_theme_id' label={translate('profile.details.theme')}>
        <Select className='w-full'>
          {themes.map((theme, index) => (
            <Select.Option key={index} value={theme.id}>
              {`${theme.name} `}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Row justify='center'>
        <Button loading={updating || uploading} disabled={updating || uploading} htmlType='submit' type='primary' className='text-center mb-15'>
          {uploading ? translate('common.uploading') : translate('profile.details.save')}
        </Button>
      </Row>
    </Form>
  );
};
