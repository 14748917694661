import { useTranslation } from 'react-i18next';
import { LoginOutlined, MailOutlined } from '@ant-design/icons';

import { Button, Col, Collapse, Form, FormInstance, Input, Row, Select, Typography } from 'antd';

import { UploadImage } from '@aduvi/components/UploadImage/UploadImage';
import Tiptap from '@aduvi/Tiptap/Tiptap';
import { IEmailConnection } from '@aduvi/types/connected-account';
import { transformDefaultMentionToSpan, transformFieldMentionsToSpan } from '@aduvi/utils/helper';

import { useAppSelector } from 'store/hooks';

export const Email = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();
  Form.useWatch(['connection_id', 'message'], form);

  const { mentions } = useAppSelector((state) => state.automation);
  const { emails, loading } = useAppSelector((state) => state.connectedAccount);

  return (
    <>
      <Row style={{ boxShadow: '0px 2px 8px 0px #5194D826', borderRadius: '5px' }}>
        <Col span={2} style={{ background: '#FFF7E6', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} className='flex-center'>
          <MailOutlined style={{ color: '#FA8C16', fontSize: '17px' }} />
        </Col>
        <Col span={22} className='p-15 pb-5'>
          <Row justify={'space-between'} align={'middle'}>
            <Col>
              <Typography.Text>{translate('automations.emailAccount')}</Typography.Text>
            </Col>
            <Col>
              <Button type='link' block>
                {translate('automations.connectNew')} <LoginOutlined />
              </Button>
            </Col>
          </Row>
          <Form.Item name={'connection_id'} className='mt-5'>
            <Select
              className='w-full'
              loading={loading}
              options={[
                { label: translate('automations.systemEmail'), value: 'SYSTEM_EMAIL' },
                ...(emails as IEmailConnection[])?.map((item) => ({
                  label: `${item.name} - ${item.meta_data?.email}`,
                  value: item.id,
                })),
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name={'to'} label={translate('automations.to')} className='mt-20'>
        <Input placeholder={translate('automations.typeOrInsert')} />
      </Form.Item>
      <Collapse
        collapsible='header'
        defaultActiveKey={['1']}
        items={[
          {
            key: '1',
            label: translate('automations.moreSettings'),
            children: (
              <>
                <Form.Item name={['more_settings', 'cc']}>
                  <Input addonBefore='CC' placeholder={translate('automations.typeOrInsert')} />
                </Form.Item>
                <Form.Item name={['more_settings', 'bcc']}>
                  <Input addonBefore='BBC' placeholder={translate('automations.typeOrInsert')} />
                </Form.Item>
                <Form.Item name={['more_settings', 'reply_to']}>
                  <Input addonBefore='Reply-To' placeholder={translate('automations.typeOrInsert')} />
                </Form.Item>
              </>
            ),
          },
        ]}
      />
      <Form.Item name={'subject'} label={translate('automations.subject')} className='mt-20'>
        <Input />
      </Form.Item>

      <Form.Item name='message' label={translate('automations.message')}>
        <Tiptap
          content={transformFieldMentionsToSpan(transformDefaultMentionToSpan(form.getFieldValue('message')), mentions)}
          onUpdate={(content) => form.setFieldValue('message', content.getHTML())}
        />
      </Form.Item>

      <Form.Item name={'attachments'} label={translate('automations.attachments')}>
        <UploadImage name={'photo'} onUploadChange={() => void 0} text={translate('automations.upload')} />
      </Form.Item>
    </>
  );
};
