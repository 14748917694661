import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RightOutlined, UnorderedListOutlined } from '@ant-design/icons';

import { Button, Card, Dropdown, Row } from 'antd';

import { PREDEFINED_QUOTE_FORMATS } from '@aduvi/constants';
import { ICustomFieldViewStyle } from '@aduvi/types';

export const QuoteFieldFormat = ({
  updateStyleForFieldId,
}: {
  updateStyleForFieldId: (newStyle: Partial<ICustomFieldViewStyle['style']>) => void;
}) => {
  const { t: translate } = useTranslation();

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleFormatChange = (format: string) => {
    updateStyleForFieldId({ quoteFormat: format });
    setDropdownVisible(false);
  };

  return (
    <Dropdown
      overlay={
        <Card className='style-modal'>
          <div className='flex flex-direction-column'>
            {PREDEFINED_QUOTE_FORMATS.map((format) => (
              <Button
                type='text'
                key={format.value}
                onClick={() => handleFormatChange(format.value)}
                className='list-format-item cursor-pointer text-left'>
                {format.label}
              </Button>
            ))}
          </div>
        </Card>
      }
      trigger={['click']}
      open={dropdownVisible}
      onOpenChange={(visible) => setDropdownVisible(visible)}>
      <Row>
        <Button icon={<UnorderedListOutlined />} type='text' className='w-full text-left flex align-center'>
          {translate('formatFields.quoteFieldFormat')}
          <RightOutlined className='fs-10' style={{ marginLeft: '20px' }} />
        </Button>
      </Row>
    </Dropdown>
  );
};
